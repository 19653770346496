import React, { FC, useEffect, useState } from 'react';

import {
  Errors,
  useAddBotToOrganizerMutation,
  useBotsListQuery,
} from '@/apolloGenerated';
import { ApolloErrorService, useModal } from '@/shared';
import {
  Button,
  Dialog,
  DialogFooter,
  DropdownItem,
  Select,
  toaster,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface AddOrganizerBotModalProps {
  idOrganizer: number;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    addOrganizerToBot: AddOrganizerBotModalProps;
  }
}
export const AddOrganizerBotModal: FC<
  WithCloseModal<AddOrganizerBotModalProps>
> = ({ idOrganizer, onClose, onSuccess }): JSX.Element => {
  const openCreateBotModal = useModal('createBot');
  const [addBotToOrganizer, { client, data, error, loading }] =
    useAddBotToOrganizerMutation();
  const { data: usersBotsData, loading: loadingBots } = useBotsListQuery({
    variables: { filters: {}, isActive: true },
  });
  const selectItems = usersBotsData?.botsList.rows || [];
  const isHasGroups = !loadingBots && selectItems.length > 0;
  const [idBot, setBotId] = useState<number>();

  const handleSubmit = () => {
    if (!idBot) {
      return;
    }
    addBotToOrganizer({ variables: { idBot, idOrganizer } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: `Организатор прикреплён к боту`,
      });
      client.cache.evict({ fieldName: 'botsList' });
      onSuccess && onSuccess();
      onClose();
    }
    if (
      ApolloErrorService.hasError(
        error?.graphQLErrors,
        Errors.OrganizerAlreadyHasBot,
      )
    ) {
      toaster.error({
        title: 'Организатор уже имеет данного бота',
      });
    } else if (error) {
      toaster.error({
        title:
          'Ошибка прикрепления организатора к боту. Обратитесь за помощью в техподдержку',
      });
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title={`Прикрепить организатора к боту`}
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            isHasGroups ? (
              <Button
                fullWidth
                onClick={handleSubmit}
                disabled={loading || !idBot}>
                Подтверить
              </Button>
            ) : (
              <Button fullWidth onClick={() => openCreateBotModal()}>
                Создать бота
              </Button>
            )
          }
        />
      }>
      {isHasGroups ? (
        <Select
          size="large"
          placeholder="Выберите бота"
          onChange={(e) => setBotId(e as number)}
          renderValue={(val) =>
            selectItems.find((el) => el.id === val)?.name || (val as string)
          }
          hideOnSelect
          fullWidth>
          {selectItems.map(({ id, name }) => (
            <DropdownItem key={id} label={name} value={id} />
          ))}
        </Select>
      ) : (
        <div>
          <Typography variant="body-16" color="on-surface-primary-1">
            В данный момент у вас не создано ни одного бота
          </Typography>
        </div>
      )}
    </Dialog>
  );
};
