import React, { FC, useEffect } from 'react';

import { OrganizerFeatureInput, OrganizerFeatureType } from '@/apolloGenerated';
import { NestedKeys, Spacer, Switch, useForm } from '@letsdance/ui-kit';

export interface OrganizerFeatureFormProps {
  initValues: OrganizerFeatureType;
  onChange(val: OrganizerFeatureInput): void;
  errors?: Record<NestedKeys<OrganizerFeatureInput>, string[]>;
}

export const OrganizerFeatureForm: FC<OrganizerFeatureFormProps> = ({
  initValues,
  onChange,
}) => {
  const { setBatchValues, values } = useForm<OrganizerFeatureInput>(
    console.log,
    initValues,
  );

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div>
      <Switch
        label="Создание реф. ссылок"
        checked={values.createReferral}
        onChange={(e) => setBatchValues({ createReferral: e.target.checked })}
      />
      <Spacer size={8} />
      <Switch
        label="Отображение реф. поступлений"
        checked={values.viewReferral}
        onChange={(e) => setBatchValues({ viewReferral: e.target.checked })}
      />
      <Spacer size={8} />
      <Switch
        label="Оплата по реквизитам"
        checked={values.manualPayment}
        onChange={(e) => setBatchValues({ manualPayment: e.target.checked })}
      />
    </div>
  );
};
