import React, { FC, useState } from 'react';

import { Container, FloatDriver } from '@/shared';
import { UsersControlTable } from '@features/Contact';
import { Button, PlusDenseIcon, Typography } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';
import { CreateOrganizerAdmin } from '@widgets/Organizer';

export interface OrganizerAdminsSectionProps {
  organizerId: number;
}
export const OrganizerAdminsSection: FC<OrganizerAdminsSectionProps> = ({
  organizerId,
}) => {
  const [isOpenAdministratorCreate, setIsOpenAdministratorCreate] =
    useState<boolean>(false);

  const handleCloseFloatDivider = () => {
    setIsOpenAdministratorCreate(false);
  };

  return (
    <>
      <Head
        hideTopSpacing
        title={
          <Typography variant="head-24" color="on-surface-primary-1">
            Администраторы
          </Typography>
        }
        rightSlot={
          <Button
            onClick={() => setIsOpenAdministratorCreate(true)}
            prependIcon={PlusDenseIcon}
            size="small">
            Администратор
          </Button>
        }
      />
      <UsersControlTable organizerId={organizerId} />
      <FloatDriver
        isOpen={isOpenAdministratorCreate}
        onClose={handleCloseFloatDivider}>
        <Container>
          <Head
            title="Создание администратора"
            onBack={handleCloseFloatDivider}
          />
        </Container>
        <CreateOrganizerAdmin
          onClose={handleCloseFloatDivider}
          organizerId={organizerId}
        />
      </FloatDriver>
    </>
  );
};
