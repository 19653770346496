import { FC, useState } from 'react';

import {
  PaymentStatusEnum,
  useFinanceListsCountQuery,
  usePaymentsCountQuery,
  WithdrawalFromEnum,
} from '@/apolloGenerated';
import { Container, useOrganizerId } from '@/shared';
import { AwaitSubmitPaymentsTable, ReceiptsTable } from '@features/Orders';
import { WithdrawTable } from '@features/Withdraw';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export enum FinanceTabs {
  Incoming,
  AwaitIncoming,
  Outcoming,
}
export const FinanceLists: FC = () => {
  const idOrganizer = useOrganizerId()!;
  const { data } = useFinanceListsCountQuery({
    skip: !idOrganizer,
    variables: { idOrganizer },
  });
  const { data: paymentCount } = usePaymentsCountQuery({
    skip: !idOrganizer,
    variables: {
      organizerId: idOrganizer!,
      status: [PaymentStatusEnum.AwaitingConfirmation],
    },
  });
  const receiptCount = data?.paymentsList.count || 0;
  const withdrawCount = data?.withdrawalsByOrganizer.count || 0;
  const awaitPayCount = paymentCount?.paymentsList.count || 0;
  const [tab, setTab] = useState<FinanceTabs>(FinanceTabs.Incoming);

  return (
    <div>
      <Container>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem
            value={FinanceTabs.Incoming}
            label={`Поступления (${receiptCount})`}
          />
          <TabItem
            value={FinanceTabs.AwaitIncoming}
            label={`Ожидают подтверждение (${awaitPayCount})`}
          />
          <TabItem
            value={FinanceTabs.Outcoming}
            label={`Вывод (${withdrawCount})`}
          />
        </Tabs>
      </Container>
      <Divider />
      <Spacer size={12} />
      {tab === FinanceTabs.Incoming && <ReceiptsTable />}
      {tab === FinanceTabs.AwaitIncoming && <AwaitSubmitPaymentsTable />}
      {tab === FinanceTabs.Outcoming && (
        <WithdrawTable from={WithdrawalFromEnum.SalesBalance} />
      )}
    </div>
  );
};
