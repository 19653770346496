import React, { FC } from 'react';

import { BotOrganizerController } from '@features/Bot';
import { Typography } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export interface OrganizerBotsSectionProps {
  organizerId: number;
}
export const OrganizerBotsSection: FC<OrganizerBotsSectionProps> = ({
  organizerId,
}) => (
  <>
    <Head
      hideTopSpacing
      title={
        <Typography variant="head-24" color="on-surface-primary-1">
          Боты
        </Typography>
      }
    />
    <BotOrganizerController organizerId={organizerId} />
  </>
);
