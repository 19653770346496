import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  AddressesListQuery,
  Maybe,
  ProductAddressSortKeys,
  useAddressesListQuery,
} from '@/apolloGenerated';
import { formatSort, renderCellDate, useOrganizerId } from '@/shared';
import { RouterName } from '@app/router';
import {
  ArrayType,
  LoaderOverlay,
  MobileItemConfig,
  SortType,
  TableController,
} from '@letsdance/ui-kit';

import { AddressControl } from '../AddressControl/AddressControl';

const headers = [
  { hide: true, key: true, value: 'uuid' },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 180 },
  { sortable: true, title: 'Дата обновления', value: 'updatedAt', width: 180 },
  { sortable: true, title: 'Адрес', value: 'address' },
  { title: ' ', value: 'settings', width: 48 },
];
const pageSize = 15;

export const AddressesTable: FC = () => {
  const { navigate } = useNavigate();
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<ProductAddressSortKeys>(
    ProductAddressSortKeys.UpdatedAt,
  );

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useAddressesListQuery({
    skip: !organizerId,
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      params: {
        organizerId,
      },
    },
  });
  const data = curData || previousData;
  const rowTemplate = (
    address: AddressesListQuery['addressesList']['rows'][0],
  ) => ({
    address: address.address,
    createdAt: renderCellDate({ timestamp: address.createdAt }),
    settings: <AddressControl uuid={address.uuid} />,
    updatedAt: renderCellDate({ timestamp: address.updatedAt }),
    uuid: address.uuid,
  });

  const items = useMemo(
    () => (data?.addressesList.rows || []).map(rowTemplate),
    [data],
  );

  const mobileItemConfig: MobileItemConfig<ArrayType<typeof items>> = {
    titleSlot: {
      key: 'address',
    },
  };

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        mobileItemConfig={mobileItemConfig}
        total={data?.addressesList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as ProductAddressSortKeys);
        }}
        onClickRow={(e, event) =>
          navigate(RouterName.AddressEdit, { uuid: event.uuid })
        }
        notResetPage
        onRefetch={error && refetch}
      />
    </div>
  );
};
