import { IStep } from '@/shared';
import { IStepperItem } from '@shared/ui/Stepper/StepperNav/StepperNav';

export class StepperHelper {
  public static getWithLabels<T extends string>(
    steps: IStep<T>[],
    labels: Record<T, string>,
  ): IStepperItem<T>[] {
    return steps.map((el) => ({ ...el, label: labels[el.key] }));
  }
}
