import { LoaderOverlay, Spacer } from '@letsdance/ui-kit';

import { IStepperItem, StepperNav } from '../StepperNav/StepperNav';

interface StepperProps<Key extends string> {
  nav: IStepperItem<Key>[];
  contentSlot: JSX.Element;
  actionSlot: JSX.Element;
  onSelectStep(key: Key): void;
  loading?: boolean;
}
export const Stepper = function <Key extends string = string>({
  actionSlot,
  contentSlot,
  loading,
  nav,
  onSelectStep,
}: StepperProps<Key>) {
  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <StepperNav items={nav} onSelect={onSelectStep} />
      <Spacer size={12} />
      <div>{contentSlot}</div>
      <Spacer size={12} />
      <div>{actionSlot}</div>
    </div>
  );
};
