import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import { PropsWithNativeTypes } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ContainerProps {
  // will be deprecated after migrate to new design
  slim?: boolean;
  slimV2?: boolean;
}
export const Container: FC<
  PropsWithChildren<PropsWithNativeTypes<ContainerProps, HTMLDivElement>>
> = ({ children, className, slim, slimV2, ...rest }) => (
  <div
    className={cx(
      styles.container,
      slim && styles.container_slim,
      slimV2 && styles.container_slimV2,
      className,
    )}
    {...rest}>
    {children}
  </div>
);
