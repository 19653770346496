import { FC } from 'react';

import { CreateEventSteps, EventCreateStepper } from '@entities/Event';
import { useCompositeCreateEvent } from '@features/Event';
import { CreateEventMainInfo } from '@widgets/Event';
import { CreateEventTariffs } from '@widgets/Tariff';
import { CreateEventTerminals } from '@widgets/Terminal';

export const EventCreate: FC = () => {
  const { handleChangeStep, handleDraft, handlePublish } =
    useCompositeCreateEvent();

  return (
    <EventCreateStepper
      contents={{
        [CreateEventSteps.MainInfo]: CreateEventMainInfo,
        [CreateEventSteps.PaymentInfo]: CreateEventTerminals,
        [CreateEventSteps.Tariffs]: CreateEventTariffs,
      }}
      onDraft={handleDraft}
      onPublish={handlePublish}
      onChangeStep={handleChangeStep}
    />
  );
};
