import { Route } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { NotFoundView } from '@pages/Static';

export const staticRoutes: Route[] = [
  {
    component: NotFoundView,
    name: RouterName.NotFound,
    path: '(.*)',
  },
];
