import React, { FC } from 'react';

import { Container } from '@/shared';
import { AddressesTable } from '@features/Address';
import { Spacer } from '@letsdance/ui-kit';

export const AddressesLists: FC = () => (
  <div>
    <Container>
      <Spacer size={12} />
      <AddressesTable />
    </Container>
  </div>
);
