import React, { FC, useMemo, useState } from 'react';

import { StatusEnum, useTerminalsCountQuery } from '@/apolloGenerated';
import { Container, ScrollXWrapper } from '@/shared';
import { TerminalsTable } from '@features/Terminal';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export enum TerminalsTabs {
  Archive = 'archive',
  Active = 'active',
}

export const TerminalsLists: FC = () => {
  const [tab, setTab] = useState<TerminalsTabs>(TerminalsTabs.Active);
  const { data: archiveOrganizers } = useTerminalsCountQuery({
    variables: {
      status: [StatusEnum.Archive],
    },
  });
  const { data: activeOrganizers } = useTerminalsCountQuery({
    variables: {
      status: [StatusEnum.Active],
    },
  });

  const status = useMemo(() => {
    switch (tab) {
      case TerminalsTabs.Archive: {
        return [StatusEnum.Archive];
      }
      case TerminalsTabs.Active: {
        return [StatusEnum.Active];
      }
    }
  }, [tab]);

  return (
    <div>
      <Container>
        <ScrollXWrapper>
          <Tabs initValue={tab} onChange={setTab}>
            <TabItem
              value={TerminalsTabs.Active}
              label={`Активные (${activeOrganizers?.terminalsList?.count || 0})`}
            />
            <TabItem
              value={TerminalsTabs.Archive}
              label={`Архивные (${archiveOrganizers?.terminalsList?.count || 0})`}
            />
          </Tabs>
        </ScrollXWrapper>
      </Container>
      <Divider />
      <Container>
        <Spacer size={12} />
        <ScrollXWrapper>
          <TerminalsTable status={status} />
        </ScrollXWrapper>
      </Container>
    </div>
  );
};
