import React, { FC, useEffect, useState } from 'react';

import {
  OrganizerFeatureInput,
  useOrganizerFeaturesQuery,
  useUpdateAdminOrganizerFeaturesMutation,
} from '@/apolloGenerated';
import {
  getOrganizerFeatureInput,
  OrganizerFeatureForm,
} from '@entities/Organizer';
import {
  Button,
  Dialog,
  DialogFooter,
  LoaderOverlay,
  toaster,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface EditFeatureOrganizerModalProps {
  id: number;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    editFeatureOrganizer: EditFeatureOrganizerModalProps;
  }
}
export const EditFeatureOrganizerModal: FC<
  WithCloseModal<EditFeatureOrganizerModalProps>
> = ({ id, onClose, onSuccess }): JSX.Element => {
  const [updateFeatures, { client, data, error, loading: saveLoading }] =
    useUpdateAdminOrganizerFeaturesMutation();
  const { data: currentFeatureData, loading } = useOrganizerFeaturesQuery({
    variables: {
      idOrganizer: +id,
    },
  });
  const [featuresData, setFeaturesData] = useState<OrganizerFeatureInput>();

  const handleSubmit = () => {
    if (!featuresData) {
      return;
    }
    updateFeatures({
      variables: {
        idOrganizer: +id,
        input: getOrganizerFeatureInput(featuresData),
      },
    });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Фича флаги обновлены',
      });
      client.cache.evict({
        fieldName: 'organizerFeatures',
      });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка сохранения. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Фича флаги"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button fullWidth onClick={handleSubmit} disabled={saveLoading}>
                Сохранить
              </Button>
            </div>
          }
        />
      }>
      <LoaderOverlay show={loading} />
      {currentFeatureData && (
        <OrganizerFeatureForm
          initValues={currentFeatureData.organizerFeatures}
          onChange={setFeaturesData}
        />
      )}
    </Dialog>
  );
};
