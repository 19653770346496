import { OrganizerFeatureInput } from '@/apolloGenerated';

export const getOrganizerFeatureInput = ({
  createReferral,
  manualPayment,
  viewReferral,
}: OrganizerFeatureInput): OrganizerFeatureInput => ({
  createReferral,
  manualPayment,
  viewReferral,
});
