import { Route } from 'react-justanother-router';

import { RoleEnum } from '@/apolloGenerated';
import { RouterName } from '@app/router';
import {
  AddressCreateView,
  AddressEditView,
  AddressesView,
} from '@pages/Address';
import { AuthView } from '@pages/Auth';
import { BotsView } from '@pages/Bot';
import { ContactEditView, ContactView } from '@pages/Contact';
import { EventCreateView, EventEditView, EventsView } from '@pages/Event';
import { CreateWithdrawView, FinanceView, PurchasesView } from '@pages/Finance';
import {
  OrganizerCreateView,
  OrganizerEditView,
  OrganizersView,
  ProfileOrganizerView,
} from '@pages/Organizer';
import { CreateReferralWithdrawView, ReferralView } from '@pages/Referral';
import {
  BaseTariffCreateView,
  BaseTariffEditView,
  BaseTariffsView,
} from '@pages/Tariff';
import {
  TerminalCreateView,
  TerminalEditView,
  TerminalsView,
} from '@pages/Terminal';
import { AuthLayout, UserLayout } from '@widgets/Layout';

export const adminRoutes: Route[] = [
  {
    component: AuthView,
    name: RouterName.Authorization,
    path: '/',
    props: {
      layout: AuthLayout,
    },
  },
  {
    children: [
      {
        component: EventEditView,
        name: RouterName.EventEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
      {
        component: EventCreateView,
        name: RouterName.EventCreate,
        path: '/create',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: EventsView,
    name: RouterName.Events,
    path: '/events',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: BaseTariffEditView,
        name: RouterName.BaseTariffEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
      {
        component: BaseTariffCreateView,
        name: RouterName.BaseTariffCreate,
        path: '/create',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: BaseTariffsView,
    name: RouterName.BaseTariffs,
    path: '/tariffs',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: ContactEditView,
        name: RouterName.ContactEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: ContactView,
    name: RouterName.Contact,
    path: '/contacts',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    component: PurchasesView,
    name: RouterName.Purchases,
    path: '/purchases',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: CreateWithdrawView,
        name: RouterName.WithdrawCreate,
        path: '/withdraw',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: FinanceView,
    name: RouterName.Finance,
    path: '/finances',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: CreateReferralWithdrawView,
        name: RouterName.ReferralWithdrawCreate,
        path: '/withdraw',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: ReferralView,
    name: RouterName.Referral,
    path: '/referral',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    component: ProfileOrganizerView,
    name: RouterName.Profile,
    path: '/profile',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: OrganizerEditView,
        name: RouterName.OrganizerEdit,
        path: '/edit/:id',
        props: {
          accessRole: [RoleEnum.Admin],
          layout: UserLayout,
        },
      },
      {
        component: OrganizerCreateView,
        name: RouterName.OrganizerCreate,
        path: '/create',
        props: {
          accessRole: [RoleEnum.Admin],
          layout: UserLayout,
        },
      },
    ],
    component: OrganizersView,
    name: RouterName.Organizers,
    path: '/organizers',
    props: {
      accessRole: [RoleEnum.Admin],
      layout: UserLayout,
    },
  },
  {
    component: BotsView,
    name: RouterName.Bots,
    path: '/bots',
    props: {
      accessRole: [RoleEnum.Admin],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: TerminalEditView,
        name: RouterName.TerminalEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: [RoleEnum.Admin],
          layout: UserLayout,
        },
      },
      {
        component: TerminalCreateView,
        name: RouterName.TerminalCreate,
        path: '/:idOrganizer/create',
        props: {
          accessRole: [RoleEnum.Admin],
          layout: UserLayout,
        },
      },
    ],
    component: TerminalsView,
    name: RouterName.Terminals,
    path: '/terminals',
    props: {
      accessRole: [RoleEnum.Admin],
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: AddressEditView,
        name: RouterName.AddressEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
      {
        component: AddressCreateView,
        name: RouterName.AddressCreate,
        path: '/create',
        props: {
          accessRole: [RoleEnum.Organizer],
          layout: UserLayout,
        },
      },
    ],
    component: AddressesView,
    name: RouterName.Addresses,
    path: '/addresses',
    props: {
      accessRole: [RoleEnum.Organizer],
      layout: UserLayout,
    },
  },
];
