import { useMemo } from 'react';

import {
  StatusEnum,
  TerminalEnum,
  TerminalType,
  useTerminalsByProductQuery,
  useTerminalsQuery,
  useUpdateProductTerminalsMutation,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';

export const useOnlineTerminal = (
  uuidEvent: string,
): [
  (terminals: TerminalEnum[]) => void,
  {
    terminals: TerminalType[];
    currentTerminals: TerminalEnum[];
    loading: boolean;
  },
] => {
  const idOrganizer = useOrganizerId()!;
  const [updateProductTerminals] = useUpdateProductTerminalsMutation();
  const { data: allTerminals, loading: loadingTerminals } = useTerminalsQuery({
    variables: {
      idOrganizer,
      status: [StatusEnum.Active],
    },
  });
  const {
    client,
    data: curTerminals,
    loading: loadingCurrentTerminals,
  } = useTerminalsByProductQuery({
    variables: {
      uuidProduct: uuidEvent,
    },
  });

  const handleUpdateTerminals = async (terminalsType: TerminalEnum[]) => {
    await updateProductTerminals({
      variables: {
        uuidProduct: uuidEvent,
        uuidTerminals:
          allTerminals?.terminalsList.rows
            .filter((el) => terminalsType.includes(el.type))
            .map((el) => el.uuid) || [],
      },
    });
    client.cache.evict({ fieldName: 'terminalsByProduct' });
  };

  return [
    handleUpdateTerminals,
    {
      currentTerminals: useMemo(
        () => curTerminals?.terminalsByProduct.map((el) => el.type) || [],
        [curTerminals],
      ),
      loading: loadingTerminals || loadingCurrentTerminals,
      terminals: useMemo(
        () => (allTerminals?.terminalsList.rows as TerminalType[]) || [],
        [allTerminals],
      ),
    },
  ];
};
