import React, { FC, useContext } from 'react';

import { usePurchaseStatsQuery } from '@/apolloGenerated';
import { StatItem, StatWrap, useOrganizerId } from '@/shared';
import { PurchasesContext, PurchasesContextType } from '@entities/Purchases';
import { Dropdown, InfoDenseIcon, Paper, stopEvent } from '@letsdance/ui-kit';
import { useFeatureFlags } from '@shared/libs/hooks/useFeatureFlags';

import styles from './styles.module.scss';

export const StatsOrdersDropdown: FC = () => {
  const { productUuid } = useContext<PurchasesContextType>(PurchasesContext);
  const organizerId = useOrganizerId()!;
  const { data } = usePurchaseStatsQuery({
    skip: !organizerId,
    variables: { organizerId, uuidProduct: productUuid },
  });
  const { commission, createReferral } = useFeatureFlags();

  return (
    <Dropdown
      width={250}
      targetSlot={
        <InfoDenseIcon className={styles.statsOrdersIcon} onClick={stopEvent} />
      }>
      <Paper px={4} py={4} radius={6} className="paper-list">
        <div className={styles.statsOrders}>
          <StatWrap>
            <StatItem
              label="Всего получено, ₽"
              value={data?.statsOrders?.turnover || 0}
            />
          </StatWrap>
          <StatWrap>
            <StatItem
              label="Прибыль, ₽"
              value={data?.statsOrders?.profit || 0}
            />
          </StatWrap>
          {commission && (
            <StatWrap>
              <StatItem
                label="Комиссия платформы, ₽"
                value={data?.statsOrders?.commission || 0}
              />
            </StatWrap>
          )}
          {createReferral && (
            <StatWrap>
              <StatItem
                label="Реферальные отчисления, ₽"
                value={data?.statsOrders?.referral || 0}
              />
            </StatWrap>
          )}
        </div>
      </Paper>
    </Dropdown>
  );
};
