import { gql } from '@apollo/client';

export const userGroupFragment = gql`
  fragment UserGroupFields on UserGroupType {
    createdAt
    updatedAt
    uuid
    name
    usersCount
  }
`;

export const userListFragment = gql`
  fragment UserListFields on UsersResponse {
    rows {
      createdAt
      uuid
      first_name
      last_name
      username
      phone
      email
      gender
      statsPayments {
        turnover
      }
      activatedOrdersCount
      ordersCount
      balance(idOrganizer: $organizerId)
      isBlocked(idOrganizer: $organizerId)
      hasInviteByProduct(productUuid: $productUuid)
      hasOrderByProduct(productUuid: $productUuid)
    }
    count
  }
`;

export const usersList = gql`
  ${userListFragment}
  query usersList(
    $filters: UserListFilterInput
    $organizerId: Int!
    $isActive: Boolean
    $productUuid: UUID
  ) {
    users(
      filters: $filters
      organizerId: $organizerId
      args: { isActive: $isActive }
    ) {
      ...UserListFields
    }
  }
`;

export const usersBlockedList = gql`
  ${userListFragment}
  query usersBlockedList(
    $filters: UserListFilterInput
    $organizerId: Int!
    $productUuid: UUID
  ) {
    blacklist(organizerId: $organizerId, filters: $filters) {
      ...UserListFields
    }
  }
`;

export const usersRegisteredList = gql`
  ${userListFragment}
  query usersRegisteredList(
    $filters: UserListFilterInput
    $organizerId: Int!
    $productUuid: UUID
  ) {
    users(
      filters: $filters
      organizerId: $organizerId
      args: { isRegistered: true }
    ) {
      ...UserListFields
    }
  }
`;

export const usersByGroupList = gql`
  ${userListFragment}
  query usersByGroupList(
    $filters: UserListFilterInput!
    $groupUuid: UUID!
    $organizerId: Int!
    $productUuid: UUID
  ) {
    usersByGroup(filters: $filters, groupUuid: $groupUuid) {
      ...UserListFields
    }
  }
`;

export const usersRegisteredCount = gql`
  query usersRegisteredCount($organizerId: Int!) {
    users(
      filters: { pagination: { page: 1, pageSize: 1 } }
      organizerId: $organizerId
      args: { isRegistered: true }
    ) {
      count
    }
  }
`;

export const usersBlockedCount = gql`
  query usersBlockedCount($organizerId: Int!) {
    blacklist(
      organizerId: $organizerId
      filters: { pagination: { page: 1, pageSize: 1 } }
    ) {
      count
    }
  }
`;

export const userGroups = gql`
  ${userGroupFragment}
  query userGroups($filters: UserGroupsListFilterInput, $idOrganizer: Int!) {
    userGroups(filters: $filters, idOrganizer: $idOrganizer) {
      rows {
        ...UserGroupFields
      }
      count
    }
  }
`;

export const addGroupUsers = gql`
  ${userGroupFragment}
  mutation addGroupUsers($idOrganizer: Int!, $name: String!) {
    crateUserGroup(idOrganizer: $idOrganizer, name: $name) {
      ...UserGroupFields
    }
  }
`;

export const updateGroupUsersName = gql`
  ${userGroupFragment}
  mutation updateGroupUsersName($uuid: UUID!, $name: String!) {
    updateUserGroupName(uuid: $uuid, name: $name) {
      ...UserGroupFields
    }
  }
`;

export const removeGroupUsers = gql`
  mutation removeGroupUsers($uuid: UUID!) {
    removeUserGroup(uuid: $uuid)
  }
`;

export const addUserToGroup = gql`
  mutation addUserToGroup($uuidUser: UUID!, $uuidGroup: UUID!) {
    addUserToGroup(uuidUser: $uuidUser, uuidGroup: $uuidGroup)
  }
`;

export const removeUserFromGroup = gql`
  mutation removeUserFromGroup($uuidUser: UUID!, $uuidGroup: UUID!) {
    removeUserFromGroup(uuidUser: $uuidUser, uuidGroup: $uuidGroup)
  }
`;

export const blockUser = gql`
  mutation blockUser($organizerId: Float!, $userUuid: UUID!) {
    addUserToBlacklist(organizerId: $organizerId, uuid: $userUuid)
  }
`;

export const unblockUser = gql`
  mutation unblockUser($organizerId: Float!, $userUuid: UUID!) {
    removeUserFromBlacklist(organizerId: $organizerId, uuid: $userUuid)
  }
`;

export const updateUsersInGroup = gql`
  mutation updateUsersInGroup($uuidGroup: UUID!, $uuidUsers: [UUID!]!) {
    updateUsersInGroup(uuidGroup: $uuidGroup, uuidUsers: $uuidUsers)
  }
`;
