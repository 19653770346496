import Decimal from 'decimal.js-light';

import { Maybe, SortEnum, StatusEnum } from '@/apolloGenerated';
import { SortType } from '@letsdance/ui-kit';

export const formatEmpty = (
  value: string | number | null | undefined,
  renderedValue: string | number,
): string | number => (value ? renderedValue : '-');
export const formatAmount = (number: number, fixed?: boolean): string =>
  Intl.NumberFormat('ru-Ru', {
    currency: 'RUB',
    ...(fixed
      ? { maximumFractionDigits: 0 }
      : { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
  }).format(number);
export const formatSort = (sort: Maybe<SortType>): SortEnum | undefined => {
  if (!sort) {
    return undefined;
  }

  return sort === 'asc' ? SortEnum.Asc : SortEnum.Desc;
};

export const readableStatus = (status: StatusEnum) => {
  switch (status) {
    case StatusEnum.Active: {
      return 'Активный';
    }
    case StatusEnum.Archive: {
      return 'Архивный';
    }
    case StatusEnum.Draft: {
      return 'Черновик';
    }
  }
};

interface CalculateAmountsParams {
  price: number;
  holdPercentage: number;
  referralPercentage?: number;
}
export const calculateAmounts = ({
  holdPercentage,
  price,
  referralPercentage,
}: CalculateAmountsParams) => {
  const commissionValue = new Decimal(holdPercentage).div(100);
  const referralValue = new Decimal(referralPercentage || 0).div(100);
  const amount = new Decimal(price);
  const commission = amount.mul(commissionValue);
  const referral = referralPercentage
    ? amount.minus(commission).mul(referralValue)
    : new Decimal(0);
  const profit = amount.minus(commission).minus(referral);

  return {
    commission: commission.toNumber(),
    profit: profit.toNumber(),
    referral: referral.toNumber(),
    turnover: amount.toNumber(),
  };
};

export const declension = function (
  number: number,
  words: [string, string, string],
): string {
  const cases = [2, 0, 1, 1, 1, 2];

  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};
