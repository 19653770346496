import React, { FC, useEffect } from 'react';

import { useRemoveReferralOrganizerMutation } from '@/apolloGenerated';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface RemoveReferralModalProps {
  code: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    removeReferral: RemoveReferralModalProps;
  }
}
export const RemoveReferralModal: FC<
  WithCloseModal<RemoveReferralModalProps>
> = ({ code, onClose, onSuccess }): JSX.Element => {
  const [removeReferral, { client, data, error, loading }] =
    useRemoveReferralOrganizerMutation();

  const handleSubmit = () => {
    removeReferral({ variables: { key: code } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Реферальная ссылка успешно удалена',
      });
      client.cache.evict({ fieldName: 'referralsOrganizerList' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка удаления. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Удалить реферальную ссылку?"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button
                color="danger"
                fullWidth
                onClick={handleSubmit}
                disabled={loading}>
                Удалить
              </Button>
            </div>
          }
        />
      }>
      <Typography variant="body-16" color="on-surface-primary-1">
        Восстановить удаленную ссылку будет невозможно. Реферальная программа
        после удаления действовать не будет!
      </Typography>
    </Dialog>
  );
};
