import { SubTariffInput } from '@/apolloGenerated';

export const getTariffInput = ({
  description,
  endDate,
  isLimitedOrders,
  name,
  ordersCount,
  price,
  query,
  specialPrice,
  startedDate,
  visible,
}: SubTariffInput) => ({
  description,
  endDate,
  isLimitedOrders,
  name,
  ordersCount,
  price,
  query,
  specialPrice,
  startedDate,
  visible,
});

export const getTariffInitState = (): SubTariffInput => ({
  description: null,
  endDate: null,
  isLimitedOrders: null,
  name: '',
  ordersCount: null,
  price: 0,
  query: null,
  specialPrice: null,
  startedDate: null,
  visible: null,
});
