import { useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import { ProductMainInfoInput } from '@/apolloGenerated';
import { useValidate } from '@/shared';
import { RouterName } from '@app/router';
import {
  CreateEventSteps,
  eventMainInfoSchema,
  getEventMainInfoInput,
  useCreateEventStepperStore,
  useEventMainInfoStore,
} from '@entities/Event';
import { useEventTariffsStore } from '@entities/Tariff';
import {
  createEventTariffsSchema,
  EventTariffsValidationObject,
} from '@entities/Tariff/const';
import {
  createEventTerminalsSchema,
  EventTerminalsValidationObject,
  useEventTerminalsStore,
} from '@entities/Terminal';

import { useCreateEvent } from './useCreateEvent';
import { usePublishEvent } from './usePublishEvent';
import { useUpdateEventMainInfo } from './useUpdateEventMainInfo';

export const useCompositeCreateEvent = () => {
  const { navigate } = useNavigate();
  const { currentStep, resetStepper } = useCreateEventStepperStore();
  const {
    clearErrors: clearMainInfoErrors,
    fields,
    reset: resetMainInfo,
    setErrors: setMainInfoErrors,
    setUuid: setEventUuid,
    uuid: eventUuid,
  } = useEventMainInfoStore();
  const {
    clearErrors: clearEventTariffsErrors,
    reset: resetTariffs,
    setErrors: setEventTariffsErrors,
    tariffs,
  } = useEventTariffsStore();
  const {
    clearErrors: clearEventTerminalsErrors,
    reset: resetTerminals,
    setErrors: setEventTerminalsErrors,
    terminals,
  } = useEventTerminalsStore();
  const [validateMainInfo] =
    useValidate<ProductMainInfoInput>(eventMainInfoSchema);
  const [validateTariffs] = useValidate<EventTariffsValidationObject>(
    createEventTariffsSchema,
  );
  const [validateTerminals] = useValidate<EventTerminalsValidationObject>(
    createEventTerminalsSchema,
  );
  const baseActionSettings = {
    notify: false,
    resetCache: true,
  };
  const [{ handler: handleCreateEvent }] = useCreateEvent({
    publish: false,
    ...baseActionSettings,
  });
  const [{ handler: handleUpdateEvent }] =
    useUpdateEventMainInfo(baseActionSettings);
  const [{ handler: handlePublishEvent }] = usePublishEvent(baseActionSettings);

  useEffect(
    () => () => {
      resetStepper();
      resetMainInfo();
      resetTariffs();
      resetTerminals();
    },
    [],
  );

  const handleMainInfo = async () => {
    const input = getEventMainInfoInput(fields);
    const { errors } = await validateMainInfo(input);

    clearMainInfoErrors();
    if (errors) {
      setMainInfoErrors(errors);

      return false;
    }

    if (eventUuid) {
      const { data } = await handleUpdateEvent({
        input,
        uuid: eventUuid,
      });

      return !!data?.updateMainInfoProduct;
    }
    const { data } = await handleCreateEvent({
      input,
    });

    if (data?.createProduct) {
      setEventUuid(data.createProduct.uuid);
    }

    return !!data?.createProduct;
  };

  const handleTariffs = async () => {
    const { errors } = await validateTariffs({ tariffs });

    clearEventTariffsErrors();
    if (errors?.tariffs) {
      setEventTariffsErrors(errors.tariffs);

      return false;
    }

    return true;
  };

  const handlePayments = async () => {
    const { errors } = await validateTerminals({ terminals });

    clearEventTerminalsErrors();
    if (errors?.terminals) {
      setEventTerminalsErrors(errors.terminals);

      return false;
    }

    return true;
  };

  const handleDraft = async () => {
    let isCanDraft = true;

    if (currentStep.key === CreateEventSteps.MainInfo) {
      isCanDraft = await handleMainInfo();
    }
    if (isCanDraft) {
      navigate(RouterName.Events);
    }
  };

  const handlePublish = async () => {
    const isCanPublish = await handlePayments();

    if (isCanPublish && eventUuid) {
      await handlePublishEvent({
        uuid: eventUuid,
      });
      navigate(RouterName.Events);
    }
  };

  const handleChangeStep = async () => {
    switch (currentStep.key) {
      case CreateEventSteps.MainInfo: {
        return handleMainInfo();
      }
      case CreateEventSteps.Tariffs: {
        return handleTariffs();
      }
    }

    return true;
  };

  return {
    handleChangeStep,
    handleDraft,
    handlePublish,
  };
};
