import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const RobokassaIcon: FC<IconProps> = ({
  height = 24,
  width = 24,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}>
    <path d="M1 23L1 1L12 12L23 23L1 23Z" fill="#FF5722" />
    <path d="M1 1H23L12 12L1 23V1Z" fill="#013D5E" />
  </svg>
);
