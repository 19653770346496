import { FC, PropsWithChildren } from 'react';

export interface DisplayWrapperProps {
  visible: boolean;
}

export const DisplayWrapper: FC<PropsWithChildren<DisplayWrapperProps>> = ({
  children,
  visible,
}) => <div style={{ display: visible ? 'block' : 'none' }}>{children}</div>;
