import { FC } from 'react';

import { useEventMainInfoStore } from '@entities/Event';
import { useEventTerminalsStore } from '@entities/Terminal';
import { EventTerminals } from '@features/Terminal';

export const CreateEventTerminals: FC = () => {
  const { uuid } = useEventMainInfoStore();
  const { errors, setTerminals } = useEventTerminalsStore();

  if (!uuid) {
    throw new Error('Event not was created');
  }

  return (
    <EventTerminals eventUuid={uuid} errors={errors} onChange={setTerminals} />
  );
};
