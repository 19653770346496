import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  Maybe,
  StatusEnum,
  TerminalSortKeys,
  TerminalsQuery,
  useTerminalsQuery,
} from '@/apolloGenerated';
import {
  createTgLink,
  formatEmpty,
  formatSort,
  renderCellDate,
  renderCellItemValue,
} from '@/shared';
import { RouterName } from '@app/router';
import { TerminalControl } from '@features/Terminal';
import {
  LoaderOverlay,
  SortType,
  stopEvent,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

const headers = [
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 180 },
  { hide: true, key: true, title: '', value: 'uuid' },
  { sortable: true, title: 'Название', value: 'name' },
  { title: 'Организатор', value: 'organizer' },
  { sortable: true, title: 'Процент удерж.', value: 'holdPercentage' },
  { sortable: true, title: 'Тип', value: 'type' },
  { title: '', value: 'control', width: 60 },
];

const pageSize = 15;
const DEFAULT_PAGE = 1;

export interface TerminalsTableProps {
  status: StatusEnum[];
}

export const TerminalsTable: FC<TerminalsTableProps> = ({ status }) => {
  const { navigate } = useNavigate();
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<TerminalSortKeys>(
    TerminalSortKeys.UpdatedAt,
  );

  useEffect(() => {
    if (status) {
      setPage(DEFAULT_PAGE);
    }
  }, [status]);

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useTerminalsQuery({
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      status,
    },
  });

  const data = curData || previousData;

  const rowTemplate = (
    terminal: TerminalsQuery['terminalsList']['rows'][0],
  ) => ({
    control:
      terminal.status === StatusEnum.Active ? (
        <TerminalControl uuid={terminal.uuid} />
      ) : (
        ' '
      ),
    createdAt: renderCellDate({ timestamp: terminal.createdAt }),
    holdPercentage: formatEmpty(
      terminal.holdPercentage,
      `${terminal.holdPercentage}%`,
    ),
    name: renderCellItemValue({
      label: `${terminal.name} ${terminal.token ? '(Кастомный терминал)' : ''}`,
      value: terminal.label,
    }),
    organizer: renderCellItemValue({
      label: terminal.organizer.name,
      value: terminal.organizer.telegram ? (
        <Typography
          className="cursor-pointer"
          onClick={(e) => {
            stopEvent(e);
            window.open(createTgLink(terminal.organizer.telegram), '_blank');
          }}
          variant="body-14"
          color="primary-1">
          {`@${terminal.organizer.telegram}`}
        </Typography>
      ) : (
        '-'
      ),
    }),
    type: terminal.type,
    uuid: terminal.uuid,
  });
  const items = useMemo(
    () => (data?.terminalsList?.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.terminalsList?.count}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as TerminalSortKeys);
        }}
        onClickRow={(_, el) => {
          navigate(RouterName.TerminalEdit, { uuid: el.uuid });
        }}
        notResetPage
        onRefetch={error && refetch}
        hasError={!!error}
      />
    </div>
  );
};
