import { FC } from 'react';

import { useManualTerminal } from '@features/Terminal';
import { LoaderOverlay, Spacer, Switch, TextField } from '@letsdance/ui-kit';
import { useFeatureFlags } from '@shared/libs/hooks/useFeatureFlags';

export interface EventManualTerminalControllerProps {
  productUuid: string;
  disabled?: boolean;
}
export const EventManualTerminalController: FC<
  EventManualTerminalControllerProps
> = ({ disabled, productUuid }) => {
  const { manualPayment } = useFeatureFlags();
  const [{ updateActive, updateText }, { active, loading, text }] =
    useManualTerminal(productUuid);

  if (!manualPayment) {
    return <></>;
  }

  return (
    <div>
      <LoaderOverlay show={loading} />
      <Switch
        checked={active}
        label="Онлайн-платеж по реквизитам"
        onChange={(e) => updateActive(e.target.checked)}
        disabled={disabled}
      />
      {active && (
        <>
          <Spacer size={6} />
          <TextField
            tag="textarea"
            label="Инструкция для онлайн оплаты"
            size="large"
            style={{ height: 200 }}
            disabled={disabled}
            fullWidth
            initValue={text || ''}
            onChange={(e) => updateText(e.target.value || null)}
          />
        </>
      )}
    </div>
  );
};
