import { createElement, FC } from 'react';
import cx from 'classnames';

import { IconProps, Switch, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ExtendedSwitchProps {
  icon: FC<IconProps>;
  label: string;
  checked: boolean;
  hasError?: boolean;
  onChange(val: boolean): void;
  bottomSlot?: JSX.Element;
}
export const ExtendedSwitch: FC<ExtendedSwitchProps> = ({
  bottomSlot,
  checked,
  hasError,
  icon,
  label,
  onChange,
}) => (
  <div
    className={cx(
      styles.extendedSwitch,
      hasError && styles.extendedSwitch_error,
    )}>
    <div className={styles.extendedSwitch__wrap}>
      {createElement(icon, {
        className: styles.extendedSwitch__icon,
        height: 24,
        width: 24,
      })}
      <Typography variant="body-16" color="on-surface-primary-1">
        {label}
      </Typography>
      <Switch checked={checked} onChange={(e) => onChange(e.target.checked)} />
    </div>
    {bottomSlot}
  </div>
);
