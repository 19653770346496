import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { BaseBotType, Maybe } from '@/apolloGenerated';

interface OrganizerBotStoreState {
  allOrganizerBots: BaseBotType[];
  selectedOrganizerBot: Maybe<BaseBotType>;
}

interface OrganizerBotStoreType extends OrganizerBotStoreState {
  selectOrganizerBot: (bot: BaseBotType) => void;
  resetOrganizerBot: () => void;
  initOrganizerBotStore: (bots: BaseBotType[]) => void;
}

const initState: OrganizerBotStoreState = {
  allOrganizerBots: [],
  selectedOrganizerBot: null,
};

export const useOrganizerBotStore = create<OrganizerBotStoreType>()(
  persist(
    (set, get) => ({
      ...initState,
      initOrganizerBotStore: (bots: BaseBotType[]) => {
        const { resetOrganizerBot } = get();

        if (bots.length === 0) {
          resetOrganizerBot();

          return;
        }
        set({
          allOrganizerBots: bots,
        });
        const { selectOrganizerBot, selectedOrganizerBot } = get();
        const isSavedBotActive =
          selectedOrganizerBot &&
          bots.some((b) => b.id === selectedOrganizerBot?.id);

        if (!isSavedBotActive) {
          selectOrganizerBot(bots[0]);
        }
      },
      resetOrganizerBot: () => {
        set({
          selectedOrganizerBot: null,
        });
      },
      selectOrganizerBot: (bot: BaseBotType) => {
        set({
          selectedOrganizerBot: bot,
        });
      },
    }),
    {
      name: 'organizerBot',
      partialize: (state) => ({
        selectedOrganizerBot: state.selectedOrganizerBot,
      }),
      version: 0,
    },
  ),
);
