import React, { FC, useEffect } from 'react';

import {
  ReferralOrganizerInput,
  useCreateReferralOrganizerMutation,
  useOrganizersReferralListQuery,
} from '@/apolloGenerated';
import {
  ApolloErrorService,
  handleNumberInput,
  useOrganizerId,
} from '@/shared';
import {
  Button,
  Dialog,
  DialogFooter,
  DropdownItem,
  Select,
  Spacer,
  TextField,
  toaster,
  useForm,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface CreateReferralModalProps {
  uuidProduct: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    createReferral: CreateReferralModalProps;
  }
}
export const CreateReferralModal: FC<
  WithCloseModal<CreateReferralModalProps>
> = ({ onClose, onSuccess, uuidProduct }): JSX.Element => {
  const organizerId = useOrganizerId()!;
  const [createReferral, { client, data, error, loading }] =
    useCreateReferralOrganizerMutation();
  const errors = ApolloErrorService.getFirstError(error?.graphQLErrors)
    ?.extensions?.validationErrors;
  const { data: organizersData } = useOrganizersReferralListQuery();
  const selectItems = (organizersData?.organizersList.rows || []).filter(
    (el) => +el.id !== organizerId,
  );

  const { onChange, setBatchValues, values } = useForm<
    Partial<ReferralOrganizerInput>
  >(console.log, {
    uuidProduct,
  });
  const disableBtn = !values.name || !values.idOrganizer || !values.percentage;

  const handleSubmit = () => {
    createReferral({ variables: { input: values as ReferralOrganizerInput } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Реферальная ссылка успешно создана',
      });
      client.cache.evict({ fieldName: 'referralsOrganizerList' });
      onSuccess && onSuccess();
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Создание реферальной ссылки"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            <Button
              loading={loading}
              disabled={disableBtn}
              fullWidth
              onClick={() => handleSubmit()}>
              Создать
            </Button>
          }
        />
      }>
      <TextField
        label="Название"
        size="large"
        fullWidth
        error={errors?.name}
        name="name"
        initValue={values.name}
        onChange={onChange}
      />
      <Spacer size={10} />
      <TextField
        label="Процент удержания"
        size="large"
        fullWidth
        error={errors?.percentage}
        name="address"
        initValue={String(values.percentage || '')}
        onChange={(e) => setBatchValues({ percentage: +e.target.value || 1 })}
        onKeyPress={handleNumberInput}
      />
      <Spacer size={10} />
      <Select
        size="large"
        placeholder="Выберите организатора"
        onChange={(e) => setBatchValues({ idOrganizer: +e })}
        renderValue={(val) =>
          selectItems.find((el) => el.id === val)?.name || (val as string)
        }
        hideOnSelect
        fullWidth>
        {selectItems.map(({ id, name }) => (
          <DropdownItem key={id} label={name} value={id} />
        ))}
      </Select>
    </Dialog>
  );
};
