import { IStep } from '@/shared';

import { StepperNavItem } from '../StepperNavItem/StepperNavItem';

import styles from './styles.module.scss';

export interface IStepperItem<Key = string> extends IStep<Key> {
  label: string;
}
interface StepperNavProps<Key> {
  items: IStepperItem<Key>[];
  onSelect?(key: Key): void;
}
export const StepperNav = function <Key extends string>({
  items,
  onSelect,
}: StepperNavProps<Key>) {
  return (
    <div className={styles.stepperNav}>
      {items.map((item, i) => (
        <StepperNavItem
          key={item.key}
          className={styles.stepperNav__item}
          label={item.label}
          index={i + 1}
          disabled={!item.isCompleted}
          completed={item.isCompleted}
          active={item.isCurrent}
          onClick={() => onSelect && onSelect(item.key)}
        />
      ))}
    </div>
  );
};
