import { FC, useState } from 'react';

import { Container, FloatDriver } from '@/shared';
import { PurchasesProvider } from '@entities/Purchases';
import { PurchasesExportCsvButton } from '@features/Purchases/ui/PurchasesExportCsvButton/PurchasesExportCsvButton';
import { BagIcon, Button, Spacer, StarIcon } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';
import {
  GiveOrderUsers,
  InviteOrderUsers,
  PurchasesLists,
  PurchasesProductPicker,
  PurchasesStats,
} from '@widgets/Purchases';

import styles from './styles.module.scss';

export const PurchasesView: FC = () => {
  const [isOpenInviteScene, setIsOpenInviteScene] = useState<boolean>(false);
  const [isOpenCreateOrderScene, setIsOpenCreateOrderScene] =
    useState<boolean>(false);

  return (
    <PurchasesProvider>
      <Container>
        <Head
          title="Билеты"
          rightSlot={
            <div className={styles.purchasesView__btns}>
              <Button
                variant="outlined"
                prependIcon={StarIcon}
                onClick={() => setIsOpenInviteScene(true)}>
                Пригласить
              </Button>
              <Button
                variant="outlined"
                prependIcon={BagIcon}
                onClick={() => setIsOpenCreateOrderScene(true)}>
                Оформить билет
              </Button>
              <PurchasesExportCsvButton />
            </div>
          }
        />
      </Container>
      <Container>
        <PurchasesProductPicker />
        <Spacer size={12} />
        <PurchasesStats />
      </Container>
      <Spacer size={12} />
      <PurchasesLists />
      <FloatDriver
        isOpen={isOpenCreateOrderScene}
        onClose={() => setIsOpenCreateOrderScene(false)}>
        <GiveOrderUsers onCancel={() => setIsOpenCreateOrderScene(false)} />
      </FloatDriver>
      <FloatDriver
        isOpen={isOpenInviteScene}
        onClose={() => setIsOpenInviteScene(false)}>
        <InviteOrderUsers onCancel={() => setIsOpenInviteScene(false)} />
      </FloatDriver>
    </PurchasesProvider>
  );
};
