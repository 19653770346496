import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { ProductMainInfoInput } from '@/apolloGenerated';
import { Maybe, NestedKeys } from '@letsdance/ui-kit';

import { getEventMainInfoInitState } from '../utils';

interface EventMainInfoStoreState {
  uuid: Maybe<string>;
  fields: ProductMainInfoInput;
  errors: Partial<Record<NestedKeys<ProductMainInfoInput>, string[]>>;
}

interface EventMainInfoStoreType extends EventMainInfoStoreState {
  updateField: <K extends keyof ProductMainInfoInput>(
    key: K,
    value: ProductMainInfoInput[K],
  ) => void;
  setFields: (data: ProductMainInfoInput) => void;
  setUuid: (uuid: string) => void;
  clearErrors: () => void;
  reset: () => void;
  setErrors: (
    errors: Partial<Record<NestedKeys<ProductMainInfoInput>, string[]>>,
  ) => void;
}

const initState: EventMainInfoStoreState = {
  errors: {},
  fields: getEventMainInfoInitState(),
  uuid: null,
};

export const useEventMainInfoStore = create<EventMainInfoStoreType>()(
  immer((set) => ({
    ...initState,
    clearErrors: () =>
      set((state) => {
        state.errors = {};
      }),
    reset: () => set(() => initState),
    setErrors: (errors) =>
      set((state) => {
        state.errors = errors;
      }),
    setFields: (data) =>
      set((state) => {
        state.fields = data;
      }),
    setUuid: (uuid) =>
      set((state) => {
        state.uuid = uuid;
      }),
    updateField: (key, value) =>
      set((state) => {
        state.fields[key] = value;
      }),
  })),
);
