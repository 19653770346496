import React, { FC } from 'react';

import { TerminalOrganizerController } from '@features/Terminal';
import { Typography } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export interface OrganizerTerminalSectionProps {
  organizerId: number;
}
export const OrganizerTerminalSection: FC<OrganizerTerminalSectionProps> = ({
  organizerId,
}) => (
  <>
    <Head
      hideTopSpacing
      title={
        <Typography variant="head-24" color="on-surface-primary-1">
          Терминалы
        </Typography>
      }
    />
    <TerminalOrganizerController organizerId={organizerId} />
  </>
);
