import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { ArrowLeftIcon, Button, Maybe, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface HeadProps {
  title: string | JSX.Element;
  onBack?(): void;
  rightSlot?: Maybe<JSX.Element>;
  hideTopSpacing?: boolean;
}
export const Head: FC<HeadProps> = ({
  hideTopSpacing,
  onBack,
  rightSlot,
  title,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div
      className={cx(
        styles.head,
        hideTopSpacing && styles.head__withoutTopSpacing,
      )}>
      <div className={styles.head__left}>
        {onBack && (
          <Button
            icon
            prependIcon={ArrowLeftIcon}
            onClick={onBack}
            color={'secondary'}
          />
        )}

        <div>
          <Typography
            className={styles.head__title}
            variant={isMobile ? 'head-20' : 'head-32'}
            color="on-surface-primary-1">
            {title}
          </Typography>
        </div>
      </div>

      {rightSlot && <div className={styles.head__right}>{rightSlot}</div>}
    </div>
  );
};
