import {
  useOrganizerFeaturesQuery,
  useTerminalsQuery,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { Maybe } from '@letsdance/ui-kit';

export const useFeatureFlags = () => {
  const idOrganizer = useOrganizerId()!;
  const { data } = useTerminalsQuery({
    skip: !idOrganizer,
    variables: {
      idOrganizer,
    },
  });
  const { data: featureData } = useOrganizerFeaturesQuery({
    skip: !idOrganizer,
    variables: {
      idOrganizer,
    },
  });
  const { createReferral, manualPayment, viewReferral } =
    featureData?.organizerFeatures || {};
  const maybe = (val: Maybe<boolean | undefined>) => val || false;

  return {
    commission: data?.terminalsList.rows.some((el) => !el.token),
    createReferral: maybe(createReferral),
    manualPayment: maybe(manualPayment),
    viewReferral: maybe(viewReferral),
  };
};
