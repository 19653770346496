import React, { FC, useEffect } from 'react';

import {
  BaseTariffInput,
  BaseTariffType,
  OrderFeatureFlagsType,
  TariffTypeEnum,
} from '@/apolloGenerated';
import { FormSection, handleNumberInput, handleQueryInput } from '@/shared';
import { TariffTypeTranslate } from '@entities/Tariff/const';
import {
  AmountField,
  DropdownItem,
  EditorTextField,
  NestedKeys,
  Select,
  Spacer,
  Switch,
  TextField,
  Tooltip,
  useForm,
} from '@letsdance/ui-kit';

export interface BaseTariffFormProps {
  initValues: BaseTariffType;
  onChange(val: BaseTariffInput): void;
  errors?: Record<NestedKeys<BaseTariffInput>, string[]>;
  readonly?: boolean;
}
export const BaseTariffForm: FC<BaseTariffFormProps> = ({
  errors,
  initValues,
  onChange,
  readonly,
}) => {
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<BaseTariffInput>(console.log, initValues);
  const hasSubTariffs = !!initValues.countSubTariffs;

  useEffect(() => {
    onChange(values);
  }, [values]);

  const updateFeatureFlags = function <T extends keyof OrderFeatureFlagsType>(
    key: T,
    val: OrderFeatureFlagsType[T],
  ) {
    setBatchValues({
      featureFlags: {
        ...values.featureFlags,
        [key]: val,
      },
    });
  };

  return (
    <div>
      <FormSection title="Основная информация">
        <div className="grid-2">
          <TextField
            label="Название тарифа"
            size="large"
            disabled={readonly}
            fullWidth
            error={errors?.name}
            name="name"
            initValue={values.name!}
            onChange={onChangeValue}
          />
          <AmountField
            label="Цена тарифа"
            size="large"
            disabled={readonly}
            fullWidth
            error={errors?.price}
            name="price"
            value={values.price}
            postfix="₽"
            onChange={(e) => setBatchValues({ price: +e.target.value })}
          />
        </div>
        <Spacer size={10} />
        <EditorTextField
          label="Описание"
          disabled={readonly}
          error={errors?.description}
          initValue={values.description!}
          onChange={(val) => setBatchValues({ description: val })}
        />
        <Spacer size={10} />
        <TextField
          label="Ключ диплинка (латиница)"
          size="large"
          fullWidth
          name="query"
          disabled={readonly}
          initValue={values.query!}
          onKeyDown={handleQueryInput}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <Switch
          label="Видимый"
          checked={values.visible}
          disabled={readonly}
          onChange={(e) => setBatchValues({ visible: e.target.checked })}
        />
      </FormSection>

      <FormSection title="Фичи">
        <div className="grid-2">
          <div>
            <Switch
              label="Возврат билета баллами"
              checked={values.featureFlags.refundOrder}
              disabled={readonly}
              onChange={(e) =>
                updateFeatureFlags('refundOrder', e.target.checked)
              }
            />
            <Spacer size={10} />
            <Switch
              label="Передача билета"
              checked={values.featureFlags.transferToUserOrder}
              disabled={readonly}
              onChange={(e) =>
                updateFeatureFlags('transferToUserOrder', e.target.checked)
              }
            />
            <Spacer size={10} />
            <Switch
              label="Оплата бонусными баллама"
              checked={values.featureFlags.bonusPay}
              disabled={readonly}
              onChange={(e) => updateFeatureFlags('bonusPay', e.target.checked)}
            />
          </div>
          <div>
            <Switch
              label="Перенос билета на другие события"
              checked={values.featureFlags.transferToProductOrder}
              disabled={readonly}
              onChange={(e) =>
                updateFeatureFlags('transferToProductOrder', e.target.checked)
              }
            />
            <Spacer size={10} />
            <Switch
              label="Повторная покупка"
              checked={values.featureFlags.repeatBuyOrder}
              disabled={readonly}
              onChange={(e) =>
                updateFeatureFlags('repeatBuyOrder', e.target.checked)
              }
            />
          </div>
        </div>
        <Spacer size={10} />
      </FormSection>
      <FormSection title="Доступы">
        <div className="grid-2">
          <Switch
            label="Ограниченное кол-во билетов"
            checked={values.isLimitedOrders}
            disabled={readonly}
            onChange={(e) =>
              setBatchValues({ isLimitedOrders: e.target.checked })
            }
          />
          {values.isLimitedOrders && (
            <TextField
              label="Кол-во билетов"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.ordersCount}
              name="ordersCount"
              onKeyPress={handleNumberInput}
              value={String(values.ordersCount || '')}
              onChange={(e) =>
                setBatchValues({ ordersCount: Number(e.target.value) || 1 })
              }
            />
          )}
        </div>
        <Spacer size={10} />
        <Tooltip
          className="flex"
          label={
            'Нельзя изменить тип билета, так как уже имеются дочерние тарифы'
          }
          disabled={!hasSubTariffs}
          zIndex={1000}>
          <Select
            size="large"
            placeholder="Тип билета"
            name="type"
            initValue={values.type as TariffTypeEnum}
            renderValue={(val) => TariffTypeTranslate[val as TariffTypeEnum]}
            disabled={readonly || hasSubTariffs}
            onChangeNative={onChangeValue}
            hideOnSelect
            fullWidth>
            {Object.entries(TariffTypeTranslate).map(([key, label]) => (
              <DropdownItem key={key} label={label} value={key} />
            ))}
          </Select>
        </Tooltip>
      </FormSection>
    </div>
  );
};
