import { FC } from 'react';

import { Container, useModal } from '@/shared';
import { Button, PlusDenseIcon } from '@letsdance/ui-kit';
import { BotsLists } from '@widgets/Bot';
import { Head } from '@widgets/Layout';

export const BotsView: FC = () => {
  const openCreateBotModal = useModal('createBot');

  return (
    <div>
      <Container>
        <Head
          title="Боты"
          rightSlot={
            <Button
              onClick={() => openCreateBotModal()}
              prependIcon={PlusDenseIcon}>
              Добавить бота
            </Button>
          }
        />
      </Container>
      <BotsLists />
    </div>
  );
};
