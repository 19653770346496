import { ProductMainInfoInput } from '@/apolloGenerated';

export const getEventMainInfoInput = ({
  description,
  endDate,
  img,
  name,
  query,
  startedDate,
  uuidAddress,
}: ProductMainInfoInput): ProductMainInfoInput => ({
  description,
  endDate,
  img,
  name,
  query,
  startedDate,
  uuidAddress,
});

export const getEventMainInfoInitState = (): ProductMainInfoInput => ({
  description: '',
  endDate: new Date(),
  img: '',
  name: '',
  query: null,
  startedDate: new Date(),
  uuidAddress: null,
});
