import React, { FC } from 'react';

import {
  BaseTariffType,
  StatusEnum,
  useBaseTariffsListQuery,
} from '@/apolloGenerated';
import { formatAmount, useOrganizerId } from '@/shared';
import { TariffTypeTranslate } from '@entities/Tariff/const';
import { Spacer, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface BaseTariffPickerProps {
  onChange(val: BaseTariffType): void;
}
export const BaseTariffPicker: FC<BaseTariffPickerProps> = ({ onChange }) => {
  const idOrganizer = useOrganizerId()!;
  const { data } = useBaseTariffsListQuery({
    variables: { idOrganizer, status: [StatusEnum.Active] },
  });

  return (
    <div className={styles.baseTariffPicker}>
      {data?.baseTariffsList.rows.map((el) => (
        <div
          className={styles.baseTariffPicker__item}
          key={el.uuid}
          onClick={() => onChange(el as BaseTariffType)}>
          <Typography variant="head-16" color="on-surface-primary-1">
            {el.name} {!el.visible && '(Скрытый)'}
          </Typography>
          <Spacer size={4} />
          <Typography variant="body-14" color="on-surface-primary-1">
            Тип билета: {TariffTypeTranslate[el.type]}
          </Typography>
          <Typography variant="body-14" color="on-surface-primary-1">
            Кол-во билетов: {el.isLimitedOrders ? el.ordersCount : '-'}
          </Typography>
          <Typography variant="body-14" color="on-surface-primary-1">
            Стоимость: {formatAmount(el.price)} ₽
          </Typography>
        </div>
      ))}
    </div>
  );
};
