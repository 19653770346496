import { useEffect } from 'react';

import { useBotsByOrganizerQuery } from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { useOrganizerBotStore } from '@entities/Bot';

export const useInitBot = (id?: number) => {
  const organizerId = useOrganizerId()!;
  const { data } = useBotsByOrganizerQuery({
    skip: !organizerId && !id,
    variables: {
      id: id || organizerId,
    },
  });
  const { initOrganizerBotStore } = useOrganizerBotStore();

  useEffect(() => {
    if (!data) {
      return;
    }
    initOrganizerBotStore(data?.organizerAdmin?.bots || []);
  }, [data]);
};
