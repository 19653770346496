import React, { FC } from 'react';

import { StatusEnum } from '@/apolloGenerated';
import { Container } from '@/shared';
import { BaseTariffsTable } from '@features/Tariff';
import { Spacer } from '@letsdance/ui-kit';

export const BaseTariffsLists: FC = () => (
  <div>
    <Container>
      <Spacer size={12} />
      <BaseTariffsTable status={[StatusEnum.Active]} />
    </Container>
  </div>
);
