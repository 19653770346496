import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { ProductAddressInput, useAddressQuery } from '@/apolloGenerated';
import { ActionButton, ActionButtons, Container } from '@/shared';
import { RouterName } from '@app/router';
import {
  AddressForm,
  getAddressInput,
  useUpdateAddress,
} from '@entities/Address';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export interface AddressEditViewProps {
  params: {
    uuid: string;
  };
}

export const AddressEditView: FC<AddressEditViewProps> = ({
  params: { uuid },
}) => {
  const { navigate } = useNavigate();
  const [
    { handler: updateAddress },
    { validationErrors },
    { loading: saveLoading },
  ] = useUpdateAddress({
    notify: true,
    resetCache: true,
  });
  const [addressData, setAddressData] = useState<ProductAddressInput>();

  const { data, loading } = useAddressQuery({
    variables: {
      uuid,
    },
  });
  const address = data?.address;

  const onClose = () => {
    navigate(RouterName.Addresses);
  };
  const onSave = async () => {
    if (!addressData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateAddress({
      input: getAddressInput(addressData),
      uuid,
    });
  };

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: onSave,
      label: 'Сохранить изменения',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title={address?.address || ''} onBack={onClose} />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        {data && (
          <AddressForm
            initValues={data.address}
            onChange={setAddressData}
            errors={validationErrors as any}
          />
        )}
      </Container>
    </>
  );
};
