import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  OrganizerInput,
  OrganizerType,
  useOrganizerQuery,
} from '@/apolloGenerated';
import {
  ActionButtons,
  ApolloErrorService,
  Container,
  DisplayWrapper,
  ScrollXWrapper,
} from '@/shared';
import { RouterName } from '@app/router';
import { useInitBot } from '@entities/Bot';
import { OrganizerForm, useUpdateOrganizer } from '@entities/Organizer';
import {
  Divider,
  LoaderOverlay,
  Spacer,
  TabItem,
  Tabs,
  toaster,
} from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';
import {
  OrganizerAdminsSection,
  OrganizerBotsSection,
} from '@widgets/Organizer';
import { OrganizerTerminalSection } from '@widgets/Terminal';

enum OrganizerTabs {
  Main = 'Main',
  Admins = 'Admins',
  Bots = 'Bots',
  Terminals = 'Terminals',
}
export interface OrganizerEditViewProps {
  params: {
    id: string;
  };
}
export const OrganizerEditView: FC<OrganizerEditViewProps> = ({ params }) => {
  const id = +params.id;
  const [tab, setTab] = useState<OrganizerTabs>(OrganizerTabs.Main);
  const [synonyms, setSynonyms] = useState<string[]>([]);

  useInitBot(id);
  const { navigate } = useNavigate();
  const [updateOrganizer, { error, loading: saveLoading }] =
    useUpdateOrganizer();
  const [organizerData, setOrganizerData] = useState<OrganizerInput>();

  const { data, loading, refetch } = useOrganizerQuery({
    variables: {
      id,
    },
  });

  const onClose = () => {
    navigate(RouterName.Organizers);
  };

  const onSave = async () => {
    if (!organizerData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateOrganizer({
      id,
      input: organizerData!,
      synonyms: synonyms,
    });
    refetch();
  };

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: onSave,
      label: 'Сохранить изменения',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title="Профиль" onBack={onClose} />
      </Container>
      <Container slim>
        <ScrollXWrapper>
          <Tabs initValue={tab} onChange={setTab}>
            <TabItem value={OrganizerTabs.Main} label="Основная информация" />
            <TabItem value={OrganizerTabs.Admins} label="Администраторы" />
            <TabItem value={OrganizerTabs.Bots} label="Боты" />
            <TabItem value={OrganizerTabs.Terminals} label="Терминалы" />
          </Tabs>
        </ScrollXWrapper>
      </Container>
      <Divider />
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        <DisplayWrapper visible={tab === OrganizerTabs.Main}>
          {data && (
            <OrganizerForm
              initValues={data.organizerAdmin as OrganizerType}
              onChange={setOrganizerData}
              onSynonymsChange={setSynonyms}
              errors={
                ApolloErrorService.getFirstError(error?.graphQLErrors)
                  ?.extensions?.validationErrors
              }
            />
          )}
        </DisplayWrapper>
        <DisplayWrapper visible={tab === OrganizerTabs.Admins}>
          <OrganizerAdminsSection organizerId={id} />
        </DisplayWrapper>
        <DisplayWrapper visible={tab === OrganizerTabs.Bots}>
          <OrganizerBotsSection organizerId={id} />
        </DisplayWrapper>
        <DisplayWrapper visible={tab === OrganizerTabs.Terminals}>
          <OrganizerTerminalSection organizerId={id} />
        </DisplayWrapper>
      </Container>
    </>
  );
};
