import { useEffect } from 'react';

import {
  UpdateTerminalMutation,
  UpdateTerminalMutationVariables,
  useUpdateTerminalMutation,
} from '@/apolloGenerated';
import { MutationResult } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

import { getTerminalInput } from './getTerminalInput';

export const useUpdateTerminal = (): [
  (vars: UpdateTerminalMutationVariables) => Promise<void>,
  MutationResult<UpdateTerminalMutation>,
] => {
  const [updateTerminal, result] = useUpdateTerminalMutation();

  const onUpdateTerminal = async ({
    input,
    uuid,
  }: UpdateTerminalMutationVariables) => {
    await updateTerminal({
      variables: {
        input: getTerminalInput(input),
        uuid,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      toaster.success({ title: 'Терминал успешно обновлён' });
      result.client.cache.evict({
        args: { id: result.data.updateTerminal?.uuid },
        fieldName: 'terminal',
      });
    }
  }, [result.data, result.error]);

  return [onUpdateTerminal, result];
};
