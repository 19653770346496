import React, { FC, useEffect, useState } from 'react';

import {
  Errors,
  useAddUserToGroupMutation,
  useUserGroupsQuery,
  useUserQuery,
} from '@/apolloGenerated';
import { ApolloErrorService, useModal, useOrganizerId } from '@/shared';
import {
  Button,
  Dialog,
  DialogFooter,
  DropdownItem,
  Select,
  toaster,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface AddUserToGroupModalProps {
  uuidUser: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    addUserToGroup: AddUserToGroupModalProps;
  }
}
export const AddUserToGroupModal: FC<
  WithCloseModal<AddUserToGroupModalProps>
> = ({ onClose, onSuccess, uuidUser }): JSX.Element => {
  const organizerId = useOrganizerId()!;
  const openCreateGroupModal = useModal('createUserGroup');
  const { data: userData } = useUserQuery({
    variables: { organizerId, uuid: uuidUser },
  });
  const userVisibleName =
    userData?.user?.username ||
    `${userData?.user?.first_name}${userData?.user?.last_name ? ' ' + userData?.user?.last_name : ''}`;
  const [addUserToGroup, { client, data, error, loading }] =
    useAddUserToGroupMutation();
  const { data: usersGroupData, loading: loadingGroups } = useUserGroupsQuery({
    skip: !organizerId,
    variables: { filters: {}, idOrganizer: organizerId },
  });
  const selectItems = usersGroupData?.userGroups.rows || [];
  const isHasGroups = !loadingGroups && selectItems.length > 0;
  const [groupUuid, setGroupUuid] = useState<string>();

  const handleSubmit = () => {
    addUserToGroup({ variables: { uuidGroup: groupUuid!, uuidUser } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: `Пользователь ${userVisibleName} успешно добавлен в группу`,
      });
      client.cache.evict({ fieldName: 'userGroups' });
      client.cache.evict({
        fieldName: 'usersByGroup',
      });
      onSuccess && onSuccess();
      onClose();
    }
    if (
      ApolloErrorService.hasError(
        error?.graphQLErrors,
        Errors.UserAlreadyMemberInGroup,
      )
    ) {
      toaster.error({
        title: 'Пользователь уже состоит в данной группе',
      });
    } else if (error) {
      toaster.error({
        title:
          'Ошибка добавления пользователя в группу. Обратитесь за помощью в техподдержку',
      });
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title={`Добавить пользователя "${userVisibleName}" в группу`}
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            isHasGroups ? (
              <Button
                fullWidth
                onClick={handleSubmit}
                disabled={loading || !groupUuid}>
                Подтверить
              </Button>
            ) : (
              <Button fullWidth onClick={() => openCreateGroupModal()}>
                Создать группу
              </Button>
            )
          }
        />
      }>
      {isHasGroups ? (
        <Select
          size="large"
          placeholder="Выберите группу"
          onChange={(e) => setGroupUuid(e as string)}
          renderValue={(val) =>
            selectItems.find((el) => el.uuid === val)?.name || (val as string)
          }
          hideOnSelect
          fullWidth>
          {selectItems.map(({ name, uuid }) => (
            <DropdownItem key={uuid} label={name} value={uuid} />
          ))}
        </Select>
      ) : (
        <div>
          <Typography variant="body-16" color="on-surface-primary-1">
            В данный момент у вас не создано ни одной группы
          </Typography>
        </div>
      )}
    </Dialog>
  );
};
