import { FC } from 'react';

import { useFinanceStatsQuery } from '@/apolloGenerated';
import { StatItem, useOrganizerId } from '@/shared';
import { Divider } from '@letsdance/ui-kit';
import { useFeatureFlags } from '@shared/libs/hooks/useFeatureFlags';

import styles from './styles.module.scss';

export interface FinanceStatsProps {
  filterSlot?: JSX.Element;
  exportSlot?: JSX.Element;
}
export const FinanceStats: FC<FinanceStatsProps> = ({
  exportSlot,
  filterSlot,
}) => {
  const { viewReferral: refFlag } = useFeatureFlags();
  const organizerId = useOrganizerId()!;
  const { data } = useFinanceStatsQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const withdrawals = data?.statsSalesWithdrawal.total || 0;
  const balance = data?.organizerAdmin.salesBalance || 0;
  const creditBalance = data?.organizerAdmin.creditBalance || 0;
  const orderCount = data?.paymentsList.count || 0;
  const usersCount = data?.users.count || 0;

  const statsAmount = data?.statsPayments;
  const commission = statsAmount?.commission || 0;
  const turnover = statsAmount?.turnover || 0;
  const referral = statsAmount?.referral || 0;
  const profit = statsAmount?.profit || 0;

  return (
    <div className={styles.financeStats}>
      <div className={styles.financeStats__totalBlock}>
        <StatItem
          label="Текущий баланс, ₽"
          value={balance}
          format
          fixed={false}
        />
        <Divider />
        <StatItem
          label="Задолжность, ₽"
          value={creditBalance}
          format
          fixed={false}
        />
      </div>
      <div className={styles.financeStats__totalBlock}>
        <StatItem label="Покупок" value={orderCount} />
        <Divider />
        <StatItem label="Пользователей" value={usersCount} />
      </div>
      <div className={styles.financeStats__statsBlock}>
        {filterSlot && (
          <div className={styles.financeStats__filterWrap}>
            {filterSlot}
            {exportSlot}
          </div>
        )}
        <div className={styles.financeStats__statsWrap}>
          <StatItem
            label="Поступило, ₽"
            value={turnover}
            format
            fixed={false}
          />
          <Divider vertical />
          <StatItem
            label="Комиссия, ₽"
            value={commission}
            format
            fixed={false}
          />
          <Divider vertical />
          {refFlag && (
            <>
              <StatItem
                label="Реф. отч., ₽"
                value={referral}
                format
                fixed={false}
              />
              <Divider vertical />
            </>
          )}
          <StatItem label="Прибыль, ₽" value={profit} format fixed={false} />
          <Divider vertical />
          <StatItem
            label="Выведено, ₽"
            value={withdrawals}
            format
            fixed={false}
          />
        </div>
      </div>
    </div>
  );
};
