import { IStep, StepperStoreState } from './types';

export class StepperManager<Key> {
  initializeSteps(keys: Key[]): IStep<Key>[] {
    return keys.map((key, index) => ({
      isCompleted: false,
      isCurrent: index === 0,
      isNext: index === 1,
      isPrev: false,
      key,
    }));
  }

  initializeState(keys: Key[]): StepperStoreState<Key> {
    const steps = this.initializeSteps(keys);

    return {
      currentStep: steps[0],
      steps,
    };
  }

  getNextStepState(
    currentSteps: IStep<Key>[],
    currentStepKey: Key,
  ): StepperStoreState<Key> {
    const steps = [...currentSteps];
    const currentStepIndex = steps.findIndex(
      (step) => step.key === currentStepKey,
    );

    if (currentStepIndex === steps.length - 1) {
      return { currentStep: steps[currentStepIndex], steps };
    }

    if (steps[currentStepIndex - 1]) {
      steps[currentStepIndex - 1] = {
        ...steps[currentStepIndex - 1],
        isPrev: false,
      };
    }

    steps[currentStepIndex] = {
      ...steps[currentStepIndex],
      isCompleted: true,
      isCurrent: false,
      isNext: false,
      isPrev: true,
    };

    steps[currentStepIndex + 1] = {
      ...steps[currentStepIndex + 1],
      isCurrent: true,
      isNext: false,
      isPrev: false,
    };

    if (currentStepIndex + 2 < steps.length) {
      steps[currentStepIndex + 2] = {
        ...steps[currentStepIndex + 2],
        isNext: true,
      };
    }

    return {
      currentStep: steps[currentStepIndex + 1],
      steps,
    };
  }

  getPrevStepState(
    currentSteps: IStep<Key>[],
    currentStepKey: Key,
  ): StepperStoreState<Key> {
    const steps = [...currentSteps];
    const currentStepIndex = steps.findIndex(
      (step) => step.key === currentStepKey,
    );

    if (currentStepIndex === 0) {
      return { currentStep: steps[currentStepIndex], steps };
    }

    if (steps[currentStepIndex + 1]) {
      steps[currentStepIndex + 1] = {
        ...steps[currentStepIndex + 1],
        isNext: false,
      };
    }

    steps[currentStepIndex] = {
      ...steps[currentStepIndex],
      isCurrent: false,
      isNext: true,
      isPrev: false,
    };

    steps[currentStepIndex - 1] = {
      ...steps[currentStepIndex - 1],
      isCurrent: true,
      isNext: false,
      isPrev: false,
    };

    if (currentStepIndex - 2 >= 0) {
      steps[currentStepIndex - 2] = {
        ...steps[currentStepIndex - 2],
        isPrev: true,
      };
    }

    return {
      currentStep: steps[currentStepIndex - 1],
      steps,
    };
  }

  getSelectStepState(
    currentSteps: IStep<Key>[],
    targetKey: Key,
  ): StepperStoreState<Key> {
    const steps = [...currentSteps];
    const targetStepIndex = steps.findIndex((step) => step.key === targetKey);

    if (targetStepIndex === -1) {
      return {
        currentStep: steps.find((step) => step.isCurrent) || steps[0],
        steps,
      };
    }
    if (!steps[targetStepIndex].isCompleted) {
      throw new Error('Can`t select incomplete step');
    }

    const updatedSteps = steps.map((step, index) => {
      if (index < targetStepIndex) {
        return {
          ...step,
          isCurrent: false,
          isNext: false,
          isPrev: index === targetStepIndex - 1,
        };
      } else if (index === targetStepIndex) {
        return {
          ...step,
          isCurrent: true,
          isNext: false,
          isPrev: false,
        };
      } else {
        return {
          ...step,
          isCurrent: false,
          isNext: index === targetStepIndex + 1,
          isPrev: false,
        };
      }
    });

    return {
      currentStep: updatedSteps[targetStepIndex],
      steps: updatedSteps,
    };
  }
}
