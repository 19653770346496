import React, { FC, useEffect } from 'react';

import {
  BaseTariffType,
  ProductInput,
  SubTariffInput,
  SubTariffType,
} from '@/apolloGenerated';
import {
  DeeplinkCopy,
  FormSection,
  handleNumberInput,
  handleQueryInput,
} from '@/shared';
import { TariffTypeTranslate } from '@entities/Tariff/const';
import {
  AmountField,
  DateTimePicker,
  EditorTextField,
  InfoIcon,
  Maybe,
  NestedKeys,
  Spacer,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useForm,
} from '@letsdance/ui-kit';

export interface TariffFormProps {
  eventData: ProductInput;
  initValues: SubTariffType;
  baseValues: BaseTariffType;
  onChange(val: SubTariffInput): void;
  errors?: Record<NestedKeys<SubTariffInput>, string[]>;
  readonly?: boolean;
}
export const InfoBaseTariff: FC<{
  value?: Maybe<string | number | boolean>;
  baseValue?: Maybe<string | number | boolean>;
}> = ({ baseValue, value }) => {
  if ((!value && !!baseValue) || (baseValue && baseValue === value)) {
    return (
      <Tooltip
        className="flex"
        label="Значение использутеся от базового тарифа"
        zIndex={1000}>
        <InfoIcon className="cursor-pointer" />
      </Tooltip>
    );
  }

  return <></>;
};
export const SubTariffForm: FC<TariffFormProps> = ({
  baseValues,
  errors,
  eventData,
  initValues,
  onChange,
  readonly,
}) => {
  const availableOrdersText = initValues.availableOrdersCount
    ? `(Доступно: ${initValues.availableOrdersCount})`
    : '';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<SubTariffInput>(console.log, initValues);
  const isLimitedOrders = values.isLimitedOrders ?? baseValues.isLimitedOrders;

  const getValuesForUpdate = (values: SubTariffInput) => {
    const valuesForUpdate: SubTariffInput = { ...values };

    for (const valuesForUpdateKey in valuesForUpdate) {
      const key = valuesForUpdateKey as keyof SubTariffInput;
      const value = valuesForUpdate[key];

      if (
        value !== null &&
        value !== undefined &&
        value === baseValues[key as keyof BaseTariffType]
      ) {
        valuesForUpdate[key] = null;
      }
    }

    return valuesForUpdate;
  };

  useEffect(() => {
    onChange(getValuesForUpdate(values));
  }, [values]);

  return (
    <div>
      <FormSection title="Основная информация">
        <div className="grid-2">
          <TextField
            label="Название тарифа"
            size="large"
            disabled={readonly}
            fullWidth
            error={errors?.name}
            name="name"
            appendSlot={
              <InfoBaseTariff value={values.name} baseValue={baseValues.name} />
            }
            initValue={values.name! || baseValues.name}
            onChange={onChangeValue}
          />
          <div className="grid-2">
            <AmountField
              label="Цена тарифа"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.price}
              name="price"
              postfix="₽"
              appendSlot={
                <InfoBaseTariff
                  value={values.price}
                  baseValue={baseValues.price}
                />
              }
              value={values.price}
              initValue={values.price || baseValues.price}
              onChange={(e) => setBatchValues({ price: +e.target.value })}
            />
            <AmountField
              label="Спец. цена тарифа"
              postfix="₽"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.specialPrice}
              name="specialPrice"
              // @ts-ignore
              value={values.specialPrice}
              onChange={(e) =>
                setBatchValues({
                  specialPrice: e.target.value ? +e.target.value : null,
                })
              }
            />
          </div>
        </div>
        <Spacer size={10} />
        <TextField
          label="Ключ диплинка (латиница)"
          size="large"
          fullWidth
          error={
            eventData.query
              ? errors?.query
              : 'Отсутствует Ключ диплинка события'
          }
          name="query"
          appendSlot={
            <InfoBaseTariff value={values.query} baseValue={baseValues.query} />
          }
          initValue={values.query! || baseValues.query!}
          onKeyDown={handleQueryInput}
          onChange={onChangeValue}
        />
        <Spacer size={12} />
        {eventData.query && (
          <>
            <DeeplinkCopy
              to={`tariff-${eventData.query}`}
              value={values.query || baseValues.query!}
            />
            <Spacer size={10} />
          </>
        )}
        <EditorTextField
          label="Описание"
          disabled={readonly}
          error={errors?.description}
          initValue={values.description || baseValues.description!}
          onChange={(val) => setBatchValues({ description: val })}
        />
      </FormSection>

      <FormSection title="Доступы">
        <Switch
          label="Видимый"
          checked={values.visible ?? baseValues.visible}
          onChange={(e) => setBatchValues({ visible: e.target.checked })}
        />

        <Spacer size={10} />
        <Switch
          label="Ограниченное кол-во билетов"
          checked={isLimitedOrders}
          disabled={readonly}
          onChange={(e) => {
            const value = e.target.checked;

            setBatchValues({ isLimitedOrders: value });
            if (value && !(values.ordersCount || baseValues.ordersCount)) {
              setBatchValues({ ordersCount: 1 });
            }
          }}
        />
        <Spacer size={10} />
        {isLimitedOrders && (
          <>
            <TextField
              label={`Кол-во билетов ${availableOrdersText}`}
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.ordersCount}
              appendSlot={
                <InfoBaseTariff
                  value={values.ordersCount}
                  baseValue={baseValues.ordersCount}
                />
              }
              name="ordersCount"
              onKeyPress={handleNumberInput}
              value={String(values.ordersCount || baseValues.ordersCount || '')}
              onChange={(e) =>
                setBatchValues({ ordersCount: Number(e.target.value) || 1 })
              }
            />
            <Spacer size={10} />
          </>
        )}
        <div className="grid-2">
          <DateTimePicker
            initValue={values.startedDate ? new Date(values.startedDate) : null}
            label={`Дата активации (${timeZone})`}
            fullWidth
            size="large"
            onChange={(val) => setBatchValues({ startedDate: val })}
            error={errors?.startedDate}
            disabled={readonly}
            minDate={new Date()}
            clearable
          />
          <DateTimePicker
            initValue={values.endDate ? new Date(values.endDate) : null}
            label={`Дата деактивации (${timeZone})`}
            fullWidth
            size="large"
            onChange={(val) => setBatchValues({ endDate: val })}
            error={errors?.endDate}
            disabled={readonly}
            minDate={
              values?.startedDate ? new Date(values.startedDate) : new Date()
            }
            clearable
          />
        </div>
        <Spacer size={10} />
        <Typography variant="body-14" color="on-surface-primary-2">
          Если заполнено поле "Дата активации", то после сохранения тариф будет
          помещён в "Черновик" и станет доступен только в указанное время
        </Typography>
      </FormSection>

      <FormSection title="Информация по базовому тарифу">
        <TextField
          label="Тип билета"
          size="large"
          fullWidth
          readonly
          initValue={TariffTypeTranslate[baseValues.type]}
        />
        <Spacer size={10} />
        <div className="grid-2">
          <div>
            <Switch
              label="Возврат билета баллами"
              checked={baseValues.featureFlags.refundOrder}
              disabled
            />
            <Spacer size={10} />
            <Switch
              label="Передача билета"
              checked={baseValues.featureFlags.transferToUserOrder}
              disabled
            />
            <Spacer size={10} />
            <Switch
              label="Оплата бонусными баллама"
              checked={baseValues.featureFlags.bonusPay}
              disabled
            />
          </div>
          <div>
            <Switch
              label="Перенос билета на другие события"
              checked={baseValues.featureFlags.transferToProductOrder}
              disabled
            />
            <Spacer size={10} />
            <Switch
              label="Повторная покупка"
              checked={baseValues.featureFlags.repeatBuyOrder}
              disabled
            />
          </div>
        </div>
      </FormSection>
    </div>
  );
};
