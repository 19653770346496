import React, { FC, useMemo } from 'react';

import { useAddressesListQuery } from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { DropdownItem, Maybe, Select } from '@letsdance/ui-kit';

export interface AddressSelectProps {
  initValue?: Maybe<string>;
  onChange(value: string): void;
}
export const AddressSelect: FC<AddressSelectProps> = ({
  initValue,
  onChange,
}) => {
  const organizerId = useOrganizerId()!;
  const { data: addresses } = useAddressesListQuery({
    skip: !organizerId,
    variables: { params: { organizerId } },
  });

  const selectItems = useMemo(
    () => addresses?.addressesList.rows || [],
    [addresses],
  );

  return (
    <Select
      size="large"
      placeholder="Адрес"
      initValue={initValue!}
      onChange={(e) => onChange(e)}
      renderValue={(val) =>
        selectItems.find((el) => el.uuid === val)?.address || (val as string)
      }
      hideOnSelect
      fullWidth>
      {selectItems.map(({ address, uuid }) => (
        <DropdownItem key={uuid} label={address} value={uuid} />
      ))}
    </Select>
  );
};
