import { useEffect } from 'react';

import { useRemoveBotFromOrganizerMutation } from '@/apolloGenerated';
import { toaster } from '@letsdance/ui-kit';

export const useRemoveBotOrganizer = () => {
  const [removeBotFromOrganizer, { client, data, error }] =
    useRemoveBotFromOrganizerMutation();

  const handleRemove = (idBot: number, idOrganizer: number) => {
    removeBotFromOrganizer({
      variables: {
        idBot,
        idOrganizer,
      },
    });
  };

  useEffect(() => {
    if (data) {
      toaster.success({ title: 'Бот откреплён от организатора' });
      client.cache.evict({ fieldName: 'botsList' });
    }
    if (error) {
      toaster.error({ title: 'Ошибка открепления бота от организатора' });
    }
  }, [data, error]);

  return {
    handleRemove,
  };
};
