import { FC } from 'react';
import cx from 'classnames';

import { CheckDenseIcon, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

interface StepperNavItemProps {
  label: string;
  index: number;
  className?: string;
  active?: boolean;
  disabled?: boolean;
  completed?: boolean;
  onClick?: () => void;
}
export const StepperNavItem: FC<StepperNavItemProps> = ({
  active,
  className,
  completed,
  disabled,
  index,
  label,
  onClick,
}) => {
  const isDisabled = disabled && !active;
  const classes = cx(
    styles.stepperNavItem,
    active && styles.stepperNavItem_active,
    isDisabled && styles.stepperNavItem_disabled,
    completed && styles.stepperNavItem_completed,
    className,
  );

  const handleClick = () => {
    if (disabled || active) {
      return;
    }
    onClick && onClick();
  };

  return (
    <div className={classes} onClick={handleClick}>
      <div className={styles.stepperNavItem__index}>
        {completed ? (
          <CheckDenseIcon />
        ) : (
          <Typography variant="head-12">{index}</Typography>
        )}
      </div>
      <Typography variant="head-14">{label}</Typography>
    </div>
  );
};
