import React, { FC } from 'react';

import { useRemoveTerminal } from '@features/Terminal/libs/hooks';
import {
  Button,
  Dialog,
  DialogFooter,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface RemoveTerminalModalProps {
  uuid: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    removeTerminal: RemoveTerminalModalProps;
  }
}
export const RemoveTerminalModal: FC<
  WithCloseModal<RemoveTerminalModalProps>
> = ({ onClose, onSuccess, uuid }): JSX.Element => {
  const { handleRemove, loading } = useRemoveTerminal(
    () => {
      onSuccess && onSuccess();
      onClose();
    },
    () => {
      onClose();
    },
  );

  return (
    <Dialog
      title={`Удалить терминал?`}
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button
                fullWidth
                color="danger"
                onClick={() => handleRemove(uuid)}
                disabled={loading}>
                Удалить
              </Button>
            </div>
          }
        />
      }>
      <Typography variant="body-16" color="on-surface-primary-1">
        Если через данный терминал пытались проводиться платежи, то при удалении
        он будет перемещён в архив
      </Typography>
    </Dialog>
  );
};
