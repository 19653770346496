import { ProductAddressInput } from '@/apolloGenerated';

export const getAddressInput = ({
  address,
  description,
  img,
}: ProductAddressInput): ProductAddressInput => ({
  address,
  description,
  img,
});

export const getAddressInitState = (): ProductAddressInput => ({
  address: '',
  description: null,
  img: null,
});
