import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  TerminalInput,
  TerminalType,
  useTerminalQuery,
} from '@/apolloGenerated';
import { ActionButtons, ApolloErrorService, Container } from '@/shared';
import { RouterName } from '@app/router';
import { TerminalForm, useUpdateTerminal } from '@entities/Terminal';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

export interface OrganizerEditViewProps {
  params: {
    uuid: string;
  };
}
export const TerminalEditView: FC<OrganizerEditViewProps> = ({ params }) => {
  const { navigate } = useNavigate();
  const [updateTerminal, { error, loading: saveLoading }] = useUpdateTerminal();
  const [terminalData, setTerminalData] = useState<TerminalInput>();

  const { data, loading, refetch } = useTerminalQuery({
    variables: {
      uuid: params.uuid,
    },
  });

  const onClose = () => {
    navigate(RouterName.Terminals);
  };
  const onSave = async () => {
    if (!terminalData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateTerminal({
      input: terminalData!,
      uuid: params.uuid,
    });
    refetch();
  };

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: onSave,
      label: 'Сохранить изменения',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title="Терминал" onBack={onClose} />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        {data && (
          <TerminalForm
            initValues={data.terminalAdmin as TerminalType}
            onChange={setTerminalData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
          />
        )}
      </Container>
    </>
  );
};
