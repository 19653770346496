import { useEffect } from 'react';

import { useRemoveTerminalMutation } from '@/apolloGenerated';
import { toaster } from '@letsdance/ui-kit';

export const useRemoveTerminal = (
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const [removeTerminal, { client, data, error, loading }] =
    useRemoveTerminalMutation();

  const handleRemove = (uuid: string) => {
    removeTerminal({ variables: { uuid } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: `Терминал успешно удалён`,
      });
      client.cache.evict({ fieldName: 'terminalsList' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка удаления терминала',
      });
      onError && onError();
    }
  }, [data, error]);

  return {
    handleRemove,
    loading,
  };
};
