import { date, object, ObjectSchema, string } from 'yup';

import { ProductMainInfoInput } from '@/apolloGenerated';

const requiredText = 'Обязательное поле';

export const eventMainInfoSchema: ObjectSchema<ProductMainInfoInput> =
  object().shape({
    description: string().required(requiredText),

    endDate: date().required(requiredText),

    img: string().required(requiredText),

    name: string().required(requiredText),

    query: string().nullable().optional(),

    startedDate: date().required(requiredText),

    uuidAddress: string().nullable().optional(),
  });
