import React, { FC } from 'react';

import { useSubTariffQuery } from '@/apolloGenerated';
import { StatItem, StatWrap } from '@/shared';
import {
  Button,
  Dialog,
  DialogFooter,
  Spacer,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface TariffOrderInfoModalProps {
  uuid: string;
}
declare global {
  interface ModalProps {
    tariffOrderInfo: TariffOrderInfoModalProps;
  }
}
export const TariffOrderInfoModal: FC<
  WithCloseModal<TariffOrderInfoModalProps>
> = ({ onClose, uuid }): JSX.Element => {
  const { data } = useSubTariffQuery({ variables: { uuid } });
  const stats = data?.subTariff.statsOrdersCount;
  const ordersCount = data?.subTariff?.ordersCount;

  return (
    <Dialog
      title="Информация о билетах"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Закрыть
            </Button>
          }
        />
      }>
      <StatWrap>
        <StatItem
          label="Всего билетов"
          value={ordersCount || 'Безлимит'}
          fixed={false}
        />
        {ordersCount && (
          <StatItem
            label="Доступно билетов"
            value={data?.subTariff?.availableOrdersCount || 0}
            fixed={false}
          />
        )}
      </StatWrap>
      <Spacer size={10} />
      <StatWrap>
        <StatItem label="Оплачено" value={stats?.paid || 0} fixed={false} />
        <StatItem
          label="Ожидает оплаты"
          value={stats?.awaitPay || 0}
          fixed={false}
        />
        <StatItem
          label="Возвращено"
          value={stats?.refunded || 0}
          fixed={false}
        />
        <StatItem
          label="Перенесено"
          value={stats?.transferredProduct || 0}
          fixed={false}
        />
      </StatWrap>
    </Dialog>
  );
};
