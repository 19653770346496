import { FC } from 'react';

import { useBotsListQuery } from '@/apolloGenerated';
import { ListPicker, useModal } from '@/shared';
import { useRemoveBotOrganizer } from '@features/Bot';

export interface BotOrganizerControllerProps {
  organizerId: number;
}
export const BotOrganizerController: FC<BotOrganizerControllerProps> = ({
  organizerId,
}) => {
  const openAddOrganizerToBotModal = useModal('addOrganizerToBot');
  const { handleRemove } = useRemoveBotOrganizer();
  const { data, loading } = useBotsListQuery({
    variables: {
      params: {
        organizerId,
      },
    },
  });

  if (loading) {
    return <></>;
  }

  return (
    <>
      <ListPicker
        initValues={data?.botsList.rows}
        labelRender={(item) => item.name}
        valueKey="id"
        renderSubtitle={(val) => `@${val.username}`}
        onAdd={() => openAddOrganizerToBotModal({ idOrganizer: organizerId })}
        hasAdd={(data?.botsList?.count || 0) < 2}
        onRemove={(val) => handleRemove(val.id, organizerId)}
        texts={{
          addBtn: 'Добавить бота',
          removeModal: {
            title: 'Открепить бота?',
          },
        }}
      />
    </>
  );
};
