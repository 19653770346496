import { OrganizerInput } from '@/apolloGenerated';

export const getOrganizerInput = ({
  description,
  featureFlags: { allowedNumberForTransferToProductOrder },
  image,
  name,
  orderInstructions: { refund, transferToProduct, transferToUser },
  query,
  supplierInfo: { inn, name: supplierName, phone },
  telegram,
}: OrganizerInput): OrganizerInput => ({
  description,
  featureFlags: {
    allowedNumberForTransferToProductOrder,
  },
  image,
  name,
  orderInstructions: { refund, transferToProduct, transferToUser },
  query,
  supplierInfo: {
    inn,
    name: supplierName,
    phone,
  },
  telegram,
});

export const getOrganizerInitState = (): OrganizerInput => ({
  description: '',
  featureFlags: {
    allowedNumberForTransferToProductOrder: 3,
  },
  image: '',
  name: '',
  orderInstructions: {
    refund: '',
    transferToProduct: '',
    transferToUser: '',
  },
  supplierInfo: {
    inn: null,
    name: null,
    phone: null,
  },
  telegram: '',
});
