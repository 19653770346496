import React, { FC, useState } from 'react';

import { BaseBotType } from '@/apolloGenerated';
import { useOrganizerBotStore } from '@entities/Bot';
import {
  Button,
  CheckDenseIcon,
  Dropdown,
  DropdownItem,
  Paper,
  SettingDenseIcon,
  Typography,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export const BotSelect: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { allOrganizerBots, selectOrganizerBot, selectedOrganizerBot } =
    useOrganizerBotStore();

  const selectBot = (bot: BaseBotType) => {
    selectOrganizerBot(bot!);
    setIsOpen(false);
  };

  return (
    <Dropdown
      targetSlot={
        <Button>
          <div className="flex-align-center">
            Бот <SettingDenseIcon />
          </div>
        </Button>
      }
      value={isOpen}
      onChange={setIsOpen}
      placement="right-end"
      width={260}
      zIndex={1000}>
      <Paper px={4} py={4} radius={4}>
        {allOrganizerBots.map((b) => (
          <DropdownItem
            key={b.id}
            fullWidth
            disabled={selectedOrganizerBot?.id === b.id}
            onClick={() => selectBot(b)}>
            <Typography variant="body-16" className={styles.botSelect__item}>
              {b.username}
              {selectedOrganizerBot?.id === b.id && <CheckDenseIcon />}
            </Typography>
          </DropdownItem>
        ))}
      </Paper>
    </Dropdown>
  );
};
