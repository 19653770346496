import { FC } from 'react';

import { useOrganizerFilesQuery } from '@/apolloGenerated';
import { openSupportTg, useOrganizerId } from '@/shared';
import { Button, Typography } from '@letsdance/ui-kit';

import styles from './footer.module.scss';

export const Footer: FC = () => {
  const organizerId = useOrganizerId()!;
  const { data } = useOrganizerFilesQuery({
    skip: !organizerId,
    variables: { id: organizerId },
  });
  const files = data?.organizerAdmin.files;

  return (
    <div className={styles.footer}>
      <ul className={styles.footer__list}>
        <li>
          <Typography
            tag={'a'}
            // @ts-ignore
            href={files?.oferta || '/docs/oferta.pdf'}
            variant={'head-14'}
            color={'on-surface-primary-1'}
            className={styles.footer__link}
            target="_blank">
            Оферта
          </Typography>
        </li>
        <li>
          <Typography
            tag={'a'}
            // @ts-ignore
            href={files?.policy || '/docs/policy.pdf'}
            variant={'head-14'}
            color={'on-surface-primary-1'}
            className={styles.footer__link}
            target="_blank">
            Политика обработки данных
          </Typography>
        </li>
        <li>
          <Typography
            tag={'a'}
            // @ts-ignore
            href={files?.agent || '/docs/agent.pdf'}
            variant={'head-14'}
            color={'on-surface-primary-1'}
            className={styles.footer__link}
            target="_blank">
            Условия использования
          </Typography>
        </li>
      </ul>
      <div className={styles.footer__list}>
        <Button variant={'outlined'} onClick={openSupportTg}>
          Написать в поддержку
        </Button>
        <Typography variant={'body-14'} color={'on-surface-primary-1'}>
          © {new Date().getFullYear()} W2Dance
        </Typography>
      </div>
    </div>
  );
};
