import { array, object, ObjectSchema } from 'yup';

import { SubTariffType } from '@/apolloGenerated';

export interface EventTariffsValidationObject {
  tariffs: SubTariffType[];
}
export const createEventTariffsSchema: ObjectSchema<EventTariffsValidationObject> =
  object().shape({
    tariffs: array()
      .required()
      .min(1, 'Добавьте тариф чтобы перейти к настройке оплаты'),
  });
