import { FC } from 'react';

import { TerminalEnum } from '@/apolloGenerated';
import { RobokassaIcon, TinkoffIcon } from '@/shared/ui/icons';
import { IconProps } from '@letsdance/ui-kit';

export const ICON_ONLINE_TERMINAL: Record<TerminalEnum, FC<IconProps>> = {
  [TerminalEnum.Robokassa]: RobokassaIcon,
  [TerminalEnum.Tinkoff]: TinkoffIcon,
};
