declare global {
  interface Window {
    settings: {
      VITE_ENDPOINT: string;
      VITE_SENTRY_DSN: string;
      VITE_ENVIROPMENT: string;
    };
  }
}

const app_config = {
  API_URL: window?.settings?.VITE_ENDPOINT || import.meta.env?.VITE_ENDPOINT,
  ENVIROPMENT:
    window?.settings?.VITE_ENVIROPMENT || import.meta.env?.VITE_ENVIROPMENT,
  SENTRY_DSN:
    window?.settings?.VITE_SENTRY_DSN || import.meta.env?.VITE_SENTRY_DSN,
};

export default app_config;
