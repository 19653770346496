import React, { FC, useState } from 'react';

import { useOrganizersListCountQuery } from '@/apolloGenerated';
import { Container, ScrollXWrapper } from '@/shared';
import { OrganizersTable } from '@features/Organizer';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export enum OrganizersTabs {
  All = 'all',
  Verified = 'verified',
}

export const OrganizersLists: FC = () => {
  const [tab, setTab] = useState<OrganizersTabs>(OrganizersTabs.All);
  const { data: allOrganizers } = useOrganizersListCountQuery();
  const { data: verifiedOrganizers } = useOrganizersListCountQuery({
    variables: {
      verified: true,
    },
  });

  return (
    <div>
      <Container>
        <ScrollXWrapper>
          <Tabs initValue={tab} onChange={setTab}>
            <TabItem
              value={OrganizersTabs.All}
              label={`Активные (${allOrganizers?.organizersList?.count || 0})`}
            />
            <TabItem
              value={OrganizersTabs.Verified}
              label={`Верифицированные (${verifiedOrganizers?.organizersList?.count || 0})`}
            />
          </Tabs>
        </ScrollXWrapper>
      </Container>
      <Divider />
      <Container>
        <Spacer size={12} />
        <ScrollXWrapper>
          <OrganizersTable verified={tab === OrganizersTabs.Verified} />
        </ScrollXWrapper>
      </Container>
    </div>
  );
};
