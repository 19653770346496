import React, { FC, useEffect } from 'react';

import { TerminalEnum, TerminalInput, TerminalType } from '@/apolloGenerated';
import {
  NestedKeys,
  PeriodTextField,
  Select,
  Spacer,
  TextField,
  useForm,
} from '@letsdance/ui-kit';
import { validateNumberInput } from '@shared/libs/utils/filter';

export interface TerminalFormProps {
  initValues: TerminalType;
  onChange(val: TerminalInput): void;
  errors?: Record<NestedKeys<TerminalInput>, string[]>;
}
export const TerminalForm: FC<TerminalFormProps> = ({
  errors,
  initValues,
  onChange,
}) => {
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<TerminalInput>(console.log, initValues);

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div>
      <div className="grid-2">
        <TextField
          label="Название терминала"
          size="large"
          fullWidth
          error={errors?.name}
          name="name"
          value={values.name!}
          onChange={onChangeValue}
        />
        <TextField
          label="Подпись на кнопке"
          size="large"
          fullWidth
          error={errors?.label}
          name="label"
          value={values.label!}
          onChange={onChangeValue}
        />
      </div>
      <Spacer size={10} />
      <div className="grid-2">
        <Select
          size="large"
          placeholder="Тип терминала"
          initValue={values.type}
          // @ts-ignore
          items={Object.values(TerminalEnum) as TerminalEnum[]}
          onChange={(val) => setBatchValues({ type: val })}
          fullWidth
          hideOnSelect
        />
        <TextField
          label="Токен"
          size="large"
          fullWidth
          error={errors?.token}
          name="token"
          value={values.token!}
          onChange={(e) => setBatchValues({ token: e.target.value || null })}
        />
      </div>
      <Spacer size={10} />
      <div className="grid-2">
        <PeriodTextField
          label="Минут на оплату билета"
          size="large"
          fullWidth
          error={errors?.invoiceLifetime}
          name="invoiceLifetime"
          initValue={values.invoiceLifetime!}
          onChange={(e) => setBatchValues({ invoiceLifetime: e.target.value })}
          period="minute"
        />
        <TextField
          label="Процент удержания"
          size="large"
          fullWidth
          error={errors?.holdPercentage}
          name="holdPercentage"
          onKeyPress={validateNumberInput({
            allowDecimal: true,
            decimalPlaces: 2,
          })}
          initValue={String(
            typeof values.holdPercentage === 'number'
              ? values.holdPercentage
              : '',
          )}
          onChange={(e) =>
            setBatchValues({
              holdPercentage: e.target.value ? +e.target.value : null,
            })
          }
        />
      </div>
    </div>
  );
};
