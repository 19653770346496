import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { StatusEnum, TerminalEnum, useTerminalsQuery } from '@/apolloGenerated';
import { ListPicker } from '@/shared';
import { RouterName } from '@app/router';
import { useRemoveTerminal } from '@features/Terminal/libs/hooks';

export interface TerminalOrganizerControllerProps {
  organizerId: number;
}
export const TerminalOrganizerController: FC<
  TerminalOrganizerControllerProps
> = ({ organizerId }) => {
  const { urlFor } = useNavigate();
  const { handleRemove } = useRemoveTerminal();
  const { data } = useTerminalsQuery({
    pollInterval: 8000,
    variables: {
      idOrganizer: organizerId,
      status: [StatusEnum.Active],
    },
  });

  if (!data?.terminalsList) {
    return <></>;
  }

  return (
    <>
      <ListPicker
        initValues={data?.terminalsList.rows}
        labelRender={(item) =>
          `${item.name} ${item.token ? '(Кастомный терминал)' : ''}`
        }
        valueKey="uuid"
        renderSubtitle={(val) => val.type}
        onAdd={() =>
          window.open(
            urlFor(RouterName.TerminalCreate, {
              idOrganizer: organizerId,
            }),
            '_blank',
          )
        }
        hasAdd={
          (data?.terminalsList?.count || 0) < Object.keys(TerminalEnum).length
        }
        onEdit={(val) =>
          window.open(
            urlFor(RouterName.TerminalEdit, {
              uuid: val.uuid,
            }),
            '_blank',
          )
        }
        onRemove={(val) => handleRemove(val.uuid)}
        texts={{
          addBtn: 'Добавить терминал',
          removeModal: {
            title: 'Удалить терминал?',
          },
        }}
      />
    </>
  );
};
