export enum RouterName {
  Home = 'Home',
  NotFound = '404',
  Authorization = 'auth',
  Events = 'events',
  BaseTariffs = '-base-tariffs',
  Profile = 'profile',
  Contact = 'contact',
  Purchases = 'purchases',
  Finance = 'finances',
  Referral = 'referral',
  ReferralWithdrawCreate = 'referral-withdraw-create',
  WithdrawCreate = 'withdraw-create',
  ContactEdit = 'contact-edit',
  EventEdit = 'event-edit',
  EventCreate = 'event-create',
  BaseTariffEdit = 'base-tariff-edit',
  BaseTariffCreate = 'base-tariff-create',
  Organizers = 'organizers',
  Bots = 'bots',
  Terminals = 'terminals',
  TerminalEdit = 'terminal-edit',
  TerminalCreate = 'terminal-create',
  Addresses = 'addresses',
  AddressEdit = 'address-edit',
  AddressCreate = 'address-create',
  OrganizerCreate = 'organizer-create',
  OrganizerEdit = 'organizer-edit',
}
