import { FC, useState } from 'react';

import {
  useReferralFinanceListsCountQuery,
  WithdrawalFromEnum,
} from '@/apolloGenerated';
import { Container, useOrganizerId } from '@/shared';
import { ReferralReceiptsTable, ReferralTable } from '@features/Referral';
import { WithdrawTable } from '@features/Withdraw';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export enum FinanceTabs {
  Incoming,
  Referral,
  Outcoming,
}
export const ReferralFinanceLists: FC = () => {
  const idOrganizer = useOrganizerId()!;
  const { data } = useReferralFinanceListsCountQuery({
    skip: !idOrganizer,
    variables: { idOrganizer },
  });
  const receiptCount = data?.ordersReferralOrganizerList.count || 0;
  const withdrawCount = data?.withdrawalsByOrganizer.count || 0;
  const referralCount = data?.referralsOrganizerList.count || 0;
  const [tab, setTab] = useState<FinanceTabs>(FinanceTabs.Incoming);

  return (
    <div>
      <Container>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem
            value={FinanceTabs.Incoming}
            label={`Поступления (${receiptCount})`}
          />
          <TabItem
            value={FinanceTabs.Referral}
            label={`Реф. ссылки (${referralCount})`}
          />
          <TabItem
            value={FinanceTabs.Outcoming}
            label={`Вывод (${withdrawCount})`}
          />
        </Tabs>
      </Container>
      <Divider />
      <Spacer size={12} />
      {tab === FinanceTabs.Incoming && <ReferralReceiptsTable />}
      {tab === FinanceTabs.Referral && <ReferralTable />}
      {tab === FinanceTabs.Outcoming && (
        <WithdrawTable from={WithdrawalFromEnum.ReferralBalance} />
      )}
    </div>
  );
};
