import { useEffect } from 'react';

import {
  CreateBaseTariffMutation,
  CreateBaseTariffMutationVariables,
  useCreateBaseTariffMutation,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { MutationResult } from '@apollo/client';
import { history, router, RouterName } from '@app/router';
import { getBaseTariffInput } from '@entities/Tariff';
import { toaster } from '@letsdance/ui-kit';

export const useCreateBaseTariff = (): [
  (vars: Pick<CreateBaseTariffMutationVariables, 'input'>) => Promise<void>,
  MutationResult<CreateBaseTariffMutation>,
] => {
  const idOrganizer = useOrganizerId()!;
  const [createBaseTariff, result] = useCreateBaseTariffMutation();

  const onCreateBaseTariff = async ({
    input,
  }: Pick<CreateBaseTariffMutationVariables, 'input'>) => {
    createBaseTariff({
      variables: {
        idOrganizer,
        input: getBaseTariffInput(input),
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      const { client } = result;

      client.cache.evict({ fieldName: 'baseTariffsList' });
      toaster.success({ title: 'Базовый тариф успешно создан' });
      history.navigate(
        router.urlFor(RouterName.BaseTariffEdit, {
          uuid: result.data.createBaseTariff.uuid,
        }),
      );
    }
  }, [result.data, result.error]);

  return [onCreateBaseTariff, result];
};
