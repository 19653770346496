import React, { FC } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  WithCloseModal,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

interface ProofPaymentModalProps {
  proof: string;
}
declare global {
  interface ModalProps {
    proofPayment: ProofPaymentModalProps;
  }
}
export const ProofPaymentModal: FC<WithCloseModal<ProofPaymentModalProps>> = ({
  onClose,
  proof,
}): JSX.Element => (
  <Dialog
    title={`Чек об оплате билета`}
    closed
    width={500}
    footerSlot={
      <DialogFooter
        fullWidth
        endSlot={
          <Button color="secondary" fullWidth onClick={onClose}>
            Закрыть
          </Button>
        }
      />
    }>
    <div className={styles.proofModal}>
      <img className={styles.proofModal__img} src={proof} alt="proof" />
    </div>
  </Dialog>
);
