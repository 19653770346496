import React, { FC } from 'react';

import { ProductInput, ProductMainInfoInput } from '@/apolloGenerated';
import {
  ErrorNestedKeys,
  handleQueryInput,
  SectionCard,
  useImageUploader,
} from '@/shared';
import { AddressSelect } from '@entities/Address';
import {
  DateTimePicker,
  EditorTextField,
  FileSize,
  ImageUploader,
  Spacer,
  TextField,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

interface EventMainInfoFormProps {
  onChange<K extends keyof ProductMainInfoInput>(
    key: K,
    val: ProductMainInfoInput[K],
  ): void;
  initValues: ProductMainInfoInput;
  errors?: Partial<Record<ErrorNestedKeys<ProductInput>, string[]>>;
}
export const EventMainInfoForm: FC<EventMainInfoFormProps> = ({
  errors,
  initValues,
  onChange,
}) => {
  const uploadImage = useImageUploader();

  return (
    <div className={styles.eventMainInfoForm}>
      <SectionCard title="Основная информация">
        <div className={styles.eventMainInfoForm__top}>
          <div className={styles.eventMainInfoForm__image}>
            <ImageUploader
              initValue={initValues.img}
              onChange={(val) => onChange('img', val)}
              error={errors?.img}
              uploadImageCallback={uploadImage}
              size={168}
              maxFileSize={{
                size: 10,
                unit: FileSize.MB,
              }}
            />
          </div>
          <div className={styles.eventMainInfoForm__topRight}>
            <TextField
              label="Название"
              size="large"
              fullWidth
              error={errors?.name}
              name="name"
              initValue={initValues.name}
              onChange={(val) => onChange('name', val.target.value)}
            />
            <Spacer size={8}></Spacer>
            <TextField
              label="Уникальное название для ссылки на мероприятие"
              size="large"
              fullWidth
              error={errors?.query}
              name="query"
              initValue={initValues.query!}
              onKeyDown={handleQueryInput}
              onChange={(val) => onChange('query', val.target.value)}
            />
          </div>
        </div>
        <Spacer size={12}></Spacer>
        <EditorTextField
          label="Описание"
          error={errors?.description}
          initValue={initValues.description}
          onChange={(val) => onChange('description', val)}
        />
      </SectionCard>
      <Spacer size={12} />
      <SectionCard title="Дата и место">
        <div className={styles.eventMainInfoForm__bottom}>
          <div className={styles.eventMainInfoForm__bottomDates}>
            <DateTimePicker
              initValue={
                initValues.startedDate
                  ? new Date(initValues.startedDate)
                  : new Date()
              }
              label={`Дата начала`}
              fullWidth
              size="large"
              onChange={(val) => onChange('startedDate', val!)}
              error={errors?.startedDate}
              minDate={new Date()}
            />
            <DateTimePicker
              initValue={
                initValues.endDate ? new Date(initValues.endDate) : new Date()
              }
              label={`Дата окончания`}
              fullWidth
              size="large"
              onChange={(val) => onChange('endDate', val!)}
              error={errors?.endDate}
              disabled={!initValues?.startedDate}
              minDate={new Date()}
            />
          </div>
          <Spacer size={8} />
          <AddressSelect
            initValue={initValues.uuidAddress}
            onChange={(val) => onChange('uuidAddress', val)}
          />
        </div>
      </SectionCard>
    </div>
  );
};
