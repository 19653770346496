import React, { createElement, FC, useMemo, useState } from 'react';

import { MaybePromise, Stepper, StepperHelper } from '@/shared';
import { STEPPER_LABELS } from '@entities/Event/const';
import { useCreateEventStepperStore } from '@entities/Event/libs/store';
import { CreateEventSteps } from '@entities/Event/types';
import { Button } from '@letsdance/ui-kit';
import { StepperActionsWrap } from '@shared/ui/Stepper';

interface EventCreateStepperProps {
  contents: Record<CreateEventSteps, FC>;
  onDraft(): MaybePromise<any>;
  onPublish(): MaybePromise<any>;
  onChangeStep(step: CreateEventSteps): MaybePromise<boolean>;
}

export const EventCreateStepper: FC<EventCreateStepperProps> = ({
  contents,
  onChangeStep,
  onDraft,
  onPublish,
}) => {
  const {
    currentStep,
    nextStep: handleNextStep,
    selectStep: handleSelectStep,
    steps,
  } = useCreateEventStepperStore();
  const nextStep = useMemo(
    () => steps.find((step) => step.isNext),
    [currentStep],
  );
  const navItems = useMemo(
    () => StepperHelper.getWithLabels(steps, STEPPER_LABELS),
    [steps],
  );
  const [loading, setLoading] = useState<boolean>(false);

  const actionWithLoading = async (callback: () => MaybePromise<any>) => {
    try {
      setLoading(true);
      await callback();
    } catch (error) {
      console.debug(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStep = async (
    action: 'next' | 'select',
    step: CreateEventSteps,
  ) => {
    const isSuccess = await onChangeStep(step);

    if (isSuccess) {
      if (action === 'next') {
        handleNextStep();
      } else if (action === 'select') {
        handleSelectStep(step);
      }
    } else {
      throw new Error('Failed change step');
    }
  };

  return (
    <Stepper
      nav={navItems}
      contentSlot={createElement(contents[currentStep.key])}
      loading={loading}
      onSelectStep={(step) =>
        actionWithLoading(() => handleChangeStep('select', step))
      }
      actionSlot={
        <StepperActionsWrap>
          <Button color="secondary" onClick={() => actionWithLoading(onDraft)}>
            Сохранить в черновик
          </Button>
          {nextStep ? (
            <Button
              onClick={() =>
                actionWithLoading(() => handleChangeStep('next', nextStep.key))
              }>
              Далее: {STEPPER_LABELS[nextStep.key]}
            </Button>
          ) : (
            <Button onClick={() => actionWithLoading(onPublish)}>
              Опубликовать
            </Button>
          )}
        </StepperActionsWrap>
      }
    />
  );
};
