import { gql } from '@apollo/client';

export const ordersList = gql`
  query ordersList(
    $filter: OrdersListFilterInput
    $organizerId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $status: [OrderStatusEnum!]
  ) {
    ordersList(
      filters: $filter
      args: {
        organizerId: $organizerId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: $status }
    ) {
      count
      rows {
        createdAt
        id
        status
        fixedHoldPercentage
        payment {
          price
          status
        }
        referralOrganizer {
          key
          percentage
        }
        user {
          first_name
          last_name
          email
          username
          uuid
          phone
        }
        product {
          name
          startedDate
          endDate
        }
        userBalanceWithdrawal {
          amount
        }
        tariff {
          name
        }
        pairOrder {
          user {
            first_name
            last_name
            email
            username
            uuid
            phone
          }
        }
      }
    }
  }
`;
export const ordersCount = gql`
  query ordersCount(
    $filter: OrdersListFilterInput
    $organizerId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $status: [OrderStatusEnum!]
  ) {
    ordersList(
      filters: $filter
      args: {
        organizerId: $organizerId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: $status }
    ) {
      count
    }
  }
`;

export const giveOrders = gql`
  mutation giveOrders(
    $usersUuids: [UUID!]!
    $tariffUuid: UUID!
    $productUuid: UUID!
    $params: OrdersActionNotifyParams
  ) {
    giveOrders(
      usersUuids: $usersUuids
      tariffUuid: $tariffUuid
      productUuid: $productUuid
      params: $params
    ) {
      status
      uuidUser
      error
    }
  }
`;
export const eventInviteTexts = gql`
  query eventInviteTexts($uuid: UUID!) {
    product(uuid: $uuid) {
      inviteOrderSuccessText(disableShortcode: true)
      inviteOrderText(disableShortcode: true)
    }
  }
`;
export const createInvitesOrders = gql`
  mutation createInvitesOrders(
    $usersUuids: [UUID!]!
    $tariffUuid: UUID!
    $productUuid: UUID!
    $params: OrdersActionNotifyParams
  ) {
    createInviteOrders(
      usersUuids: $usersUuids
      tariffUuid: $tariffUuid
      productUuid: $productUuid
      params: $params
    ) {
      status
      uuidUser
      error
    }
  }
`;

export const exportOrdersCSV = gql`
  mutation exportOrdersCSV($productUuid: UUID!) {
    exportOrdersCSV(productUuid: $productUuid) {
      url
    }
  }
`;

export const disableOrder = gql`
  mutation disableOrder($id: Int!) {
    disableOrder(id: $id) {
      uuid
    }
  }
`;

export const transferOrderToProduct = gql`
  mutation transferOrderToProduct(
    $notify: OrderActionEmailNotifyParam
    $productUuid: UUID!
    $orderId: Int!
  ) {
    transferUserOrderToProduct(
      notify: $notify
      productUuid: $productUuid
      orderId: $orderId
    ) {
      uuid
    }
  }
`;
