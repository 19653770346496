import { FC, PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';

import { MeQuery, RoleEnum, useMeQuery } from '@/apolloGenerated';
import { BotSelect } from '@/features/Bot';
import { isAdmin, LogoIconShort } from '@/shared';
import { HomeRouteName, RouterName } from '@app/router';
import { useOrganizerBotStore } from '@entities/Bot';
import {
  BagIcon,
  BookmarkIcon,
  CodeIcon,
  CompassIcon,
  DocumentIcon,
  EditIcon,
  Header as HeaderUI,
  NavItem,
  useRouterPath,
  UserSquareIcon,
  WalletIcon,
} from '@letsdance/ui-kit';
import { useFeatureFlags } from '@shared/libs/hooks/useFeatureFlags';

import { ProfileControl } from '../ProfileControl/ProfileControl';

export interface HeaderProps {
  user?: MeQuery['me'];
  dense?: boolean;

  onNavClick?(): void;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({
  dense,
  onNavClick,
}) => {
  const { checkActivePath } = useRouterPath();
  const { data } = useMeQuery();
  const { viewReferral } = useFeatureFlags();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { allOrganizerBots } = useOrganizerBotStore();

  return (
    <HeaderUI
      dense={dense}
      homeLink={HomeRouteName[data?.me.role || RoleEnum.Organizer]}
      logoSlot={<LogoIconShort color="var(--neutrals-1)" />}
      navigationSlot={
        <>
          {data?.me.role === RoleEnum.Organizer && (
            <>
              <NavItem
                to={RouterName.Profile}
                icon={EditIcon}
                active={checkActivePath(RouterName.Profile)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Профиль
              </NavItem>
              <NavItem
                to={RouterName.Events}
                icon={BookmarkIcon}
                active={checkActivePath(RouterName.Events)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Меропр.
              </NavItem>
              <NavItem
                to={RouterName.BaseTariffs}
                icon={DocumentIcon}
                active={checkActivePath(RouterName.BaseTariffs)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Тарифы
              </NavItem>
              <NavItem
                to={RouterName.Addresses}
                icon={CompassIcon}
                active={checkActivePath(RouterName.Addresses)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Места
              </NavItem>
              <NavItem
                to={RouterName.Contact}
                icon={UserSquareIcon}
                active={checkActivePath(RouterName.Contact)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Контакты
              </NavItem>
              <NavItem
                to={RouterName.Purchases}
                icon={BagIcon}
                active={checkActivePath(RouterName.Purchases)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Билеты
              </NavItem>
              <NavItem
                to={RouterName.Finance}
                icon={WalletIcon}
                active={checkActivePath(RouterName.Finance)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Финансы
              </NavItem>
              {viewReferral && (
                <NavItem
                  to={RouterName.Referral}
                  icon={CodeIcon}
                  active={checkActivePath(RouterName.Referral)}
                  fullWidth
                  hideLabel={dense}
                  onClick={onNavClick}>
                  Рефер. система
                </NavItem>
              )}
            </>
          )}
          {isAdmin(data?.me.role) && (
            <>
              <NavItem
                to={RouterName.Organizers}
                icon={UserSquareIcon}
                active={checkActivePath(RouterName.Organizers)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Организ.
              </NavItem>
              <NavItem
                to={RouterName.Bots}
                icon={CodeIcon}
                active={checkActivePath(RouterName.Bots)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Боты
              </NavItem>
              <NavItem
                to={RouterName.Terminals}
                icon={BagIcon}
                active={checkActivePath(RouterName.Terminals)}
                fullWidth
                hideLabel={dense}
                onClick={onNavClick}>
                Терминалы
              </NavItem>
            </>
          )}
        </>
      }
      bottomSlot={
        isMobile ? (
          <></>
        ) : (
          <>
            {!isAdmin(data?.me.role) && allOrganizerBots.length > 1 && (
              <BotSelect />
            )}
            <ProfileControl />
          </>
        )
      }
    />
  );
};
