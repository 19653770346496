import { FC } from 'react';

import { Container } from '@/shared';
import { Head } from '@widgets/Layout';
import { TerminalsLists } from '@widgets/Terminal';

export const TerminalsView: FC = () => (
  <div>
    <Container>
      <Head title="Платёжные терминалы" />
    </Container>
    <TerminalsLists />
  </div>
);
