import { FC, useEffect, useMemo, useState } from 'react';

import {
  BotsListQuery,
  BotSortKeys,
  Maybe,
  useBotsListQuery,
} from '@/apolloGenerated';
import {
  createTgLink,
  formatSort,
  renderCellDate,
  renderCellItemValue,
} from '@/shared';
import { BotStatus } from '@entities/Bot';
import { BotControl } from '@features/Bot';
import {
  LoaderOverlay,
  SortType,
  stopEvent,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

const headers = [
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 180 },
  { sortable: true, title: 'Статус', value: 'active', width: 120 },
  { key: true, sortable: true, title: 'ID', value: 'id', width: 120 },
  { sortable: true, title: 'Данные', value: 'username' },
  { title: 'Пользователей', value: 'usersCount' },
  { title: 'Организаторов', value: 'organizersCount' },
  { title: '', value: 'control', width: 60 },
];

const pageSize = 15;
const DEFAULT_PAGE = 1;

export interface BotsTableProps {
  active?: boolean;
}

export const BotsTable: FC<BotsTableProps> = ({ active }) => {
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<BotSortKeys>(BotSortKeys.Id);

  useEffect(() => {
    if (active) {
      setPage(DEFAULT_PAGE);
    }
  }, [active]);

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useBotsListQuery({
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      isActive: active || null,
    },
  });

  const data = curData || previousData;

  const rowTemplate = (bot: BotsListQuery['botsList']['rows'][0]) => ({
    active: <BotStatus active={bot.active} />,
    control: <BotControl id={bot.id} active={bot.active} />,
    createdAt: renderCellDate({ timestamp: bot.createdAt }),
    id: bot.id,
    organizersCount: String(bot.organizers.length),
    username: renderCellItemValue({
      label: bot.name,
      value: (
        <Typography
          className="cursor-pointer"
          onClick={(e) => {
            stopEvent(e);
            window.open(createTgLink(bot.username), '_blank');
          }}
          variant="body-14"
          color="primary-1">
          {`@${bot.username}`}
        </Typography>
      ),
    }),
    usersCount: String(bot.usersCount),
  });
  const items = useMemo(
    () => (data?.botsList?.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.botsList?.count}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as BotSortKeys);
        }}
        notResetPage
        onRefetch={error && refetch}
        hasError={!!error}
      />
    </div>
  );
};
