import React, { FC, useEffect, useState } from 'react';

import { BotInput, Errors, useCreateBotMutation } from '@/apolloGenerated';
import { ApolloErrorService } from '@/shared';
import { BotForm } from '@entities/Bot';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface CreateBotModalProps {
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    createBot: CreateBotModalProps;
  }
}
export const CreateBotModal: FC<WithCloseModal<CreateBotModalProps>> = ({
  onClose,
  onSuccess,
}): JSX.Element => {
  const [createBot, { client, data, error, loading }] = useCreateBotMutation();
  const [botInput, setBotInput] = useState<BotInput>();
  const hasBotInput = botInput?.name && botInput?.token;
  const handleSubmit = () => {
    createBot({ variables: { input: botInput! } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Бот успешно активирован',
      });
      client.cache.evict({ fieldName: 'botsList' });
      onSuccess && onSuccess();
      onClose();
    }
    if (
      ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
        ?.validationErrors
    ) {
      console.log(error);
    } else if (
      ApolloErrorService.hasError(
        error?.graphQLErrors,
        Errors.IncorrectBotToken,
      )
    ) {
      toaster.error({
        title:
          'Неверный токен telegram бота. Перепроверьте правильность его заполнения',
      });
    } else if (error) {
      toaster.error({
        title: 'Ошибка добавления бота. Обратитесь за помощью в техподдержку',
      });
    }
  }, [data, error]);

  return (
    <Dialog
      title="Добавление нового бота"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button
                fullWidth
                onClick={handleSubmit}
                disabled={loading || !hasBotInput}>
                Добавить
              </Button>
            </div>
          }
        />
      }>
      <BotForm
        onChange={setBotInput}
        errors={
          ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
            ?.validationErrors
        }
      />
    </Dialog>
  );
};
