import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: number; output: number; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: { input: Date; output: Date; }
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian
   * calendar.This scalar is serialized to a string in ISO 8601 format and parsed
   * from a string in ISO 8601 format.
   */
  DateTimeISO: { input: number; output: number; }
  /**
   * A field whose value conforms to the standard internet email address format as
   * specified in HTML Spec:
   * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address.
   */
  EmailAddress: { input: string; output: string; }
  /**
   * A string representing a duration conforming to the ISO8601 standard,
   * such as: P1W1DT13H23M34S
   * P is the duration designator (for period) placed at the start of the duration representation.
   * Y is the year designator that follows the value for the number of years.
   * M is the month designator that follows the value for the number of months.
   * W is the week designator that follows the value for the number of weeks.
   * D is the day designator that follows the value for the number of days.
   * T is the time designator that precedes the time components of the representation.
   * H is the hour designator that follows the value for the number of hours.
   * M is the minute designator that follows the value for the number of minutes.
   * S is the second designator that follows the value for the number of seconds.
   *
   * Note the time designator, T, that precedes the time value.
   *
   * Matches moment.js, Luxon and DateFns implementations
   * ,/. is valid for decimal places and +/- is a valid prefix
   */
  ISO8601Duration: { input: any; output: any; }
  /**
   * A field whose value conforms to the standard E.164 format as specified in:
   * https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234.
   */
  PhoneNumber: { input: string; output: string; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: string; output: string; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File; output: File; }
  /** Represents NULL values */
  Void: { input: any; output: any; }
};

export type AmountStatsType = {
  __typename?: 'AmountStatsType';
  commission: Scalars['Float']['output'];
  profit: Scalars['Float']['output'];
  referral: Scalars['Float']['output'];
  turnover: Scalars['Float']['output'];
};

export type BaseBotType = {
  __typename?: 'BaseBotType';
  id: Scalars['Float']['output'];
  username: Scalars['String']['output'];
};

export type BaseGraphModel = {
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type BaseTariffInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  featureFlags: OrderFeatureFlagsInput;
  isLimitedOrders: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  ordersCount?: InputMaybe<Scalars['Float']['input']>;
  price: Scalars['Float']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  type?: TariffTypeEnum;
  visible: Scalars['Boolean']['input'];
};

export type BaseTariffListFilterInput = {
  order?: InputMaybe<BaseTariffSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<BaseTariffSearchType>;
};

export enum BaseTariffSearchKeysEnum {
  Description = 'description',
  Name = 'name',
  Type = 'type'
}

export type BaseTariffSearchType = {
  search: Scalars['String']['input'];
  searchBy: BaseTariffSearchKeysEnum;
};

export enum BaseTariffSortKeysEnum {
  CreatedAt = 'createdAt',
  Name = 'name',
  OrdersCount = 'ordersCount',
  Price = 'price',
  Query = 'query',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Visible = 'visible'
}

export type BaseTariffSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: BaseTariffSortKeysEnum;
};

export type BaseTariffType = BaseGraphModel & NodeUuidType & {
  __typename?: 'BaseTariffType';
  countSubTariffs: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  featureFlags: OrderFeatureFlagsType;
  isLimitedOrders: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  ordersCount?: Maybe<Scalars['Float']['output']>;
  price: Scalars['Float']['output'];
  query?: Maybe<Scalars['String']['output']>;
  status: StatusEnum;
  type: TariffTypeEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
  visible: Scalars['Boolean']['output'];
};

export type BaseTariffsResponse = {
  __typename?: 'BaseTariffsResponse';
  count: Scalars['Int']['output'];
  rows: Array<BaseTariffType>;
};

export enum BaseTextsKeysEnum {
  OrganizerOrderInstructionsRefundText = 'OrganizerOrderInstructionsRefundText',
  OrganizerOrderInstructionsTransferToProductText = 'OrganizerOrderInstructionsTransferToProductText',
  OrganizerOrderInstructionsTransferToUserText = 'OrganizerOrderInstructionsTransferToUserText',
  ProductInviteOrderSuccessText = 'ProductInviteOrderSuccessText',
  ProductInviteOrderText = 'ProductInviteOrderText',
  ProductManualPaymentText = 'ProductManualPaymentText',
  ProductSalesStartedText = 'ProductSalesStartedText',
  ProductSuccessBuyText = 'ProductSuccessBuyText',
  ProductTariffsEmptyText = 'ProductTariffsEmptyText',
  ProductTariffsText = 'ProductTariffsText',
  ProductTariffsUnavailableText = 'ProductTariffsUnavailableText',
  ProductTransferOrderToUserSuccessText = 'ProductTransferOrderToUserSuccessText'
}

export type BaseTextsType = {
  __typename?: 'BaseTextsType';
  key: BaseTextsKeysEnum;
  text: Scalars['String']['output'];
};

export type BotInfoType = {
  __typename?: 'BotInfoType';
  id: Scalars['Float']['output'];
  token: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type BotInput = {
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type BotListFilterInput = {
  order?: InputMaybe<BotSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<BotSearchType>;
};

export type BotListParamsInput = {
  organizerId?: InputMaybe<Scalars['Float']['input']>;
};

export enum BotSearchKeys {
  Id = 'id',
  Name = 'name',
  Token = 'token',
  Username = 'username'
}

export type BotSearchType = {
  search: Scalars['String']['input'];
  searchBy: BotSearchKeys;
};

export enum BotSortKeys {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Token = 'token',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type BotSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: BotSortKeys;
};

export type BotType = BaseGraphModel & {
  __typename?: 'BotType';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  organizers: Array<OrganizerType>;
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  username: Scalars['String']['output'];
  usersCount: Scalars['Int']['output'];
};

export type BotsResponse = {
  __typename?: 'BotsResponse';
  count: Scalars['Int']['output'];
  rows: Array<BotType>;
};

export type CreateAdminUserInput = {
  organizerId: Scalars['Int']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateManualInvoiceInput = {
  keyReferralOrganizer?: InputMaybe<Scalars['String']['input']>;
  uuidManualTerminal: Scalars['UUID']['input'];
  uuidPairUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidTariff: Scalars['UUID']['input'];
};

export type CreateTerminalInvoiceInput = {
  keyReferralOrganizer?: InputMaybe<Scalars['String']['input']>;
  uuidPairUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidTariff: Scalars['UUID']['input'];
  uuidTerminal: Scalars['UUID']['input'];
};

export type ErrorType = {
  __typename?: 'ErrorType';
  errors: Errors;
};

export enum Errors {
  AcquiringTurnOff = 'AcquiringTurnOff',
  AlreadyHasInvite = 'AlreadyHasInvite',
  AlreadyPaid = 'AlreadyPaid',
  BotNotFound = 'BotNotFound',
  CanActivateOnlyDraftItem = 'CanActivateOnlyDraftItem',
  CanArchiveOnlyActiveItem = 'CanArchiveOnlyActiveItem',
  CanNotAddArchiveTariffForTransfer = 'CanNotAddArchiveTariffForTransfer',
  CanNotAddLimitedTariffForTransfer = 'CanNotAddLimitedTariffForTransfer',
  CanNotAddNotAnyGenderTariffForTransfer = 'CanNotAddNotAnyGenderTariffForTransfer',
  CanNotChangeUserInfo = 'CanNotChangeUserInfo',
  CanNotClearCurrentUser = 'CanNotClearCurrentUser',
  CanNotCreateOrderWithDifferentGender = 'CanNotCreateOrderWithDifferentGender',
  CanNotEditAdminUser = 'CanNotEditAdminUser',
  CanNotRemoveActiveProduct = 'CanNotRemoveActiveProduct',
  CanNotRemoveProductWithOrders = 'CanNotRemoveProductWithOrders',
  CanNotTransferOrderUserWithDifferentGender = 'CanNotTransferOrderUserWithDifferentGender',
  CouldNotRefreshToken = 'CouldNotRefreshToken',
  EmailAlreadyConfirmed = 'EmailAlreadyConfirmed',
  EventNotBelongOrganizer = 'EventNotBelongOrganizer',
  FailedConfirmEmail = 'FailedConfirmEmail',
  FailedGetPayment = 'FailedGetPayment',
  FailedInitPayment = 'FailedInitPayment',
  FailedRemovePaymentAccount = 'FailedRemovePaymentAccount',
  FailedSendMail = 'FailedSendMail',
  FailedUpdatePayment = 'FailedUpdatePayment',
  FailedUploadFile = 'FailedUploadFile',
  FeatureDisabled = 'FeatureDisabled',
  IncorrectBotToken = 'IncorrectBotToken',
  IncorrectOrderStatusForAction = 'IncorrectOrderStatusForAction',
  InsufficientFundsOnBalance = 'InsufficientFundsOnBalance',
  InsufficientFundsOnUserBalance = 'InsufficientFundsOnUserBalance',
  InvalidAccessToken = 'InvalidAccessToken',
  InvalidAuthData = 'InvalidAuthData',
  InvalidCode = 'InvalidCode',
  InvalidFileType = 'InvalidFileType',
  MaxBotsOrganizerHasBeenAchieved = 'MaxBotsOrganizerHasBeenAchieved',
  MaxTransfersOrderReached = 'MaxTransfersOrderReached',
  NotForSceneButton = 'NotForSceneButton',
  NotFound = 'NotFound',
  NotFoundAccessToken = 'NotFoundAccessToken',
  NotPassedFullSupplierInfo = 'NotPassedFullSupplierInfo',
  NotPassedOrderForDepositUserBalance = 'NotPassedOrderForDepositUserBalance',
  NotSendCode = 'NotSendCode',
  OrderIsNotPaid = 'OrderIsNotPaid',
  OrderNotFound = 'OrderNotFound',
  OrderWithEmptyAmount = 'OrderWithEmptyAmount',
  OrganizerAlreadyHasBot = 'OrganizerAlreadyHasBot',
  OrganizerHasMaximumSynonyms = 'OrganizerHasMaximumSynonyms',
  OrganizerHasNotBot = 'OrganizerHasNotBot',
  OrganizerNotActive = 'OrganizerNotActive',
  OrganizerNotFound = 'OrganizerNotFound',
  PairUserNotFound = 'PairUserNotFound',
  PairUserNotPassed = 'PairUserNotPassed',
  PairUserWithSameGender = 'PairUserWithSameGender',
  PaymentAccountNotFound = 'PaymentAccountNotFound',
  PaymentAlreadyCancelled = 'PaymentAlreadyCancelled',
  PaymentAlreadyExecuted = 'PaymentAlreadyExecuted',
  PaymentAlreadySubmitted = 'PaymentAlreadySubmitted',
  PaymentNotActive = 'PaymentNotActive',
  PaymentNotFound = 'PaymentNotFound',
  PermissionDenied = 'PermissionDenied',
  ProductAddressNotFound = 'ProductAddressNotFound',
  ProductAlreadyHaveThisStatus = 'ProductAlreadyHaveThisStatus',
  ProductBeforeCurrent = 'ProductBeforeCurrent',
  ProductIsArchived = 'ProductIsArchived',
  ProductIsNotActive = 'ProductIsNotActive',
  ProductNotFound = 'ProductNotFound',
  RepeatOrderTurnOff = 'RepeatOrderTurnOff',
  SalesNotStarted = 'SalesNotStarted',
  SceneButtonNotFound = 'SceneButtonNotFound',
  SceneNotFound = 'SceneNotFound',
  TariffForTransferNotFound = 'TariffForTransferNotFound',
  TariffIsUnavailable = 'TariffIsUnavailable',
  TariffNotActive = 'TariffNotActive',
  TariffNotFound = 'TariffNotFound',
  TerminalNotFound = 'TerminalNotFound',
  TooManyButtonsInScene = 'TooManyButtonsInScene',
  UnknownError = 'UnknownError',
  UserAlreadyBlocked = 'UserAlreadyBlocked',
  UserAlreadyHaveAccessTariff = 'UserAlreadyHaveAccessTariff',
  UserAlreadyHaveNotAccessTariff = 'UserAlreadyHaveNotAccessTariff',
  UserAlreadyHaveOrder = 'UserAlreadyHaveOrder',
  UserAlreadyInBot = 'UserAlreadyInBot',
  UserAlreadyMemberInGroup = 'UserAlreadyMemberInGroup',
  UserAlreadySpecial = 'UserAlreadySpecial',
  UserBalanceNotFound = 'UserBalanceNotFound',
  UserGroupNotFound = 'UserGroupNotFound',
  UserNotBlocked = 'UserNotBlocked',
  UserNotFound = 'UserNotFound',
  UserNotFoundInSpecial = 'UserNotFoundInSpecial',
  UserNotMemberInGroup = 'UserNotMemberInGroup',
  UserNotRegistered = 'UserNotRegistered',
  UserWithThisEmailAlreadyExist = 'UserWithThisEmailAlreadyExist',
  UserWithThisTokenNotFound = 'UserWithThisTokenNotFound'
}

export type ExportOrdersCsvType = {
  __typename?: 'ExportOrdersCSVType';
  url: Scalars['String']['output'];
};

export type FileType = {
  __typename?: 'FileType';
  url: Scalars['String']['output'];
};

export type FilterUsersInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isRegistered?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male'
}

export type GiveOrderResultType = {
  __typename?: 'GiveOrderResultType';
  error?: Maybe<Errors>;
  id?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Boolean']['output'];
  uuidUser: Scalars['UUID']['output'];
};

export type InvoiceType = {
  __typename?: 'InvoiceType';
  order: OrderType;
  payment?: Maybe<PaymentType>;
};

export type JwtType = {
  __typename?: 'JwtType';
  access_token: Scalars['String']['output'];
  refresh_token: Scalars['String']['output'];
};

export enum LanguageEnum {
  English = 'English',
  Russian = 'Russian'
}

export type LoginInput = {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginTgInput = {
  password: Scalars['String']['input'];
  tgId: Scalars['Float']['input'];
};

export type ManualTerminalType = BaseGraphModel & NodeUuidType & {
  __typename?: 'ManualTerminalType';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  holdPercentage: Scalars['Float']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: StatusEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateBot: Scalars['Void']['output'];
  /** Method for activating an order by organizer on event */
  activateOrder: Scalars['Void']['output'];
  activateProduct: ProductType;
  addBotToOrganizer: Scalars['Void']['output'];
  addManualTerminalToProduct: Scalars['Void']['output'];
  addPaymentAccount: PaymentAccountType;
  addUserBalance: Scalars['Boolean']['output'];
  addUserOrganizer: Scalars['Void']['output'];
  addUserToBlacklist: Scalars['Void']['output'];
  addUserToBot: Scalars['Void']['output'];
  addUserToGroup: Scalars['Void']['output'];
  archiveProduct: ProductType;
  botInfoByToken: BotInfoType;
  cancelInvite: Scalars['Void']['output'];
  cancelInvoice: Scalars['Void']['output'];
  cancelManualInvoice: PaymentType;
  cancelWithdrawal: Scalars['Void']['output'];
  changeLanguage: Scalars['Void']['output'];
  clearUserByTg: Scalars['Void']['output'];
  confirmEmail: Scalars['Void']['output'];
  crateUserGroup: UserGroupType;
  createAddressProduct: ProductAddressType;
  createAdminUser: UserType;
  createBaseTariff: BaseTariffType;
  createBot: BotType;
  createInviteOrders: Array<OrderInviteResultType>;
  createInvoice: InvoiceType;
  createManualInvoice: InvoiceType;
  createOrganizer: OrganizerType;
  createOrganizerSynonym: OrganizerSynonymType;
  createProduct: ProductType;
  createReferralOrganizer: ReferralOrganizerType;
  createScene: SceneType;
  createTariff: SubTariffType;
  createTerminal: TerminalType;
  /** Method for deactivating an order by organizer on event */
  deactivateOrder: Scalars['Void']['output'];
  deactivateUserOrganizer?: Maybe<UserType>;
  disableBot: Scalars['Void']['output'];
  /** Method for disabling an order and canceling payment with a refund from the teacher’s balance */
  disableOrder: OrderType;
  duplicateBaseTariff: BaseTariffType;
  duplicateProduct: ProductType;
  exportOrdersCSV: ExportOrdersCsvType;
  giveOrders: Array<GiveOrderResultType>;
  login: JwtType;
  loginTg: JwtType;
  logout: Scalars['Void']['output'];
  payBonusInvoice: PaymentType;
  refresh: JwtType;
  refundOrder: OrderType;
  removeAddressProduct: Scalars['Void']['output'];
  removeBaseTariff: Scalars['Void']['output'];
  removeBot: Scalars['Void']['output'];
  removeBotFromOrganizer: Scalars['Void']['output'];
  removeManualTerminalFromProduct: Scalars['Void']['output'];
  removeOrganizerSynonym: Scalars['Void']['output'];
  removePaymentAccount: Scalars['Void']['output'];
  removeProduct: Scalars['Void']['output'];
  removeReferralOrganizer: Scalars['Void']['output'];
  removeScene: Scalars['Void']['output'];
  removeTariff: Scalars['Void']['output'];
  removeTerminal: Scalars['Void']['output'];
  removeUserFromBlacklist: Scalars['Void']['output'];
  removeUserFromGroup: Scalars['Void']['output'];
  removeUserGroup: Scalars['Void']['output'];
  renameReferralOrganizer: ReferralOrganizerType;
  requestWithdrawal: Scalars['Void']['output'];
  saveInvoiceProofs: Scalars['Void']['output'];
  saveUserInfo: UserType;
  signUp: UserType;
  submitInvite: OrderType;
  submitManualInvoice: PaymentType;
  /** A way to transfer your order to another product. The order must not be completed */
  transferOrderToProduct: OrderType;
  /** Method for transferring your order to another user. The order must not be completed */
  transferOrderToUser: OrderType;
  /** Method for transfer order to product for admin */
  transferUserOrderToProduct: OrderType;
  updateAddressProduct: ProductAddressType;
  updateBaseTariff: BaseTariffType;
  updateMainInfoProduct: ProductType;
  updateManualTerminalText: Scalars['Void']['output'];
  updateOrganizer: OrganizerType;
  updateOrganizerFeatures: OrganizerFeatureType;
  updateOrganizerSynonyms: Scalars['Void']['output'];
  updatePaymentAccount?: Maybe<PaymentAccountType>;
  updateProduct: ProductType;
  updateProductInviteTexts: ProductType;
  updateProductTariffs: Scalars['Void']['output'];
  updateProductTerminals: Scalars['Void']['output'];
  updateScene: SceneType;
  updateTariff: SubTariffType;
  updateTerminal: TerminalType;
  updateTerminalLabel: TerminalType;
  updateUser: UserType;
  updateUserGroupName: UserGroupType;
  updateUserGroupsBaseTariff: Scalars['Void']['output'];
  updateUsersInGroup: Scalars['Void']['output'];
  uploadFile: FileType;
  uploadImages: Array<FileType>;
  verifyEmail: UserType;
  verifyEmailLater: UserType;
};


export type MutationActivateBotArgs = {
  id: Scalars['Float']['input'];
};


export type MutationActivateOrderArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationActivateProductArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationAddBotToOrganizerArgs = {
  idBot: Scalars['Float']['input'];
  idOrganizer: Scalars['Float']['input'];
};


export type MutationAddManualTerminalToProductArgs = {
  uuidManualTerminal: Scalars['UUID']['input'];
  uuidProduct: Scalars['UUID']['input'];
};


export type MutationAddPaymentAccountArgs = {
  idOrganizer: Scalars['Int']['input'];
  input: PaymentAccountInput;
};


export type MutationAddUserBalanceArgs = {
  amount: Scalars['Float']['input'];
  organizerId: Scalars['Int']['input'];
  uuidUser: Scalars['UUID']['input'];
};


export type MutationAddUserOrganizerArgs = {
  idOrganizer: Scalars['Float']['input'];
};


export type MutationAddUserToBlacklistArgs = {
  organizerId: Scalars['Float']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationAddUserToGroupArgs = {
  uuidGroup: Scalars['UUID']['input'];
  uuidUser: Scalars['UUID']['input'];
};


export type MutationArchiveProductArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationBotInfoByTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationCancelInviteArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationCancelInvoiceArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationCancelManualInvoiceArgs = {
  uuidPayment: Scalars['UUID']['input'];
};


export type MutationCancelWithdrawalArgs = {
  idOrganizer: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationChangeLanguageArgs = {
  language: LanguageEnum;
};


export type MutationClearUserByTgArgs = {
  tgId: Scalars['Float']['input'];
};


export type MutationConfirmEmailArgs = {
  email: Scalars['EmailAddress']['input'];
};


export type MutationCrateUserGroupArgs = {
  idOrganizer: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateAddressProductArgs = {
  idOrganizer: Scalars['Int']['input'];
  input: ProductAddressInput;
};


export type MutationCreateAdminUserArgs = {
  input: CreateAdminUserInput;
};


export type MutationCreateBaseTariffArgs = {
  idOrganizer: Scalars['Int']['input'];
  input: BaseTariffInput;
};


export type MutationCreateBotArgs = {
  input: BotInput;
};


export type MutationCreateInviteOrdersArgs = {
  params?: InputMaybe<OrdersActionNotifyParams>;
  productUuid: Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  usersUuids: Array<Scalars['UUID']['input']>;
};


export type MutationCreateInvoiceArgs = {
  input: CreateTerminalInvoiceInput;
};


export type MutationCreateManualInvoiceArgs = {
  input: CreateManualInvoiceInput;
};


export type MutationCreateOrganizerArgs = {
  input: OrganizerInput;
};


export type MutationCreateOrganizerSynonymArgs = {
  idOrganizer: Scalars['Float']['input'];
  value: Scalars['String']['input'];
};


export type MutationCreateProductArgs = {
  input: ProductMainInfoInput;
  organizerId: Scalars['Float']['input'];
  publish?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateReferralOrganizerArgs = {
  input: ReferralOrganizerInput;
};


export type MutationCreateSceneArgs = {
  buttons: Array<Array<SceneButtonInput>>;
  input: SceneInput;
  uuidButton: Scalars['UUID']['input'];
};


export type MutationCreateTariffArgs = {
  input: SubTariffInput;
  uuidParentTariff: Scalars['UUID']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCreateTerminalArgs = {
  idOrganizer: Scalars['Float']['input'];
  input: TerminalInput;
};


export type MutationDeactivateOrderArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeactivateUserOrganizerArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDisableBotArgs = {
  id: Scalars['Float']['input'];
};


export type MutationDisableOrderArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDuplicateBaseTariffArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDuplicateProductArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationExportOrdersCsvArgs = {
  productUuid: Scalars['UUID']['input'];
};


export type MutationGiveOrdersArgs = {
  params?: InputMaybe<OrdersActionNotifyParams>;
  productUuid: Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  usersUuids: Array<Scalars['UUID']['input']>;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginTgArgs = {
  input: LoginTgInput;
};


export type MutationPayBonusInvoiceArgs = {
  params: PayBonusInvoiceInput;
};


export type MutationRefreshArgs = {
  refresh_token: Scalars['String']['input'];
};


export type MutationRefundOrderArgs = {
  orderId: Scalars['Int']['input'];
};


export type MutationRemoveAddressProductArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveBaseTariffArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveBotArgs = {
  id: Scalars['Float']['input'];
};


export type MutationRemoveBotFromOrganizerArgs = {
  idBot: Scalars['Float']['input'];
  idOrganizer: Scalars['Float']['input'];
};


export type MutationRemoveManualTerminalFromProductArgs = {
  uuidProduct: Scalars['UUID']['input'];
};


export type MutationRemoveOrganizerSynonymArgs = {
  idOrganizer: Scalars['Float']['input'];
  value: Scalars['String']['input'];
};


export type MutationRemovePaymentAccountArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveProductArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveReferralOrganizerArgs = {
  key: Scalars['String']['input'];
};


export type MutationRemoveSceneArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveTariffArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveTerminalArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveUserFromBlacklistArgs = {
  organizerId: Scalars['Float']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveUserFromGroupArgs = {
  uuidGroup: Scalars['UUID']['input'];
  uuidUser: Scalars['UUID']['input'];
};


export type MutationRemoveUserGroupArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRenameReferralOrganizerArgs = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationRequestWithdrawalArgs = {
  input: WithdrawalOrganizerInput;
};


export type MutationSaveInvoiceProofsArgs = {
  image: Scalars['Upload']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationSaveUserInfoArgs = {
  input: SaveUserInfoInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSubmitInviteArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationSubmitManualInvoiceArgs = {
  uuidPayment: Scalars['UUID']['input'];
};


export type MutationTransferOrderToProductArgs = {
  orderId: Scalars['Int']['input'];
  productUuid: Scalars['UUID']['input'];
};


export type MutationTransferOrderToUserArgs = {
  orderId: Scalars['Int']['input'];
  transferUserUuid: Scalars['UUID']['input'];
};


export type MutationTransferUserOrderToProductArgs = {
  notify?: InputMaybe<OrderActionEmailNotifyParam>;
  orderId: Scalars['Int']['input'];
  productUuid: Scalars['UUID']['input'];
};


export type MutationUpdateAddressProductArgs = {
  input: ProductAddressInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateBaseTariffArgs = {
  input: BaseTariffInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateMainInfoProductArgs = {
  input: ProductMainInfoInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateManualTerminalTextArgs = {
  text?: InputMaybe<Scalars['String']['input']>;
  uuidProduct: Scalars['UUID']['input'];
};


export type MutationUpdateOrganizerArgs = {
  id: Scalars['Float']['input'];
  input: OrganizerInput;
};


export type MutationUpdateOrganizerFeaturesArgs = {
  idOrganizer: Scalars['Float']['input'];
  input: OrganizerFeatureInput;
};


export type MutationUpdateOrganizerSynonymsArgs = {
  idOrganizer: Scalars['Float']['input'];
  values: Array<Scalars['String']['input']>;
};


export type MutationUpdatePaymentAccountArgs = {
  input: PaymentAccountInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateProductArgs = {
  input: ProductInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateProductInviteTextsArgs = {
  input: ProductInviteTextsInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateProductTariffsArgs = {
  productUuid: Scalars['UUID']['input'];
  uuids: Array<Scalars['UUID']['input']>;
};


export type MutationUpdateProductTerminalsArgs = {
  uuidProduct: Scalars['UUID']['input'];
  uuidTerminals: Array<Scalars['UUID']['input']>;
};


export type MutationUpdateSceneArgs = {
  buttons: Array<Array<SceneButtonInput>>;
  input: SceneInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateTariffArgs = {
  input: SubTariffInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateTerminalArgs = {
  input: TerminalInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateTerminalLabelArgs = {
  label: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateUserGroupNameArgs = {
  name: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateUserGroupsBaseTariffArgs = {
  userGroupsUuid: Array<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateUsersInGroupArgs = {
  uuidGroup: Scalars['UUID']['input'];
  uuidUsers: Array<Scalars['UUID']['input']>;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadImagesArgs = {
  image: Array<Scalars['Upload']['input']>;
};


export type MutationVerifyEmailArgs = {
  code: Scalars['String']['input'];
};


export type MutationVerifyEmailLaterArgs = {
  email: Scalars['EmailAddress']['input'];
};

export type NodeIdType = {
  id: Scalars['ID']['output'];
};

export type NodeUuidType = {
  uuid: Scalars['UUID']['output'];
};

export type OrderActionEmailNotifyParam = {
  sendNotifyToEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderCheckFeatureFlagsType = {
  __typename?: 'OrderCheckFeatureFlagsType';
  canRefund: Scalars['Boolean']['output'];
  canTransferToProduct: Scalars['Boolean']['output'];
  canTransferToUser: Scalars['Boolean']['output'];
};

export type OrderCountsType = {
  __typename?: 'OrderCountsType';
  awaitPay: Scalars['Float']['output'];
  paid: Scalars['Float']['output'];
  refunded: Scalars['Float']['output'];
  transferredProduct: Scalars['Float']['output'];
  transferredUser: Scalars['Float']['output'];
};

export type OrderFeatureFlagsInput = {
  bonusPay: Scalars['Boolean']['input'];
  refundOrder: Scalars['Boolean']['input'];
  repeatBuyOrder: Scalars['Boolean']['input'];
  transferToProductOrder: Scalars['Boolean']['input'];
  transferToUserOrder: Scalars['Boolean']['input'];
};

export type OrderFeatureFlagsType = {
  __typename?: 'OrderFeatureFlagsType';
  bonusPay: Scalars['Boolean']['output'];
  refundOrder: Scalars['Boolean']['output'];
  repeatBuyOrder: Scalars['Boolean']['output'];
  transferToProductOrder: Scalars['Boolean']['output'];
  transferToUserOrder: Scalars['Boolean']['output'];
};

export type OrderInviteResultType = {
  __typename?: 'OrderInviteResultType';
  error?: Maybe<Errors>;
  status: Scalars['Boolean']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
  uuidUser: Scalars['UUID']['output'];
};

export enum OrderInviteStatusEnum {
  Cancelled = 'Cancelled',
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Withdrawn = 'Withdrawn'
}

export type OrderInviteType = BaseGraphModel & NodeUuidType & {
  __typename?: 'OrderInviteType';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  order: OrderType;
  product: ProductType;
  status: OrderInviteStatusEnum;
  tariff: SubTariffType;
  updatedAt: Scalars['DateTimeISO']['output'];
  user: UserType;
  uuid: Scalars['UUID']['output'];
};

export enum OrderSortKeys {
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  Id = 'id',
  Status = 'status',
  VisitedAt = 'visitedAt'
}

export type OrderSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: OrderSortKeys;
};

export enum OrderStatusEnum {
  CompletedAndNotVisited = 'CompletedAndNotVisited',
  CompletedAndVisited = 'CompletedAndVisited',
  Deactivated = 'Deactivated',
  NotPaid = 'NotPaid',
  Paid = 'Paid',
  Refunded = 'Refunded',
  TransferredToProduct = 'TransferredToProduct',
  TransferredToUser = 'TransferredToUser'
}

export type OrderType = BaseGraphModel & NodeIdType & {
  __typename?: 'OrderType';
  amount: Scalars['Float']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  featureFlags: OrderCheckFeatureFlagsType;
  fixedHoldPercentage: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  orderInvite?: Maybe<OrderType>;
  organizer: OrganizerType;
  pairOrder?: Maybe<OrderType>;
  payment?: Maybe<PaymentType>;
  product: ProductType;
  referralOrganizer?: Maybe<ReferralOrganizerType>;
  status: OrderStatusEnum;
  tariff: TariffType;
  updatedAt: Scalars['DateTimeISO']['output'];
  user: UserType;
  /** Pay by bonus in this order */
  userBalanceWithdrawal?: Maybe<UserBalanceWithdrawalType>;
  uuid: Scalars['UUID']['output'];
  visitedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrdersActionNotifyParams = {
  botId?: InputMaybe<Scalars['Float']['input']>;
  sendNotifyToBot?: InputMaybe<Scalars['Boolean']['input']>;
  sendNotifyToEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrdersFilterInput = {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
};

export type OrdersListFilterInput = {
  order?: InputMaybe<OrderSortType>;
  pagination?: InputMaybe<PaginationType>;
};

export type OrdersParamsInput = {
  status?: InputMaybe<Array<OrderStatusEnum>>;
};

export type OrdersResponse = {
  __typename?: 'OrdersResponse';
  count: Scalars['Int']['output'];
  rows: Array<OrderType>;
};

export type OrganizerFeatureFlagsInput = {
  allowedNumberForTransferToProductOrder: Scalars['Float']['input'];
};

export type OrganizerFeatureFlagsType = {
  __typename?: 'OrganizerFeatureFlagsType';
  allowedNumberForTransferToProductOrder: Scalars['Float']['output'];
};

export type OrganizerFeatureInput = {
  createReferral: Scalars['Boolean']['input'];
  manualPayment: Scalars['Boolean']['input'];
  viewReferral: Scalars['Boolean']['input'];
};

export type OrganizerFeatureType = {
  __typename?: 'OrganizerFeatureType';
  createReferral: Scalars['Boolean']['output'];
  manualPayment: Scalars['Boolean']['output'];
  viewReferral: Scalars['Boolean']['output'];
};

export type OrganizerFilesType = {
  __typename?: 'OrganizerFilesType';
  agent?: Maybe<Scalars['String']['output']>;
  oferta?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Scalars['String']['output']>;
};

export type OrganizerInput = {
  description: Scalars['String']['input'];
  featureFlags: OrganizerFeatureFlagsInput;
  holdPercentage?: InputMaybe<Scalars['Float']['input']>;
  image: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orderInstructions: OrganizerOrderInstructionsInput;
  query?: InputMaybe<Scalars['String']['input']>;
  supplierInfo: OrganizerSupplierInfoInput;
  telegram: Scalars['String']['input'];
};

export type OrganizerListFilterInput = {
  order?: InputMaybe<OrganizerSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<OrganizerSearchType>;
};

export type OrganizerOrderInstructionsInput = {
  refund?: InputMaybe<Scalars['String']['input']>;
  transferToProduct?: InputMaybe<Scalars['String']['input']>;
  transferToUser?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizerOrderInstructionsType = {
  __typename?: 'OrganizerOrderInstructionsType';
  refund: Scalars['String']['output'];
  transferToProduct: Scalars['String']['output'];
  transferToUser: Scalars['String']['output'];
};

export enum OrganizerSearchKeys {
  Description = 'description',
  Name = 'name',
  Telegram = 'telegram'
}

export type OrganizerSearchType = {
  search: Scalars['String']['input'];
  searchBy: OrganizerSearchKeys;
};

export enum OrganizerSortKeys {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Telegram = 'telegram'
}

export type OrganizerSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: OrganizerSortKeys;
};

export type OrganizerSupplierInfoInput = {
  inn?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizerSupplierInfoType = {
  __typename?: 'OrganizerSupplierInfoType';
  inn?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
};

export type OrganizerSynonymType = {
  __typename?: 'OrganizerSynonymType';
  value: Scalars['String']['output'];
};

export type OrganizerType = BaseGraphModel & NodeIdType & {
  __typename?: 'OrganizerType';
  bots: Array<BaseBotType>;
  createdAt: Scalars['DateTimeISO']['output'];
  creditBalance: Scalars['Float']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  description: Scalars['String']['output'];
  featureFlags: OrganizerFeatureFlagsType;
  files: OrganizerFilesType;
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orderInstructions: OrganizerOrderInstructionsType;
  ordersCount: Scalars['Int']['output'];
  paymentAccounts: Array<PaymentAccountType>;
  products: Array<ProductType>;
  productsCount: Scalars['Int']['output'];
  query?: Maybe<Scalars['String']['output']>;
  referralBalance: Scalars['Float']['output'];
  salesBalance: Scalars['Float']['output'];
  statsPayments: AmountStatsType;
  supplierInfo: OrganizerSupplierInfoType;
  synonyms: Array<OrganizerSynonymType>;
  telegram: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userBalance: Scalars['Float']['output'];
  withdrawals: Array<WithdrawalType>;
};


export type OrganizerTypeOrderInstructionsArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizersResponse = {
  __typename?: 'OrganizersResponse';
  count: Scalars['Int']['output'];
  rows: Array<OrganizerType>;
};

export type PaginationType = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PayBonusInvoiceInput = {
  amount: Scalars['Float']['input'];
  paymentUuid: Scalars['UUID']['input'];
};

export type PaymentAccountInput = {
  accountNumber: Scalars['String']['input'];
  bik: Scalars['String']['input'];
  inn: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type PaymentAccountType = BaseGraphModel & NodeUuidType & {
  __typename?: 'PaymentAccountType';
  accountNumber: Scalars['String']['output'];
  bik: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  inn: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
};

export type PaymentProofType = BaseGraphModel & NodeUuidType & {
  __typename?: 'PaymentProofType';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  file: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
};

export enum PaymentSortKeys {
  CreatedAt = 'createdAt',
  Price = 'price',
  Type = 'type'
}

export type PaymentSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: PaymentSortKeys;
};

export enum PaymentStatusEnum {
  AwaitingConfirmation = 'AwaitingConfirmation',
  Canceled = 'Canceled',
  Failed = 'Failed',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Success = 'Success'
}

export type PaymentType = BaseGraphModel & NodeUuidType & {
  __typename?: 'PaymentType';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  invoiceQrLink?: Maybe<Scalars['String']['output']>;
  invoice_url?: Maybe<Scalars['String']['output']>;
  order: OrderType;
  payment_uuid?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  proof?: Maybe<PaymentProofType>;
  status: PaymentStatusEnum;
  terminal?: Maybe<TerminalType>;
  type: PaymentTypeEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  user: UserType;
  uuid: Scalars['UUID']['output'];
};

export enum PaymentTypeEnum {
  Manual = 'Manual',
  Terminal = 'Terminal'
}

export type PaymentsArgsInput = {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
};

export type PaymentsListFilterInput = {
  order?: InputMaybe<PaymentSortType>;
  pagination?: InputMaybe<PaginationType>;
};

export type PaymentsResponse = {
  __typename?: 'PaymentsResponse';
  count: Scalars['Int']['output'];
  rows: Array<PaymentType>;
};

export type ProductAddressFilter = {
  organizerId: Scalars['Int']['input'];
};

export type ProductAddressInput = {
  address: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  img?: InputMaybe<Scalars['String']['input']>;
};

export type ProductAddressListFilterInput = {
  order?: InputMaybe<ProductAddressSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<ProductAddressSearchType>;
};

export enum ProductAddressSearchKeysEnum {
  Address = 'address'
}

export type ProductAddressSearchType = {
  search: Scalars['String']['input'];
  searchBy: ProductAddressSearchKeysEnum;
};

export enum ProductAddressSortKeys {
  Address = 'address',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type ProductAddressSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: ProductAddressSortKeys;
};

export type ProductAddressType = BaseGraphModel & NodeUuidType & {
  __typename?: 'ProductAddressType';
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  img?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
};

export type ProductCountsType = {
  __typename?: 'ProductCountsType';
  activeCount: Scalars['Float']['output'];
  archiveCount: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  draftCount: Scalars['Float']['output'];
};

export type ProductFeatureFlagsInput = {
  bonusPay: Scalars['Boolean']['input'];
  durationBeforeStartForRefundOrder: Scalars['String']['input'];
  durationBeforeStartForTransferToProductOrder: Scalars['String']['input'];
  durationBeforeStartForTransferToUserOrder: Scalars['String']['input'];
  refundOrder: Scalars['Boolean']['input'];
  repeatBuyOrder: Scalars['Boolean']['input'];
  transferFromProductOrder: Scalars['Boolean']['input'];
  transferToProductOrder: Scalars['Boolean']['input'];
  transferToUserOrder: Scalars['Boolean']['input'];
};

export type ProductFeatureFlagsType = {
  __typename?: 'ProductFeatureFlagsType';
  bonusPay: Scalars['Boolean']['output'];
  durationBeforeStartForRefundOrder: Scalars['ISO8601Duration']['output'];
  durationBeforeStartForTransferToProductOrder: Scalars['ISO8601Duration']['output'];
  durationBeforeStartForTransferToUserOrder: Scalars['ISO8601Duration']['output'];
  refundOrder: Scalars['Boolean']['output'];
  repeatBuyOrder: Scalars['Boolean']['output'];
  transferFromProductOrder: Scalars['Boolean']['output'];
  transferToProductOrder: Scalars['Boolean']['output'];
  transferToUserOrder: Scalars['Boolean']['output'];
};

export type ProductFilter = {
  organizerId: Scalars['Int']['input'];
};

export type ProductInput = {
  description: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  featureFlags: ProductFeatureFlagsInput;
  img: Scalars['String']['input'];
  manualPaymentText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  salesStartedDate?: InputMaybe<Scalars['DateTime']['input']>;
  salesStartedText?: InputMaybe<Scalars['String']['input']>;
  startedDate: Scalars['DateTime']['input'];
  successBuyText?: InputMaybe<Scalars['String']['input']>;
  successPairBuyText?: InputMaybe<Scalars['String']['input']>;
  tariffsEmptyText?: InputMaybe<Scalars['String']['input']>;
  tariffsText?: InputMaybe<Scalars['String']['input']>;
  tariffsUnavailableText?: InputMaybe<Scalars['String']['input']>;
  transferOrderToUserSuccessText?: InputMaybe<Scalars['String']['input']>;
  uuidAddress?: InputMaybe<Scalars['String']['input']>;
};

export type ProductInviteTextsInput = {
  inviteOrderSuccessText?: InputMaybe<Scalars['String']['input']>;
  inviteOrderText?: InputMaybe<Scalars['String']['input']>;
};

export type ProductListFilterInput = {
  order?: InputMaybe<ProductSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<ProductSearchType>;
};

export type ProductMainInfoInput = {
  description: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  img: Scalars['String']['input'];
  name: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  startedDate: Scalars['DateTime']['input'];
  uuidAddress?: InputMaybe<Scalars['String']['input']>;
};

export enum ProductSearchKeysEnum {
  Description = 'description',
  Name = 'name'
}

export type ProductSearchType = {
  search: Scalars['String']['input'];
  searchBy: ProductSearchKeysEnum;
};

export enum ProductSortKeys {
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Name = 'name',
  StartedDate = 'startedDate',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type ProductSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: ProductSortKeys;
};

export type ProductType = BaseGraphModel & NodeUuidType & {
  __typename?: 'ProductType';
  address?: Maybe<ProductAddressType>;
  canBeRemoved: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  description: Scalars['String']['output'];
  endDate: Scalars['DateTime']['output'];
  featureFlags: ProductFeatureFlagsType;
  img: Scalars['String']['output'];
  inviteOrderSuccessText: Scalars['String']['output'];
  inviteOrderText: Scalars['String']['output'];
  manualPaymentText: Scalars['String']['output'];
  manualTerminal?: Maybe<ManualTerminalType>;
  name: Scalars['String']['output'];
  organizer: OrganizerType;
  purchasedCount: Scalars['Float']['output'];
  query?: Maybe<Scalars['String']['output']>;
  salesStartedDate?: Maybe<Scalars['DateTime']['output']>;
  salesStartedText?: Maybe<Scalars['String']['output']>;
  startedDate: Scalars['DateTime']['output'];
  statsOrders: AmountStatsType;
  statsPayments: AmountStatsType;
  status: StatusEnum;
  successBuyText: Scalars['String']['output'];
  successPairBuyText: Scalars['String']['output'];
  tariffs: Array<TariffType>;
  tariffsEmptyText: Scalars['String']['output'];
  tariffsText: Scalars['String']['output'];
  tariffsUnavailableText: Scalars['String']['output'];
  transferOrderToUserSuccessText: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
  visitedCount: Scalars['Float']['output'];
};


export type ProductTypeInviteOrderSuccessTextArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductTypeInviteOrderTextArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductTypeManualPaymentTextArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductTypeSalesStartedTextArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductTypeSuccessBuyTextArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductTypeSuccessPairBuyTextArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductTypeTariffsEmptyTextArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductTypeTariffsTextArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductTypeTariffsUnavailableTextArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductTypeTransferOrderToUserSuccessTextArgs = {
  disableShortcode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductsAddressResponse = {
  __typename?: 'ProductsAddressResponse';
  count: Scalars['Int']['output'];
  rows: Array<ProductAddressType>;
};

export type ProductsResponse = {
  __typename?: 'ProductsResponse';
  count: Scalars['Int']['output'];
  rows: Array<ProductType>;
};

export type Query = {
  __typename?: 'Query';
  address: ProductAddressType;
  addressesList: ProductsAddressResponse;
  allowedUserGroupsForBaseTariff: Array<UserGroupType>;
  baseOrganizerScene: SceneType;
  baseProductScene: SceneType;
  baseTariff: BaseTariffType;
  baseTariffsList: BaseTariffsResponse;
  baseTexts: Array<BaseTextsType>;
  blacklist: UsersResponse;
  botsBySecretKey: Array<BotType>;
  botsList: BotsResponse;
  checkCanRepeatOrder: Scalars['Boolean']['output'];
  checkHasOrder: Scalars['Boolean']['output'];
  manualTerminalByOrganizer?: Maybe<ManualTerminalType>;
  me: UserType;
  order: OrderType;
  orderByProductTariff: OrderType;
  orderByUuid: OrderType;
  orderInvite: OrderInviteType;
  orderInviteByProduct: OrderInviteType;
  /** Getting a list of orders for the user */
  orders: OrdersResponse;
  ordersList: OrdersResponse;
  ordersReferralOrganizerList: OrdersResponse;
  /** Method for the client part of the bot. For administrators to use query - organizerAdmin */
  organizer: OrganizerType;
  organizerAdmin: OrganizerType;
  organizerByQuery: OrganizerType;
  organizerFeatures: OrganizerFeatureType;
  organizerShortcodes: Array<Scalars['String']['output']>;
  organizers: OrganizersResponse;
  organizersList: OrganizersResponse;
  payment: PaymentType;
  payments: PaymentsResponse;
  paymentsByOrganizer: Array<PaymentAccountType>;
  paymentsList: PaymentsResponse;
  product: ProductType;
  productByQuery: ProductType;
  productShortcodes: Array<Scalars['String']['output']>;
  products: ProductsResponse;
  productsCount: ProductCountsType;
  productsForTransfer: Array<ProductType>;
  productsList: ProductsResponse;
  referralOrganizer: ReferralOrganizerType;
  referralProfit: Scalars['Float']['output'];
  referralsOrganizerList: ReferralsOrganizerResponse;
  scene: SceneType;
  searchOrganizer?: Maybe<OrganizerType>;
  searchUserByOrganizer?: Maybe<UserType>;
  statsOrders: AmountStatsType;
  statsPayments: AmountStatsType;
  statsReferralWithdrawal: WithdrawalAmountStatsType;
  statsSalesWithdrawal: WithdrawalAmountStatsType;
  statsUsers: UsersGenderCountsType;
  statsUsersWithActiveOrders: UsersGenderCountsType;
  statsUsersWithOrders: UsersGenderCountsType;
  subTariff: SubTariffType;
  subTariffsList: SubTariffsResponse;
  tariff: TariffType;
  tariffByQuery: TariffType;
  tariffs: TariffsResponse;
  terminalAdmin: TerminalType;
  terminalsByProduct: Array<TerminalType>;
  terminalsList: TerminalsResponse;
  user?: Maybe<UserType>;
  userBalance: UserBalanceType;
  userGroups: UserGroupsResponse;
  users: UsersResponse;
  usersByGroup: UsersResponse;
  usersByProduct: UsersResponse;
  usersControlOrganizer: UsersResponse;
  withdrawalsByOrganizer: WithdrawalsResponse;
};


export type QueryAddressArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryAddressesListArgs = {
  filters?: InputMaybe<ProductAddressListFilterInput>;
  params: ProductAddressFilter;
};


export type QueryAllowedUserGroupsForBaseTariffArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryBaseOrganizerSceneArgs = {
  idOrganizer: Scalars['Float']['input'];
};


export type QueryBaseProductSceneArgs = {
  uuidProduct: Scalars['UUID']['input'];
};


export type QueryBaseTariffArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryBaseTariffsListArgs = {
  filters?: InputMaybe<BaseTariffListFilterInput>;
  idOrganizer?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<StatusEnum>>;
};


export type QueryBaseTextsArgs = {
  keys: Array<BaseTextsKeysEnum>;
};


export type QueryBlacklistArgs = {
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Int']['input'];
};


export type QueryBotsListArgs = {
  filters?: InputMaybe<BotListFilterInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  params?: InputMaybe<BotListParamsInput>;
};


export type QueryCheckCanRepeatOrderArgs = {
  productUuid: Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


export type QueryCheckHasOrderArgs = {
  productUuid: Scalars['UUID']['input'];
  tariffUuid?: InputMaybe<Scalars['UUID']['input']>;
  userUuid: Scalars['UUID']['input'];
};


export type QueryManualTerminalByOrganizerArgs = {
  idOrganizer: Scalars['Float']['input'];
};


export type QueryOrderArgs = {
  id: Scalars['Int']['input'];
};


export type QueryOrderByProductTariffArgs = {
  status?: InputMaybe<Array<OrderStatusEnum>>;
  statusPayment?: InputMaybe<Array<PaymentStatusEnum>>;
  uuidProduct: Scalars['UUID']['input'];
  uuidTariff?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryOrderByUuidArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryOrderInviteArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryOrderInviteByProductArgs = {
  uuidProduct: Scalars['UUID']['input'];
};


export type QueryOrdersArgs = {
  filters?: InputMaybe<OrdersListFilterInput>;
  params?: InputMaybe<OrdersParamsInput>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryOrdersListArgs = {
  args?: InputMaybe<OrdersFilterInput>;
  filters?: InputMaybe<OrdersListFilterInput>;
  params?: InputMaybe<OrdersParamsInput>;
};


export type QueryOrdersReferralOrganizerListArgs = {
  args?: InputMaybe<OrdersFilterInput>;
  filters?: InputMaybe<OrdersListFilterInput>;
};


export type QueryOrganizerArgs = {
  id: Scalars['Int']['input'];
};


export type QueryOrganizerAdminArgs = {
  id: Scalars['Int']['input'];
};


export type QueryOrganizerByQueryArgs = {
  query: Scalars['String']['input'];
};


export type QueryOrganizerFeaturesArgs = {
  idOrganizer: Scalars['Float']['input'];
};


export type QueryOrganizersArgs = {
  filters?: InputMaybe<OrganizerListFilterInput>;
};


export type QueryOrganizersListArgs = {
  filters?: InputMaybe<OrganizerListFilterInput>;
  status?: InputMaybe<Array<StatusEnum>>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPaymentArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryPaymentsArgs = {
  filters?: InputMaybe<PaymentsListFilterInput>;
};


export type QueryPaymentsByOrganizerArgs = {
  idOrganizer: Scalars['Int']['input'];
};


export type QueryPaymentsListArgs = {
  args?: InputMaybe<PaymentsArgsInput>;
  filters?: InputMaybe<PaymentsListFilterInput>;
  status?: Array<PaymentStatusEnum>;
};


export type QueryProductArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryProductByQueryArgs = {
  query: Scalars['String']['input'];
};


export type QueryProductsArgs = {
  filters?: InputMaybe<ProductListFilterInput>;
  params: ProductFilter;
};


export type QueryProductsCountArgs = {
  idOrganizer?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryProductsForTransferArgs = {
  currentTariffUuid: Scalars['UUID']['input'];
  organizerId: Scalars['Int']['input'];
};


export type QueryProductsListArgs = {
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
  status?: InputMaybe<Array<StatusEnum>>;
};


export type QueryReferralOrganizerArgs = {
  key: Scalars['String']['input'];
};


export type QueryReferralProfitArgs = {
  idOrganizer: Scalars['Int']['input'];
};


export type QueryReferralsOrganizerListArgs = {
  filters?: InputMaybe<ReferralOrganizerListFilterInput>;
  params?: InputMaybe<ReferralOrganizerParams>;
  status?: InputMaybe<Array<StatusEnum>>;
};


export type QuerySceneArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QuerySearchOrganizerArgs = {
  input: Scalars['String']['input'];
};


export type QuerySearchUserByOrganizerArgs = {
  idOrganizer: Scalars['Int']['input'];
  input: Scalars['String']['input'];
  params?: InputMaybe<SearchUserParamsInput>;
};


export type QueryStatsOrdersArgs = {
  organizerId: Scalars['Int']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryStatsPaymentsArgs = {
  organizerId: Scalars['Int']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryStatsReferralWithdrawalArgs = {
  idOrganizer: Scalars['Int']['input'];
};


export type QueryStatsSalesWithdrawalArgs = {
  idOrganizer: Scalars['Int']['input'];
};


export type QueryStatsUsersArgs = {
  organizerId: Scalars['Int']['input'];
};


export type QueryStatsUsersWithActiveOrdersArgs = {
  organizerId: Scalars['Int']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryStatsUsersWithOrdersArgs = {
  organizerId: Scalars['Int']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QuerySubTariffArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QuerySubTariffsListArgs = {
  filters?: InputMaybe<SubTariffListFilterInput>;
  productUuid: Scalars['UUID']['input'];
  status?: InputMaybe<Array<StatusEnum>>;
};


export type QueryTariffArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryTariffByQueryArgs = {
  productQuery: Scalars['String']['input'];
  tariffQuery: Scalars['String']['input'];
};


export type QueryTariffsArgs = {
  filters?: InputMaybe<TariffListFilterInput>;
  uuidProduct: Scalars['UUID']['input'];
};


export type QueryTerminalAdminArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryTerminalsByProductArgs = {
  uuidProduct: Scalars['UUID']['input'];
};


export type QueryTerminalsListArgs = {
  filters?: InputMaybe<TerminalListFilterInput>;
  idOrganizer?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Array<StatusEnum>>;
};


export type QueryUserArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryUserBalanceArgs = {
  organizerId: Scalars['Int']['input'];
};


export type QueryUserGroupsArgs = {
  filters?: InputMaybe<UserGroupsListFilterInput>;
  idOrganizer: Scalars['Int']['input'];
};


export type QueryUsersArgs = {
  args: FilterUsersInput;
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Int']['input'];
};


export type QueryUsersByGroupArgs = {
  filters?: InputMaybe<UserListFilterInput>;
  groupUuid: Scalars['UUID']['input'];
};


export type QueryUsersByProductArgs = {
  filters?: InputMaybe<UserListFilterInput>;
  uuidProduct: Scalars['UUID']['input'];
};


export type QueryUsersControlOrganizerArgs = {
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Int']['input'];
};


export type QueryWithdrawalsByOrganizerArgs = {
  filters?: InputMaybe<WithdrawalListFilterInput>;
  from?: InputMaybe<WithdrawalFromEnum>;
  idOrganizer: Scalars['Int']['input'];
};

export type ReferralOrganizerInput = {
  idOrganizer: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  percentage: Scalars['Float']['input'];
  uuidProduct: Scalars['String']['input'];
};

export type ReferralOrganizerListFilterInput = {
  order?: InputMaybe<ReferralOrganizerSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<ReferralOrganizerSearchType>;
};

export type ReferralOrganizerParams = {
  idUsedOrganizer?: InputMaybe<Scalars['Int']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
};

export enum ReferralOrganizerSearchKeys {
  Name = 'name'
}

export type ReferralOrganizerSearchType = {
  search: Scalars['String']['input'];
  searchBy: ReferralOrganizerSearchKeys;
};

export enum ReferralOrganizerSortKeys {
  CreatedAt = 'createdAt',
  Key = 'key',
  Name = 'name',
  Percentage = 'percentage',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type ReferralOrganizerSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: ReferralOrganizerSortKeys;
};

export type ReferralOrganizerType = BaseGraphModel & {
  __typename?: 'ReferralOrganizerType';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  ordersCount: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
  product: ProductType;
  status: StatusEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  usedOrganizer: OrganizerType;
};

export type ReferralsOrganizerResponse = {
  __typename?: 'ReferralsOrganizerResponse';
  count: Scalars['Int']['output'];
  rows: Array<ReferralOrganizerType>;
};

export enum RoleEnum {
  Admin = 'Admin',
  Organizer = 'Organizer',
  User = 'User'
}

export type SaveUserInfoInput = {
  first_name: Scalars['String']['input'];
  gender: GenderEnum;
  last_name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type SceneButtonInput = {
  label: Scalars['String']['input'];
  type: SceneButtonTypeEnum;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type SceneButtonType = BaseGraphModel & NodeUuidType & {
  __typename?: 'SceneButtonType';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  label: Scalars['String']['output'];
  type: SceneButtonTypeEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  url?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
  uuidChildScene?: Maybe<Scalars['UUID']['output']>;
};

export enum SceneButtonTypeEnum {
  Link = 'Link',
  Scene = 'Scene'
}

export type SceneInput = {
  backButtonText?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type SceneType = BaseGraphModel & NodeUuidType & {
  __typename?: 'SceneType';
  backButtonText?: Maybe<Scalars['String']['output']>;
  buttons: Array<Array<SceneButtonType>>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  parentScene?: Maybe<SceneType>;
  text?: Maybe<Scalars['String']['output']>;
  type: SceneTypeEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
};

export enum SceneTypeEnum {
  CustomScene = 'CustomScene',
  Organizer = 'Organizer',
  Product = 'Product'
}

export type SearchUserParamsInput = {
  fullUsernameMatch?: InputMaybe<Scalars['Boolean']['input']>;
  offSearchByNames?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SignUpInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  language?: LanguageEnum;
  last_name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  tgId: Scalars['Float']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum SortEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}

export enum StatusEnum {
  Active = 'Active',
  Archive = 'Archive',
  Draft = 'Draft'
}

export type SubTariffInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  isLimitedOrders?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordersCount?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  specialPrice?: InputMaybe<Scalars['Float']['input']>;
  startedDate?: InputMaybe<Scalars['DateTime']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubTariffListFilterInput = {
  order?: InputMaybe<SubTariffSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<SubTariffSearchType>;
};

export enum SubTariffSearchKeys {
  Description = 'description',
  Name = 'name',
  Type = 'type'
}

export type SubTariffSearchType = {
  search: Scalars['String']['input'];
  searchBy: SubTariffSearchKeys;
};

export enum SubTariffSortKeys {
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Name = 'name',
  OrdersCount = 'ordersCount',
  Price = 'price',
  Query = 'query',
  SpecialPrice = 'specialPrice',
  StartedDate = 'startedDate',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Visible = 'visible'
}

export type SubTariffSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: SubTariffSortKeys;
};

export type SubTariffType = BaseGraphModel & NodeUuidType & {
  __typename?: 'SubTariffType';
  availableOrdersCount?: Maybe<Scalars['Float']['output']>;
  baseTariff?: Maybe<BaseTariffType>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  isLimitedOrders?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ordersCount?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  query?: Maybe<Scalars['String']['output']>;
  specialPrice?: Maybe<Scalars['Float']['output']>;
  startedDate?: Maybe<Scalars['DateTime']['output']>;
  statsOrdersCount: OrderCountsType;
  status: StatusEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type SubTariffsResponse = {
  __typename?: 'SubTariffsResponse';
  count: Scalars['Int']['output'];
  rows: Array<SubTariffType>;
};

export type TariffListFilterInput = {
  pagination?: InputMaybe<PaginationType>;
};

export type TariffType = BaseGraphModel & NodeUuidType & {
  __typename?: 'TariffType';
  availableOrdersCount?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  featureFlags: OrderFeatureFlagsType;
  name: Scalars['String']['output'];
  ordersCount?: Maybe<Scalars['Float']['output']>;
  paidOrdersCount: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  query: Scalars['Int']['output'];
  specialPrice?: Maybe<Scalars['Float']['output']>;
  startedDate?: Maybe<Scalars['DateTime']['output']>;
  type: TariffTypeEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
  visible: Scalars['Boolean']['output'];
};

export enum TariffTypeEnum {
  AnyGender = 'AnyGender',
  Female = 'Female',
  Male = 'Male',
  Pair = 'Pair'
}

export type TariffsResponse = {
  __typename?: 'TariffsResponse';
  count: Scalars['Int']['output'];
  rows: Array<TariffType>;
};

export enum TerminalEnum {
  Robokassa = 'Robokassa',
  Tinkoff = 'Tinkoff'
}

export type TerminalInput = {
  holdPercentage?: InputMaybe<Scalars['Float']['input']>;
  invoiceLifetime: Scalars['String']['input'];
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  type: TerminalEnum;
};

export type TerminalListFilterInput = {
  order?: InputMaybe<TerminalSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<TerminalSearchType>;
};

export enum TerminalSearchKeysEnum {
  Label = 'label',
  Name = 'name'
}

export type TerminalSearchType = {
  search: Scalars['String']['input'];
  searchBy: TerminalSearchKeysEnum;
};

export enum TerminalSortKeys {
  CreatedAt = 'createdAt',
  HoldPercentage = 'holdPercentage',
  Label = 'label',
  Name = 'name',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type TerminalSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: TerminalSortKeys;
};

export type TerminalType = BaseGraphModel & NodeUuidType & {
  __typename?: 'TerminalType';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  holdPercentage: Scalars['Float']['output'];
  invoiceLifetime: Scalars['ISO8601Duration']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizer: OrganizerType;
  status: StatusEnum;
  token?: Maybe<Scalars['String']['output']>;
  type: TerminalEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
};

export type TerminalsResponse = {
  __typename?: 'TerminalsResponse';
  count: Scalars['Int']['output'];
  rows: Array<TerminalType>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UserBalanceType = BaseGraphModel & NodeUuidType & {
  __typename?: 'UserBalanceType';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  organizer: OrganizerType;
  updatedAt: Scalars['DateTimeISO']['output'];
  user: UserType;
  uuid: Scalars['UUID']['output'];
};

export type UserBalanceWithdrawalType = BaseGraphModel & NodeUuidType & {
  __typename?: 'UserBalanceWithdrawalType';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  organizer: OrganizerType;
  updatedAt: Scalars['DateTimeISO']['output'];
  user: UserType;
  uuid: Scalars['UUID']['output'];
};

export type UserGroupType = BaseGraphModel & NodeUuidType & {
  __typename?: 'UserGroupType';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  name: Scalars['String']['output'];
  organizer: OrganizerType;
  updatedAt: Scalars['DateTimeISO']['output'];
  usersCount: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
};

export type UserGroupsListFilterInput = {
  order?: InputMaybe<UserGroupsSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<UserGroupsSearchType>;
};

export type UserGroupsResponse = {
  __typename?: 'UserGroupsResponse';
  count: Scalars['Int']['output'];
  rows: Array<UserGroupType>;
};

export enum UserGroupsSearchKeys {
  Name = 'name'
}

export type UserGroupsSearchType = {
  search: Scalars['String']['input'];
  searchBy: UserGroupsSearchKeys;
};

export enum UserGroupsSortKeys {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export type UserGroupsSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: UserGroupsSortKeys;
};

export type UserListFilterInput = {
  order?: InputMaybe<UserSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum UserSortKeys {
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'first_name',
  Gender = 'gender',
  LastName = 'last_name',
  Phone = 'phone',
  TgId = 'tgId',
  Username = 'username'
}

export type UserSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: UserSortKeys;
};

export type UserType = BaseGraphModel & NodeUuidType & {
  __typename?: 'UserType';
  activatedOrdersCount: Scalars['Int']['output'];
  balance: Scalars['Float']['output'];
  controlOrganizerId?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  first_name: Scalars['String']['output'];
  gender?: Maybe<GenderEnum>;
  hasInviteByProduct: Scalars['Boolean']['output'];
  hasOrderByProduct: Scalars['Boolean']['output'];
  hasTariffAccess?: Maybe<Scalars['Boolean']['output']>;
  isBlocked: Scalars['Boolean']['output'];
  language: LanguageEnum;
  last_name?: Maybe<Scalars['String']['output']>;
  ordersCount: Scalars['Int']['output'];
  organizers: Array<OrganizerType>;
  paymentsCount: Scalars['Int']['output'];
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  role: RoleEnum;
  statsPayments: AmountStatsType;
  submittedEmail: Scalars['Boolean']['output'];
  tgId?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  username?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
};


export type UserTypeBalanceArgs = {
  idOrganizer: Scalars['Int']['input'];
};


export type UserTypeHasInviteByProductArgs = {
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type UserTypeHasOrderByProductArgs = {
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type UserTypeIsBlockedArgs = {
  idOrganizer: Scalars['Int']['input'];
};

export type UsersGenderCountsType = {
  __typename?: 'UsersGenderCountsType';
  count: Scalars['Int']['output'];
  femaleCount: Scalars['Int']['output'];
  maleCount: Scalars['Int']['output'];
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  count: Scalars['Int']['output'];
  rows: Array<UserType>;
};

export type WithdrawalAmountStatsType = {
  __typename?: 'WithdrawalAmountStatsType';
  total: Scalars['Float']['output'];
};

export enum WithdrawalFromEnum {
  ReferralBalance = 'ReferralBalance',
  SalesBalance = 'SalesBalance'
}

export type WithdrawalListFilterInput = {
  order?: InputMaybe<WithdrawalSortType>;
  pagination?: InputMaybe<PaginationType>;
};

export type WithdrawalOrganizerInput = {
  amount: Scalars['Float']['input'];
  from: WithdrawalFromEnum;
  organizerId: Scalars['Int']['input'];
  uuidPaymentAccount: Scalars['UUID']['input'];
};

export enum WithdrawalSortKeys {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type WithdrawalSortType = {
  sort?: InputMaybe<SortEnum>;
  sortBy: WithdrawalSortKeys;
};

export enum WithdrawalStatusEnum {
  Cancelled = 'Cancelled',
  Executed = 'Executed',
  Failed = 'Failed',
  InProgress = 'InProgress'
}

export type WithdrawalType = BaseGraphModel & NodeUuidType & {
  __typename?: 'WithdrawalType';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  from: WithdrawalFromEnum;
  idOrganizer: Scalars['Int']['output'];
  organizer: OrganizerType;
  paymentAccount: PaymentAccountType;
  status: WithdrawalStatusEnum;
  updatedAt: Scalars['DateTimeISO']['output'];
  uuid: Scalars['UUID']['output'];
};

export type WithdrawalsResponse = {
  __typename?: 'WithdrawalsResponse';
  count: Scalars['Int']['output'];
  rows: Array<WithdrawalType>;
};

export type JwtFieldsFragment = { __typename?: 'JwtType', access_token: string, refresh_token: string };

export type LoginMutationVariables = Exact<{
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'JwtType', access_token: string, refresh_token: string } };

export type RefreshMutationVariables = Exact<{
  refresh_token: Scalars['String']['input'];
}>;


export type RefreshMutation = { __typename?: 'Mutation', refresh: { __typename?: 'JwtType', access_token: string, refresh_token: string } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'UserType', uuid: string, role: RoleEnum, username?: string | null, controlOrganizerId?: number | null } };

export type BaseTariffFieldsFragment = { __typename?: 'BaseTariffType', createdAt: number, updatedAt: number, uuid: string, name: string, description?: string | null, query?: string | null, ordersCount?: number | null, price: number, visible: boolean, status: StatusEnum, type: TariffTypeEnum, isLimitedOrders: boolean, countSubTariffs: number, featureFlags: { __typename?: 'OrderFeatureFlagsType', bonusPay: boolean, refundOrder: boolean, transferToUserOrder: boolean, transferToProductOrder: boolean, repeatBuyOrder: boolean } };

export type BaseTariffsListQueryVariables = Exact<{
  filters?: InputMaybe<BaseTariffListFilterInput>;
  status?: InputMaybe<Array<StatusEnum> | StatusEnum>;
  idOrganizer: Scalars['Int']['input'];
}>;


export type BaseTariffsListQuery = { __typename?: 'Query', baseTariffsList: { __typename?: 'BaseTariffsResponse', count: number, rows: Array<{ __typename?: 'BaseTariffType', createdAt: number, updatedAt: number, uuid: string, name: string, description?: string | null, query?: string | null, ordersCount?: number | null, price: number, visible: boolean, status: StatusEnum, type: TariffTypeEnum, isLimitedOrders: boolean, countSubTariffs: number, featureFlags: { __typename?: 'OrderFeatureFlagsType', bonusPay: boolean, refundOrder: boolean, transferToUserOrder: boolean, transferToProductOrder: boolean, repeatBuyOrder: boolean } }> } };

export type BaseTariffQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type BaseTariffQuery = { __typename?: 'Query', baseTariff: { __typename?: 'BaseTariffType', createdAt: number, updatedAt: number, uuid: string, name: string, description?: string | null, query?: string | null, ordersCount?: number | null, price: number, visible: boolean, status: StatusEnum, type: TariffTypeEnum, isLimitedOrders: boolean, countSubTariffs: number, featureFlags: { __typename?: 'OrderFeatureFlagsType', bonusPay: boolean, refundOrder: boolean, transferToUserOrder: boolean, transferToProductOrder: boolean, repeatBuyOrder: boolean } } };

export type AllowedUserGroupsForBaseTariffQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type AllowedUserGroupsForBaseTariffQuery = { __typename?: 'Query', allowedUserGroupsForBaseTariff: Array<{ __typename?: 'UserGroupType', uuid: string, name: string }> };

export type CreateBaseTariffMutationVariables = Exact<{
  input: BaseTariffInput;
  idOrganizer: Scalars['Int']['input'];
}>;


export type CreateBaseTariffMutation = { __typename?: 'Mutation', createBaseTariff: { __typename?: 'BaseTariffType', createdAt: number, updatedAt: number, uuid: string, name: string, description?: string | null, query?: string | null, ordersCount?: number | null, price: number, visible: boolean, status: StatusEnum, type: TariffTypeEnum, isLimitedOrders: boolean, countSubTariffs: number, featureFlags: { __typename?: 'OrderFeatureFlagsType', bonusPay: boolean, refundOrder: boolean, transferToUserOrder: boolean, transferToProductOrder: boolean, repeatBuyOrder: boolean } } };

export type UpdateBaseTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: BaseTariffInput;
}>;


export type UpdateBaseTariffMutation = { __typename?: 'Mutation', updateBaseTariff: { __typename?: 'BaseTariffType', createdAt: number, updatedAt: number, uuid: string, name: string, description?: string | null, query?: string | null, ordersCount?: number | null, price: number, visible: boolean, status: StatusEnum, type: TariffTypeEnum, isLimitedOrders: boolean, countSubTariffs: number, featureFlags: { __typename?: 'OrderFeatureFlagsType', bonusPay: boolean, refundOrder: boolean, transferToUserOrder: boolean, transferToProductOrder: boolean, repeatBuyOrder: boolean } } };

export type DuplicateBaseTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DuplicateBaseTariffMutation = { __typename?: 'Mutation', duplicateBaseTariff: { __typename?: 'BaseTariffType', createdAt: number, updatedAt: number, uuid: string, name: string, description?: string | null, query?: string | null, ordersCount?: number | null, price: number, visible: boolean, status: StatusEnum, type: TariffTypeEnum, isLimitedOrders: boolean, countSubTariffs: number, featureFlags: { __typename?: 'OrderFeatureFlagsType', bonusPay: boolean, refundOrder: boolean, transferToUserOrder: boolean, transferToProductOrder: boolean, repeatBuyOrder: boolean } } };

export type RemoveBaseTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type RemoveBaseTariffMutation = { __typename?: 'Mutation', removeBaseTariff: any };

export type UpdateUsersBaseTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  userGroupsUuids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type UpdateUsersBaseTariffMutation = { __typename?: 'Mutation', updateUserGroupsBaseTariff: any };

export type BotFieldsFragment = { __typename?: 'BotType', createdAt: number, updatedAt: number, id: number, username: string, token: string, name: string, active: boolean };

export type BotsListQueryVariables = Exact<{
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<BotListFilterInput>;
  params?: InputMaybe<BotListParamsInput>;
}>;


export type BotsListQuery = { __typename?: 'Query', botsList: { __typename?: 'BotsResponse', count: number, rows: Array<{ __typename?: 'BotType', usersCount: number, createdAt: number, updatedAt: number, id: number, username: string, token: string, name: string, active: boolean, organizers: Array<{ __typename?: 'OrganizerType', id: number }> }> } };

export type BotsListCountQueryVariables = Exact<{
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type BotsListCountQuery = { __typename?: 'Query', botsList: { __typename?: 'BotsResponse', count: number } };

export type BotsByOrganizerQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type BotsByOrganizerQuery = { __typename?: 'Query', organizerAdmin: { __typename?: 'OrganizerType', bots: Array<{ __typename?: 'BaseBotType', id: number, username: string }> } };

export type BotInfoByTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type BotInfoByTokenMutation = { __typename?: 'Mutation', botInfoByToken: { __typename?: 'BotInfoType', id: number, username: string, token: string } };

export type CreateBotMutationVariables = Exact<{
  input: BotInput;
}>;


export type CreateBotMutation = { __typename?: 'Mutation', createBot: { __typename?: 'BotType', createdAt: number, updatedAt: number, id: number, username: string, token: string, name: string, active: boolean } };

export type DisableBotMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type DisableBotMutation = { __typename?: 'Mutation', disableBot: any };

export type ActivateBotMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type ActivateBotMutation = { __typename?: 'Mutation', activateBot: any };

export type RemoveBotMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type RemoveBotMutation = { __typename?: 'Mutation', removeBot: any };

export type AddBotToOrganizerMutationVariables = Exact<{
  idOrganizer: Scalars['Float']['input'];
  idBot: Scalars['Float']['input'];
}>;


export type AddBotToOrganizerMutation = { __typename?: 'Mutation', addBotToOrganizer: any };

export type RemoveBotFromOrganizerMutationVariables = Exact<{
  idOrganizer: Scalars['Float']['input'];
  idBot: Scalars['Float']['input'];
}>;


export type RemoveBotFromOrganizerMutation = { __typename?: 'Mutation', removeBotFromOrganizer: any };

export type UploadImagesMutationVariables = Exact<{
  images: Array<Scalars['Upload']['input']> | Scalars['Upload']['input'];
}>;


export type UploadImagesMutation = { __typename?: 'Mutation', uploadImages: Array<{ __typename?: 'FileType', url: string }> };

export type FinanceStatsQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
}>;


export type FinanceStatsQuery = { __typename?: 'Query', paymentsList: { __typename?: 'PaymentsResponse', count: number }, users: { __typename?: 'UsersResponse', count: number }, statsPayments: { __typename?: 'AmountStatsType', profit: number, commission: number, turnover: number, referral: number }, statsSalesWithdrawal: { __typename?: 'WithdrawalAmountStatsType', total: number }, organizerAdmin: { __typename?: 'OrganizerType', salesBalance: number, creditBalance: number } };

export type OrganizerBalancesQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
}>;


export type OrganizerBalancesQuery = { __typename?: 'Query', organizerAdmin: { __typename?: 'OrganizerType', salesBalance: number, creditBalance: number, referralBalance: number } };

export type PurchaseStatsQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type PurchaseStatsQuery = { __typename?: 'Query', statsOrders: { __typename?: 'AmountStatsType', turnover: number, profit: number, commission: number, referral: number }, statsUsersWithOrders: { __typename?: 'UsersGenderCountsType', count: number, maleCount: number, femaleCount: number } };

export type PaymentsListQueryVariables = Exact<{
  filter?: InputMaybe<PaymentsListFilterInput>;
  organizerId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Array<PaymentStatusEnum> | PaymentStatusEnum>;
}>;


export type PaymentsListQuery = { __typename?: 'Query', paymentsList: { __typename?: 'PaymentsResponse', count: number, rows: Array<{ __typename?: 'PaymentType', uuid: string, price: number, createdAt: number, payment_uuid?: string | null, status: PaymentStatusEnum, type: PaymentTypeEnum, proof?: { __typename?: 'PaymentProofType', file: string } | null, terminal?: { __typename?: 'TerminalType', name: string } | null, user: { __typename?: 'UserType', first_name: string, last_name?: string | null, email?: string | null, username?: string | null, uuid: string }, order: { __typename?: 'OrderType', id: number, status: OrderStatusEnum, fixedHoldPercentage: number, referralOrganizer?: { __typename?: 'ReferralOrganizerType', key: string, percentage: number } | null, product: { __typename?: 'ProductType', name: string, startedDate: Date, endDate: Date }, userBalanceWithdrawal?: { __typename?: 'UserBalanceWithdrawalType', amount: number } | null, tariff: { __typename?: 'TariffType', name: string }, pairOrder?: { __typename?: 'OrderType', user: { __typename?: 'UserType', first_name: string, last_name?: string | null, email?: string | null, username?: string | null, uuid: string, phone?: string | null } } | null } }> } };

export type PaymentsCountQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Array<PaymentStatusEnum> | PaymentStatusEnum>;
}>;


export type PaymentsCountQuery = { __typename?: 'Query', paymentsList: { __typename?: 'PaymentsResponse', count: number } };

export type FinanceListsCountQueryVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
}>;


export type FinanceListsCountQuery = { __typename?: 'Query', paymentsList: { __typename?: 'PaymentsResponse', count: number }, withdrawalsByOrganizer: { __typename?: 'WithdrawalsResponse', count: number } };

export type OrdersListQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  organizerId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Array<OrderStatusEnum> | OrderStatusEnum>;
}>;


export type OrdersListQuery = { __typename?: 'Query', ordersList: { __typename?: 'OrdersResponse', count: number, rows: Array<{ __typename?: 'OrderType', createdAt: number, id: number, status: OrderStatusEnum, fixedHoldPercentage: number, payment?: { __typename?: 'PaymentType', price: number, status: PaymentStatusEnum } | null, referralOrganizer?: { __typename?: 'ReferralOrganizerType', key: string, percentage: number } | null, user: { __typename?: 'UserType', first_name: string, last_name?: string | null, email?: string | null, username?: string | null, uuid: string, phone?: string | null }, product: { __typename?: 'ProductType', name: string, startedDate: Date, endDate: Date }, userBalanceWithdrawal?: { __typename?: 'UserBalanceWithdrawalType', amount: number } | null, tariff: { __typename?: 'TariffType', name: string }, pairOrder?: { __typename?: 'OrderType', user: { __typename?: 'UserType', first_name: string, last_name?: string | null, email?: string | null, username?: string | null, uuid: string, phone?: string | null } } | null }> } };

export type OrdersCountQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  organizerId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<Array<OrderStatusEnum> | OrderStatusEnum>;
}>;


export type OrdersCountQuery = { __typename?: 'Query', ordersList: { __typename?: 'OrdersResponse', count: number } };

export type GiveOrdersMutationVariables = Exact<{
  usersUuids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  productUuid: Scalars['UUID']['input'];
  params?: InputMaybe<OrdersActionNotifyParams>;
}>;


export type GiveOrdersMutation = { __typename?: 'Mutation', giveOrders: Array<{ __typename?: 'GiveOrderResultType', status: boolean, uuidUser: string, error?: Errors | null }> };

export type EventInviteTextsQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type EventInviteTextsQuery = { __typename?: 'Query', product: { __typename?: 'ProductType', inviteOrderSuccessText: string, inviteOrderText: string } };

export type CreateInvitesOrdersMutationVariables = Exact<{
  usersUuids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  productUuid: Scalars['UUID']['input'];
  params?: InputMaybe<OrdersActionNotifyParams>;
}>;


export type CreateInvitesOrdersMutation = { __typename?: 'Mutation', createInviteOrders: Array<{ __typename?: 'OrderInviteResultType', status: boolean, uuidUser: string, error?: Errors | null }> };

export type ExportOrdersCsvMutationVariables = Exact<{
  productUuid: Scalars['UUID']['input'];
}>;


export type ExportOrdersCsvMutation = { __typename?: 'Mutation', exportOrdersCSV: { __typename?: 'ExportOrdersCSVType', url: string } };

export type DisableOrderMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DisableOrderMutation = { __typename?: 'Mutation', disableOrder: { __typename?: 'OrderType', uuid: string } };

export type TransferOrderToProductMutationVariables = Exact<{
  notify?: InputMaybe<OrderActionEmailNotifyParam>;
  productUuid: Scalars['UUID']['input'];
  orderId: Scalars['Int']['input'];
}>;


export type TransferOrderToProductMutation = { __typename?: 'Mutation', transferUserOrderToProduct: { __typename?: 'OrderType', uuid: string } };

export type SupplierInfoFieldsFragment = { __typename?: 'OrganizerSupplierInfoType', name?: string | null, inn?: string | null, phone?: string | null };

export type OrganizerAdminFeatureFieldsFragment = { __typename?: 'OrganizerFeatureType', createReferral: boolean, viewReferral: boolean, manualPayment: boolean };

export type OrganizerFieldsFragment = { __typename?: 'OrganizerType', id: number, name: string, createdAt: number, productsCount: number, telegram: string, ordersCount: number, salesBalance: number, referralBalance: number, creditBalance: number, statsPayments: { __typename?: 'AmountStatsType', turnover: number } };

export type OrganizersListCountQueryVariables = Exact<{
  verified?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OrganizersListCountQuery = { __typename?: 'Query', organizersList: { __typename?: 'OrganizersResponse', count: number } };

export type OrganizersListQueryVariables = Exact<{
  verified?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<StatusEnum> | StatusEnum>;
  filters?: InputMaybe<OrganizerListFilterInput>;
}>;


export type OrganizersListQuery = { __typename?: 'Query', organizersList: { __typename?: 'OrganizersResponse', count: number, rows: Array<{ __typename?: 'OrganizerType', id: number, name: string, createdAt: number, productsCount: number, telegram: string, ordersCount: number, salesBalance: number, referralBalance: number, creditBalance: number, bots: Array<{ __typename?: 'BaseBotType', username: string, id: number }>, statsPayments: { __typename?: 'AmountStatsType', turnover: number } }> } };

export type OrganizerQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type OrganizerQuery = { __typename?: 'Query', organizerAdmin: { __typename?: 'OrganizerType', createdAt: number, updatedAt: number, deletedAt?: number | null, id: number, image: string, name: string, query?: string | null, description: string, telegram: string, featureFlags: { __typename?: 'OrganizerFeatureFlagsType', allowedNumberForTransferToProductOrder: number }, orderInstructions: { __typename?: 'OrganizerOrderInstructionsType', transferToProduct: string, transferToUser: string, refund: string }, supplierInfo: { __typename?: 'OrganizerSupplierInfoType', name?: string | null, inn?: string | null, phone?: string | null }, synonyms: Array<{ __typename?: 'OrganizerSynonymType', value: string }> } };

export type OrganizerFilesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type OrganizerFilesQuery = { __typename?: 'Query', organizerAdmin: { __typename?: 'OrganizerType', files: { __typename?: 'OrganizerFilesType', oferta?: string | null, agent?: string | null, policy?: string | null } } };

export type OrganizerFeaturesQueryVariables = Exact<{
  idOrganizer: Scalars['Float']['input'];
}>;


export type OrganizerFeaturesQuery = { __typename?: 'Query', organizerFeatures: { __typename?: 'OrganizerFeatureType', createReferral: boolean, viewReferral: boolean, manualPayment: boolean } };

export type UpdateAdminOrganizerFeaturesMutationVariables = Exact<{
  idOrganizer: Scalars['Float']['input'];
  input: OrganizerFeatureInput;
}>;


export type UpdateAdminOrganizerFeaturesMutation = { __typename?: 'Mutation', updateOrganizerFeatures: { __typename?: 'OrganizerFeatureType', createReferral: boolean, viewReferral: boolean, manualPayment: boolean } };

export type UpdateOrganizerMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  input: OrganizerInput;
}>;


export type UpdateOrganizerMutation = { __typename?: 'Mutation', updateOrganizer: { __typename?: 'OrganizerType', id: number } };

export type CreateOrganizerMutationVariables = Exact<{
  input: OrganizerInput;
}>;


export type CreateOrganizerMutation = { __typename?: 'Mutation', createOrganizer: { __typename?: 'OrganizerType', id: number } };

export type OrganizerShortcodesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizerShortcodesQuery = { __typename?: 'Query', organizerShortcodes: Array<string> };

export type UpdateOrganizerSynonymsMutationVariables = Exact<{
  idOrganizer: Scalars['Float']['input'];
  values: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type UpdateOrganizerSynonymsMutation = { __typename?: 'Mutation', updateOrganizerSynonyms: any };

export type PaymentAccountFieldsFragment = { __typename?: 'PaymentAccountType', createdAt: number, updatedAt: number, deletedAt?: number | null, uuid: string, name: string, inn: string, bik: string, accountNumber: string };

export type PaymentAccountsListQueryVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
}>;


export type PaymentAccountsListQuery = { __typename?: 'Query', paymentsByOrganizer: Array<{ __typename?: 'PaymentAccountType', createdAt: number, updatedAt: number, deletedAt?: number | null, uuid: string, name: string, inn: string, bik: string, accountNumber: string }> };

export type EventsCountQueryVariables = Exact<{
  idOrganizer?: InputMaybe<Scalars['Float']['input']>;
}>;


export type EventsCountQuery = { __typename?: 'Query', productsCount: { __typename?: 'ProductCountsType', count: number, activeCount: number, archiveCount: number, draftCount: number } };

export type EventShortcodeQueryVariables = Exact<{ [key: string]: never; }>;


export type EventShortcodeQuery = { __typename?: 'Query', productShortcodes: Array<string> };

export type EventsListQueryVariables = Exact<{
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
  status?: InputMaybe<Array<StatusEnum> | StatusEnum>;
}>;


export type EventsListQuery = { __typename?: 'Query', productsList: { __typename?: 'ProductsResponse', count: number, rows: Array<{ __typename?: 'ProductType', createdAt: number, updatedAt: number, uuid: string, status: StatusEnum, purchasedCount: number, visitedCount: number, name: string, query?: string | null, startedDate: Date, endDate: Date, canBeRemoved: boolean, statsOrders: { __typename?: 'AmountStatsType', turnover: number } }> } };

export type EventQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type EventQuery = { __typename?: 'Query', product: { __typename?: 'ProductType', createdAt: number, updatedAt: number, uuid: string, img: string, name: string, description: string, query?: string | null, startedDate: Date, salesStartedText?: string | null, endDate: Date, status: StatusEnum, successBuyText: string, successPairBuyText: string, tariffsText: string, tariffsEmptyText: string, tariffsUnavailableText: string, salesStartedDate?: Date | null, transferOrderToUserSuccessText: string, featureFlags: { __typename?: 'ProductFeatureFlagsType', refundOrder: boolean, bonusPay: boolean, transferToUserOrder: boolean, transferToProductOrder: boolean, repeatBuyOrder: boolean, durationBeforeStartForRefundOrder: any, durationBeforeStartForTransferToUserOrder: any, durationBeforeStartForTransferToProductOrder: any, transferFromProductOrder: boolean }, address?: { __typename?: 'ProductAddressType', uuid: string } | null } };

export type BaseEventTextsQueryVariables = Exact<{ [key: string]: never; }>;


export type BaseEventTextsQuery = { __typename?: 'Query', baseTexts: Array<{ __typename?: 'BaseTextsType', key: BaseTextsKeysEnum, text: string }> };

export type ArchiveEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type ArchiveEventMutation = { __typename?: 'Mutation', archiveProduct: { __typename?: 'ProductType', uuid: string } };

export type PublishEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type PublishEventMutation = { __typename?: 'Mutation', activateProduct: { __typename?: 'ProductType', uuid: string } };

export type RemoveEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type RemoveEventMutation = { __typename?: 'Mutation', removeProduct: any };

export type UpdateEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: ProductInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'ProductType', uuid: string } };

export type UpdateEventInviteTextsMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: ProductInviteTextsInput;
}>;


export type UpdateEventInviteTextsMutation = { __typename?: 'Mutation', updateProductInviteTexts: { __typename?: 'ProductType', uuid: string } };

export type DuplicateProductMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DuplicateProductMutation = { __typename?: 'Mutation', duplicateProduct: { __typename?: 'ProductType', uuid: string } };

export type CreateEventMutationVariables = Exact<{
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  input: ProductMainInfoInput;
  organizerId: Scalars['Float']['input'];
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'ProductType', uuid: string } };

export type UpdateEventMainInfoMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: ProductMainInfoInput;
}>;


export type UpdateEventMainInfoMutation = { __typename?: 'Mutation', updateMainInfoProduct: { __typename?: 'ProductType', uuid: string } };

export type AddressesListQueryVariables = Exact<{
  filters?: InputMaybe<ProductAddressListFilterInput>;
  params: ProductAddressFilter;
}>;


export type AddressesListQuery = { __typename?: 'Query', addressesList: { __typename?: 'ProductsAddressResponse', count: number, rows: Array<{ __typename?: 'ProductAddressType', createdAt: number, updatedAt: number, uuid: string, address: string, img?: string | null, description?: string | null }> } };

export type AddressQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type AddressQuery = { __typename?: 'Query', address: { __typename?: 'ProductAddressType', createdAt: number, updatedAt: number, uuid: string, address: string, img?: string | null, description?: string | null } };

export type CreateAddressMutationVariables = Exact<{
  input: ProductAddressInput;
  organizerId: Scalars['Int']['input'];
}>;


export type CreateAddressMutation = { __typename?: 'Mutation', createAddressProduct: { __typename?: 'ProductAddressType', uuid: string } };

export type UpdateAddressMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: ProductAddressInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateAddressProduct: { __typename?: 'ProductAddressType', uuid: string } };

export type RemoveAddressMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type RemoveAddressMutation = { __typename?: 'Mutation', removeAddressProduct: any };

export type ReferralOrganizerFieldsFragment = { __typename?: 'ReferralOrganizerType', createdAt: number, updatedAt: number, key: string, name: string, percentage: number, ordersCount: number, status: StatusEnum };

export type ReferralOrganizerQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type ReferralOrganizerQuery = { __typename?: 'Query', referralOrganizer: { __typename?: 'ReferralOrganizerType', createdAt: number, updatedAt: number, key: string, name: string, percentage: number, ordersCount: number, status: StatusEnum } };

export type OrganizersReferralListQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizersReferralListQuery = { __typename?: 'Query', organizersList: { __typename?: 'OrganizersResponse', rows: Array<{ __typename?: 'OrganizerType', name: string, id: number }> } };

export type ReferralsOrganizerListQueryVariables = Exact<{
  filters?: InputMaybe<ReferralOrganizerListFilterInput>;
  params?: InputMaybe<ReferralOrganizerParams>;
  status?: InputMaybe<Array<StatusEnum> | StatusEnum>;
}>;


export type ReferralsOrganizerListQuery = { __typename?: 'Query', referralsOrganizerList: { __typename?: 'ReferralsOrganizerResponse', count: number, rows: Array<{ __typename?: 'ReferralOrganizerType', createdAt: number, updatedAt: number, key: string, name: string, percentage: number, ordersCount: number, status: StatusEnum, product: { __typename?: 'ProductType', name: string, startedDate: Date, query?: string | null, organizer: { __typename?: 'OrganizerType', name: string } }, usedOrganizer: { __typename?: 'OrganizerType', name: string } }> } };

export type ReferralFinanceStatsQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
}>;


export type ReferralFinanceStatsQuery = { __typename?: 'Query', referralProfit: number, ordersReferralOrganizerList: { __typename?: 'OrdersResponse', count: number }, statsReferralWithdrawal: { __typename?: 'WithdrawalAmountStatsType', total: number }, organizer: { __typename?: 'OrganizerType', referralBalance: number } };

export type ReferralFinanceListsCountQueryVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
}>;


export type ReferralFinanceListsCountQuery = { __typename?: 'Query', ordersReferralOrganizerList: { __typename?: 'OrdersResponse', count: number }, withdrawalsByOrganizer: { __typename?: 'WithdrawalsResponse', count: number }, referralsOrganizerList: { __typename?: 'ReferralsOrganizerResponse', count: number } };

export type OrdersReferralOrganizerListQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  organizerId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type OrdersReferralOrganizerListQuery = { __typename?: 'Query', ordersReferralOrganizerList: { __typename?: 'OrdersResponse', count: number, rows: Array<{ __typename?: 'OrderType', createdAt: number, id: number, status: OrderStatusEnum, fixedHoldPercentage: number, payment?: { __typename?: 'PaymentType', price: number, status: PaymentStatusEnum } | null, referralOrganizer?: { __typename?: 'ReferralOrganizerType', key: string, percentage: number } | null, user: { __typename?: 'UserType', first_name: string, last_name?: string | null, email?: string | null, username?: string | null, uuid: string, phone?: string | null }, organizer: { __typename?: 'OrganizerType', name: string }, product: { __typename?: 'ProductType', name: string, startedDate: Date, endDate: Date, query?: string | null }, tariff: { __typename?: 'TariffType', name: string } }> } };

export type CreateReferralOrganizerMutationVariables = Exact<{
  input: ReferralOrganizerInput;
}>;


export type CreateReferralOrganizerMutation = { __typename?: 'Mutation', createReferralOrganizer: { __typename?: 'ReferralOrganizerType', createdAt: number, updatedAt: number, key: string, name: string, percentage: number, ordersCount: number, status: StatusEnum } };

export type RenameReferralOrganizerMutationVariables = Exact<{
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type RenameReferralOrganizerMutation = { __typename?: 'Mutation', renameReferralOrganizer: { __typename?: 'ReferralOrganizerType', createdAt: number, updatedAt: number, key: string, name: string, percentage: number, ordersCount: number, status: StatusEnum } };

export type RemoveReferralOrganizerMutationVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type RemoveReferralOrganizerMutation = { __typename?: 'Mutation', removeReferralOrganizer: any };

export type SubTariffFieldsFragment = { __typename?: 'SubTariffType', createdAt: number, updatedAt: number, uuid: string, name?: string | null, price?: number | null, specialPrice?: number | null, description?: string | null, query?: string | null, ordersCount?: number | null, startedDate?: Date | null, endDate?: Date | null, status: StatusEnum, availableOrdersCount?: number | null, visible?: boolean | null, isLimitedOrders?: boolean | null, statsOrdersCount: { __typename?: 'OrderCountsType', paid: number, refunded: number, transferredProduct: number, transferredUser: number, awaitPay: number } };

export type SubTariffsListQueryVariables = Exact<{
  productUuid: Scalars['UUID']['input'];
  status?: InputMaybe<Array<StatusEnum> | StatusEnum>;
  filters?: InputMaybe<SubTariffListFilterInput>;
}>;


export type SubTariffsListQuery = { __typename?: 'Query', subTariffsList: { __typename?: 'SubTariffsResponse', rows: Array<{ __typename?: 'SubTariffType', createdAt: number, updatedAt: number, uuid: string, name?: string | null, price?: number | null, specialPrice?: number | null, description?: string | null, query?: string | null, ordersCount?: number | null, startedDate?: Date | null, endDate?: Date | null, status: StatusEnum, availableOrdersCount?: number | null, visible?: boolean | null, isLimitedOrders?: boolean | null, baseTariff?: { __typename?: 'BaseTariffType', name: string, type: TariffTypeEnum, ordersCount?: number | null, isLimitedOrders: boolean } | null, statsOrdersCount: { __typename?: 'OrderCountsType', paid: number, refunded: number, transferredProduct: number, transferredUser: number, awaitPay: number } }> } };

export type SubTariffQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type SubTariffQuery = { __typename?: 'Query', subTariff: { __typename?: 'SubTariffType', createdAt: number, updatedAt: number, uuid: string, name?: string | null, price?: number | null, specialPrice?: number | null, description?: string | null, query?: string | null, ordersCount?: number | null, startedDate?: Date | null, endDate?: Date | null, status: StatusEnum, availableOrdersCount?: number | null, visible?: boolean | null, isLimitedOrders?: boolean | null, baseTariff?: { __typename?: 'BaseTariffType', createdAt: number, updatedAt: number, uuid: string, name: string, description?: string | null, query?: string | null, ordersCount?: number | null, price: number, visible: boolean, status: StatusEnum, type: TariffTypeEnum, isLimitedOrders: boolean, countSubTariffs: number, featureFlags: { __typename?: 'OrderFeatureFlagsType', bonusPay: boolean, refundOrder: boolean, transferToUserOrder: boolean, transferToProductOrder: boolean, repeatBuyOrder: boolean } } | null, statsOrdersCount: { __typename?: 'OrderCountsType', paid: number, refunded: number, transferredProduct: number, transferredUser: number, awaitPay: number } } };

export type CreateTariffMutationVariables = Exact<{
  input: SubTariffInput;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  uuidParentTariff: Scalars['UUID']['input'];
}>;


export type CreateTariffMutation = { __typename?: 'Mutation', createTariff: { __typename?: 'SubTariffType', createdAt: number, updatedAt: number, uuid: string, name?: string | null, price?: number | null, specialPrice?: number | null, description?: string | null, query?: string | null, ordersCount?: number | null, startedDate?: Date | null, endDate?: Date | null, status: StatusEnum, availableOrdersCount?: number | null, visible?: boolean | null, isLimitedOrders?: boolean | null, baseTariff?: { __typename?: 'BaseTariffType', createdAt: number, updatedAt: number, uuid: string, name: string, description?: string | null, query?: string | null, ordersCount?: number | null, price: number, visible: boolean, status: StatusEnum, type: TariffTypeEnum, isLimitedOrders: boolean, countSubTariffs: number, featureFlags: { __typename?: 'OrderFeatureFlagsType', bonusPay: boolean, refundOrder: boolean, transferToUserOrder: boolean, transferToProductOrder: boolean, repeatBuyOrder: boolean } } | null, statsOrdersCount: { __typename?: 'OrderCountsType', paid: number, refunded: number, transferredProduct: number, transferredUser: number, awaitPay: number } } };

export type UpdateTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: SubTariffInput;
}>;


export type UpdateTariffMutation = { __typename?: 'Mutation', updateTariff: { __typename?: 'SubTariffType', createdAt: number, updatedAt: number, uuid: string, name?: string | null, price?: number | null, specialPrice?: number | null, description?: string | null, query?: string | null, ordersCount?: number | null, startedDate?: Date | null, endDate?: Date | null, status: StatusEnum, availableOrdersCount?: number | null, visible?: boolean | null, isLimitedOrders?: boolean | null, baseTariff?: { __typename?: 'BaseTariffType', createdAt: number, updatedAt: number, uuid: string, name: string, description?: string | null, query?: string | null, ordersCount?: number | null, price: number, visible: boolean, status: StatusEnum, type: TariffTypeEnum, isLimitedOrders: boolean, countSubTariffs: number, featureFlags: { __typename?: 'OrderFeatureFlagsType', bonusPay: boolean, refundOrder: boolean, transferToUserOrder: boolean, transferToProductOrder: boolean, repeatBuyOrder: boolean } } | null, statsOrdersCount: { __typename?: 'OrderCountsType', paid: number, refunded: number, transferredProduct: number, transferredUser: number, awaitPay: number } } };

export type RemoveTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type RemoveTariffMutation = { __typename?: 'Mutation', removeTariff: any };

export type UpdateProductTariffsMutationVariables = Exact<{
  uuids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  productUuid: Scalars['UUID']['input'];
}>;


export type UpdateProductTariffsMutation = { __typename?: 'Mutation', updateProductTariffs: any };

export type TerminalFieldsFragment = { __typename?: 'TerminalType', createdAt: number, updatedAt: number, uuid: string, name: string, label: string, holdPercentage: number, invoiceLifetime: any, type: TerminalEnum, status: StatusEnum, token?: string | null };

export type TerminalQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type TerminalQuery = { __typename?: 'Query', terminalAdmin: { __typename?: 'TerminalType', createdAt: number, updatedAt: number, uuid: string, name: string, label: string, holdPercentage: number, invoiceLifetime: any, type: TerminalEnum, status: StatusEnum, token?: string | null } };

export type TerminalsByProductQueryVariables = Exact<{
  uuidProduct: Scalars['UUID']['input'];
}>;


export type TerminalsByProductQuery = { __typename?: 'Query', terminalsByProduct: Array<{ __typename?: 'TerminalType', createdAt: number, updatedAt: number, uuid: string, name: string, label: string, holdPercentage: number, invoiceLifetime: any, type: TerminalEnum, status: StatusEnum, token?: string | null }> };

export type TerminalsQueryVariables = Exact<{
  status?: InputMaybe<Array<StatusEnum> | StatusEnum>;
  idOrganizer?: InputMaybe<Scalars['Float']['input']>;
  filters?: InputMaybe<TerminalListFilterInput>;
}>;


export type TerminalsQuery = { __typename?: 'Query', terminalsList: { __typename?: 'TerminalsResponse', count: number, rows: Array<{ __typename?: 'TerminalType', createdAt: number, updatedAt: number, uuid: string, name: string, label: string, holdPercentage: number, invoiceLifetime: any, type: TerminalEnum, status: StatusEnum, token?: string | null, organizer: { __typename?: 'OrganizerType', name: string, telegram: string } }> } };

export type TerminalsCountQueryVariables = Exact<{
  status?: InputMaybe<Array<StatusEnum> | StatusEnum>;
  idOrganizer?: InputMaybe<Scalars['Float']['input']>;
}>;


export type TerminalsCountQuery = { __typename?: 'Query', terminalsList: { __typename?: 'TerminalsResponse', count: number } };

export type CreateTerminalMutationVariables = Exact<{
  idOrganizer: Scalars['Float']['input'];
  input: TerminalInput;
}>;


export type CreateTerminalMutation = { __typename?: 'Mutation', createTerminal: { __typename?: 'TerminalType', createdAt: number, updatedAt: number, uuid: string, name: string, label: string, holdPercentage: number, invoiceLifetime: any, type: TerminalEnum, status: StatusEnum, token?: string | null } };

export type UpdateTerminalMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: TerminalInput;
}>;


export type UpdateTerminalMutation = { __typename?: 'Mutation', updateTerminal: { __typename?: 'TerminalType', createdAt: number, updatedAt: number, uuid: string, name: string, label: string, holdPercentage: number, invoiceLifetime: any, type: TerminalEnum, status: StatusEnum, token?: string | null } };

export type UpdateProductTerminalsMutationVariables = Exact<{
  uuidProduct: Scalars['UUID']['input'];
  uuidTerminals: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type UpdateProductTerminalsMutation = { __typename?: 'Mutation', updateProductTerminals: any };

export type RemoveTerminalMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type RemoveTerminalMutation = { __typename?: 'Mutation', removeTerminal: any };

export type ManualTerminalFieldsFragment = { __typename?: 'ManualTerminalType', createdAt: number, updatedAt: number, uuid: string, name: string, label: string, status: StatusEnum };

export type ManualTerminalByOrganizerQueryVariables = Exact<{
  idOrganizer: Scalars['Float']['input'];
}>;


export type ManualTerminalByOrganizerQuery = { __typename?: 'Query', manualTerminalByOrganizer?: { __typename?: 'ManualTerminalType', createdAt: number, updatedAt: number, uuid: string, name: string, label: string, status: StatusEnum } | null };

export type ManualProductTerminalQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type ManualProductTerminalQuery = { __typename?: 'Query', product: { __typename?: 'ProductType', manualPaymentText: string, manualTerminal?: { __typename?: 'ManualTerminalType', uuid: string, label: string } | null } };

export type RemoveManualTerminalFromProductMutationVariables = Exact<{
  uuidProduct: Scalars['UUID']['input'];
}>;


export type RemoveManualTerminalFromProductMutation = { __typename?: 'Mutation', removeManualTerminalFromProduct: any };

export type AddManualTerminalToProductMutationVariables = Exact<{
  uuidProduct: Scalars['UUID']['input'];
  uuidManualTerminal: Scalars['UUID']['input'];
}>;


export type AddManualTerminalToProductMutation = { __typename?: 'Mutation', addManualTerminalToProduct: any };

export type UpdateManualTerminalTextMutationVariables = Exact<{
  uuidProduct: Scalars['UUID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateManualTerminalTextMutation = { __typename?: 'Mutation', updateManualTerminalText: any };

export type SubmitManualInvoiceMutationVariables = Exact<{
  uuidPayment: Scalars['UUID']['input'];
}>;


export type SubmitManualInvoiceMutation = { __typename?: 'Mutation', submitManualInvoice: { __typename?: 'PaymentType', uuid: string } };

export type CancelManualInvoiceMutationVariables = Exact<{
  uuidPayment: Scalars['UUID']['input'];
}>;


export type CancelManualInvoiceMutation = { __typename?: 'Mutation', cancelManualInvoice: { __typename?: 'PaymentType', uuid: string } };

export type UserGroupFieldsFragment = { __typename?: 'UserGroupType', createdAt: number, updatedAt: number, uuid: string, name: string, usersCount: number };

export type UserListFieldsFragment = { __typename?: 'UsersResponse', count: number, rows: Array<{ __typename?: 'UserType', createdAt: number, uuid: string, first_name: string, last_name?: string | null, username?: string | null, phone?: string | null, email?: string | null, gender?: GenderEnum | null, activatedOrdersCount: number, ordersCount: number, balance: number, isBlocked: boolean, hasInviteByProduct: boolean, hasOrderByProduct: boolean, statsPayments: { __typename?: 'AmountStatsType', turnover: number } }> };

export type UsersListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type UsersListQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', count: number, rows: Array<{ __typename?: 'UserType', createdAt: number, uuid: string, first_name: string, last_name?: string | null, username?: string | null, phone?: string | null, email?: string | null, gender?: GenderEnum | null, activatedOrdersCount: number, ordersCount: number, balance: number, isBlocked: boolean, hasInviteByProduct: boolean, hasOrderByProduct: boolean, statsPayments: { __typename?: 'AmountStatsType', turnover: number } }> } };

export type UsersBlockedListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Int']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type UsersBlockedListQuery = { __typename?: 'Query', blacklist: { __typename?: 'UsersResponse', count: number, rows: Array<{ __typename?: 'UserType', createdAt: number, uuid: string, first_name: string, last_name?: string | null, username?: string | null, phone?: string | null, email?: string | null, gender?: GenderEnum | null, activatedOrdersCount: number, ordersCount: number, balance: number, isBlocked: boolean, hasInviteByProduct: boolean, hasOrderByProduct: boolean, statsPayments: { __typename?: 'AmountStatsType', turnover: number } }> } };

export type UsersRegisteredListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Int']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type UsersRegisteredListQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', count: number, rows: Array<{ __typename?: 'UserType', createdAt: number, uuid: string, first_name: string, last_name?: string | null, username?: string | null, phone?: string | null, email?: string | null, gender?: GenderEnum | null, activatedOrdersCount: number, ordersCount: number, balance: number, isBlocked: boolean, hasInviteByProduct: boolean, hasOrderByProduct: boolean, statsPayments: { __typename?: 'AmountStatsType', turnover: number } }> } };

export type UsersByGroupListQueryVariables = Exact<{
  filters: UserListFilterInput;
  groupUuid: Scalars['UUID']['input'];
  organizerId: Scalars['Int']['input'];
  productUuid?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type UsersByGroupListQuery = { __typename?: 'Query', usersByGroup: { __typename?: 'UsersResponse', count: number, rows: Array<{ __typename?: 'UserType', createdAt: number, uuid: string, first_name: string, last_name?: string | null, username?: string | null, phone?: string | null, email?: string | null, gender?: GenderEnum | null, activatedOrdersCount: number, ordersCount: number, balance: number, isBlocked: boolean, hasInviteByProduct: boolean, hasOrderByProduct: boolean, statsPayments: { __typename?: 'AmountStatsType', turnover: number } }> } };

export type UsersRegisteredCountQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
}>;


export type UsersRegisteredCountQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', count: number } };

export type UsersBlockedCountQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
}>;


export type UsersBlockedCountQuery = { __typename?: 'Query', blacklist: { __typename?: 'UsersResponse', count: number } };

export type UserGroupsQueryVariables = Exact<{
  filters?: InputMaybe<UserGroupsListFilterInput>;
  idOrganizer: Scalars['Int']['input'];
}>;


export type UserGroupsQuery = { __typename?: 'Query', userGroups: { __typename?: 'UserGroupsResponse', count: number, rows: Array<{ __typename?: 'UserGroupType', createdAt: number, updatedAt: number, uuid: string, name: string, usersCount: number }> } };

export type AddGroupUsersMutationVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
  name: Scalars['String']['input'];
}>;


export type AddGroupUsersMutation = { __typename?: 'Mutation', crateUserGroup: { __typename?: 'UserGroupType', createdAt: number, updatedAt: number, uuid: string, name: string, usersCount: number } };

export type UpdateGroupUsersNameMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
}>;


export type UpdateGroupUsersNameMutation = { __typename?: 'Mutation', updateUserGroupName: { __typename?: 'UserGroupType', createdAt: number, updatedAt: number, uuid: string, name: string, usersCount: number } };

export type RemoveGroupUsersMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type RemoveGroupUsersMutation = { __typename?: 'Mutation', removeUserGroup: any };

export type AddUserToGroupMutationVariables = Exact<{
  uuidUser: Scalars['UUID']['input'];
  uuidGroup: Scalars['UUID']['input'];
}>;


export type AddUserToGroupMutation = { __typename?: 'Mutation', addUserToGroup: any };

export type RemoveUserFromGroupMutationVariables = Exact<{
  uuidUser: Scalars['UUID']['input'];
  uuidGroup: Scalars['UUID']['input'];
}>;


export type RemoveUserFromGroupMutation = { __typename?: 'Mutation', removeUserFromGroup: any };

export type BlockUserMutationVariables = Exact<{
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}>;


export type BlockUserMutation = { __typename?: 'Mutation', addUserToBlacklist: any };

export type UnblockUserMutationVariables = Exact<{
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}>;


export type UnblockUserMutation = { __typename?: 'Mutation', removeUserFromBlacklist: any };

export type UpdateUsersInGroupMutationVariables = Exact<{
  uuidGroup: Scalars['UUID']['input'];
  uuidUsers: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type UpdateUsersInGroupMutation = { __typename?: 'Mutation', updateUsersInGroup: any };

export type UserQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  organizerId: Scalars['Int']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'UserType', createdAt: number, updatedAt: number, deletedAt?: number | null, uuid: string, tgId?: number | null, username?: string | null, first_name: string, last_name?: string | null, language: LanguageEnum, role: RoleEnum, email?: string | null, phone?: string | null, ordersCount: number, activatedOrdersCount: number, gender?: GenderEnum | null, balance: number, isBlocked: boolean, statsPayments: { __typename?: 'AmountStatsType', turnover: number } } | null };

export type UsersStatsQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
}>;


export type UsersStatsQuery = { __typename?: 'Query', statsUsers: { __typename?: 'UsersGenderCountsType', count: number, maleCount: number, femaleCount: number } };

export type ActiveUsersStatsQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
}>;


export type ActiveUsersStatsQuery = { __typename?: 'Query', statsUsersWithActiveOrders: { __typename?: 'UsersGenderCountsType', count: number, maleCount: number, femaleCount: number } };

export type UsersControlOrganizerQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Int']['input'];
}>;


export type UsersControlOrganizerQuery = { __typename?: 'Query', usersControlOrganizer: { __typename?: 'UsersResponse', count: number, rows: Array<{ __typename?: 'UserType', createdAt: number, uuid: string, first_name: string, last_name?: string | null, username?: string | null, phone?: string | null, email?: string | null }> } };

export type UpdateUserMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserType', uuid: string } };

export type DeactivateUserOrganizerMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type DeactivateUserOrganizerMutation = { __typename?: 'Mutation', deactivateUserOrganizer?: { __typename?: 'UserType', uuid: string } | null };

export type CreateAdminUserMutationVariables = Exact<{
  input: CreateAdminUserInput;
}>;


export type CreateAdminUserMutation = { __typename?: 'Mutation', createAdminUser: { __typename?: 'UserType', uuid: string } };

export type WithdrawalFieldsFragment = { __typename?: 'WithdrawalType', createdAt: number, updatedAt: number, deletedAt?: number | null, uuid: string, amount: number, status: WithdrawalStatusEnum, organizer: { __typename?: 'OrganizerType', name: string } };

export type WithdrawalsListQueryVariables = Exact<{
  filter?: InputMaybe<WithdrawalListFilterInput>;
  idOrganizer: Scalars['Int']['input'];
  from?: InputMaybe<WithdrawalFromEnum>;
}>;


export type WithdrawalsListQuery = { __typename?: 'Query', withdrawalsByOrganizer: { __typename?: 'WithdrawalsResponse', count: number, rows: Array<{ __typename?: 'WithdrawalType', createdAt: number, updatedAt: number, deletedAt?: number | null, uuid: string, amount: number, status: WithdrawalStatusEnum, paymentAccount: { __typename?: 'PaymentAccountType', createdAt: number, updatedAt: number, deletedAt?: number | null, uuid: string, name: string, inn: string, bik: string, accountNumber: string }, organizer: { __typename?: 'OrganizerType', name: string } }> } };

export type WithdrawalRequestMutationVariables = Exact<{
  input: WithdrawalOrganizerInput;
}>;


export type WithdrawalRequestMutation = { __typename?: 'Mutation', requestWithdrawal: any };

export type CancelWithdrawalMutationVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
}>;


export type CancelWithdrawalMutation = { __typename?: 'Mutation', cancelWithdrawal: any };

export const JwtFieldsFragmentDoc = gql`
    fragment JwtFields on JwtType {
  access_token
  refresh_token
}
    `;
export const BaseTariffFieldsFragmentDoc = gql`
    fragment BaseTariffFields on BaseTariffType {
  createdAt
  updatedAt
  uuid
  name
  description
  query
  ordersCount
  price
  visible
  status
  type
  isLimitedOrders
  featureFlags {
    bonusPay
    refundOrder
    transferToUserOrder
    transferToProductOrder
    repeatBuyOrder
  }
  countSubTariffs
}
    `;
export const BotFieldsFragmentDoc = gql`
    fragment BotFields on BotType {
  createdAt
  updatedAt
  id
  username
  token
  name
  active
}
    `;
export const SupplierInfoFieldsFragmentDoc = gql`
    fragment SupplierInfoFields on OrganizerSupplierInfoType {
  name
  inn
  phone
}
    `;
export const OrganizerAdminFeatureFieldsFragmentDoc = gql`
    fragment OrganizerAdminFeatureFields on OrganizerFeatureType {
  createReferral
  viewReferral
  manualPayment
}
    `;
export const OrganizerFieldsFragmentDoc = gql`
    fragment OrganizerFields on OrganizerType {
  id
  name
  createdAt
  productsCount
  telegram
  ordersCount
  salesBalance
  referralBalance
  creditBalance
  statsPayments {
    turnover
  }
}
    `;
export const PaymentAccountFieldsFragmentDoc = gql`
    fragment PaymentAccountFields on PaymentAccountType {
  createdAt
  updatedAt
  deletedAt
  uuid
  name
  inn
  bik
  accountNumber
}
    `;
export const ReferralOrganizerFieldsFragmentDoc = gql`
    fragment ReferralOrganizerFields on ReferralOrganizerType {
  createdAt
  updatedAt
  key
  name
  percentage
  ordersCount
  status
}
    `;
export const SubTariffFieldsFragmentDoc = gql`
    fragment SubTariffFields on SubTariffType {
  createdAt
  updatedAt
  uuid
  name
  price
  specialPrice
  description
  query
  ordersCount
  startedDate
  endDate
  status
  availableOrdersCount
  visible
  isLimitedOrders
  statsOrdersCount {
    paid
    refunded
    transferredProduct
    transferredUser
    awaitPay
  }
}
    `;
export const TerminalFieldsFragmentDoc = gql`
    fragment TerminalFields on TerminalType {
  createdAt
  updatedAt
  uuid
  name
  label
  holdPercentage
  invoiceLifetime
  type
  status
  token
}
    `;
export const ManualTerminalFieldsFragmentDoc = gql`
    fragment ManualTerminalFields on ManualTerminalType {
  createdAt
  updatedAt
  uuid
  name
  label
  status
}
    `;
export const UserGroupFieldsFragmentDoc = gql`
    fragment UserGroupFields on UserGroupType {
  createdAt
  updatedAt
  uuid
  name
  usersCount
}
    `;
export const UserListFieldsFragmentDoc = gql`
    fragment UserListFields on UsersResponse {
  rows {
    createdAt
    uuid
    first_name
    last_name
    username
    phone
    email
    gender
    statsPayments {
      turnover
    }
    activatedOrdersCount
    ordersCount
    balance(idOrganizer: $organizerId)
    isBlocked(idOrganizer: $organizerId)
    hasInviteByProduct(productUuid: $productUuid)
    hasOrderByProduct(productUuid: $productUuid)
  }
  count
}
    `;
export const WithdrawalFieldsFragmentDoc = gql`
    fragment WithdrawalFields on WithdrawalType {
  createdAt
  updatedAt
  deletedAt
  uuid
  amount
  status
  organizer {
    name
  }
}
    `;
export const LoginDocument = gql`
    mutation login($login: String!, $password: String!) {
  login(input: {login: $login, password: $password}) {
    ...JwtFields
  }
}
    ${JwtFieldsFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RefreshDocument = gql`
    mutation refresh($refresh_token: String!) {
  refresh(refresh_token: $refresh_token) {
    ...JwtFields
  }
}
    ${JwtFieldsFragmentDoc}`;
export type RefreshMutationFn = Apollo.MutationFunction<RefreshMutation, RefreshMutationVariables>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *      refresh_token: // value for 'refresh_token'
 *   },
 * });
 */
export function useRefreshMutation(baseOptions?: Apollo.MutationHookOptions<RefreshMutation, RefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(RefreshDocument, options);
      }
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<RefreshMutation, RefreshMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    uuid
    role
    username
    controlOrganizerId
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const BaseTariffsListDocument = gql`
    query baseTariffsList($filters: BaseTariffListFilterInput, $status: [StatusEnum!], $idOrganizer: Int!) {
  baseTariffsList(status: $status, filters: $filters, idOrganizer: $idOrganizer) {
    rows {
      ...BaseTariffFields
    }
    count
  }
}
    ${BaseTariffFieldsFragmentDoc}`;

/**
 * __useBaseTariffsListQuery__
 *
 * To run a query within a React component, call `useBaseTariffsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseTariffsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseTariffsListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      status: // value for 'status'
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useBaseTariffsListQuery(baseOptions: Apollo.QueryHookOptions<BaseTariffsListQuery, BaseTariffsListQueryVariables> & ({ variables: BaseTariffsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaseTariffsListQuery, BaseTariffsListQueryVariables>(BaseTariffsListDocument, options);
      }
export function useBaseTariffsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaseTariffsListQuery, BaseTariffsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaseTariffsListQuery, BaseTariffsListQueryVariables>(BaseTariffsListDocument, options);
        }
export function useBaseTariffsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BaseTariffsListQuery, BaseTariffsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BaseTariffsListQuery, BaseTariffsListQueryVariables>(BaseTariffsListDocument, options);
        }
export type BaseTariffsListQueryHookResult = ReturnType<typeof useBaseTariffsListQuery>;
export type BaseTariffsListLazyQueryHookResult = ReturnType<typeof useBaseTariffsListLazyQuery>;
export type BaseTariffsListSuspenseQueryHookResult = ReturnType<typeof useBaseTariffsListSuspenseQuery>;
export type BaseTariffsListQueryResult = Apollo.QueryResult<BaseTariffsListQuery, BaseTariffsListQueryVariables>;
export const BaseTariffDocument = gql`
    query baseTariff($uuid: UUID!) {
  baseTariff(uuid: $uuid) {
    ...BaseTariffFields
  }
}
    ${BaseTariffFieldsFragmentDoc}`;

/**
 * __useBaseTariffQuery__
 *
 * To run a query within a React component, call `useBaseTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseTariffQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useBaseTariffQuery(baseOptions: Apollo.QueryHookOptions<BaseTariffQuery, BaseTariffQueryVariables> & ({ variables: BaseTariffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaseTariffQuery, BaseTariffQueryVariables>(BaseTariffDocument, options);
      }
export function useBaseTariffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaseTariffQuery, BaseTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaseTariffQuery, BaseTariffQueryVariables>(BaseTariffDocument, options);
        }
export function useBaseTariffSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BaseTariffQuery, BaseTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BaseTariffQuery, BaseTariffQueryVariables>(BaseTariffDocument, options);
        }
export type BaseTariffQueryHookResult = ReturnType<typeof useBaseTariffQuery>;
export type BaseTariffLazyQueryHookResult = ReturnType<typeof useBaseTariffLazyQuery>;
export type BaseTariffSuspenseQueryHookResult = ReturnType<typeof useBaseTariffSuspenseQuery>;
export type BaseTariffQueryResult = Apollo.QueryResult<BaseTariffQuery, BaseTariffQueryVariables>;
export const AllowedUserGroupsForBaseTariffDocument = gql`
    query allowedUserGroupsForBaseTariff($uuid: UUID!) {
  allowedUserGroupsForBaseTariff(uuid: $uuid) {
    uuid
    name
  }
}
    `;

/**
 * __useAllowedUserGroupsForBaseTariffQuery__
 *
 * To run a query within a React component, call `useAllowedUserGroupsForBaseTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedUserGroupsForBaseTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedUserGroupsForBaseTariffQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useAllowedUserGroupsForBaseTariffQuery(baseOptions: Apollo.QueryHookOptions<AllowedUserGroupsForBaseTariffQuery, AllowedUserGroupsForBaseTariffQueryVariables> & ({ variables: AllowedUserGroupsForBaseTariffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllowedUserGroupsForBaseTariffQuery, AllowedUserGroupsForBaseTariffQueryVariables>(AllowedUserGroupsForBaseTariffDocument, options);
      }
export function useAllowedUserGroupsForBaseTariffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllowedUserGroupsForBaseTariffQuery, AllowedUserGroupsForBaseTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllowedUserGroupsForBaseTariffQuery, AllowedUserGroupsForBaseTariffQueryVariables>(AllowedUserGroupsForBaseTariffDocument, options);
        }
export function useAllowedUserGroupsForBaseTariffSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllowedUserGroupsForBaseTariffQuery, AllowedUserGroupsForBaseTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllowedUserGroupsForBaseTariffQuery, AllowedUserGroupsForBaseTariffQueryVariables>(AllowedUserGroupsForBaseTariffDocument, options);
        }
export type AllowedUserGroupsForBaseTariffQueryHookResult = ReturnType<typeof useAllowedUserGroupsForBaseTariffQuery>;
export type AllowedUserGroupsForBaseTariffLazyQueryHookResult = ReturnType<typeof useAllowedUserGroupsForBaseTariffLazyQuery>;
export type AllowedUserGroupsForBaseTariffSuspenseQueryHookResult = ReturnType<typeof useAllowedUserGroupsForBaseTariffSuspenseQuery>;
export type AllowedUserGroupsForBaseTariffQueryResult = Apollo.QueryResult<AllowedUserGroupsForBaseTariffQuery, AllowedUserGroupsForBaseTariffQueryVariables>;
export const CreateBaseTariffDocument = gql`
    mutation createBaseTariff($input: BaseTariffInput!, $idOrganizer: Int!) {
  createBaseTariff(input: $input, idOrganizer: $idOrganizer) {
    ...BaseTariffFields
  }
}
    ${BaseTariffFieldsFragmentDoc}`;
export type CreateBaseTariffMutationFn = Apollo.MutationFunction<CreateBaseTariffMutation, CreateBaseTariffMutationVariables>;

/**
 * __useCreateBaseTariffMutation__
 *
 * To run a mutation, you first call `useCreateBaseTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBaseTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBaseTariffMutation, { data, loading, error }] = useCreateBaseTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useCreateBaseTariffMutation(baseOptions?: Apollo.MutationHookOptions<CreateBaseTariffMutation, CreateBaseTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBaseTariffMutation, CreateBaseTariffMutationVariables>(CreateBaseTariffDocument, options);
      }
export type CreateBaseTariffMutationHookResult = ReturnType<typeof useCreateBaseTariffMutation>;
export type CreateBaseTariffMutationResult = Apollo.MutationResult<CreateBaseTariffMutation>;
export type CreateBaseTariffMutationOptions = Apollo.BaseMutationOptions<CreateBaseTariffMutation, CreateBaseTariffMutationVariables>;
export const UpdateBaseTariffDocument = gql`
    mutation updateBaseTariff($uuid: UUID!, $input: BaseTariffInput!) {
  updateBaseTariff(input: $input, uuid: $uuid) {
    ...BaseTariffFields
  }
}
    ${BaseTariffFieldsFragmentDoc}`;
export type UpdateBaseTariffMutationFn = Apollo.MutationFunction<UpdateBaseTariffMutation, UpdateBaseTariffMutationVariables>;

/**
 * __useUpdateBaseTariffMutation__
 *
 * To run a mutation, you first call `useUpdateBaseTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBaseTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBaseTariffMutation, { data, loading, error }] = useUpdateBaseTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBaseTariffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBaseTariffMutation, UpdateBaseTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBaseTariffMutation, UpdateBaseTariffMutationVariables>(UpdateBaseTariffDocument, options);
      }
export type UpdateBaseTariffMutationHookResult = ReturnType<typeof useUpdateBaseTariffMutation>;
export type UpdateBaseTariffMutationResult = Apollo.MutationResult<UpdateBaseTariffMutation>;
export type UpdateBaseTariffMutationOptions = Apollo.BaseMutationOptions<UpdateBaseTariffMutation, UpdateBaseTariffMutationVariables>;
export const DuplicateBaseTariffDocument = gql`
    mutation duplicateBaseTariff($uuid: UUID!) {
  duplicateBaseTariff(uuid: $uuid) {
    ...BaseTariffFields
  }
}
    ${BaseTariffFieldsFragmentDoc}`;
export type DuplicateBaseTariffMutationFn = Apollo.MutationFunction<DuplicateBaseTariffMutation, DuplicateBaseTariffMutationVariables>;

/**
 * __useDuplicateBaseTariffMutation__
 *
 * To run a mutation, you first call `useDuplicateBaseTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateBaseTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateBaseTariffMutation, { data, loading, error }] = useDuplicateBaseTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDuplicateBaseTariffMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateBaseTariffMutation, DuplicateBaseTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateBaseTariffMutation, DuplicateBaseTariffMutationVariables>(DuplicateBaseTariffDocument, options);
      }
export type DuplicateBaseTariffMutationHookResult = ReturnType<typeof useDuplicateBaseTariffMutation>;
export type DuplicateBaseTariffMutationResult = Apollo.MutationResult<DuplicateBaseTariffMutation>;
export type DuplicateBaseTariffMutationOptions = Apollo.BaseMutationOptions<DuplicateBaseTariffMutation, DuplicateBaseTariffMutationVariables>;
export const RemoveBaseTariffDocument = gql`
    mutation removeBaseTariff($uuid: UUID!) {
  removeBaseTariff(uuid: $uuid)
}
    `;
export type RemoveBaseTariffMutationFn = Apollo.MutationFunction<RemoveBaseTariffMutation, RemoveBaseTariffMutationVariables>;

/**
 * __useRemoveBaseTariffMutation__
 *
 * To run a mutation, you first call `useRemoveBaseTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBaseTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBaseTariffMutation, { data, loading, error }] = useRemoveBaseTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveBaseTariffMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBaseTariffMutation, RemoveBaseTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBaseTariffMutation, RemoveBaseTariffMutationVariables>(RemoveBaseTariffDocument, options);
      }
export type RemoveBaseTariffMutationHookResult = ReturnType<typeof useRemoveBaseTariffMutation>;
export type RemoveBaseTariffMutationResult = Apollo.MutationResult<RemoveBaseTariffMutation>;
export type RemoveBaseTariffMutationOptions = Apollo.BaseMutationOptions<RemoveBaseTariffMutation, RemoveBaseTariffMutationVariables>;
export const UpdateUsersBaseTariffDocument = gql`
    mutation updateUsersBaseTariff($uuid: UUID!, $userGroupsUuids: [UUID!]!) {
  updateUserGroupsBaseTariff(uuid: $uuid, userGroupsUuid: $userGroupsUuids)
}
    `;
export type UpdateUsersBaseTariffMutationFn = Apollo.MutationFunction<UpdateUsersBaseTariffMutation, UpdateUsersBaseTariffMutationVariables>;

/**
 * __useUpdateUsersBaseTariffMutation__
 *
 * To run a mutation, you first call `useUpdateUsersBaseTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersBaseTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersBaseTariffMutation, { data, loading, error }] = useUpdateUsersBaseTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      userGroupsUuids: // value for 'userGroupsUuids'
 *   },
 * });
 */
export function useUpdateUsersBaseTariffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUsersBaseTariffMutation, UpdateUsersBaseTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUsersBaseTariffMutation, UpdateUsersBaseTariffMutationVariables>(UpdateUsersBaseTariffDocument, options);
      }
export type UpdateUsersBaseTariffMutationHookResult = ReturnType<typeof useUpdateUsersBaseTariffMutation>;
export type UpdateUsersBaseTariffMutationResult = Apollo.MutationResult<UpdateUsersBaseTariffMutation>;
export type UpdateUsersBaseTariffMutationOptions = Apollo.BaseMutationOptions<UpdateUsersBaseTariffMutation, UpdateUsersBaseTariffMutationVariables>;
export const BotsListDocument = gql`
    query botsList($isActive: Boolean, $filters: BotListFilterInput, $params: BotListParamsInput) {
  botsList(filters: $filters, params: $params, isActive: $isActive) {
    rows {
      ...BotFields
      organizers {
        id
      }
      usersCount
    }
    count
  }
}
    ${BotFieldsFragmentDoc}`;

/**
 * __useBotsListQuery__
 *
 * To run a query within a React component, call `useBotsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBotsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBotsListQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      filters: // value for 'filters'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useBotsListQuery(baseOptions?: Apollo.QueryHookOptions<BotsListQuery, BotsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BotsListQuery, BotsListQueryVariables>(BotsListDocument, options);
      }
export function useBotsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BotsListQuery, BotsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BotsListQuery, BotsListQueryVariables>(BotsListDocument, options);
        }
export function useBotsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BotsListQuery, BotsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BotsListQuery, BotsListQueryVariables>(BotsListDocument, options);
        }
export type BotsListQueryHookResult = ReturnType<typeof useBotsListQuery>;
export type BotsListLazyQueryHookResult = ReturnType<typeof useBotsListLazyQuery>;
export type BotsListSuspenseQueryHookResult = ReturnType<typeof useBotsListSuspenseQuery>;
export type BotsListQueryResult = Apollo.QueryResult<BotsListQuery, BotsListQueryVariables>;
export const BotsListCountDocument = gql`
    query botsListCount($isActive: Boolean) {
  botsList(filters: {pagination: {pageSize: 1, page: 1}}, isActive: $isActive) {
    count
  }
}
    `;

/**
 * __useBotsListCountQuery__
 *
 * To run a query within a React component, call `useBotsListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBotsListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBotsListCountQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useBotsListCountQuery(baseOptions?: Apollo.QueryHookOptions<BotsListCountQuery, BotsListCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BotsListCountQuery, BotsListCountQueryVariables>(BotsListCountDocument, options);
      }
export function useBotsListCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BotsListCountQuery, BotsListCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BotsListCountQuery, BotsListCountQueryVariables>(BotsListCountDocument, options);
        }
export function useBotsListCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BotsListCountQuery, BotsListCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BotsListCountQuery, BotsListCountQueryVariables>(BotsListCountDocument, options);
        }
export type BotsListCountQueryHookResult = ReturnType<typeof useBotsListCountQuery>;
export type BotsListCountLazyQueryHookResult = ReturnType<typeof useBotsListCountLazyQuery>;
export type BotsListCountSuspenseQueryHookResult = ReturnType<typeof useBotsListCountSuspenseQuery>;
export type BotsListCountQueryResult = Apollo.QueryResult<BotsListCountQuery, BotsListCountQueryVariables>;
export const BotsByOrganizerDocument = gql`
    query botsByOrganizer($id: Int!) {
  organizerAdmin(id: $id) {
    bots {
      id
      username
    }
  }
}
    `;

/**
 * __useBotsByOrganizerQuery__
 *
 * To run a query within a React component, call `useBotsByOrganizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBotsByOrganizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBotsByOrganizerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBotsByOrganizerQuery(baseOptions: Apollo.QueryHookOptions<BotsByOrganizerQuery, BotsByOrganizerQueryVariables> & ({ variables: BotsByOrganizerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BotsByOrganizerQuery, BotsByOrganizerQueryVariables>(BotsByOrganizerDocument, options);
      }
export function useBotsByOrganizerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BotsByOrganizerQuery, BotsByOrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BotsByOrganizerQuery, BotsByOrganizerQueryVariables>(BotsByOrganizerDocument, options);
        }
export function useBotsByOrganizerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BotsByOrganizerQuery, BotsByOrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BotsByOrganizerQuery, BotsByOrganizerQueryVariables>(BotsByOrganizerDocument, options);
        }
export type BotsByOrganizerQueryHookResult = ReturnType<typeof useBotsByOrganizerQuery>;
export type BotsByOrganizerLazyQueryHookResult = ReturnType<typeof useBotsByOrganizerLazyQuery>;
export type BotsByOrganizerSuspenseQueryHookResult = ReturnType<typeof useBotsByOrganizerSuspenseQuery>;
export type BotsByOrganizerQueryResult = Apollo.QueryResult<BotsByOrganizerQuery, BotsByOrganizerQueryVariables>;
export const BotInfoByTokenDocument = gql`
    mutation botInfoByToken($token: String!) {
  botInfoByToken(token: $token) {
    id
    username
    token
  }
}
    `;
export type BotInfoByTokenMutationFn = Apollo.MutationFunction<BotInfoByTokenMutation, BotInfoByTokenMutationVariables>;

/**
 * __useBotInfoByTokenMutation__
 *
 * To run a mutation, you first call `useBotInfoByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBotInfoByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [botInfoByTokenMutation, { data, loading, error }] = useBotInfoByTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useBotInfoByTokenMutation(baseOptions?: Apollo.MutationHookOptions<BotInfoByTokenMutation, BotInfoByTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BotInfoByTokenMutation, BotInfoByTokenMutationVariables>(BotInfoByTokenDocument, options);
      }
export type BotInfoByTokenMutationHookResult = ReturnType<typeof useBotInfoByTokenMutation>;
export type BotInfoByTokenMutationResult = Apollo.MutationResult<BotInfoByTokenMutation>;
export type BotInfoByTokenMutationOptions = Apollo.BaseMutationOptions<BotInfoByTokenMutation, BotInfoByTokenMutationVariables>;
export const CreateBotDocument = gql`
    mutation createBot($input: BotInput!) {
  createBot(input: $input) {
    ...BotFields
  }
}
    ${BotFieldsFragmentDoc}`;
export type CreateBotMutationFn = Apollo.MutationFunction<CreateBotMutation, CreateBotMutationVariables>;

/**
 * __useCreateBotMutation__
 *
 * To run a mutation, you first call `useCreateBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBotMutation, { data, loading, error }] = useCreateBotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBotMutation(baseOptions?: Apollo.MutationHookOptions<CreateBotMutation, CreateBotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBotMutation, CreateBotMutationVariables>(CreateBotDocument, options);
      }
export type CreateBotMutationHookResult = ReturnType<typeof useCreateBotMutation>;
export type CreateBotMutationResult = Apollo.MutationResult<CreateBotMutation>;
export type CreateBotMutationOptions = Apollo.BaseMutationOptions<CreateBotMutation, CreateBotMutationVariables>;
export const DisableBotDocument = gql`
    mutation disableBot($id: Float!) {
  disableBot(id: $id)
}
    `;
export type DisableBotMutationFn = Apollo.MutationFunction<DisableBotMutation, DisableBotMutationVariables>;

/**
 * __useDisableBotMutation__
 *
 * To run a mutation, you first call `useDisableBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableBotMutation, { data, loading, error }] = useDisableBotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableBotMutation(baseOptions?: Apollo.MutationHookOptions<DisableBotMutation, DisableBotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableBotMutation, DisableBotMutationVariables>(DisableBotDocument, options);
      }
export type DisableBotMutationHookResult = ReturnType<typeof useDisableBotMutation>;
export type DisableBotMutationResult = Apollo.MutationResult<DisableBotMutation>;
export type DisableBotMutationOptions = Apollo.BaseMutationOptions<DisableBotMutation, DisableBotMutationVariables>;
export const ActivateBotDocument = gql`
    mutation activateBot($id: Float!) {
  activateBot(id: $id)
}
    `;
export type ActivateBotMutationFn = Apollo.MutationFunction<ActivateBotMutation, ActivateBotMutationVariables>;

/**
 * __useActivateBotMutation__
 *
 * To run a mutation, you first call `useActivateBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBotMutation, { data, loading, error }] = useActivateBotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateBotMutation(baseOptions?: Apollo.MutationHookOptions<ActivateBotMutation, ActivateBotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateBotMutation, ActivateBotMutationVariables>(ActivateBotDocument, options);
      }
export type ActivateBotMutationHookResult = ReturnType<typeof useActivateBotMutation>;
export type ActivateBotMutationResult = Apollo.MutationResult<ActivateBotMutation>;
export type ActivateBotMutationOptions = Apollo.BaseMutationOptions<ActivateBotMutation, ActivateBotMutationVariables>;
export const RemoveBotDocument = gql`
    mutation removeBot($id: Float!) {
  removeBot(id: $id)
}
    `;
export type RemoveBotMutationFn = Apollo.MutationFunction<RemoveBotMutation, RemoveBotMutationVariables>;

/**
 * __useRemoveBotMutation__
 *
 * To run a mutation, you first call `useRemoveBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBotMutation, { data, loading, error }] = useRemoveBotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBotMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBotMutation, RemoveBotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBotMutation, RemoveBotMutationVariables>(RemoveBotDocument, options);
      }
export type RemoveBotMutationHookResult = ReturnType<typeof useRemoveBotMutation>;
export type RemoveBotMutationResult = Apollo.MutationResult<RemoveBotMutation>;
export type RemoveBotMutationOptions = Apollo.BaseMutationOptions<RemoveBotMutation, RemoveBotMutationVariables>;
export const AddBotToOrganizerDocument = gql`
    mutation addBotToOrganizer($idOrganizer: Float!, $idBot: Float!) {
  addBotToOrganizer(idOrganizer: $idOrganizer, idBot: $idBot)
}
    `;
export type AddBotToOrganizerMutationFn = Apollo.MutationFunction<AddBotToOrganizerMutation, AddBotToOrganizerMutationVariables>;

/**
 * __useAddBotToOrganizerMutation__
 *
 * To run a mutation, you first call `useAddBotToOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBotToOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBotToOrganizerMutation, { data, loading, error }] = useAddBotToOrganizerMutation({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *      idBot: // value for 'idBot'
 *   },
 * });
 */
export function useAddBotToOrganizerMutation(baseOptions?: Apollo.MutationHookOptions<AddBotToOrganizerMutation, AddBotToOrganizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBotToOrganizerMutation, AddBotToOrganizerMutationVariables>(AddBotToOrganizerDocument, options);
      }
export type AddBotToOrganizerMutationHookResult = ReturnType<typeof useAddBotToOrganizerMutation>;
export type AddBotToOrganizerMutationResult = Apollo.MutationResult<AddBotToOrganizerMutation>;
export type AddBotToOrganizerMutationOptions = Apollo.BaseMutationOptions<AddBotToOrganizerMutation, AddBotToOrganizerMutationVariables>;
export const RemoveBotFromOrganizerDocument = gql`
    mutation removeBotFromOrganizer($idOrganizer: Float!, $idBot: Float!) {
  removeBotFromOrganizer(idOrganizer: $idOrganizer, idBot: $idBot)
}
    `;
export type RemoveBotFromOrganizerMutationFn = Apollo.MutationFunction<RemoveBotFromOrganizerMutation, RemoveBotFromOrganizerMutationVariables>;

/**
 * __useRemoveBotFromOrganizerMutation__
 *
 * To run a mutation, you first call `useRemoveBotFromOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBotFromOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBotFromOrganizerMutation, { data, loading, error }] = useRemoveBotFromOrganizerMutation({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *      idBot: // value for 'idBot'
 *   },
 * });
 */
export function useRemoveBotFromOrganizerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBotFromOrganizerMutation, RemoveBotFromOrganizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBotFromOrganizerMutation, RemoveBotFromOrganizerMutationVariables>(RemoveBotFromOrganizerDocument, options);
      }
export type RemoveBotFromOrganizerMutationHookResult = ReturnType<typeof useRemoveBotFromOrganizerMutation>;
export type RemoveBotFromOrganizerMutationResult = Apollo.MutationResult<RemoveBotFromOrganizerMutation>;
export type RemoveBotFromOrganizerMutationOptions = Apollo.BaseMutationOptions<RemoveBotFromOrganizerMutation, RemoveBotFromOrganizerMutationVariables>;
export const UploadImagesDocument = gql`
    mutation uploadImages($images: [Upload!]!) {
  uploadImages(image: $images) {
    url
  }
}
    `;
export type UploadImagesMutationFn = Apollo.MutationFunction<UploadImagesMutation, UploadImagesMutationVariables>;

/**
 * __useUploadImagesMutation__
 *
 * To run a mutation, you first call `useUploadImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagesMutation, { data, loading, error }] = useUploadImagesMutation({
 *   variables: {
 *      images: // value for 'images'
 *   },
 * });
 */
export function useUploadImagesMutation(baseOptions?: Apollo.MutationHookOptions<UploadImagesMutation, UploadImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImagesMutation, UploadImagesMutationVariables>(UploadImagesDocument, options);
      }
export type UploadImagesMutationHookResult = ReturnType<typeof useUploadImagesMutation>;
export type UploadImagesMutationResult = Apollo.MutationResult<UploadImagesMutation>;
export type UploadImagesMutationOptions = Apollo.BaseMutationOptions<UploadImagesMutation, UploadImagesMutationVariables>;
export const FinanceStatsDocument = gql`
    query financeStats($organizerId: Int!) {
  paymentsList(
    filters: {pagination: {page: 1, pageSize: 1}}
    args: {organizerId: $organizerId}
  ) {
    count
  }
  users(
    filters: {pagination: {page: 1, pageSize: 1}}
    organizerId: $organizerId
    args: {}
  ) {
    count
  }
  statsPayments(organizerId: $organizerId) {
    profit
    commission
    turnover
    referral
  }
  statsSalesWithdrawal(idOrganizer: $organizerId) {
    total
  }
  organizerAdmin(id: $organizerId) {
    salesBalance
    creditBalance
  }
}
    `;

/**
 * __useFinanceStatsQuery__
 *
 * To run a query within a React component, call `useFinanceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useFinanceStatsQuery(baseOptions: Apollo.QueryHookOptions<FinanceStatsQuery, FinanceStatsQueryVariables> & ({ variables: FinanceStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(FinanceStatsDocument, options);
      }
export function useFinanceStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinanceStatsQuery, FinanceStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(FinanceStatsDocument, options);
        }
export function useFinanceStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FinanceStatsQuery, FinanceStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(FinanceStatsDocument, options);
        }
export type FinanceStatsQueryHookResult = ReturnType<typeof useFinanceStatsQuery>;
export type FinanceStatsLazyQueryHookResult = ReturnType<typeof useFinanceStatsLazyQuery>;
export type FinanceStatsSuspenseQueryHookResult = ReturnType<typeof useFinanceStatsSuspenseQuery>;
export type FinanceStatsQueryResult = Apollo.QueryResult<FinanceStatsQuery, FinanceStatsQueryVariables>;
export const OrganizerBalancesDocument = gql`
    query organizerBalances($organizerId: Int!) {
  organizerAdmin(id: $organizerId) {
    salesBalance
    creditBalance
    referralBalance
  }
}
    `;

/**
 * __useOrganizerBalancesQuery__
 *
 * To run a query within a React component, call `useOrganizerBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerBalancesQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useOrganizerBalancesQuery(baseOptions: Apollo.QueryHookOptions<OrganizerBalancesQuery, OrganizerBalancesQueryVariables> & ({ variables: OrganizerBalancesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizerBalancesQuery, OrganizerBalancesQueryVariables>(OrganizerBalancesDocument, options);
      }
export function useOrganizerBalancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizerBalancesQuery, OrganizerBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizerBalancesQuery, OrganizerBalancesQueryVariables>(OrganizerBalancesDocument, options);
        }
export function useOrganizerBalancesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizerBalancesQuery, OrganizerBalancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizerBalancesQuery, OrganizerBalancesQueryVariables>(OrganizerBalancesDocument, options);
        }
export type OrganizerBalancesQueryHookResult = ReturnType<typeof useOrganizerBalancesQuery>;
export type OrganizerBalancesLazyQueryHookResult = ReturnType<typeof useOrganizerBalancesLazyQuery>;
export type OrganizerBalancesSuspenseQueryHookResult = ReturnType<typeof useOrganizerBalancesSuspenseQuery>;
export type OrganizerBalancesQueryResult = Apollo.QueryResult<OrganizerBalancesQuery, OrganizerBalancesQueryVariables>;
export const PurchaseStatsDocument = gql`
    query purchaseStats($organizerId: Int!, $uuidProduct: UUID) {
  statsOrders(organizerId: $organizerId, uuidProduct: $uuidProduct) {
    turnover
    profit
    commission
    referral
  }
  statsUsersWithOrders(organizerId: $organizerId, productUuid: $uuidProduct) {
    count
    maleCount
    femaleCount
  }
}
    `;

/**
 * __usePurchaseStatsQuery__
 *
 * To run a query within a React component, call `usePurchaseStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      uuidProduct: // value for 'uuidProduct'
 *   },
 * });
 */
export function usePurchaseStatsQuery(baseOptions: Apollo.QueryHookOptions<PurchaseStatsQuery, PurchaseStatsQueryVariables> & ({ variables: PurchaseStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseStatsQuery, PurchaseStatsQueryVariables>(PurchaseStatsDocument, options);
      }
export function usePurchaseStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseStatsQuery, PurchaseStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseStatsQuery, PurchaseStatsQueryVariables>(PurchaseStatsDocument, options);
        }
export function usePurchaseStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PurchaseStatsQuery, PurchaseStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PurchaseStatsQuery, PurchaseStatsQueryVariables>(PurchaseStatsDocument, options);
        }
export type PurchaseStatsQueryHookResult = ReturnType<typeof usePurchaseStatsQuery>;
export type PurchaseStatsLazyQueryHookResult = ReturnType<typeof usePurchaseStatsLazyQuery>;
export type PurchaseStatsSuspenseQueryHookResult = ReturnType<typeof usePurchaseStatsSuspenseQuery>;
export type PurchaseStatsQueryResult = Apollo.QueryResult<PurchaseStatsQuery, PurchaseStatsQueryVariables>;
export const PaymentsListDocument = gql`
    query paymentsList($filter: PaymentsListFilterInput, $organizerId: Int!, $uuidUser: UUID, $status: [PaymentStatusEnum!] = [Success]) {
  paymentsList(
    filters: $filter
    args: {organizerId: $organizerId, uuidUser: $uuidUser}
    status: $status
  ) {
    count
    rows {
      uuid
      price
      createdAt
      payment_uuid
      status
      type
      proof {
        file
      }
      terminal {
        name
      }
      user {
        first_name
        last_name
        email
        username
        uuid
      }
      order {
        id
        status
        fixedHoldPercentage
        referralOrganizer {
          key
          percentage
        }
        product {
          name
          startedDate
          endDate
        }
        userBalanceWithdrawal {
          amount
        }
        tariff {
          name
        }
        pairOrder {
          user {
            first_name
            last_name
            email
            username
            uuid
            phone
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePaymentsListQuery__
 *
 * To run a query within a React component, call `usePaymentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      organizerId: // value for 'organizerId'
 *      uuidUser: // value for 'uuidUser'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePaymentsListQuery(baseOptions: Apollo.QueryHookOptions<PaymentsListQuery, PaymentsListQueryVariables> & ({ variables: PaymentsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentsListQuery, PaymentsListQueryVariables>(PaymentsListDocument, options);
      }
export function usePaymentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsListQuery, PaymentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentsListQuery, PaymentsListQueryVariables>(PaymentsListDocument, options);
        }
export function usePaymentsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PaymentsListQuery, PaymentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentsListQuery, PaymentsListQueryVariables>(PaymentsListDocument, options);
        }
export type PaymentsListQueryHookResult = ReturnType<typeof usePaymentsListQuery>;
export type PaymentsListLazyQueryHookResult = ReturnType<typeof usePaymentsListLazyQuery>;
export type PaymentsListSuspenseQueryHookResult = ReturnType<typeof usePaymentsListSuspenseQuery>;
export type PaymentsListQueryResult = Apollo.QueryResult<PaymentsListQuery, PaymentsListQueryVariables>;
export const PaymentsCountDocument = gql`
    query paymentsCount($organizerId: Int!, $uuidUser: UUID, $status: [PaymentStatusEnum!] = [Success]) {
  paymentsList(
    filters: {pagination: {pageSize: 1, page: 1}}
    args: {organizerId: $organizerId, uuidUser: $uuidUser}
    status: $status
  ) {
    count
  }
}
    `;

/**
 * __usePaymentsCountQuery__
 *
 * To run a query within a React component, call `usePaymentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsCountQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      uuidUser: // value for 'uuidUser'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePaymentsCountQuery(baseOptions: Apollo.QueryHookOptions<PaymentsCountQuery, PaymentsCountQueryVariables> & ({ variables: PaymentsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentsCountQuery, PaymentsCountQueryVariables>(PaymentsCountDocument, options);
      }
export function usePaymentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsCountQuery, PaymentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentsCountQuery, PaymentsCountQueryVariables>(PaymentsCountDocument, options);
        }
export function usePaymentsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PaymentsCountQuery, PaymentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentsCountQuery, PaymentsCountQueryVariables>(PaymentsCountDocument, options);
        }
export type PaymentsCountQueryHookResult = ReturnType<typeof usePaymentsCountQuery>;
export type PaymentsCountLazyQueryHookResult = ReturnType<typeof usePaymentsCountLazyQuery>;
export type PaymentsCountSuspenseQueryHookResult = ReturnType<typeof usePaymentsCountSuspenseQuery>;
export type PaymentsCountQueryResult = Apollo.QueryResult<PaymentsCountQuery, PaymentsCountQueryVariables>;
export const FinanceListsCountDocument = gql`
    query financeListsCount($idOrganizer: Int!) {
  paymentsList(
    args: {organizerId: $idOrganizer}
    filters: {pagination: {pageSize: 1, page: 1}}
  ) {
    count
  }
  withdrawalsByOrganizer(
    idOrganizer: $idOrganizer
    filters: {pagination: {pageSize: 1, page: 1}}
    from: SalesBalance
  ) {
    count
  }
}
    `;

/**
 * __useFinanceListsCountQuery__
 *
 * To run a query within a React component, call `useFinanceListsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceListsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceListsCountQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useFinanceListsCountQuery(baseOptions: Apollo.QueryHookOptions<FinanceListsCountQuery, FinanceListsCountQueryVariables> & ({ variables: FinanceListsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinanceListsCountQuery, FinanceListsCountQueryVariables>(FinanceListsCountDocument, options);
      }
export function useFinanceListsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinanceListsCountQuery, FinanceListsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinanceListsCountQuery, FinanceListsCountQueryVariables>(FinanceListsCountDocument, options);
        }
export function useFinanceListsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FinanceListsCountQuery, FinanceListsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FinanceListsCountQuery, FinanceListsCountQueryVariables>(FinanceListsCountDocument, options);
        }
export type FinanceListsCountQueryHookResult = ReturnType<typeof useFinanceListsCountQuery>;
export type FinanceListsCountLazyQueryHookResult = ReturnType<typeof useFinanceListsCountLazyQuery>;
export type FinanceListsCountSuspenseQueryHookResult = ReturnType<typeof useFinanceListsCountSuspenseQuery>;
export type FinanceListsCountQueryResult = Apollo.QueryResult<FinanceListsCountQuery, FinanceListsCountQueryVariables>;
export const OrdersListDocument = gql`
    query ordersList($filter: OrdersListFilterInput, $organizerId: Int!, $uuidUser: UUID, $uuidProduct: UUID, $status: [OrderStatusEnum!]) {
  ordersList(
    filters: $filter
    args: {organizerId: $organizerId, uuidUser: $uuidUser, uuidProduct: $uuidProduct}
    params: {status: $status}
  ) {
    count
    rows {
      createdAt
      id
      status
      fixedHoldPercentage
      payment {
        price
        status
      }
      referralOrganizer {
        key
        percentage
      }
      user {
        first_name
        last_name
        email
        username
        uuid
        phone
      }
      product {
        name
        startedDate
        endDate
      }
      userBalanceWithdrawal {
        amount
      }
      tariff {
        name
      }
      pairOrder {
        user {
          first_name
          last_name
          email
          username
          uuid
          phone
        }
      }
    }
  }
}
    `;

/**
 * __useOrdersListQuery__
 *
 * To run a query within a React component, call `useOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      organizerId: // value for 'organizerId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOrdersListQuery(baseOptions: Apollo.QueryHookOptions<OrdersListQuery, OrdersListQueryVariables> & ({ variables: OrdersListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
      }
export function useOrdersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
        }
export function useOrdersListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
        }
export type OrdersListQueryHookResult = ReturnType<typeof useOrdersListQuery>;
export type OrdersListLazyQueryHookResult = ReturnType<typeof useOrdersListLazyQuery>;
export type OrdersListSuspenseQueryHookResult = ReturnType<typeof useOrdersListSuspenseQuery>;
export type OrdersListQueryResult = Apollo.QueryResult<OrdersListQuery, OrdersListQueryVariables>;
export const OrdersCountDocument = gql`
    query ordersCount($filter: OrdersListFilterInput, $organizerId: Int!, $uuidUser: UUID, $uuidProduct: UUID, $status: [OrderStatusEnum!]) {
  ordersList(
    filters: $filter
    args: {organizerId: $organizerId, uuidUser: $uuidUser, uuidProduct: $uuidProduct}
    params: {status: $status}
  ) {
    count
  }
}
    `;

/**
 * __useOrdersCountQuery__
 *
 * To run a query within a React component, call `useOrdersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      organizerId: // value for 'organizerId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOrdersCountQuery(baseOptions: Apollo.QueryHookOptions<OrdersCountQuery, OrdersCountQueryVariables> & ({ variables: OrdersCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersCountQuery, OrdersCountQueryVariables>(OrdersCountDocument, options);
      }
export function useOrdersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersCountQuery, OrdersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersCountQuery, OrdersCountQueryVariables>(OrdersCountDocument, options);
        }
export function useOrdersCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrdersCountQuery, OrdersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrdersCountQuery, OrdersCountQueryVariables>(OrdersCountDocument, options);
        }
export type OrdersCountQueryHookResult = ReturnType<typeof useOrdersCountQuery>;
export type OrdersCountLazyQueryHookResult = ReturnType<typeof useOrdersCountLazyQuery>;
export type OrdersCountSuspenseQueryHookResult = ReturnType<typeof useOrdersCountSuspenseQuery>;
export type OrdersCountQueryResult = Apollo.QueryResult<OrdersCountQuery, OrdersCountQueryVariables>;
export const GiveOrdersDocument = gql`
    mutation giveOrders($usersUuids: [UUID!]!, $tariffUuid: UUID!, $productUuid: UUID!, $params: OrdersActionNotifyParams) {
  giveOrders(
    usersUuids: $usersUuids
    tariffUuid: $tariffUuid
    productUuid: $productUuid
    params: $params
  ) {
    status
    uuidUser
    error
  }
}
    `;
export type GiveOrdersMutationFn = Apollo.MutationFunction<GiveOrdersMutation, GiveOrdersMutationVariables>;

/**
 * __useGiveOrdersMutation__
 *
 * To run a mutation, you first call `useGiveOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveOrdersMutation, { data, loading, error }] = useGiveOrdersMutation({
 *   variables: {
 *      usersUuids: // value for 'usersUuids'
 *      tariffUuid: // value for 'tariffUuid'
 *      productUuid: // value for 'productUuid'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGiveOrdersMutation(baseOptions?: Apollo.MutationHookOptions<GiveOrdersMutation, GiveOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GiveOrdersMutation, GiveOrdersMutationVariables>(GiveOrdersDocument, options);
      }
export type GiveOrdersMutationHookResult = ReturnType<typeof useGiveOrdersMutation>;
export type GiveOrdersMutationResult = Apollo.MutationResult<GiveOrdersMutation>;
export type GiveOrdersMutationOptions = Apollo.BaseMutationOptions<GiveOrdersMutation, GiveOrdersMutationVariables>;
export const EventInviteTextsDocument = gql`
    query eventInviteTexts($uuid: UUID!) {
  product(uuid: $uuid) {
    inviteOrderSuccessText(disableShortcode: true)
    inviteOrderText(disableShortcode: true)
  }
}
    `;

/**
 * __useEventInviteTextsQuery__
 *
 * To run a query within a React component, call `useEventInviteTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventInviteTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventInviteTextsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEventInviteTextsQuery(baseOptions: Apollo.QueryHookOptions<EventInviteTextsQuery, EventInviteTextsQueryVariables> & ({ variables: EventInviteTextsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventInviteTextsQuery, EventInviteTextsQueryVariables>(EventInviteTextsDocument, options);
      }
export function useEventInviteTextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventInviteTextsQuery, EventInviteTextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventInviteTextsQuery, EventInviteTextsQueryVariables>(EventInviteTextsDocument, options);
        }
export function useEventInviteTextsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventInviteTextsQuery, EventInviteTextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventInviteTextsQuery, EventInviteTextsQueryVariables>(EventInviteTextsDocument, options);
        }
export type EventInviteTextsQueryHookResult = ReturnType<typeof useEventInviteTextsQuery>;
export type EventInviteTextsLazyQueryHookResult = ReturnType<typeof useEventInviteTextsLazyQuery>;
export type EventInviteTextsSuspenseQueryHookResult = ReturnType<typeof useEventInviteTextsSuspenseQuery>;
export type EventInviteTextsQueryResult = Apollo.QueryResult<EventInviteTextsQuery, EventInviteTextsQueryVariables>;
export const CreateInvitesOrdersDocument = gql`
    mutation createInvitesOrders($usersUuids: [UUID!]!, $tariffUuid: UUID!, $productUuid: UUID!, $params: OrdersActionNotifyParams) {
  createInviteOrders(
    usersUuids: $usersUuids
    tariffUuid: $tariffUuid
    productUuid: $productUuid
    params: $params
  ) {
    status
    uuidUser
    error
  }
}
    `;
export type CreateInvitesOrdersMutationFn = Apollo.MutationFunction<CreateInvitesOrdersMutation, CreateInvitesOrdersMutationVariables>;

/**
 * __useCreateInvitesOrdersMutation__
 *
 * To run a mutation, you first call `useCreateInvitesOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitesOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitesOrdersMutation, { data, loading, error }] = useCreateInvitesOrdersMutation({
 *   variables: {
 *      usersUuids: // value for 'usersUuids'
 *      tariffUuid: // value for 'tariffUuid'
 *      productUuid: // value for 'productUuid'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateInvitesOrdersMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvitesOrdersMutation, CreateInvitesOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvitesOrdersMutation, CreateInvitesOrdersMutationVariables>(CreateInvitesOrdersDocument, options);
      }
export type CreateInvitesOrdersMutationHookResult = ReturnType<typeof useCreateInvitesOrdersMutation>;
export type CreateInvitesOrdersMutationResult = Apollo.MutationResult<CreateInvitesOrdersMutation>;
export type CreateInvitesOrdersMutationOptions = Apollo.BaseMutationOptions<CreateInvitesOrdersMutation, CreateInvitesOrdersMutationVariables>;
export const ExportOrdersCsvDocument = gql`
    mutation exportOrdersCSV($productUuid: UUID!) {
  exportOrdersCSV(productUuid: $productUuid) {
    url
  }
}
    `;
export type ExportOrdersCsvMutationFn = Apollo.MutationFunction<ExportOrdersCsvMutation, ExportOrdersCsvMutationVariables>;

/**
 * __useExportOrdersCsvMutation__
 *
 * To run a mutation, you first call `useExportOrdersCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportOrdersCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportOrdersCsvMutation, { data, loading, error }] = useExportOrdersCsvMutation({
 *   variables: {
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useExportOrdersCsvMutation(baseOptions?: Apollo.MutationHookOptions<ExportOrdersCsvMutation, ExportOrdersCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportOrdersCsvMutation, ExportOrdersCsvMutationVariables>(ExportOrdersCsvDocument, options);
      }
export type ExportOrdersCsvMutationHookResult = ReturnType<typeof useExportOrdersCsvMutation>;
export type ExportOrdersCsvMutationResult = Apollo.MutationResult<ExportOrdersCsvMutation>;
export type ExportOrdersCsvMutationOptions = Apollo.BaseMutationOptions<ExportOrdersCsvMutation, ExportOrdersCsvMutationVariables>;
export const DisableOrderDocument = gql`
    mutation disableOrder($id: Int!) {
  disableOrder(id: $id) {
    uuid
  }
}
    `;
export type DisableOrderMutationFn = Apollo.MutationFunction<DisableOrderMutation, DisableOrderMutationVariables>;

/**
 * __useDisableOrderMutation__
 *
 * To run a mutation, you first call `useDisableOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableOrderMutation, { data, loading, error }] = useDisableOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableOrderMutation(baseOptions?: Apollo.MutationHookOptions<DisableOrderMutation, DisableOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableOrderMutation, DisableOrderMutationVariables>(DisableOrderDocument, options);
      }
export type DisableOrderMutationHookResult = ReturnType<typeof useDisableOrderMutation>;
export type DisableOrderMutationResult = Apollo.MutationResult<DisableOrderMutation>;
export type DisableOrderMutationOptions = Apollo.BaseMutationOptions<DisableOrderMutation, DisableOrderMutationVariables>;
export const TransferOrderToProductDocument = gql`
    mutation transferOrderToProduct($notify: OrderActionEmailNotifyParam, $productUuid: UUID!, $orderId: Int!) {
  transferUserOrderToProduct(
    notify: $notify
    productUuid: $productUuid
    orderId: $orderId
  ) {
    uuid
  }
}
    `;
export type TransferOrderToProductMutationFn = Apollo.MutationFunction<TransferOrderToProductMutation, TransferOrderToProductMutationVariables>;

/**
 * __useTransferOrderToProductMutation__
 *
 * To run a mutation, you first call `useTransferOrderToProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferOrderToProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferOrderToProductMutation, { data, loading, error }] = useTransferOrderToProductMutation({
 *   variables: {
 *      notify: // value for 'notify'
 *      productUuid: // value for 'productUuid'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useTransferOrderToProductMutation(baseOptions?: Apollo.MutationHookOptions<TransferOrderToProductMutation, TransferOrderToProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferOrderToProductMutation, TransferOrderToProductMutationVariables>(TransferOrderToProductDocument, options);
      }
export type TransferOrderToProductMutationHookResult = ReturnType<typeof useTransferOrderToProductMutation>;
export type TransferOrderToProductMutationResult = Apollo.MutationResult<TransferOrderToProductMutation>;
export type TransferOrderToProductMutationOptions = Apollo.BaseMutationOptions<TransferOrderToProductMutation, TransferOrderToProductMutationVariables>;
export const OrganizersListCountDocument = gql`
    query organizersListCount($verified: Boolean) {
  organizersList(verified: $verified) {
    count
  }
}
    `;

/**
 * __useOrganizersListCountQuery__
 *
 * To run a query within a React component, call `useOrganizersListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizersListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizersListCountQuery({
 *   variables: {
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useOrganizersListCountQuery(baseOptions?: Apollo.QueryHookOptions<OrganizersListCountQuery, OrganizersListCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizersListCountQuery, OrganizersListCountQueryVariables>(OrganizersListCountDocument, options);
      }
export function useOrganizersListCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizersListCountQuery, OrganizersListCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizersListCountQuery, OrganizersListCountQueryVariables>(OrganizersListCountDocument, options);
        }
export function useOrganizersListCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizersListCountQuery, OrganizersListCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizersListCountQuery, OrganizersListCountQueryVariables>(OrganizersListCountDocument, options);
        }
export type OrganizersListCountQueryHookResult = ReturnType<typeof useOrganizersListCountQuery>;
export type OrganizersListCountLazyQueryHookResult = ReturnType<typeof useOrganizersListCountLazyQuery>;
export type OrganizersListCountSuspenseQueryHookResult = ReturnType<typeof useOrganizersListCountSuspenseQuery>;
export type OrganizersListCountQueryResult = Apollo.QueryResult<OrganizersListCountQuery, OrganizersListCountQueryVariables>;
export const OrganizersListDocument = gql`
    query organizersList($verified: Boolean, $status: [StatusEnum!], $filters: OrganizerListFilterInput) {
  organizersList(verified: $verified, status: $status, filters: $filters) {
    rows {
      ...OrganizerFields
      bots {
        username
        id
      }
    }
    count
  }
}
    ${OrganizerFieldsFragmentDoc}`;

/**
 * __useOrganizersListQuery__
 *
 * To run a query within a React component, call `useOrganizersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizersListQuery({
 *   variables: {
 *      verified: // value for 'verified'
 *      status: // value for 'status'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOrganizersListQuery(baseOptions?: Apollo.QueryHookOptions<OrganizersListQuery, OrganizersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizersListQuery, OrganizersListQueryVariables>(OrganizersListDocument, options);
      }
export function useOrganizersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizersListQuery, OrganizersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizersListQuery, OrganizersListQueryVariables>(OrganizersListDocument, options);
        }
export function useOrganizersListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizersListQuery, OrganizersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizersListQuery, OrganizersListQueryVariables>(OrganizersListDocument, options);
        }
export type OrganizersListQueryHookResult = ReturnType<typeof useOrganizersListQuery>;
export type OrganizersListLazyQueryHookResult = ReturnType<typeof useOrganizersListLazyQuery>;
export type OrganizersListSuspenseQueryHookResult = ReturnType<typeof useOrganizersListSuspenseQuery>;
export type OrganizersListQueryResult = Apollo.QueryResult<OrganizersListQuery, OrganizersListQueryVariables>;
export const OrganizerDocument = gql`
    query organizer($id: Int!) {
  organizerAdmin(id: $id) {
    createdAt
    updatedAt
    deletedAt
    id
    image
    name
    query
    description
    telegram
    featureFlags {
      allowedNumberForTransferToProductOrder
    }
    orderInstructions {
      transferToProduct
      transferToUser
      refund
    }
    supplierInfo {
      ...SupplierInfoFields
    }
    synonyms {
      value
    }
  }
}
    ${SupplierInfoFieldsFragmentDoc}`;

/**
 * __useOrganizerQuery__
 *
 * To run a query within a React component, call `useOrganizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizerQuery(baseOptions: Apollo.QueryHookOptions<OrganizerQuery, OrganizerQueryVariables> & ({ variables: OrganizerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizerQuery, OrganizerQueryVariables>(OrganizerDocument, options);
      }
export function useOrganizerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizerQuery, OrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizerQuery, OrganizerQueryVariables>(OrganizerDocument, options);
        }
export function useOrganizerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizerQuery, OrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizerQuery, OrganizerQueryVariables>(OrganizerDocument, options);
        }
export type OrganizerQueryHookResult = ReturnType<typeof useOrganizerQuery>;
export type OrganizerLazyQueryHookResult = ReturnType<typeof useOrganizerLazyQuery>;
export type OrganizerSuspenseQueryHookResult = ReturnType<typeof useOrganizerSuspenseQuery>;
export type OrganizerQueryResult = Apollo.QueryResult<OrganizerQuery, OrganizerQueryVariables>;
export const OrganizerFilesDocument = gql`
    query organizerFiles($id: Int!) {
  organizerAdmin(id: $id) {
    files {
      oferta
      agent
      policy
    }
  }
}
    `;

/**
 * __useOrganizerFilesQuery__
 *
 * To run a query within a React component, call `useOrganizerFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerFilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizerFilesQuery(baseOptions: Apollo.QueryHookOptions<OrganizerFilesQuery, OrganizerFilesQueryVariables> & ({ variables: OrganizerFilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizerFilesQuery, OrganizerFilesQueryVariables>(OrganizerFilesDocument, options);
      }
export function useOrganizerFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizerFilesQuery, OrganizerFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizerFilesQuery, OrganizerFilesQueryVariables>(OrganizerFilesDocument, options);
        }
export function useOrganizerFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizerFilesQuery, OrganizerFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizerFilesQuery, OrganizerFilesQueryVariables>(OrganizerFilesDocument, options);
        }
export type OrganizerFilesQueryHookResult = ReturnType<typeof useOrganizerFilesQuery>;
export type OrganizerFilesLazyQueryHookResult = ReturnType<typeof useOrganizerFilesLazyQuery>;
export type OrganizerFilesSuspenseQueryHookResult = ReturnType<typeof useOrganizerFilesSuspenseQuery>;
export type OrganizerFilesQueryResult = Apollo.QueryResult<OrganizerFilesQuery, OrganizerFilesQueryVariables>;
export const OrganizerFeaturesDocument = gql`
    query organizerFeatures($idOrganizer: Float!) {
  organizerFeatures(idOrganizer: $idOrganizer) {
    ...OrganizerAdminFeatureFields
  }
}
    ${OrganizerAdminFeatureFieldsFragmentDoc}`;

/**
 * __useOrganizerFeaturesQuery__
 *
 * To run a query within a React component, call `useOrganizerFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerFeaturesQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useOrganizerFeaturesQuery(baseOptions: Apollo.QueryHookOptions<OrganizerFeaturesQuery, OrganizerFeaturesQueryVariables> & ({ variables: OrganizerFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizerFeaturesQuery, OrganizerFeaturesQueryVariables>(OrganizerFeaturesDocument, options);
      }
export function useOrganizerFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizerFeaturesQuery, OrganizerFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizerFeaturesQuery, OrganizerFeaturesQueryVariables>(OrganizerFeaturesDocument, options);
        }
export function useOrganizerFeaturesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizerFeaturesQuery, OrganizerFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizerFeaturesQuery, OrganizerFeaturesQueryVariables>(OrganizerFeaturesDocument, options);
        }
export type OrganizerFeaturesQueryHookResult = ReturnType<typeof useOrganizerFeaturesQuery>;
export type OrganizerFeaturesLazyQueryHookResult = ReturnType<typeof useOrganizerFeaturesLazyQuery>;
export type OrganizerFeaturesSuspenseQueryHookResult = ReturnType<typeof useOrganizerFeaturesSuspenseQuery>;
export type OrganizerFeaturesQueryResult = Apollo.QueryResult<OrganizerFeaturesQuery, OrganizerFeaturesQueryVariables>;
export const UpdateAdminOrganizerFeaturesDocument = gql`
    mutation updateAdminOrganizerFeatures($idOrganizer: Float!, $input: OrganizerFeatureInput!) {
  updateOrganizerFeatures(idOrganizer: $idOrganizer, input: $input) {
    ...OrganizerAdminFeatureFields
  }
}
    ${OrganizerAdminFeatureFieldsFragmentDoc}`;
export type UpdateAdminOrganizerFeaturesMutationFn = Apollo.MutationFunction<UpdateAdminOrganizerFeaturesMutation, UpdateAdminOrganizerFeaturesMutationVariables>;

/**
 * __useUpdateAdminOrganizerFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateAdminOrganizerFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminOrganizerFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminOrganizerFeaturesMutation, { data, loading, error }] = useUpdateAdminOrganizerFeaturesMutation({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminOrganizerFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminOrganizerFeaturesMutation, UpdateAdminOrganizerFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminOrganizerFeaturesMutation, UpdateAdminOrganizerFeaturesMutationVariables>(UpdateAdminOrganizerFeaturesDocument, options);
      }
export type UpdateAdminOrganizerFeaturesMutationHookResult = ReturnType<typeof useUpdateAdminOrganizerFeaturesMutation>;
export type UpdateAdminOrganizerFeaturesMutationResult = Apollo.MutationResult<UpdateAdminOrganizerFeaturesMutation>;
export type UpdateAdminOrganizerFeaturesMutationOptions = Apollo.BaseMutationOptions<UpdateAdminOrganizerFeaturesMutation, UpdateAdminOrganizerFeaturesMutationVariables>;
export const UpdateOrganizerDocument = gql`
    mutation updateOrganizer($id: Float!, $input: OrganizerInput!) {
  updateOrganizer(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateOrganizerMutationFn = Apollo.MutationFunction<UpdateOrganizerMutation, UpdateOrganizerMutationVariables>;

/**
 * __useUpdateOrganizerMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizerMutation, { data, loading, error }] = useUpdateOrganizerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizerMutation, UpdateOrganizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizerMutation, UpdateOrganizerMutationVariables>(UpdateOrganizerDocument, options);
      }
export type UpdateOrganizerMutationHookResult = ReturnType<typeof useUpdateOrganizerMutation>;
export type UpdateOrganizerMutationResult = Apollo.MutationResult<UpdateOrganizerMutation>;
export type UpdateOrganizerMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizerMutation, UpdateOrganizerMutationVariables>;
export const CreateOrganizerDocument = gql`
    mutation createOrganizer($input: OrganizerInput!) {
  createOrganizer(input: $input) {
    id
  }
}
    `;
export type CreateOrganizerMutationFn = Apollo.MutationFunction<CreateOrganizerMutation, CreateOrganizerMutationVariables>;

/**
 * __useCreateOrganizerMutation__
 *
 * To run a mutation, you first call `useCreateOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizerMutation, { data, loading, error }] = useCreateOrganizerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizerMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizerMutation, CreateOrganizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizerMutation, CreateOrganizerMutationVariables>(CreateOrganizerDocument, options);
      }
export type CreateOrganizerMutationHookResult = ReturnType<typeof useCreateOrganizerMutation>;
export type CreateOrganizerMutationResult = Apollo.MutationResult<CreateOrganizerMutation>;
export type CreateOrganizerMutationOptions = Apollo.BaseMutationOptions<CreateOrganizerMutation, CreateOrganizerMutationVariables>;
export const OrganizerShortcodesDocument = gql`
    query organizerShortcodes {
  organizerShortcodes
}
    `;

/**
 * __useOrganizerShortcodesQuery__
 *
 * To run a query within a React component, call `useOrganizerShortcodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerShortcodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerShortcodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizerShortcodesQuery(baseOptions?: Apollo.QueryHookOptions<OrganizerShortcodesQuery, OrganizerShortcodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizerShortcodesQuery, OrganizerShortcodesQueryVariables>(OrganizerShortcodesDocument, options);
      }
export function useOrganizerShortcodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizerShortcodesQuery, OrganizerShortcodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizerShortcodesQuery, OrganizerShortcodesQueryVariables>(OrganizerShortcodesDocument, options);
        }
export function useOrganizerShortcodesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizerShortcodesQuery, OrganizerShortcodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizerShortcodesQuery, OrganizerShortcodesQueryVariables>(OrganizerShortcodesDocument, options);
        }
export type OrganizerShortcodesQueryHookResult = ReturnType<typeof useOrganizerShortcodesQuery>;
export type OrganizerShortcodesLazyQueryHookResult = ReturnType<typeof useOrganizerShortcodesLazyQuery>;
export type OrganizerShortcodesSuspenseQueryHookResult = ReturnType<typeof useOrganizerShortcodesSuspenseQuery>;
export type OrganizerShortcodesQueryResult = Apollo.QueryResult<OrganizerShortcodesQuery, OrganizerShortcodesQueryVariables>;
export const UpdateOrganizerSynonymsDocument = gql`
    mutation updateOrganizerSynonyms($idOrganizer: Float!, $values: [String!]!) {
  updateOrganizerSynonyms(idOrganizer: $idOrganizer, values: $values)
}
    `;
export type UpdateOrganizerSynonymsMutationFn = Apollo.MutationFunction<UpdateOrganizerSynonymsMutation, UpdateOrganizerSynonymsMutationVariables>;

/**
 * __useUpdateOrganizerSynonymsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizerSynonymsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizerSynonymsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizerSynonymsMutation, { data, loading, error }] = useUpdateOrganizerSynonymsMutation({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateOrganizerSynonymsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizerSynonymsMutation, UpdateOrganizerSynonymsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizerSynonymsMutation, UpdateOrganizerSynonymsMutationVariables>(UpdateOrganizerSynonymsDocument, options);
      }
export type UpdateOrganizerSynonymsMutationHookResult = ReturnType<typeof useUpdateOrganizerSynonymsMutation>;
export type UpdateOrganizerSynonymsMutationResult = Apollo.MutationResult<UpdateOrganizerSynonymsMutation>;
export type UpdateOrganizerSynonymsMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizerSynonymsMutation, UpdateOrganizerSynonymsMutationVariables>;
export const PaymentAccountsListDocument = gql`
    query paymentAccountsList($idOrganizer: Int!) {
  paymentsByOrganizer(idOrganizer: $idOrganizer) {
    ...PaymentAccountFields
  }
}
    ${PaymentAccountFieldsFragmentDoc}`;

/**
 * __usePaymentAccountsListQuery__
 *
 * To run a query within a React component, call `usePaymentAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentAccountsListQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function usePaymentAccountsListQuery(baseOptions: Apollo.QueryHookOptions<PaymentAccountsListQuery, PaymentAccountsListQueryVariables> & ({ variables: PaymentAccountsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentAccountsListQuery, PaymentAccountsListQueryVariables>(PaymentAccountsListDocument, options);
      }
export function usePaymentAccountsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentAccountsListQuery, PaymentAccountsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentAccountsListQuery, PaymentAccountsListQueryVariables>(PaymentAccountsListDocument, options);
        }
export function usePaymentAccountsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PaymentAccountsListQuery, PaymentAccountsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentAccountsListQuery, PaymentAccountsListQueryVariables>(PaymentAccountsListDocument, options);
        }
export type PaymentAccountsListQueryHookResult = ReturnType<typeof usePaymentAccountsListQuery>;
export type PaymentAccountsListLazyQueryHookResult = ReturnType<typeof usePaymentAccountsListLazyQuery>;
export type PaymentAccountsListSuspenseQueryHookResult = ReturnType<typeof usePaymentAccountsListSuspenseQuery>;
export type PaymentAccountsListQueryResult = Apollo.QueryResult<PaymentAccountsListQuery, PaymentAccountsListQueryVariables>;
export const EventsCountDocument = gql`
    query eventsCount($idOrganizer: Float) {
  productsCount(idOrganizer: $idOrganizer) {
    count
    activeCount
    archiveCount
    draftCount
  }
}
    `;

/**
 * __useEventsCountQuery__
 *
 * To run a query within a React component, call `useEventsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsCountQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useEventsCountQuery(baseOptions?: Apollo.QueryHookOptions<EventsCountQuery, EventsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsCountQuery, EventsCountQueryVariables>(EventsCountDocument, options);
      }
export function useEventsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsCountQuery, EventsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsCountQuery, EventsCountQueryVariables>(EventsCountDocument, options);
        }
export function useEventsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventsCountQuery, EventsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventsCountQuery, EventsCountQueryVariables>(EventsCountDocument, options);
        }
export type EventsCountQueryHookResult = ReturnType<typeof useEventsCountQuery>;
export type EventsCountLazyQueryHookResult = ReturnType<typeof useEventsCountLazyQuery>;
export type EventsCountSuspenseQueryHookResult = ReturnType<typeof useEventsCountSuspenseQuery>;
export type EventsCountQueryResult = Apollo.QueryResult<EventsCountQuery, EventsCountQueryVariables>;
export const EventShortcodeDocument = gql`
    query eventShortcode {
  productShortcodes
}
    `;

/**
 * __useEventShortcodeQuery__
 *
 * To run a query within a React component, call `useEventShortcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventShortcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventShortcodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventShortcodeQuery(baseOptions?: Apollo.QueryHookOptions<EventShortcodeQuery, EventShortcodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventShortcodeQuery, EventShortcodeQueryVariables>(EventShortcodeDocument, options);
      }
export function useEventShortcodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventShortcodeQuery, EventShortcodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventShortcodeQuery, EventShortcodeQueryVariables>(EventShortcodeDocument, options);
        }
export function useEventShortcodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventShortcodeQuery, EventShortcodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventShortcodeQuery, EventShortcodeQueryVariables>(EventShortcodeDocument, options);
        }
export type EventShortcodeQueryHookResult = ReturnType<typeof useEventShortcodeQuery>;
export type EventShortcodeLazyQueryHookResult = ReturnType<typeof useEventShortcodeLazyQuery>;
export type EventShortcodeSuspenseQueryHookResult = ReturnType<typeof useEventShortcodeSuspenseQuery>;
export type EventShortcodeQueryResult = Apollo.QueryResult<EventShortcodeQuery, EventShortcodeQueryVariables>;
export const EventsListDocument = gql`
    query eventsList($filters: ProductListFilterInput, $params: ProductFilter, $status: [StatusEnum!]) {
  productsList(filters: $filters, params: $params, status: $status) {
    rows {
      createdAt
      updatedAt
      uuid
      status
      purchasedCount
      visitedCount
      statsOrders {
        turnover
      }
      name
      query
      startedDate
      endDate
      canBeRemoved
    }
    count
  }
}
    `;

/**
 * __useEventsListQuery__
 *
 * To run a query within a React component, call `useEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      params: // value for 'params'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEventsListQuery(baseOptions?: Apollo.QueryHookOptions<EventsListQuery, EventsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsListQuery, EventsListQueryVariables>(EventsListDocument, options);
      }
export function useEventsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsListQuery, EventsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsListQuery, EventsListQueryVariables>(EventsListDocument, options);
        }
export function useEventsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventsListQuery, EventsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventsListQuery, EventsListQueryVariables>(EventsListDocument, options);
        }
export type EventsListQueryHookResult = ReturnType<typeof useEventsListQuery>;
export type EventsListLazyQueryHookResult = ReturnType<typeof useEventsListLazyQuery>;
export type EventsListSuspenseQueryHookResult = ReturnType<typeof useEventsListSuspenseQuery>;
export type EventsListQueryResult = Apollo.QueryResult<EventsListQuery, EventsListQueryVariables>;
export const EventDocument = gql`
    query event($uuid: UUID!) {
  product(uuid: $uuid) {
    createdAt
    updatedAt
    uuid
    img
    name
    description
    query
    startedDate
    salesStartedText(disableShortcode: true)
    endDate
    status
    featureFlags {
      refundOrder
      bonusPay
      transferToUserOrder
      transferToProductOrder
      repeatBuyOrder
      durationBeforeStartForRefundOrder
      durationBeforeStartForTransferToUserOrder
      durationBeforeStartForTransferToProductOrder
      transferFromProductOrder
    }
    address {
      uuid
    }
    successBuyText(disableShortcode: true)
    successPairBuyText(disableShortcode: true)
    tariffsText(disableShortcode: true)
    tariffsEmptyText(disableShortcode: true)
    tariffsUnavailableText(disableShortcode: true)
    salesStartedDate
    transferOrderToUserSuccessText(disableShortcode: true)
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables> & ({ variables: EventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export function useEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventSuspenseQueryHookResult = ReturnType<typeof useEventSuspenseQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const BaseEventTextsDocument = gql`
    query baseEventTexts {
  baseTexts(
    keys: [ProductSalesStartedText, ProductSuccessBuyText, ProductTariffsText, ProductTariffsEmptyText, ProductTariffsUnavailableText, ProductTransferOrderToUserSuccessText]
  ) {
    key
    text
  }
}
    `;

/**
 * __useBaseEventTextsQuery__
 *
 * To run a query within a React component, call `useBaseEventTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseEventTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseEventTextsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBaseEventTextsQuery(baseOptions?: Apollo.QueryHookOptions<BaseEventTextsQuery, BaseEventTextsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaseEventTextsQuery, BaseEventTextsQueryVariables>(BaseEventTextsDocument, options);
      }
export function useBaseEventTextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaseEventTextsQuery, BaseEventTextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaseEventTextsQuery, BaseEventTextsQueryVariables>(BaseEventTextsDocument, options);
        }
export function useBaseEventTextsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BaseEventTextsQuery, BaseEventTextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BaseEventTextsQuery, BaseEventTextsQueryVariables>(BaseEventTextsDocument, options);
        }
export type BaseEventTextsQueryHookResult = ReturnType<typeof useBaseEventTextsQuery>;
export type BaseEventTextsLazyQueryHookResult = ReturnType<typeof useBaseEventTextsLazyQuery>;
export type BaseEventTextsSuspenseQueryHookResult = ReturnType<typeof useBaseEventTextsSuspenseQuery>;
export type BaseEventTextsQueryResult = Apollo.QueryResult<BaseEventTextsQuery, BaseEventTextsQueryVariables>;
export const ArchiveEventDocument = gql`
    mutation archiveEvent($uuid: UUID!) {
  archiveProduct(uuid: $uuid) {
    uuid
  }
}
    `;
export type ArchiveEventMutationFn = Apollo.MutationFunction<ArchiveEventMutation, ArchiveEventMutationVariables>;

/**
 * __useArchiveEventMutation__
 *
 * To run a mutation, you first call `useArchiveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveEventMutation, { data, loading, error }] = useArchiveEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useArchiveEventMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveEventMutation, ArchiveEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveEventMutation, ArchiveEventMutationVariables>(ArchiveEventDocument, options);
      }
export type ArchiveEventMutationHookResult = ReturnType<typeof useArchiveEventMutation>;
export type ArchiveEventMutationResult = Apollo.MutationResult<ArchiveEventMutation>;
export type ArchiveEventMutationOptions = Apollo.BaseMutationOptions<ArchiveEventMutation, ArchiveEventMutationVariables>;
export const PublishEventDocument = gql`
    mutation publishEvent($uuid: UUID!) {
  activateProduct(uuid: $uuid) {
    uuid
  }
}
    `;
export type PublishEventMutationFn = Apollo.MutationFunction<PublishEventMutation, PublishEventMutationVariables>;

/**
 * __usePublishEventMutation__
 *
 * To run a mutation, you first call `usePublishEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEventMutation, { data, loading, error }] = usePublishEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishEventMutation(baseOptions?: Apollo.MutationHookOptions<PublishEventMutation, PublishEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishEventMutation, PublishEventMutationVariables>(PublishEventDocument, options);
      }
export type PublishEventMutationHookResult = ReturnType<typeof usePublishEventMutation>;
export type PublishEventMutationResult = Apollo.MutationResult<PublishEventMutation>;
export type PublishEventMutationOptions = Apollo.BaseMutationOptions<PublishEventMutation, PublishEventMutationVariables>;
export const RemoveEventDocument = gql`
    mutation removeEvent($uuid: UUID!) {
  removeProduct(uuid: $uuid)
}
    `;
export type RemoveEventMutationFn = Apollo.MutationFunction<RemoveEventMutation, RemoveEventMutationVariables>;

/**
 * __useRemoveEventMutation__
 *
 * To run a mutation, you first call `useRemoveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventMutation, { data, loading, error }] = useRemoveEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveEventMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEventMutation, RemoveEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEventMutation, RemoveEventMutationVariables>(RemoveEventDocument, options);
      }
export type RemoveEventMutationHookResult = ReturnType<typeof useRemoveEventMutation>;
export type RemoveEventMutationResult = Apollo.MutationResult<RemoveEventMutation>;
export type RemoveEventMutationOptions = Apollo.BaseMutationOptions<RemoveEventMutation, RemoveEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($uuid: UUID!, $input: ProductInput!) {
  updateProduct(input: $input, uuid: $uuid) {
    uuid
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const UpdateEventInviteTextsDocument = gql`
    mutation updateEventInviteTexts($uuid: UUID!, $input: ProductInviteTextsInput!) {
  updateProductInviteTexts(input: $input, uuid: $uuid) {
    uuid
  }
}
    `;
export type UpdateEventInviteTextsMutationFn = Apollo.MutationFunction<UpdateEventInviteTextsMutation, UpdateEventInviteTextsMutationVariables>;

/**
 * __useUpdateEventInviteTextsMutation__
 *
 * To run a mutation, you first call `useUpdateEventInviteTextsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventInviteTextsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventInviteTextsMutation, { data, loading, error }] = useUpdateEventInviteTextsMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventInviteTextsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventInviteTextsMutation, UpdateEventInviteTextsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventInviteTextsMutation, UpdateEventInviteTextsMutationVariables>(UpdateEventInviteTextsDocument, options);
      }
export type UpdateEventInviteTextsMutationHookResult = ReturnType<typeof useUpdateEventInviteTextsMutation>;
export type UpdateEventInviteTextsMutationResult = Apollo.MutationResult<UpdateEventInviteTextsMutation>;
export type UpdateEventInviteTextsMutationOptions = Apollo.BaseMutationOptions<UpdateEventInviteTextsMutation, UpdateEventInviteTextsMutationVariables>;
export const DuplicateProductDocument = gql`
    mutation duplicateProduct($uuid: UUID!) {
  duplicateProduct(uuid: $uuid) {
    uuid
  }
}
    `;
export type DuplicateProductMutationFn = Apollo.MutationFunction<DuplicateProductMutation, DuplicateProductMutationVariables>;

/**
 * __useDuplicateProductMutation__
 *
 * To run a mutation, you first call `useDuplicateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProductMutation, { data, loading, error }] = useDuplicateProductMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDuplicateProductMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateProductMutation, DuplicateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateProductMutation, DuplicateProductMutationVariables>(DuplicateProductDocument, options);
      }
export type DuplicateProductMutationHookResult = ReturnType<typeof useDuplicateProductMutation>;
export type DuplicateProductMutationResult = Apollo.MutationResult<DuplicateProductMutation>;
export type DuplicateProductMutationOptions = Apollo.BaseMutationOptions<DuplicateProductMutation, DuplicateProductMutationVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($publish: Boolean, $input: ProductMainInfoInput!, $organizerId: Float!) {
  createProduct(publish: $publish, input: $input, organizerId: $organizerId) {
    uuid
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      publish: // value for 'publish'
 *      input: // value for 'input'
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventMainInfoDocument = gql`
    mutation updateEventMainInfo($uuid: UUID!, $input: ProductMainInfoInput!) {
  updateMainInfoProduct(input: $input, uuid: $uuid) {
    uuid
  }
}
    `;
export type UpdateEventMainInfoMutationFn = Apollo.MutationFunction<UpdateEventMainInfoMutation, UpdateEventMainInfoMutationVariables>;

/**
 * __useUpdateEventMainInfoMutation__
 *
 * To run a mutation, you first call `useUpdateEventMainInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMainInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMainInfoMutation, { data, loading, error }] = useUpdateEventMainInfoMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMainInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMainInfoMutation, UpdateEventMainInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMainInfoMutation, UpdateEventMainInfoMutationVariables>(UpdateEventMainInfoDocument, options);
      }
export type UpdateEventMainInfoMutationHookResult = ReturnType<typeof useUpdateEventMainInfoMutation>;
export type UpdateEventMainInfoMutationResult = Apollo.MutationResult<UpdateEventMainInfoMutation>;
export type UpdateEventMainInfoMutationOptions = Apollo.BaseMutationOptions<UpdateEventMainInfoMutation, UpdateEventMainInfoMutationVariables>;
export const AddressesListDocument = gql`
    query addressesList($filters: ProductAddressListFilterInput, $params: ProductAddressFilter!) {
  addressesList(filters: $filters, params: $params) {
    rows {
      createdAt
      updatedAt
      uuid
      address
      img
      description
    }
    count
  }
}
    `;

/**
 * __useAddressesListQuery__
 *
 * To run a query within a React component, call `useAddressesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressesListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAddressesListQuery(baseOptions: Apollo.QueryHookOptions<AddressesListQuery, AddressesListQueryVariables> & ({ variables: AddressesListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressesListQuery, AddressesListQueryVariables>(AddressesListDocument, options);
      }
export function useAddressesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressesListQuery, AddressesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressesListQuery, AddressesListQueryVariables>(AddressesListDocument, options);
        }
export function useAddressesListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AddressesListQuery, AddressesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddressesListQuery, AddressesListQueryVariables>(AddressesListDocument, options);
        }
export type AddressesListQueryHookResult = ReturnType<typeof useAddressesListQuery>;
export type AddressesListLazyQueryHookResult = ReturnType<typeof useAddressesListLazyQuery>;
export type AddressesListSuspenseQueryHookResult = ReturnType<typeof useAddressesListSuspenseQuery>;
export type AddressesListQueryResult = Apollo.QueryResult<AddressesListQuery, AddressesListQueryVariables>;
export const AddressDocument = gql`
    query address($uuid: UUID!) {
  address(uuid: $uuid) {
    createdAt
    updatedAt
    uuid
    address
    img
    description
  }
}
    `;

/**
 * __useAddressQuery__
 *
 * To run a query within a React component, call `useAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useAddressQuery(baseOptions: Apollo.QueryHookOptions<AddressQuery, AddressQueryVariables> & ({ variables: AddressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressQuery, AddressQueryVariables>(AddressDocument, options);
      }
export function useAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressQuery, AddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressQuery, AddressQueryVariables>(AddressDocument, options);
        }
export function useAddressSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AddressQuery, AddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddressQuery, AddressQueryVariables>(AddressDocument, options);
        }
export type AddressQueryHookResult = ReturnType<typeof useAddressQuery>;
export type AddressLazyQueryHookResult = ReturnType<typeof useAddressLazyQuery>;
export type AddressSuspenseQueryHookResult = ReturnType<typeof useAddressSuspenseQuery>;
export type AddressQueryResult = Apollo.QueryResult<AddressQuery, AddressQueryVariables>;
export const CreateAddressDocument = gql`
    mutation createAddress($input: ProductAddressInput!, $organizerId: Int!) {
  createAddressProduct(idOrganizer: $organizerId, input: $input) {
    uuid
  }
}
    `;
export type CreateAddressMutationFn = Apollo.MutationFunction<CreateAddressMutation, CreateAddressMutationVariables>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useCreateAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateAddressMutation, CreateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAddressMutation, CreateAddressMutationVariables>(CreateAddressDocument, options);
      }
export type CreateAddressMutationHookResult = ReturnType<typeof useCreateAddressMutation>;
export type CreateAddressMutationResult = Apollo.MutationResult<CreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<CreateAddressMutation, CreateAddressMutationVariables>;
export const UpdateAddressDocument = gql`
    mutation updateAddress($uuid: UUID!, $input: ProductAddressInput!) {
  updateAddressProduct(input: $input, uuid: $uuid) {
    uuid
  }
}
    `;
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const RemoveAddressDocument = gql`
    mutation removeAddress($uuid: UUID!) {
  removeAddressProduct(uuid: $uuid)
}
    `;
export type RemoveAddressMutationFn = Apollo.MutationFunction<RemoveAddressMutation, RemoveAddressMutationVariables>;

/**
 * __useRemoveAddressMutation__
 *
 * To run a mutation, you first call `useRemoveAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAddressMutation, { data, loading, error }] = useRemoveAddressMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveAddressMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAddressMutation, RemoveAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAddressMutation, RemoveAddressMutationVariables>(RemoveAddressDocument, options);
      }
export type RemoveAddressMutationHookResult = ReturnType<typeof useRemoveAddressMutation>;
export type RemoveAddressMutationResult = Apollo.MutationResult<RemoveAddressMutation>;
export type RemoveAddressMutationOptions = Apollo.BaseMutationOptions<RemoveAddressMutation, RemoveAddressMutationVariables>;
export const ReferralOrganizerDocument = gql`
    query referralOrganizer($key: String!) {
  referralOrganizer(key: $key) {
    ...ReferralOrganizerFields
  }
}
    ${ReferralOrganizerFieldsFragmentDoc}`;

/**
 * __useReferralOrganizerQuery__
 *
 * To run a query within a React component, call `useReferralOrganizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralOrganizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralOrganizerQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useReferralOrganizerQuery(baseOptions: Apollo.QueryHookOptions<ReferralOrganizerQuery, ReferralOrganizerQueryVariables> & ({ variables: ReferralOrganizerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralOrganizerQuery, ReferralOrganizerQueryVariables>(ReferralOrganizerDocument, options);
      }
export function useReferralOrganizerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralOrganizerQuery, ReferralOrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralOrganizerQuery, ReferralOrganizerQueryVariables>(ReferralOrganizerDocument, options);
        }
export function useReferralOrganizerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReferralOrganizerQuery, ReferralOrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReferralOrganizerQuery, ReferralOrganizerQueryVariables>(ReferralOrganizerDocument, options);
        }
export type ReferralOrganizerQueryHookResult = ReturnType<typeof useReferralOrganizerQuery>;
export type ReferralOrganizerLazyQueryHookResult = ReturnType<typeof useReferralOrganizerLazyQuery>;
export type ReferralOrganizerSuspenseQueryHookResult = ReturnType<typeof useReferralOrganizerSuspenseQuery>;
export type ReferralOrganizerQueryResult = Apollo.QueryResult<ReferralOrganizerQuery, ReferralOrganizerQueryVariables>;
export const OrganizersReferralListDocument = gql`
    query organizersReferralList {
  organizersList(status: [Active], verified: true) {
    rows {
      name
      id
    }
  }
}
    `;

/**
 * __useOrganizersReferralListQuery__
 *
 * To run a query within a React component, call `useOrganizersReferralListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizersReferralListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizersReferralListQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizersReferralListQuery(baseOptions?: Apollo.QueryHookOptions<OrganizersReferralListQuery, OrganizersReferralListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizersReferralListQuery, OrganizersReferralListQueryVariables>(OrganizersReferralListDocument, options);
      }
export function useOrganizersReferralListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizersReferralListQuery, OrganizersReferralListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizersReferralListQuery, OrganizersReferralListQueryVariables>(OrganizersReferralListDocument, options);
        }
export function useOrganizersReferralListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizersReferralListQuery, OrganizersReferralListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizersReferralListQuery, OrganizersReferralListQueryVariables>(OrganizersReferralListDocument, options);
        }
export type OrganizersReferralListQueryHookResult = ReturnType<typeof useOrganizersReferralListQuery>;
export type OrganizersReferralListLazyQueryHookResult = ReturnType<typeof useOrganizersReferralListLazyQuery>;
export type OrganizersReferralListSuspenseQueryHookResult = ReturnType<typeof useOrganizersReferralListSuspenseQuery>;
export type OrganizersReferralListQueryResult = Apollo.QueryResult<OrganizersReferralListQuery, OrganizersReferralListQueryVariables>;
export const ReferralsOrganizerListDocument = gql`
    query referralsOrganizerList($filters: ReferralOrganizerListFilterInput, $params: ReferralOrganizerParams, $status: [StatusEnum!]) {
  referralsOrganizerList(filters: $filters, params: $params, status: $status) {
    rows {
      ...ReferralOrganizerFields
      product {
        name
        startedDate
        query
        organizer {
          name
        }
      }
      usedOrganizer {
        name
      }
    }
    count
  }
}
    ${ReferralOrganizerFieldsFragmentDoc}`;

/**
 * __useReferralsOrganizerListQuery__
 *
 * To run a query within a React component, call `useReferralsOrganizerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsOrganizerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsOrganizerListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      params: // value for 'params'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useReferralsOrganizerListQuery(baseOptions?: Apollo.QueryHookOptions<ReferralsOrganizerListQuery, ReferralsOrganizerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralsOrganizerListQuery, ReferralsOrganizerListQueryVariables>(ReferralsOrganizerListDocument, options);
      }
export function useReferralsOrganizerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralsOrganizerListQuery, ReferralsOrganizerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralsOrganizerListQuery, ReferralsOrganizerListQueryVariables>(ReferralsOrganizerListDocument, options);
        }
export function useReferralsOrganizerListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReferralsOrganizerListQuery, ReferralsOrganizerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReferralsOrganizerListQuery, ReferralsOrganizerListQueryVariables>(ReferralsOrganizerListDocument, options);
        }
export type ReferralsOrganizerListQueryHookResult = ReturnType<typeof useReferralsOrganizerListQuery>;
export type ReferralsOrganizerListLazyQueryHookResult = ReturnType<typeof useReferralsOrganizerListLazyQuery>;
export type ReferralsOrganizerListSuspenseQueryHookResult = ReturnType<typeof useReferralsOrganizerListSuspenseQuery>;
export type ReferralsOrganizerListQueryResult = Apollo.QueryResult<ReferralsOrganizerListQuery, ReferralsOrganizerListQueryVariables>;
export const ReferralFinanceStatsDocument = gql`
    query referralFinanceStats($organizerId: Int!) {
  ordersReferralOrganizerList(
    filters: {pagination: {page: 1, pageSize: 1}}
    args: {organizerId: $organizerId}
  ) {
    count
  }
  statsReferralWithdrawal(idOrganizer: $organizerId) {
    total
  }
  referralProfit(idOrganizer: $organizerId)
  organizer(id: $organizerId) {
    referralBalance
  }
}
    `;

/**
 * __useReferralFinanceStatsQuery__
 *
 * To run a query within a React component, call `useReferralFinanceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralFinanceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralFinanceStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useReferralFinanceStatsQuery(baseOptions: Apollo.QueryHookOptions<ReferralFinanceStatsQuery, ReferralFinanceStatsQueryVariables> & ({ variables: ReferralFinanceStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralFinanceStatsQuery, ReferralFinanceStatsQueryVariables>(ReferralFinanceStatsDocument, options);
      }
export function useReferralFinanceStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralFinanceStatsQuery, ReferralFinanceStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralFinanceStatsQuery, ReferralFinanceStatsQueryVariables>(ReferralFinanceStatsDocument, options);
        }
export function useReferralFinanceStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReferralFinanceStatsQuery, ReferralFinanceStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReferralFinanceStatsQuery, ReferralFinanceStatsQueryVariables>(ReferralFinanceStatsDocument, options);
        }
export type ReferralFinanceStatsQueryHookResult = ReturnType<typeof useReferralFinanceStatsQuery>;
export type ReferralFinanceStatsLazyQueryHookResult = ReturnType<typeof useReferralFinanceStatsLazyQuery>;
export type ReferralFinanceStatsSuspenseQueryHookResult = ReturnType<typeof useReferralFinanceStatsSuspenseQuery>;
export type ReferralFinanceStatsQueryResult = Apollo.QueryResult<ReferralFinanceStatsQuery, ReferralFinanceStatsQueryVariables>;
export const ReferralFinanceListsCountDocument = gql`
    query referralFinanceListsCount($idOrganizer: Int!) {
  ordersReferralOrganizerList(
    args: {organizerId: $idOrganizer}
    filters: {pagination: {pageSize: 1, page: 1}}
  ) {
    count
  }
  withdrawalsByOrganizer(
    idOrganizer: $idOrganizer
    filters: {pagination: {pageSize: 1, page: 1}}
    from: ReferralBalance
  ) {
    count
  }
  referralsOrganizerList(
    filters: {pagination: {pageSize: 1, page: 1}}
    params: {idUsedOrganizer: $idOrganizer}
  ) {
    count
  }
}
    `;

/**
 * __useReferralFinanceListsCountQuery__
 *
 * To run a query within a React component, call `useReferralFinanceListsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralFinanceListsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralFinanceListsCountQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useReferralFinanceListsCountQuery(baseOptions: Apollo.QueryHookOptions<ReferralFinanceListsCountQuery, ReferralFinanceListsCountQueryVariables> & ({ variables: ReferralFinanceListsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralFinanceListsCountQuery, ReferralFinanceListsCountQueryVariables>(ReferralFinanceListsCountDocument, options);
      }
export function useReferralFinanceListsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralFinanceListsCountQuery, ReferralFinanceListsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralFinanceListsCountQuery, ReferralFinanceListsCountQueryVariables>(ReferralFinanceListsCountDocument, options);
        }
export function useReferralFinanceListsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReferralFinanceListsCountQuery, ReferralFinanceListsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReferralFinanceListsCountQuery, ReferralFinanceListsCountQueryVariables>(ReferralFinanceListsCountDocument, options);
        }
export type ReferralFinanceListsCountQueryHookResult = ReturnType<typeof useReferralFinanceListsCountQuery>;
export type ReferralFinanceListsCountLazyQueryHookResult = ReturnType<typeof useReferralFinanceListsCountLazyQuery>;
export type ReferralFinanceListsCountSuspenseQueryHookResult = ReturnType<typeof useReferralFinanceListsCountSuspenseQuery>;
export type ReferralFinanceListsCountQueryResult = Apollo.QueryResult<ReferralFinanceListsCountQuery, ReferralFinanceListsCountQueryVariables>;
export const OrdersReferralOrganizerListDocument = gql`
    query ordersReferralOrganizerList($filter: OrdersListFilterInput, $organizerId: Int!, $uuidUser: UUID, $uuidProduct: UUID) {
  ordersReferralOrganizerList(
    filters: $filter
    args: {organizerId: $organizerId, uuidUser: $uuidUser, uuidProduct: $uuidProduct}
  ) {
    count
    rows {
      createdAt
      id
      status
      fixedHoldPercentage
      payment {
        price
        status
      }
      referralOrganizer {
        key
        percentage
      }
      user {
        first_name
        last_name
        email
        username
        uuid
        phone
      }
      organizer {
        name
      }
      product {
        name
        startedDate
        endDate
        query
      }
      tariff {
        name
      }
    }
  }
}
    `;

/**
 * __useOrdersReferralOrganizerListQuery__
 *
 * To run a query within a React component, call `useOrdersReferralOrganizerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersReferralOrganizerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersReferralOrganizerListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      organizerId: // value for 'organizerId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *   },
 * });
 */
export function useOrdersReferralOrganizerListQuery(baseOptions: Apollo.QueryHookOptions<OrdersReferralOrganizerListQuery, OrdersReferralOrganizerListQueryVariables> & ({ variables: OrdersReferralOrganizerListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersReferralOrganizerListQuery, OrdersReferralOrganizerListQueryVariables>(OrdersReferralOrganizerListDocument, options);
      }
export function useOrdersReferralOrganizerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersReferralOrganizerListQuery, OrdersReferralOrganizerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersReferralOrganizerListQuery, OrdersReferralOrganizerListQueryVariables>(OrdersReferralOrganizerListDocument, options);
        }
export function useOrdersReferralOrganizerListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrdersReferralOrganizerListQuery, OrdersReferralOrganizerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrdersReferralOrganizerListQuery, OrdersReferralOrganizerListQueryVariables>(OrdersReferralOrganizerListDocument, options);
        }
export type OrdersReferralOrganizerListQueryHookResult = ReturnType<typeof useOrdersReferralOrganizerListQuery>;
export type OrdersReferralOrganizerListLazyQueryHookResult = ReturnType<typeof useOrdersReferralOrganizerListLazyQuery>;
export type OrdersReferralOrganizerListSuspenseQueryHookResult = ReturnType<typeof useOrdersReferralOrganizerListSuspenseQuery>;
export type OrdersReferralOrganizerListQueryResult = Apollo.QueryResult<OrdersReferralOrganizerListQuery, OrdersReferralOrganizerListQueryVariables>;
export const CreateReferralOrganizerDocument = gql`
    mutation createReferralOrganizer($input: ReferralOrganizerInput!) {
  createReferralOrganizer(input: $input) {
    ...ReferralOrganizerFields
  }
}
    ${ReferralOrganizerFieldsFragmentDoc}`;
export type CreateReferralOrganizerMutationFn = Apollo.MutationFunction<CreateReferralOrganizerMutation, CreateReferralOrganizerMutationVariables>;

/**
 * __useCreateReferralOrganizerMutation__
 *
 * To run a mutation, you first call `useCreateReferralOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferralOrganizerMutation, { data, loading, error }] = useCreateReferralOrganizerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReferralOrganizerMutation(baseOptions?: Apollo.MutationHookOptions<CreateReferralOrganizerMutation, CreateReferralOrganizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReferralOrganizerMutation, CreateReferralOrganizerMutationVariables>(CreateReferralOrganizerDocument, options);
      }
export type CreateReferralOrganizerMutationHookResult = ReturnType<typeof useCreateReferralOrganizerMutation>;
export type CreateReferralOrganizerMutationResult = Apollo.MutationResult<CreateReferralOrganizerMutation>;
export type CreateReferralOrganizerMutationOptions = Apollo.BaseMutationOptions<CreateReferralOrganizerMutation, CreateReferralOrganizerMutationVariables>;
export const RenameReferralOrganizerDocument = gql`
    mutation renameReferralOrganizer($key: String!, $name: String!) {
  renameReferralOrganizer(key: $key, name: $name) {
    ...ReferralOrganizerFields
  }
}
    ${ReferralOrganizerFieldsFragmentDoc}`;
export type RenameReferralOrganizerMutationFn = Apollo.MutationFunction<RenameReferralOrganizerMutation, RenameReferralOrganizerMutationVariables>;

/**
 * __useRenameReferralOrganizerMutation__
 *
 * To run a mutation, you first call `useRenameReferralOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameReferralOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameReferralOrganizerMutation, { data, loading, error }] = useRenameReferralOrganizerMutation({
 *   variables: {
 *      key: // value for 'key'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameReferralOrganizerMutation(baseOptions?: Apollo.MutationHookOptions<RenameReferralOrganizerMutation, RenameReferralOrganizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameReferralOrganizerMutation, RenameReferralOrganizerMutationVariables>(RenameReferralOrganizerDocument, options);
      }
export type RenameReferralOrganizerMutationHookResult = ReturnType<typeof useRenameReferralOrganizerMutation>;
export type RenameReferralOrganizerMutationResult = Apollo.MutationResult<RenameReferralOrganizerMutation>;
export type RenameReferralOrganizerMutationOptions = Apollo.BaseMutationOptions<RenameReferralOrganizerMutation, RenameReferralOrganizerMutationVariables>;
export const RemoveReferralOrganizerDocument = gql`
    mutation removeReferralOrganizer($key: String!) {
  removeReferralOrganizer(key: $key)
}
    `;
export type RemoveReferralOrganizerMutationFn = Apollo.MutationFunction<RemoveReferralOrganizerMutation, RemoveReferralOrganizerMutationVariables>;

/**
 * __useRemoveReferralOrganizerMutation__
 *
 * To run a mutation, you first call `useRemoveReferralOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReferralOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReferralOrganizerMutation, { data, loading, error }] = useRemoveReferralOrganizerMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useRemoveReferralOrganizerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReferralOrganizerMutation, RemoveReferralOrganizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveReferralOrganizerMutation, RemoveReferralOrganizerMutationVariables>(RemoveReferralOrganizerDocument, options);
      }
export type RemoveReferralOrganizerMutationHookResult = ReturnType<typeof useRemoveReferralOrganizerMutation>;
export type RemoveReferralOrganizerMutationResult = Apollo.MutationResult<RemoveReferralOrganizerMutation>;
export type RemoveReferralOrganizerMutationOptions = Apollo.BaseMutationOptions<RemoveReferralOrganizerMutation, RemoveReferralOrganizerMutationVariables>;
export const SubTariffsListDocument = gql`
    query subTariffsList($productUuid: UUID!, $status: [StatusEnum!], $filters: SubTariffListFilterInput) {
  subTariffsList(productUuid: $productUuid, status: $status, filters: $filters) {
    rows {
      ...SubTariffFields
      baseTariff {
        name
        type
        ordersCount
        isLimitedOrders
      }
    }
  }
}
    ${SubTariffFieldsFragmentDoc}`;

/**
 * __useSubTariffsListQuery__
 *
 * To run a query within a React component, call `useSubTariffsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubTariffsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubTariffsListQuery({
 *   variables: {
 *      productUuid: // value for 'productUuid'
 *      status: // value for 'status'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSubTariffsListQuery(baseOptions: Apollo.QueryHookOptions<SubTariffsListQuery, SubTariffsListQueryVariables> & ({ variables: SubTariffsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubTariffsListQuery, SubTariffsListQueryVariables>(SubTariffsListDocument, options);
      }
export function useSubTariffsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubTariffsListQuery, SubTariffsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubTariffsListQuery, SubTariffsListQueryVariables>(SubTariffsListDocument, options);
        }
export function useSubTariffsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SubTariffsListQuery, SubTariffsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubTariffsListQuery, SubTariffsListQueryVariables>(SubTariffsListDocument, options);
        }
export type SubTariffsListQueryHookResult = ReturnType<typeof useSubTariffsListQuery>;
export type SubTariffsListLazyQueryHookResult = ReturnType<typeof useSubTariffsListLazyQuery>;
export type SubTariffsListSuspenseQueryHookResult = ReturnType<typeof useSubTariffsListSuspenseQuery>;
export type SubTariffsListQueryResult = Apollo.QueryResult<SubTariffsListQuery, SubTariffsListQueryVariables>;
export const SubTariffDocument = gql`
    query subTariff($uuid: UUID!) {
  subTariff(uuid: $uuid) {
    ...SubTariffFields
    baseTariff {
      ...BaseTariffFields
    }
  }
}
    ${SubTariffFieldsFragmentDoc}
${BaseTariffFieldsFragmentDoc}`;

/**
 * __useSubTariffQuery__
 *
 * To run a query within a React component, call `useSubTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubTariffQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSubTariffQuery(baseOptions: Apollo.QueryHookOptions<SubTariffQuery, SubTariffQueryVariables> & ({ variables: SubTariffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubTariffQuery, SubTariffQueryVariables>(SubTariffDocument, options);
      }
export function useSubTariffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubTariffQuery, SubTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubTariffQuery, SubTariffQueryVariables>(SubTariffDocument, options);
        }
export function useSubTariffSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SubTariffQuery, SubTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubTariffQuery, SubTariffQueryVariables>(SubTariffDocument, options);
        }
export type SubTariffQueryHookResult = ReturnType<typeof useSubTariffQuery>;
export type SubTariffLazyQueryHookResult = ReturnType<typeof useSubTariffLazyQuery>;
export type SubTariffSuspenseQueryHookResult = ReturnType<typeof useSubTariffSuspenseQuery>;
export type SubTariffQueryResult = Apollo.QueryResult<SubTariffQuery, SubTariffQueryVariables>;
export const CreateTariffDocument = gql`
    mutation createTariff($input: SubTariffInput!, $uuidProduct: UUID, $uuidParentTariff: UUID!) {
  createTariff(
    input: $input
    uuidParentTariff: $uuidParentTariff
    uuidProduct: $uuidProduct
  ) {
    ...SubTariffFields
    baseTariff {
      ...BaseTariffFields
    }
  }
}
    ${SubTariffFieldsFragmentDoc}
${BaseTariffFieldsFragmentDoc}`;
export type CreateTariffMutationFn = Apollo.MutationFunction<CreateTariffMutation, CreateTariffMutationVariables>;

/**
 * __useCreateTariffMutation__
 *
 * To run a mutation, you first call `useCreateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTariffMutation, { data, loading, error }] = useCreateTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *      uuidProduct: // value for 'uuidProduct'
 *      uuidParentTariff: // value for 'uuidParentTariff'
 *   },
 * });
 */
export function useCreateTariffMutation(baseOptions?: Apollo.MutationHookOptions<CreateTariffMutation, CreateTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTariffMutation, CreateTariffMutationVariables>(CreateTariffDocument, options);
      }
export type CreateTariffMutationHookResult = ReturnType<typeof useCreateTariffMutation>;
export type CreateTariffMutationResult = Apollo.MutationResult<CreateTariffMutation>;
export type CreateTariffMutationOptions = Apollo.BaseMutationOptions<CreateTariffMutation, CreateTariffMutationVariables>;
export const UpdateTariffDocument = gql`
    mutation updateTariff($uuid: UUID!, $input: SubTariffInput!) {
  updateTariff(input: $input, uuid: $uuid) {
    ...SubTariffFields
    baseTariff {
      ...BaseTariffFields
    }
  }
}
    ${SubTariffFieldsFragmentDoc}
${BaseTariffFieldsFragmentDoc}`;
export type UpdateTariffMutationFn = Apollo.MutationFunction<UpdateTariffMutation, UpdateTariffMutationVariables>;

/**
 * __useUpdateTariffMutation__
 *
 * To run a mutation, you first call `useUpdateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTariffMutation, { data, loading, error }] = useUpdateTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTariffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTariffMutation, UpdateTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTariffMutation, UpdateTariffMutationVariables>(UpdateTariffDocument, options);
      }
export type UpdateTariffMutationHookResult = ReturnType<typeof useUpdateTariffMutation>;
export type UpdateTariffMutationResult = Apollo.MutationResult<UpdateTariffMutation>;
export type UpdateTariffMutationOptions = Apollo.BaseMutationOptions<UpdateTariffMutation, UpdateTariffMutationVariables>;
export const RemoveTariffDocument = gql`
    mutation removeTariff($uuid: UUID!) {
  removeTariff(uuid: $uuid)
}
    `;
export type RemoveTariffMutationFn = Apollo.MutationFunction<RemoveTariffMutation, RemoveTariffMutationVariables>;

/**
 * __useRemoveTariffMutation__
 *
 * To run a mutation, you first call `useRemoveTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTariffMutation, { data, loading, error }] = useRemoveTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveTariffMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTariffMutation, RemoveTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTariffMutation, RemoveTariffMutationVariables>(RemoveTariffDocument, options);
      }
export type RemoveTariffMutationHookResult = ReturnType<typeof useRemoveTariffMutation>;
export type RemoveTariffMutationResult = Apollo.MutationResult<RemoveTariffMutation>;
export type RemoveTariffMutationOptions = Apollo.BaseMutationOptions<RemoveTariffMutation, RemoveTariffMutationVariables>;
export const UpdateProductTariffsDocument = gql`
    mutation updateProductTariffs($uuids: [UUID!]!, $productUuid: UUID!) {
  updateProductTariffs(uuids: $uuids, productUuid: $productUuid)
}
    `;
export type UpdateProductTariffsMutationFn = Apollo.MutationFunction<UpdateProductTariffsMutation, UpdateProductTariffsMutationVariables>;

/**
 * __useUpdateProductTariffsMutation__
 *
 * To run a mutation, you first call `useUpdateProductTariffsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductTariffsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductTariffsMutation, { data, loading, error }] = useUpdateProductTariffsMutation({
 *   variables: {
 *      uuids: // value for 'uuids'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUpdateProductTariffsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductTariffsMutation, UpdateProductTariffsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductTariffsMutation, UpdateProductTariffsMutationVariables>(UpdateProductTariffsDocument, options);
      }
export type UpdateProductTariffsMutationHookResult = ReturnType<typeof useUpdateProductTariffsMutation>;
export type UpdateProductTariffsMutationResult = Apollo.MutationResult<UpdateProductTariffsMutation>;
export type UpdateProductTariffsMutationOptions = Apollo.BaseMutationOptions<UpdateProductTariffsMutation, UpdateProductTariffsMutationVariables>;
export const TerminalDocument = gql`
    query terminal($uuid: UUID!) {
  terminalAdmin(uuid: $uuid) {
    ...TerminalFields
  }
}
    ${TerminalFieldsFragmentDoc}`;

/**
 * __useTerminalQuery__
 *
 * To run a query within a React component, call `useTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminalQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useTerminalQuery(baseOptions: Apollo.QueryHookOptions<TerminalQuery, TerminalQueryVariables> & ({ variables: TerminalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TerminalQuery, TerminalQueryVariables>(TerminalDocument, options);
      }
export function useTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerminalQuery, TerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TerminalQuery, TerminalQueryVariables>(TerminalDocument, options);
        }
export function useTerminalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TerminalQuery, TerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TerminalQuery, TerminalQueryVariables>(TerminalDocument, options);
        }
export type TerminalQueryHookResult = ReturnType<typeof useTerminalQuery>;
export type TerminalLazyQueryHookResult = ReturnType<typeof useTerminalLazyQuery>;
export type TerminalSuspenseQueryHookResult = ReturnType<typeof useTerminalSuspenseQuery>;
export type TerminalQueryResult = Apollo.QueryResult<TerminalQuery, TerminalQueryVariables>;
export const TerminalsByProductDocument = gql`
    query terminalsByProduct($uuidProduct: UUID!) {
  terminalsByProduct(uuidProduct: $uuidProduct) {
    ...TerminalFields
  }
}
    ${TerminalFieldsFragmentDoc}`;

/**
 * __useTerminalsByProductQuery__
 *
 * To run a query within a React component, call `useTerminalsByProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminalsByProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminalsByProductQuery({
 *   variables: {
 *      uuidProduct: // value for 'uuidProduct'
 *   },
 * });
 */
export function useTerminalsByProductQuery(baseOptions: Apollo.QueryHookOptions<TerminalsByProductQuery, TerminalsByProductQueryVariables> & ({ variables: TerminalsByProductQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TerminalsByProductQuery, TerminalsByProductQueryVariables>(TerminalsByProductDocument, options);
      }
export function useTerminalsByProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerminalsByProductQuery, TerminalsByProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TerminalsByProductQuery, TerminalsByProductQueryVariables>(TerminalsByProductDocument, options);
        }
export function useTerminalsByProductSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TerminalsByProductQuery, TerminalsByProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TerminalsByProductQuery, TerminalsByProductQueryVariables>(TerminalsByProductDocument, options);
        }
export type TerminalsByProductQueryHookResult = ReturnType<typeof useTerminalsByProductQuery>;
export type TerminalsByProductLazyQueryHookResult = ReturnType<typeof useTerminalsByProductLazyQuery>;
export type TerminalsByProductSuspenseQueryHookResult = ReturnType<typeof useTerminalsByProductSuspenseQuery>;
export type TerminalsByProductQueryResult = Apollo.QueryResult<TerminalsByProductQuery, TerminalsByProductQueryVariables>;
export const TerminalsDocument = gql`
    query terminals($status: [StatusEnum!], $idOrganizer: Float, $filters: TerminalListFilterInput) {
  terminalsList(filters: $filters, idOrganizer: $idOrganizer, status: $status) {
    rows {
      ...TerminalFields
      organizer {
        name
        telegram
      }
    }
    count
  }
}
    ${TerminalFieldsFragmentDoc}`;

/**
 * __useTerminalsQuery__
 *
 * To run a query within a React component, call `useTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminalsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      idOrganizer: // value for 'idOrganizer'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTerminalsQuery(baseOptions?: Apollo.QueryHookOptions<TerminalsQuery, TerminalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TerminalsQuery, TerminalsQueryVariables>(TerminalsDocument, options);
      }
export function useTerminalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerminalsQuery, TerminalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TerminalsQuery, TerminalsQueryVariables>(TerminalsDocument, options);
        }
export function useTerminalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TerminalsQuery, TerminalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TerminalsQuery, TerminalsQueryVariables>(TerminalsDocument, options);
        }
export type TerminalsQueryHookResult = ReturnType<typeof useTerminalsQuery>;
export type TerminalsLazyQueryHookResult = ReturnType<typeof useTerminalsLazyQuery>;
export type TerminalsSuspenseQueryHookResult = ReturnType<typeof useTerminalsSuspenseQuery>;
export type TerminalsQueryResult = Apollo.QueryResult<TerminalsQuery, TerminalsQueryVariables>;
export const TerminalsCountDocument = gql`
    query terminalsCount($status: [StatusEnum!], $idOrganizer: Float) {
  terminalsList(
    filters: {pagination: {pageSize: 1, page: 1}}
    idOrganizer: $idOrganizer
    status: $status
  ) {
    count
  }
}
    `;

/**
 * __useTerminalsCountQuery__
 *
 * To run a query within a React component, call `useTerminalsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerminalsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerminalsCountQuery({
 *   variables: {
 *      status: // value for 'status'
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useTerminalsCountQuery(baseOptions?: Apollo.QueryHookOptions<TerminalsCountQuery, TerminalsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TerminalsCountQuery, TerminalsCountQueryVariables>(TerminalsCountDocument, options);
      }
export function useTerminalsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerminalsCountQuery, TerminalsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TerminalsCountQuery, TerminalsCountQueryVariables>(TerminalsCountDocument, options);
        }
export function useTerminalsCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TerminalsCountQuery, TerminalsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TerminalsCountQuery, TerminalsCountQueryVariables>(TerminalsCountDocument, options);
        }
export type TerminalsCountQueryHookResult = ReturnType<typeof useTerminalsCountQuery>;
export type TerminalsCountLazyQueryHookResult = ReturnType<typeof useTerminalsCountLazyQuery>;
export type TerminalsCountSuspenseQueryHookResult = ReturnType<typeof useTerminalsCountSuspenseQuery>;
export type TerminalsCountQueryResult = Apollo.QueryResult<TerminalsCountQuery, TerminalsCountQueryVariables>;
export const CreateTerminalDocument = gql`
    mutation createTerminal($idOrganizer: Float!, $input: TerminalInput!) {
  createTerminal(input: $input, idOrganizer: $idOrganizer) {
    ...TerminalFields
  }
}
    ${TerminalFieldsFragmentDoc}`;
export type CreateTerminalMutationFn = Apollo.MutationFunction<CreateTerminalMutation, CreateTerminalMutationVariables>;

/**
 * __useCreateTerminalMutation__
 *
 * To run a mutation, you first call `useCreateTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTerminalMutation, { data, loading, error }] = useCreateTerminalMutation({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTerminalMutation(baseOptions?: Apollo.MutationHookOptions<CreateTerminalMutation, CreateTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTerminalMutation, CreateTerminalMutationVariables>(CreateTerminalDocument, options);
      }
export type CreateTerminalMutationHookResult = ReturnType<typeof useCreateTerminalMutation>;
export type CreateTerminalMutationResult = Apollo.MutationResult<CreateTerminalMutation>;
export type CreateTerminalMutationOptions = Apollo.BaseMutationOptions<CreateTerminalMutation, CreateTerminalMutationVariables>;
export const UpdateTerminalDocument = gql`
    mutation updateTerminal($uuid: UUID!, $input: TerminalInput!) {
  updateTerminal(input: $input, uuid: $uuid) {
    ...TerminalFields
  }
}
    ${TerminalFieldsFragmentDoc}`;
export type UpdateTerminalMutationFn = Apollo.MutationFunction<UpdateTerminalMutation, UpdateTerminalMutationVariables>;

/**
 * __useUpdateTerminalMutation__
 *
 * To run a mutation, you first call `useUpdateTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTerminalMutation, { data, loading, error }] = useUpdateTerminalMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTerminalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTerminalMutation, UpdateTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTerminalMutation, UpdateTerminalMutationVariables>(UpdateTerminalDocument, options);
      }
export type UpdateTerminalMutationHookResult = ReturnType<typeof useUpdateTerminalMutation>;
export type UpdateTerminalMutationResult = Apollo.MutationResult<UpdateTerminalMutation>;
export type UpdateTerminalMutationOptions = Apollo.BaseMutationOptions<UpdateTerminalMutation, UpdateTerminalMutationVariables>;
export const UpdateProductTerminalsDocument = gql`
    mutation updateProductTerminals($uuidProduct: UUID!, $uuidTerminals: [UUID!]!) {
  updateProductTerminals(uuidProduct: $uuidProduct, uuidTerminals: $uuidTerminals)
}
    `;
export type UpdateProductTerminalsMutationFn = Apollo.MutationFunction<UpdateProductTerminalsMutation, UpdateProductTerminalsMutationVariables>;

/**
 * __useUpdateProductTerminalsMutation__
 *
 * To run a mutation, you first call `useUpdateProductTerminalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductTerminalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductTerminalsMutation, { data, loading, error }] = useUpdateProductTerminalsMutation({
 *   variables: {
 *      uuidProduct: // value for 'uuidProduct'
 *      uuidTerminals: // value for 'uuidTerminals'
 *   },
 * });
 */
export function useUpdateProductTerminalsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductTerminalsMutation, UpdateProductTerminalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductTerminalsMutation, UpdateProductTerminalsMutationVariables>(UpdateProductTerminalsDocument, options);
      }
export type UpdateProductTerminalsMutationHookResult = ReturnType<typeof useUpdateProductTerminalsMutation>;
export type UpdateProductTerminalsMutationResult = Apollo.MutationResult<UpdateProductTerminalsMutation>;
export type UpdateProductTerminalsMutationOptions = Apollo.BaseMutationOptions<UpdateProductTerminalsMutation, UpdateProductTerminalsMutationVariables>;
export const RemoveTerminalDocument = gql`
    mutation removeTerminal($uuid: UUID!) {
  removeTerminal(uuid: $uuid)
}
    `;
export type RemoveTerminalMutationFn = Apollo.MutationFunction<RemoveTerminalMutation, RemoveTerminalMutationVariables>;

/**
 * __useRemoveTerminalMutation__
 *
 * To run a mutation, you first call `useRemoveTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTerminalMutation, { data, loading, error }] = useRemoveTerminalMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveTerminalMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTerminalMutation, RemoveTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTerminalMutation, RemoveTerminalMutationVariables>(RemoveTerminalDocument, options);
      }
export type RemoveTerminalMutationHookResult = ReturnType<typeof useRemoveTerminalMutation>;
export type RemoveTerminalMutationResult = Apollo.MutationResult<RemoveTerminalMutation>;
export type RemoveTerminalMutationOptions = Apollo.BaseMutationOptions<RemoveTerminalMutation, RemoveTerminalMutationVariables>;
export const ManualTerminalByOrganizerDocument = gql`
    query manualTerminalByOrganizer($idOrganizer: Float!) {
  manualTerminalByOrganizer(idOrganizer: $idOrganizer) {
    ...ManualTerminalFields
  }
}
    ${ManualTerminalFieldsFragmentDoc}`;

/**
 * __useManualTerminalByOrganizerQuery__
 *
 * To run a query within a React component, call `useManualTerminalByOrganizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualTerminalByOrganizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualTerminalByOrganizerQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useManualTerminalByOrganizerQuery(baseOptions: Apollo.QueryHookOptions<ManualTerminalByOrganizerQuery, ManualTerminalByOrganizerQueryVariables> & ({ variables: ManualTerminalByOrganizerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManualTerminalByOrganizerQuery, ManualTerminalByOrganizerQueryVariables>(ManualTerminalByOrganizerDocument, options);
      }
export function useManualTerminalByOrganizerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManualTerminalByOrganizerQuery, ManualTerminalByOrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManualTerminalByOrganizerQuery, ManualTerminalByOrganizerQueryVariables>(ManualTerminalByOrganizerDocument, options);
        }
export function useManualTerminalByOrganizerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ManualTerminalByOrganizerQuery, ManualTerminalByOrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ManualTerminalByOrganizerQuery, ManualTerminalByOrganizerQueryVariables>(ManualTerminalByOrganizerDocument, options);
        }
export type ManualTerminalByOrganizerQueryHookResult = ReturnType<typeof useManualTerminalByOrganizerQuery>;
export type ManualTerminalByOrganizerLazyQueryHookResult = ReturnType<typeof useManualTerminalByOrganizerLazyQuery>;
export type ManualTerminalByOrganizerSuspenseQueryHookResult = ReturnType<typeof useManualTerminalByOrganizerSuspenseQuery>;
export type ManualTerminalByOrganizerQueryResult = Apollo.QueryResult<ManualTerminalByOrganizerQuery, ManualTerminalByOrganizerQueryVariables>;
export const ManualProductTerminalDocument = gql`
    query manualProductTerminal($uuid: UUID!) {
  product(uuid: $uuid) {
    manualPaymentText
    manualTerminal {
      uuid
      label
    }
  }
}
    `;

/**
 * __useManualProductTerminalQuery__
 *
 * To run a query within a React component, call `useManualProductTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualProductTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualProductTerminalQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useManualProductTerminalQuery(baseOptions: Apollo.QueryHookOptions<ManualProductTerminalQuery, ManualProductTerminalQueryVariables> & ({ variables: ManualProductTerminalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManualProductTerminalQuery, ManualProductTerminalQueryVariables>(ManualProductTerminalDocument, options);
      }
export function useManualProductTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManualProductTerminalQuery, ManualProductTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManualProductTerminalQuery, ManualProductTerminalQueryVariables>(ManualProductTerminalDocument, options);
        }
export function useManualProductTerminalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ManualProductTerminalQuery, ManualProductTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ManualProductTerminalQuery, ManualProductTerminalQueryVariables>(ManualProductTerminalDocument, options);
        }
export type ManualProductTerminalQueryHookResult = ReturnType<typeof useManualProductTerminalQuery>;
export type ManualProductTerminalLazyQueryHookResult = ReturnType<typeof useManualProductTerminalLazyQuery>;
export type ManualProductTerminalSuspenseQueryHookResult = ReturnType<typeof useManualProductTerminalSuspenseQuery>;
export type ManualProductTerminalQueryResult = Apollo.QueryResult<ManualProductTerminalQuery, ManualProductTerminalQueryVariables>;
export const RemoveManualTerminalFromProductDocument = gql`
    mutation removeManualTerminalFromProduct($uuidProduct: UUID!) {
  removeManualTerminalFromProduct(uuidProduct: $uuidProduct)
}
    `;
export type RemoveManualTerminalFromProductMutationFn = Apollo.MutationFunction<RemoveManualTerminalFromProductMutation, RemoveManualTerminalFromProductMutationVariables>;

/**
 * __useRemoveManualTerminalFromProductMutation__
 *
 * To run a mutation, you first call `useRemoveManualTerminalFromProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveManualTerminalFromProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeManualTerminalFromProductMutation, { data, loading, error }] = useRemoveManualTerminalFromProductMutation({
 *   variables: {
 *      uuidProduct: // value for 'uuidProduct'
 *   },
 * });
 */
export function useRemoveManualTerminalFromProductMutation(baseOptions?: Apollo.MutationHookOptions<RemoveManualTerminalFromProductMutation, RemoveManualTerminalFromProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveManualTerminalFromProductMutation, RemoveManualTerminalFromProductMutationVariables>(RemoveManualTerminalFromProductDocument, options);
      }
export type RemoveManualTerminalFromProductMutationHookResult = ReturnType<typeof useRemoveManualTerminalFromProductMutation>;
export type RemoveManualTerminalFromProductMutationResult = Apollo.MutationResult<RemoveManualTerminalFromProductMutation>;
export type RemoveManualTerminalFromProductMutationOptions = Apollo.BaseMutationOptions<RemoveManualTerminalFromProductMutation, RemoveManualTerminalFromProductMutationVariables>;
export const AddManualTerminalToProductDocument = gql`
    mutation addManualTerminalToProduct($uuidProduct: UUID!, $uuidManualTerminal: UUID!) {
  addManualTerminalToProduct(
    uuidProduct: $uuidProduct
    uuidManualTerminal: $uuidManualTerminal
  )
}
    `;
export type AddManualTerminalToProductMutationFn = Apollo.MutationFunction<AddManualTerminalToProductMutation, AddManualTerminalToProductMutationVariables>;

/**
 * __useAddManualTerminalToProductMutation__
 *
 * To run a mutation, you first call `useAddManualTerminalToProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManualTerminalToProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManualTerminalToProductMutation, { data, loading, error }] = useAddManualTerminalToProductMutation({
 *   variables: {
 *      uuidProduct: // value for 'uuidProduct'
 *      uuidManualTerminal: // value for 'uuidManualTerminal'
 *   },
 * });
 */
export function useAddManualTerminalToProductMutation(baseOptions?: Apollo.MutationHookOptions<AddManualTerminalToProductMutation, AddManualTerminalToProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddManualTerminalToProductMutation, AddManualTerminalToProductMutationVariables>(AddManualTerminalToProductDocument, options);
      }
export type AddManualTerminalToProductMutationHookResult = ReturnType<typeof useAddManualTerminalToProductMutation>;
export type AddManualTerminalToProductMutationResult = Apollo.MutationResult<AddManualTerminalToProductMutation>;
export type AddManualTerminalToProductMutationOptions = Apollo.BaseMutationOptions<AddManualTerminalToProductMutation, AddManualTerminalToProductMutationVariables>;
export const UpdateManualTerminalTextDocument = gql`
    mutation updateManualTerminalText($uuidProduct: UUID!, $text: String) {
  updateManualTerminalText(uuidProduct: $uuidProduct, text: $text)
}
    `;
export type UpdateManualTerminalTextMutationFn = Apollo.MutationFunction<UpdateManualTerminalTextMutation, UpdateManualTerminalTextMutationVariables>;

/**
 * __useUpdateManualTerminalTextMutation__
 *
 * To run a mutation, you first call `useUpdateManualTerminalTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManualTerminalTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManualTerminalTextMutation, { data, loading, error }] = useUpdateManualTerminalTextMutation({
 *   variables: {
 *      uuidProduct: // value for 'uuidProduct'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateManualTerminalTextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManualTerminalTextMutation, UpdateManualTerminalTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManualTerminalTextMutation, UpdateManualTerminalTextMutationVariables>(UpdateManualTerminalTextDocument, options);
      }
export type UpdateManualTerminalTextMutationHookResult = ReturnType<typeof useUpdateManualTerminalTextMutation>;
export type UpdateManualTerminalTextMutationResult = Apollo.MutationResult<UpdateManualTerminalTextMutation>;
export type UpdateManualTerminalTextMutationOptions = Apollo.BaseMutationOptions<UpdateManualTerminalTextMutation, UpdateManualTerminalTextMutationVariables>;
export const SubmitManualInvoiceDocument = gql`
    mutation submitManualInvoice($uuidPayment: UUID!) {
  submitManualInvoice(uuidPayment: $uuidPayment) {
    uuid
  }
}
    `;
export type SubmitManualInvoiceMutationFn = Apollo.MutationFunction<SubmitManualInvoiceMutation, SubmitManualInvoiceMutationVariables>;

/**
 * __useSubmitManualInvoiceMutation__
 *
 * To run a mutation, you first call `useSubmitManualInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitManualInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitManualInvoiceMutation, { data, loading, error }] = useSubmitManualInvoiceMutation({
 *   variables: {
 *      uuidPayment: // value for 'uuidPayment'
 *   },
 * });
 */
export function useSubmitManualInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<SubmitManualInvoiceMutation, SubmitManualInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitManualInvoiceMutation, SubmitManualInvoiceMutationVariables>(SubmitManualInvoiceDocument, options);
      }
export type SubmitManualInvoiceMutationHookResult = ReturnType<typeof useSubmitManualInvoiceMutation>;
export type SubmitManualInvoiceMutationResult = Apollo.MutationResult<SubmitManualInvoiceMutation>;
export type SubmitManualInvoiceMutationOptions = Apollo.BaseMutationOptions<SubmitManualInvoiceMutation, SubmitManualInvoiceMutationVariables>;
export const CancelManualInvoiceDocument = gql`
    mutation cancelManualInvoice($uuidPayment: UUID!) {
  cancelManualInvoice(uuidPayment: $uuidPayment) {
    uuid
  }
}
    `;
export type CancelManualInvoiceMutationFn = Apollo.MutationFunction<CancelManualInvoiceMutation, CancelManualInvoiceMutationVariables>;

/**
 * __useCancelManualInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelManualInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelManualInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelManualInvoiceMutation, { data, loading, error }] = useCancelManualInvoiceMutation({
 *   variables: {
 *      uuidPayment: // value for 'uuidPayment'
 *   },
 * });
 */
export function useCancelManualInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CancelManualInvoiceMutation, CancelManualInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelManualInvoiceMutation, CancelManualInvoiceMutationVariables>(CancelManualInvoiceDocument, options);
      }
export type CancelManualInvoiceMutationHookResult = ReturnType<typeof useCancelManualInvoiceMutation>;
export type CancelManualInvoiceMutationResult = Apollo.MutationResult<CancelManualInvoiceMutation>;
export type CancelManualInvoiceMutationOptions = Apollo.BaseMutationOptions<CancelManualInvoiceMutation, CancelManualInvoiceMutationVariables>;
export const UsersListDocument = gql`
    query usersList($filters: UserListFilterInput, $organizerId: Int!, $isActive: Boolean, $productUuid: UUID) {
  users(filters: $filters, organizerId: $organizerId, args: {isActive: $isActive}) {
    ...UserListFields
  }
}
    ${UserListFieldsFragmentDoc}`;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *      isActive: // value for 'isActive'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUsersListQuery(baseOptions: Apollo.QueryHookOptions<UsersListQuery, UsersListQueryVariables> & ({ variables: UsersListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
      }
export function useUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersListQuery, UsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
        }
export function useUsersListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersListQuery, UsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
        }
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<typeof useUsersListLazyQuery>;
export type UsersListSuspenseQueryHookResult = ReturnType<typeof useUsersListSuspenseQuery>;
export type UsersListQueryResult = Apollo.QueryResult<UsersListQuery, UsersListQueryVariables>;
export const UsersBlockedListDocument = gql`
    query usersBlockedList($filters: UserListFilterInput, $organizerId: Int!, $productUuid: UUID) {
  blacklist(organizerId: $organizerId, filters: $filters) {
    ...UserListFields
  }
}
    ${UserListFieldsFragmentDoc}`;

/**
 * __useUsersBlockedListQuery__
 *
 * To run a query within a React component, call `useUsersBlockedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersBlockedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersBlockedListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUsersBlockedListQuery(baseOptions: Apollo.QueryHookOptions<UsersBlockedListQuery, UsersBlockedListQueryVariables> & ({ variables: UsersBlockedListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersBlockedListQuery, UsersBlockedListQueryVariables>(UsersBlockedListDocument, options);
      }
export function useUsersBlockedListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersBlockedListQuery, UsersBlockedListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersBlockedListQuery, UsersBlockedListQueryVariables>(UsersBlockedListDocument, options);
        }
export function useUsersBlockedListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersBlockedListQuery, UsersBlockedListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersBlockedListQuery, UsersBlockedListQueryVariables>(UsersBlockedListDocument, options);
        }
export type UsersBlockedListQueryHookResult = ReturnType<typeof useUsersBlockedListQuery>;
export type UsersBlockedListLazyQueryHookResult = ReturnType<typeof useUsersBlockedListLazyQuery>;
export type UsersBlockedListSuspenseQueryHookResult = ReturnType<typeof useUsersBlockedListSuspenseQuery>;
export type UsersBlockedListQueryResult = Apollo.QueryResult<UsersBlockedListQuery, UsersBlockedListQueryVariables>;
export const UsersRegisteredListDocument = gql`
    query usersRegisteredList($filters: UserListFilterInput, $organizerId: Int!, $productUuid: UUID) {
  users(filters: $filters, organizerId: $organizerId, args: {isRegistered: true}) {
    ...UserListFields
  }
}
    ${UserListFieldsFragmentDoc}`;

/**
 * __useUsersRegisteredListQuery__
 *
 * To run a query within a React component, call `useUsersRegisteredListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersRegisteredListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersRegisteredListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUsersRegisteredListQuery(baseOptions: Apollo.QueryHookOptions<UsersRegisteredListQuery, UsersRegisteredListQueryVariables> & ({ variables: UsersRegisteredListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersRegisteredListQuery, UsersRegisteredListQueryVariables>(UsersRegisteredListDocument, options);
      }
export function useUsersRegisteredListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersRegisteredListQuery, UsersRegisteredListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersRegisteredListQuery, UsersRegisteredListQueryVariables>(UsersRegisteredListDocument, options);
        }
export function useUsersRegisteredListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersRegisteredListQuery, UsersRegisteredListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersRegisteredListQuery, UsersRegisteredListQueryVariables>(UsersRegisteredListDocument, options);
        }
export type UsersRegisteredListQueryHookResult = ReturnType<typeof useUsersRegisteredListQuery>;
export type UsersRegisteredListLazyQueryHookResult = ReturnType<typeof useUsersRegisteredListLazyQuery>;
export type UsersRegisteredListSuspenseQueryHookResult = ReturnType<typeof useUsersRegisteredListSuspenseQuery>;
export type UsersRegisteredListQueryResult = Apollo.QueryResult<UsersRegisteredListQuery, UsersRegisteredListQueryVariables>;
export const UsersByGroupListDocument = gql`
    query usersByGroupList($filters: UserListFilterInput!, $groupUuid: UUID!, $organizerId: Int!, $productUuid: UUID) {
  usersByGroup(filters: $filters, groupUuid: $groupUuid) {
    ...UserListFields
  }
}
    ${UserListFieldsFragmentDoc}`;

/**
 * __useUsersByGroupListQuery__
 *
 * To run a query within a React component, call `useUsersByGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByGroupListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      groupUuid: // value for 'groupUuid'
 *      organizerId: // value for 'organizerId'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUsersByGroupListQuery(baseOptions: Apollo.QueryHookOptions<UsersByGroupListQuery, UsersByGroupListQueryVariables> & ({ variables: UsersByGroupListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersByGroupListQuery, UsersByGroupListQueryVariables>(UsersByGroupListDocument, options);
      }
export function useUsersByGroupListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersByGroupListQuery, UsersByGroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersByGroupListQuery, UsersByGroupListQueryVariables>(UsersByGroupListDocument, options);
        }
export function useUsersByGroupListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersByGroupListQuery, UsersByGroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersByGroupListQuery, UsersByGroupListQueryVariables>(UsersByGroupListDocument, options);
        }
export type UsersByGroupListQueryHookResult = ReturnType<typeof useUsersByGroupListQuery>;
export type UsersByGroupListLazyQueryHookResult = ReturnType<typeof useUsersByGroupListLazyQuery>;
export type UsersByGroupListSuspenseQueryHookResult = ReturnType<typeof useUsersByGroupListSuspenseQuery>;
export type UsersByGroupListQueryResult = Apollo.QueryResult<UsersByGroupListQuery, UsersByGroupListQueryVariables>;
export const UsersRegisteredCountDocument = gql`
    query usersRegisteredCount($organizerId: Int!) {
  users(
    filters: {pagination: {page: 1, pageSize: 1}}
    organizerId: $organizerId
    args: {isRegistered: true}
  ) {
    count
  }
}
    `;

/**
 * __useUsersRegisteredCountQuery__
 *
 * To run a query within a React component, call `useUsersRegisteredCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersRegisteredCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersRegisteredCountQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUsersRegisteredCountQuery(baseOptions: Apollo.QueryHookOptions<UsersRegisteredCountQuery, UsersRegisteredCountQueryVariables> & ({ variables: UsersRegisteredCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersRegisteredCountQuery, UsersRegisteredCountQueryVariables>(UsersRegisteredCountDocument, options);
      }
export function useUsersRegisteredCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersRegisteredCountQuery, UsersRegisteredCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersRegisteredCountQuery, UsersRegisteredCountQueryVariables>(UsersRegisteredCountDocument, options);
        }
export function useUsersRegisteredCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersRegisteredCountQuery, UsersRegisteredCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersRegisteredCountQuery, UsersRegisteredCountQueryVariables>(UsersRegisteredCountDocument, options);
        }
export type UsersRegisteredCountQueryHookResult = ReturnType<typeof useUsersRegisteredCountQuery>;
export type UsersRegisteredCountLazyQueryHookResult = ReturnType<typeof useUsersRegisteredCountLazyQuery>;
export type UsersRegisteredCountSuspenseQueryHookResult = ReturnType<typeof useUsersRegisteredCountSuspenseQuery>;
export type UsersRegisteredCountQueryResult = Apollo.QueryResult<UsersRegisteredCountQuery, UsersRegisteredCountQueryVariables>;
export const UsersBlockedCountDocument = gql`
    query usersBlockedCount($organizerId: Int!) {
  blacklist(
    organizerId: $organizerId
    filters: {pagination: {page: 1, pageSize: 1}}
  ) {
    count
  }
}
    `;

/**
 * __useUsersBlockedCountQuery__
 *
 * To run a query within a React component, call `useUsersBlockedCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersBlockedCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersBlockedCountQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUsersBlockedCountQuery(baseOptions: Apollo.QueryHookOptions<UsersBlockedCountQuery, UsersBlockedCountQueryVariables> & ({ variables: UsersBlockedCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersBlockedCountQuery, UsersBlockedCountQueryVariables>(UsersBlockedCountDocument, options);
      }
export function useUsersBlockedCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersBlockedCountQuery, UsersBlockedCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersBlockedCountQuery, UsersBlockedCountQueryVariables>(UsersBlockedCountDocument, options);
        }
export function useUsersBlockedCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersBlockedCountQuery, UsersBlockedCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersBlockedCountQuery, UsersBlockedCountQueryVariables>(UsersBlockedCountDocument, options);
        }
export type UsersBlockedCountQueryHookResult = ReturnType<typeof useUsersBlockedCountQuery>;
export type UsersBlockedCountLazyQueryHookResult = ReturnType<typeof useUsersBlockedCountLazyQuery>;
export type UsersBlockedCountSuspenseQueryHookResult = ReturnType<typeof useUsersBlockedCountSuspenseQuery>;
export type UsersBlockedCountQueryResult = Apollo.QueryResult<UsersBlockedCountQuery, UsersBlockedCountQueryVariables>;
export const UserGroupsDocument = gql`
    query userGroups($filters: UserGroupsListFilterInput, $idOrganizer: Int!) {
  userGroups(filters: $filters, idOrganizer: $idOrganizer) {
    rows {
      ...UserGroupFields
    }
    count
  }
}
    ${UserGroupFieldsFragmentDoc}`;

/**
 * __useUserGroupsQuery__
 *
 * To run a query within a React component, call `useUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useUserGroupsQuery(baseOptions: Apollo.QueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables> & ({ variables: UserGroupsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
      }
export function useUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
        }
export function useUserGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
        }
export type UserGroupsQueryHookResult = ReturnType<typeof useUserGroupsQuery>;
export type UserGroupsLazyQueryHookResult = ReturnType<typeof useUserGroupsLazyQuery>;
export type UserGroupsSuspenseQueryHookResult = ReturnType<typeof useUserGroupsSuspenseQuery>;
export type UserGroupsQueryResult = Apollo.QueryResult<UserGroupsQuery, UserGroupsQueryVariables>;
export const AddGroupUsersDocument = gql`
    mutation addGroupUsers($idOrganizer: Int!, $name: String!) {
  crateUserGroup(idOrganizer: $idOrganizer, name: $name) {
    ...UserGroupFields
  }
}
    ${UserGroupFieldsFragmentDoc}`;
export type AddGroupUsersMutationFn = Apollo.MutationFunction<AddGroupUsersMutation, AddGroupUsersMutationVariables>;

/**
 * __useAddGroupUsersMutation__
 *
 * To run a mutation, you first call `useAddGroupUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupUsersMutation, { data, loading, error }] = useAddGroupUsersMutation({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddGroupUsersMutation(baseOptions?: Apollo.MutationHookOptions<AddGroupUsersMutation, AddGroupUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGroupUsersMutation, AddGroupUsersMutationVariables>(AddGroupUsersDocument, options);
      }
export type AddGroupUsersMutationHookResult = ReturnType<typeof useAddGroupUsersMutation>;
export type AddGroupUsersMutationResult = Apollo.MutationResult<AddGroupUsersMutation>;
export type AddGroupUsersMutationOptions = Apollo.BaseMutationOptions<AddGroupUsersMutation, AddGroupUsersMutationVariables>;
export const UpdateGroupUsersNameDocument = gql`
    mutation updateGroupUsersName($uuid: UUID!, $name: String!) {
  updateUserGroupName(uuid: $uuid, name: $name) {
    ...UserGroupFields
  }
}
    ${UserGroupFieldsFragmentDoc}`;
export type UpdateGroupUsersNameMutationFn = Apollo.MutationFunction<UpdateGroupUsersNameMutation, UpdateGroupUsersNameMutationVariables>;

/**
 * __useUpdateGroupUsersNameMutation__
 *
 * To run a mutation, you first call `useUpdateGroupUsersNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupUsersNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupUsersNameMutation, { data, loading, error }] = useUpdateGroupUsersNameMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateGroupUsersNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupUsersNameMutation, UpdateGroupUsersNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupUsersNameMutation, UpdateGroupUsersNameMutationVariables>(UpdateGroupUsersNameDocument, options);
      }
export type UpdateGroupUsersNameMutationHookResult = ReturnType<typeof useUpdateGroupUsersNameMutation>;
export type UpdateGroupUsersNameMutationResult = Apollo.MutationResult<UpdateGroupUsersNameMutation>;
export type UpdateGroupUsersNameMutationOptions = Apollo.BaseMutationOptions<UpdateGroupUsersNameMutation, UpdateGroupUsersNameMutationVariables>;
export const RemoveGroupUsersDocument = gql`
    mutation removeGroupUsers($uuid: UUID!) {
  removeUserGroup(uuid: $uuid)
}
    `;
export type RemoveGroupUsersMutationFn = Apollo.MutationFunction<RemoveGroupUsersMutation, RemoveGroupUsersMutationVariables>;

/**
 * __useRemoveGroupUsersMutation__
 *
 * To run a mutation, you first call `useRemoveGroupUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupUsersMutation, { data, loading, error }] = useRemoveGroupUsersMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveGroupUsersMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGroupUsersMutation, RemoveGroupUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveGroupUsersMutation, RemoveGroupUsersMutationVariables>(RemoveGroupUsersDocument, options);
      }
export type RemoveGroupUsersMutationHookResult = ReturnType<typeof useRemoveGroupUsersMutation>;
export type RemoveGroupUsersMutationResult = Apollo.MutationResult<RemoveGroupUsersMutation>;
export type RemoveGroupUsersMutationOptions = Apollo.BaseMutationOptions<RemoveGroupUsersMutation, RemoveGroupUsersMutationVariables>;
export const AddUserToGroupDocument = gql`
    mutation addUserToGroup($uuidUser: UUID!, $uuidGroup: UUID!) {
  addUserToGroup(uuidUser: $uuidUser, uuidGroup: $uuidGroup)
}
    `;
export type AddUserToGroupMutationFn = Apollo.MutationFunction<AddUserToGroupMutation, AddUserToGroupMutationVariables>;

/**
 * __useAddUserToGroupMutation__
 *
 * To run a mutation, you first call `useAddUserToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToGroupMutation, { data, loading, error }] = useAddUserToGroupMutation({
 *   variables: {
 *      uuidUser: // value for 'uuidUser'
 *      uuidGroup: // value for 'uuidGroup'
 *   },
 * });
 */
export function useAddUserToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToGroupMutation, AddUserToGroupMutationVariables>(AddUserToGroupDocument, options);
      }
export type AddUserToGroupMutationHookResult = ReturnType<typeof useAddUserToGroupMutation>;
export type AddUserToGroupMutationResult = Apollo.MutationResult<AddUserToGroupMutation>;
export type AddUserToGroupMutationOptions = Apollo.BaseMutationOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>;
export const RemoveUserFromGroupDocument = gql`
    mutation removeUserFromGroup($uuidUser: UUID!, $uuidGroup: UUID!) {
  removeUserFromGroup(uuidUser: $uuidUser, uuidGroup: $uuidGroup)
}
    `;
export type RemoveUserFromGroupMutationFn = Apollo.MutationFunction<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;

/**
 * __useRemoveUserFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromGroupMutation, { data, loading, error }] = useRemoveUserFromGroupMutation({
 *   variables: {
 *      uuidUser: // value for 'uuidUser'
 *      uuidGroup: // value for 'uuidGroup'
 *   },
 * });
 */
export function useRemoveUserFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>(RemoveUserFromGroupDocument, options);
      }
export type RemoveUserFromGroupMutationHookResult = ReturnType<typeof useRemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationResult = Apollo.MutationResult<RemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;
export const BlockUserDocument = gql`
    mutation blockUser($organizerId: Float!, $userUuid: UUID!) {
  addUserToBlacklist(organizerId: $organizerId, uuid: $userUuid)
}
    `;
export type BlockUserMutationFn = Apollo.MutationFunction<BlockUserMutation, BlockUserMutationVariables>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useBlockUserMutation(baseOptions?: Apollo.MutationHookOptions<BlockUserMutation, BlockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(BlockUserDocument, options);
      }
export type BlockUserMutationHookResult = ReturnType<typeof useBlockUserMutation>;
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<BlockUserMutation, BlockUserMutationVariables>;
export const UnblockUserDocument = gql`
    mutation unblockUser($organizerId: Float!, $userUuid: UUID!) {
  removeUserFromBlacklist(organizerId: $organizerId, uuid: $userUuid)
}
    `;
export type UnblockUserMutationFn = Apollo.MutationFunction<UnblockUserMutation, UnblockUserMutationVariables>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useUnblockUserMutation(baseOptions?: Apollo.MutationHookOptions<UnblockUserMutation, UnblockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(UnblockUserDocument, options);
      }
export type UnblockUserMutationHookResult = ReturnType<typeof useUnblockUserMutation>;
export type UnblockUserMutationResult = Apollo.MutationResult<UnblockUserMutation>;
export type UnblockUserMutationOptions = Apollo.BaseMutationOptions<UnblockUserMutation, UnblockUserMutationVariables>;
export const UpdateUsersInGroupDocument = gql`
    mutation updateUsersInGroup($uuidGroup: UUID!, $uuidUsers: [UUID!]!) {
  updateUsersInGroup(uuidGroup: $uuidGroup, uuidUsers: $uuidUsers)
}
    `;
export type UpdateUsersInGroupMutationFn = Apollo.MutationFunction<UpdateUsersInGroupMutation, UpdateUsersInGroupMutationVariables>;

/**
 * __useUpdateUsersInGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUsersInGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersInGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersInGroupMutation, { data, loading, error }] = useUpdateUsersInGroupMutation({
 *   variables: {
 *      uuidGroup: // value for 'uuidGroup'
 *      uuidUsers: // value for 'uuidUsers'
 *   },
 * });
 */
export function useUpdateUsersInGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUsersInGroupMutation, UpdateUsersInGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUsersInGroupMutation, UpdateUsersInGroupMutationVariables>(UpdateUsersInGroupDocument, options);
      }
export type UpdateUsersInGroupMutationHookResult = ReturnType<typeof useUpdateUsersInGroupMutation>;
export type UpdateUsersInGroupMutationResult = Apollo.MutationResult<UpdateUsersInGroupMutation>;
export type UpdateUsersInGroupMutationOptions = Apollo.BaseMutationOptions<UpdateUsersInGroupMutation, UpdateUsersInGroupMutationVariables>;
export const UserDocument = gql`
    query User($uuid: UUID!, $organizerId: Int!) {
  user(uuid: $uuid) {
    createdAt
    updatedAt
    deletedAt
    uuid
    tgId
    username
    first_name
    last_name
    language
    role
    email
    phone
    ordersCount
    activatedOrdersCount
    statsPayments {
      turnover
    }
    gender
    balance(idOrganizer: $organizerId)
    isBlocked(idOrganizer: $organizerId)
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersStatsDocument = gql`
    query usersStats($organizerId: Int!) {
  statsUsers(organizerId: $organizerId) {
    count
    maleCount
    femaleCount
  }
}
    `;

/**
 * __useUsersStatsQuery__
 *
 * To run a query within a React component, call `useUsersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUsersStatsQuery(baseOptions: Apollo.QueryHookOptions<UsersStatsQuery, UsersStatsQueryVariables> & ({ variables: UsersStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersStatsQuery, UsersStatsQueryVariables>(UsersStatsDocument, options);
      }
export function useUsersStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersStatsQuery, UsersStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersStatsQuery, UsersStatsQueryVariables>(UsersStatsDocument, options);
        }
export function useUsersStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersStatsQuery, UsersStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersStatsQuery, UsersStatsQueryVariables>(UsersStatsDocument, options);
        }
export type UsersStatsQueryHookResult = ReturnType<typeof useUsersStatsQuery>;
export type UsersStatsLazyQueryHookResult = ReturnType<typeof useUsersStatsLazyQuery>;
export type UsersStatsSuspenseQueryHookResult = ReturnType<typeof useUsersStatsSuspenseQuery>;
export type UsersStatsQueryResult = Apollo.QueryResult<UsersStatsQuery, UsersStatsQueryVariables>;
export const ActiveUsersStatsDocument = gql`
    query activeUsersStats($organizerId: Int!) {
  statsUsersWithActiveOrders(organizerId: $organizerId) {
    count
    maleCount
    femaleCount
  }
}
    `;

/**
 * __useActiveUsersStatsQuery__
 *
 * To run a query within a React component, call `useActiveUsersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveUsersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveUsersStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useActiveUsersStatsQuery(baseOptions: Apollo.QueryHookOptions<ActiveUsersStatsQuery, ActiveUsersStatsQueryVariables> & ({ variables: ActiveUsersStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveUsersStatsQuery, ActiveUsersStatsQueryVariables>(ActiveUsersStatsDocument, options);
      }
export function useActiveUsersStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveUsersStatsQuery, ActiveUsersStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveUsersStatsQuery, ActiveUsersStatsQueryVariables>(ActiveUsersStatsDocument, options);
        }
export function useActiveUsersStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ActiveUsersStatsQuery, ActiveUsersStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActiveUsersStatsQuery, ActiveUsersStatsQueryVariables>(ActiveUsersStatsDocument, options);
        }
export type ActiveUsersStatsQueryHookResult = ReturnType<typeof useActiveUsersStatsQuery>;
export type ActiveUsersStatsLazyQueryHookResult = ReturnType<typeof useActiveUsersStatsLazyQuery>;
export type ActiveUsersStatsSuspenseQueryHookResult = ReturnType<typeof useActiveUsersStatsSuspenseQuery>;
export type ActiveUsersStatsQueryResult = Apollo.QueryResult<ActiveUsersStatsQuery, ActiveUsersStatsQueryVariables>;
export const UsersControlOrganizerDocument = gql`
    query usersControlOrganizer($filters: UserListFilterInput, $organizerId: Int!) {
  usersControlOrganizer(filters: $filters, organizerId: $organizerId) {
    rows {
      createdAt
      uuid
      first_name
      last_name
      username
      phone
      email
    }
    count
  }
}
    `;

/**
 * __useUsersControlOrganizerQuery__
 *
 * To run a query within a React component, call `useUsersControlOrganizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersControlOrganizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersControlOrganizerQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUsersControlOrganizerQuery(baseOptions: Apollo.QueryHookOptions<UsersControlOrganizerQuery, UsersControlOrganizerQueryVariables> & ({ variables: UsersControlOrganizerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersControlOrganizerQuery, UsersControlOrganizerQueryVariables>(UsersControlOrganizerDocument, options);
      }
export function useUsersControlOrganizerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersControlOrganizerQuery, UsersControlOrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersControlOrganizerQuery, UsersControlOrganizerQueryVariables>(UsersControlOrganizerDocument, options);
        }
export function useUsersControlOrganizerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersControlOrganizerQuery, UsersControlOrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersControlOrganizerQuery, UsersControlOrganizerQueryVariables>(UsersControlOrganizerDocument, options);
        }
export type UsersControlOrganizerQueryHookResult = ReturnType<typeof useUsersControlOrganizerQuery>;
export type UsersControlOrganizerLazyQueryHookResult = ReturnType<typeof useUsersControlOrganizerLazyQuery>;
export type UsersControlOrganizerSuspenseQueryHookResult = ReturnType<typeof useUsersControlOrganizerSuspenseQuery>;
export type UsersControlOrganizerQueryResult = Apollo.QueryResult<UsersControlOrganizerQuery, UsersControlOrganizerQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($uuid: UUID!, $input: UpdateUserInput!) {
  updateUser(uuid: $uuid, input: $input) {
    uuid
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeactivateUserOrganizerDocument = gql`
    mutation deactivateUserOrganizer($uuid: UUID!) {
  deactivateUserOrganizer(uuid: $uuid) {
    uuid
  }
}
    `;
export type DeactivateUserOrganizerMutationFn = Apollo.MutationFunction<DeactivateUserOrganizerMutation, DeactivateUserOrganizerMutationVariables>;

/**
 * __useDeactivateUserOrganizerMutation__
 *
 * To run a mutation, you first call `useDeactivateUserOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserOrganizerMutation, { data, loading, error }] = useDeactivateUserOrganizerMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeactivateUserOrganizerMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateUserOrganizerMutation, DeactivateUserOrganizerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateUserOrganizerMutation, DeactivateUserOrganizerMutationVariables>(DeactivateUserOrganizerDocument, options);
      }
export type DeactivateUserOrganizerMutationHookResult = ReturnType<typeof useDeactivateUserOrganizerMutation>;
export type DeactivateUserOrganizerMutationResult = Apollo.MutationResult<DeactivateUserOrganizerMutation>;
export type DeactivateUserOrganizerMutationOptions = Apollo.BaseMutationOptions<DeactivateUserOrganizerMutation, DeactivateUserOrganizerMutationVariables>;
export const CreateAdminUserDocument = gql`
    mutation createAdminUser($input: CreateAdminUserInput!) {
  createAdminUser(input: $input) {
    uuid
  }
}
    `;
export type CreateAdminUserMutationFn = Apollo.MutationFunction<CreateAdminUserMutation, CreateAdminUserMutationVariables>;

/**
 * __useCreateAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminUserMutation, { data, loading, error }] = useCreateAdminUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminUserMutation, CreateAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminUserMutation, CreateAdminUserMutationVariables>(CreateAdminUserDocument, options);
      }
export type CreateAdminUserMutationHookResult = ReturnType<typeof useCreateAdminUserMutation>;
export type CreateAdminUserMutationResult = Apollo.MutationResult<CreateAdminUserMutation>;
export type CreateAdminUserMutationOptions = Apollo.BaseMutationOptions<CreateAdminUserMutation, CreateAdminUserMutationVariables>;
export const WithdrawalsListDocument = gql`
    query withdrawalsList($filter: WithdrawalListFilterInput, $idOrganizer: Int!, $from: WithdrawalFromEnum) {
  withdrawalsByOrganizer(idOrganizer: $idOrganizer, filters: $filter, from: $from) {
    count
    rows {
      ...WithdrawalFields
      paymentAccount {
        ...PaymentAccountFields
      }
    }
  }
}
    ${WithdrawalFieldsFragmentDoc}
${PaymentAccountFieldsFragmentDoc}`;

/**
 * __useWithdrawalsListQuery__
 *
 * To run a query within a React component, call `useWithdrawalsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithdrawalsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      idOrganizer: // value for 'idOrganizer'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useWithdrawalsListQuery(baseOptions: Apollo.QueryHookOptions<WithdrawalsListQuery, WithdrawalsListQueryVariables> & ({ variables: WithdrawalsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WithdrawalsListQuery, WithdrawalsListQueryVariables>(WithdrawalsListDocument, options);
      }
export function useWithdrawalsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WithdrawalsListQuery, WithdrawalsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WithdrawalsListQuery, WithdrawalsListQueryVariables>(WithdrawalsListDocument, options);
        }
export function useWithdrawalsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WithdrawalsListQuery, WithdrawalsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WithdrawalsListQuery, WithdrawalsListQueryVariables>(WithdrawalsListDocument, options);
        }
export type WithdrawalsListQueryHookResult = ReturnType<typeof useWithdrawalsListQuery>;
export type WithdrawalsListLazyQueryHookResult = ReturnType<typeof useWithdrawalsListLazyQuery>;
export type WithdrawalsListSuspenseQueryHookResult = ReturnType<typeof useWithdrawalsListSuspenseQuery>;
export type WithdrawalsListQueryResult = Apollo.QueryResult<WithdrawalsListQuery, WithdrawalsListQueryVariables>;
export const WithdrawalRequestDocument = gql`
    mutation withdrawalRequest($input: WithdrawalOrganizerInput!) {
  requestWithdrawal(input: $input)
}
    `;
export type WithdrawalRequestMutationFn = Apollo.MutationFunction<WithdrawalRequestMutation, WithdrawalRequestMutationVariables>;

/**
 * __useWithdrawalRequestMutation__
 *
 * To run a mutation, you first call `useWithdrawalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawalRequestMutation, { data, loading, error }] = useWithdrawalRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawalRequestMutation(baseOptions?: Apollo.MutationHookOptions<WithdrawalRequestMutation, WithdrawalRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WithdrawalRequestMutation, WithdrawalRequestMutationVariables>(WithdrawalRequestDocument, options);
      }
export type WithdrawalRequestMutationHookResult = ReturnType<typeof useWithdrawalRequestMutation>;
export type WithdrawalRequestMutationResult = Apollo.MutationResult<WithdrawalRequestMutation>;
export type WithdrawalRequestMutationOptions = Apollo.BaseMutationOptions<WithdrawalRequestMutation, WithdrawalRequestMutationVariables>;
export const CancelWithdrawalDocument = gql`
    mutation cancelWithdrawal($idOrganizer: Int!, $uuid: UUID!) {
  cancelWithdrawal(idOrganizer: $idOrganizer, uuid: $uuid)
}
    `;
export type CancelWithdrawalMutationFn = Apollo.MutationFunction<CancelWithdrawalMutation, CancelWithdrawalMutationVariables>;

/**
 * __useCancelWithdrawalMutation__
 *
 * To run a mutation, you first call `useCancelWithdrawalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWithdrawalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWithdrawalMutation, { data, loading, error }] = useCancelWithdrawalMutation({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCancelWithdrawalMutation(baseOptions?: Apollo.MutationHookOptions<CancelWithdrawalMutation, CancelWithdrawalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelWithdrawalMutation, CancelWithdrawalMutationVariables>(CancelWithdrawalDocument, options);
      }
export type CancelWithdrawalMutationHookResult = ReturnType<typeof useCancelWithdrawalMutation>;
export type CancelWithdrawalMutationResult = Apollo.MutationResult<CancelWithdrawalMutation>;
export type CancelWithdrawalMutationOptions = Apollo.BaseMutationOptions<CancelWithdrawalMutation, CancelWithdrawalMutationVariables>;
export const namedOperations = {
  Query: {
    me: 'me',
    baseTariffsList: 'baseTariffsList',
    baseTariff: 'baseTariff',
    allowedUserGroupsForBaseTariff: 'allowedUserGroupsForBaseTariff',
    botsList: 'botsList',
    botsListCount: 'botsListCount',
    botsByOrganizer: 'botsByOrganizer',
    financeStats: 'financeStats',
    organizerBalances: 'organizerBalances',
    purchaseStats: 'purchaseStats',
    paymentsList: 'paymentsList',
    paymentsCount: 'paymentsCount',
    financeListsCount: 'financeListsCount',
    ordersList: 'ordersList',
    ordersCount: 'ordersCount',
    eventInviteTexts: 'eventInviteTexts',
    organizersListCount: 'organizersListCount',
    organizersList: 'organizersList',
    organizer: 'organizer',
    organizerFiles: 'organizerFiles',
    organizerFeatures: 'organizerFeatures',
    organizerShortcodes: 'organizerShortcodes',
    paymentAccountsList: 'paymentAccountsList',
    eventsCount: 'eventsCount',
    eventShortcode: 'eventShortcode',
    eventsList: 'eventsList',
    event: 'event',
    baseEventTexts: 'baseEventTexts',
    addressesList: 'addressesList',
    address: 'address',
    referralOrganizer: 'referralOrganizer',
    organizersReferralList: 'organizersReferralList',
    referralsOrganizerList: 'referralsOrganizerList',
    referralFinanceStats: 'referralFinanceStats',
    referralFinanceListsCount: 'referralFinanceListsCount',
    ordersReferralOrganizerList: 'ordersReferralOrganizerList',
    subTariffsList: 'subTariffsList',
    subTariff: 'subTariff',
    terminal: 'terminal',
    terminalsByProduct: 'terminalsByProduct',
    terminals: 'terminals',
    terminalsCount: 'terminalsCount',
    manualTerminalByOrganizer: 'manualTerminalByOrganizer',
    manualProductTerminal: 'manualProductTerminal',
    usersList: 'usersList',
    usersBlockedList: 'usersBlockedList',
    usersRegisteredList: 'usersRegisteredList',
    usersByGroupList: 'usersByGroupList',
    usersRegisteredCount: 'usersRegisteredCount',
    usersBlockedCount: 'usersBlockedCount',
    userGroups: 'userGroups',
    User: 'User',
    usersStats: 'usersStats',
    activeUsersStats: 'activeUsersStats',
    usersControlOrganizer: 'usersControlOrganizer',
    withdrawalsList: 'withdrawalsList'
  },
  Mutation: {
    login: 'login',
    refresh: 'refresh',
    createBaseTariff: 'createBaseTariff',
    updateBaseTariff: 'updateBaseTariff',
    duplicateBaseTariff: 'duplicateBaseTariff',
    removeBaseTariff: 'removeBaseTariff',
    updateUsersBaseTariff: 'updateUsersBaseTariff',
    botInfoByToken: 'botInfoByToken',
    createBot: 'createBot',
    disableBot: 'disableBot',
    activateBot: 'activateBot',
    removeBot: 'removeBot',
    addBotToOrganizer: 'addBotToOrganizer',
    removeBotFromOrganizer: 'removeBotFromOrganizer',
    uploadImages: 'uploadImages',
    giveOrders: 'giveOrders',
    createInvitesOrders: 'createInvitesOrders',
    exportOrdersCSV: 'exportOrdersCSV',
    disableOrder: 'disableOrder',
    transferOrderToProduct: 'transferOrderToProduct',
    updateAdminOrganizerFeatures: 'updateAdminOrganizerFeatures',
    updateOrganizer: 'updateOrganizer',
    createOrganizer: 'createOrganizer',
    updateOrganizerSynonyms: 'updateOrganizerSynonyms',
    archiveEvent: 'archiveEvent',
    publishEvent: 'publishEvent',
    removeEvent: 'removeEvent',
    updateEvent: 'updateEvent',
    updateEventInviteTexts: 'updateEventInviteTexts',
    duplicateProduct: 'duplicateProduct',
    createEvent: 'createEvent',
    updateEventMainInfo: 'updateEventMainInfo',
    createAddress: 'createAddress',
    updateAddress: 'updateAddress',
    removeAddress: 'removeAddress',
    createReferralOrganizer: 'createReferralOrganizer',
    renameReferralOrganizer: 'renameReferralOrganizer',
    removeReferralOrganizer: 'removeReferralOrganizer',
    createTariff: 'createTariff',
    updateTariff: 'updateTariff',
    removeTariff: 'removeTariff',
    updateProductTariffs: 'updateProductTariffs',
    createTerminal: 'createTerminal',
    updateTerminal: 'updateTerminal',
    updateProductTerminals: 'updateProductTerminals',
    removeTerminal: 'removeTerminal',
    removeManualTerminalFromProduct: 'removeManualTerminalFromProduct',
    addManualTerminalToProduct: 'addManualTerminalToProduct',
    updateManualTerminalText: 'updateManualTerminalText',
    submitManualInvoice: 'submitManualInvoice',
    cancelManualInvoice: 'cancelManualInvoice',
    addGroupUsers: 'addGroupUsers',
    updateGroupUsersName: 'updateGroupUsersName',
    removeGroupUsers: 'removeGroupUsers',
    addUserToGroup: 'addUserToGroup',
    removeUserFromGroup: 'removeUserFromGroup',
    blockUser: 'blockUser',
    unblockUser: 'unblockUser',
    updateUsersInGroup: 'updateUsersInGroup',
    updateUser: 'updateUser',
    deactivateUserOrganizer: 'deactivateUserOrganizer',
    createAdminUser: 'createAdminUser',
    withdrawalRequest: 'withdrawalRequest',
    cancelWithdrawal: 'cancelWithdrawal'
  },
  Fragment: {
    JwtFields: 'JwtFields',
    BaseTariffFields: 'BaseTariffFields',
    BotFields: 'BotFields',
    SupplierInfoFields: 'SupplierInfoFields',
    OrganizerAdminFeatureFields: 'OrganizerAdminFeatureFields',
    OrganizerFields: 'OrganizerFields',
    PaymentAccountFields: 'PaymentAccountFields',
    ReferralOrganizerFields: 'ReferralOrganizerFields',
    SubTariffFields: 'SubTariffFields',
    TerminalFields: 'TerminalFields',
    ManualTerminalFields: 'ManualTerminalFields',
    UserGroupFields: 'UserGroupFields',
    UserListFields: 'UserListFields',
    WithdrawalFields: 'WithdrawalFields'
  }
}

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BaseGraphModel": [
      "BaseTariffType",
      "BotType",
      "ManualTerminalType",
      "OrderInviteType",
      "OrderType",
      "OrganizerType",
      "PaymentAccountType",
      "PaymentProofType",
      "PaymentType",
      "ProductAddressType",
      "ProductType",
      "ReferralOrganizerType",
      "SceneButtonType",
      "SceneType",
      "SubTariffType",
      "TariffType",
      "TerminalType",
      "UserBalanceType",
      "UserBalanceWithdrawalType",
      "UserGroupType",
      "UserType",
      "WithdrawalType"
    ],
    "NodeIdType": [
      "OrderType",
      "OrganizerType"
    ],
    "NodeUuidType": [
      "BaseTariffType",
      "ManualTerminalType",
      "OrderInviteType",
      "PaymentAccountType",
      "PaymentProofType",
      "PaymentType",
      "ProductAddressType",
      "ProductType",
      "SceneButtonType",
      "SceneType",
      "SubTariffType",
      "TariffType",
      "TerminalType",
      "UserBalanceType",
      "UserBalanceWithdrawalType",
      "UserGroupType",
      "UserType",
      "WithdrawalType"
    ]
  }
};
      export default result;
    