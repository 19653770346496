import { useEffect } from 'react';

import {
  PublishEventMutation,
  PublishEventMutationVariables,
  usePublishEventMutation,
} from '@/apolloGenerated';
import {
  ApolloErrorService,
  BaseModelAction,
  BaseModelActionParams,
} from '@/shared';
import { ApolloError } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

type UsePublishEventParams = BaseModelActionParams<PublishEventMutation>;

type UsePublishEvent = BaseModelAction<
  PublishEventMutation,
  PublishEventMutationVariables,
  UsePublishEventParams
>;
export const usePublishEvent: UsePublishEvent = ({
  notify,
  onError,
  onSuccess,
  resetCache,
}) => {
  const [publishEvent, result] = usePublishEventMutation();
  const { client, data, error } = result;

  const handlerAction: ReturnType<UsePublishEvent>[0]['handler'] = ({ uuid }) =>
    publishEvent({
      variables: {
        uuid,
      },
    });

  const handlerResetCache: ReturnType<UsePublishEvent>[0]['handlerResetCache'] =
    () => {
      client.cache.evict({ fieldName: 'productsList' });
      client.cache.evict({ fieldName: 'productsCount' });
      client.cache.evict({
        args: { uuid: result.data?.activateProduct.uuid },
        fieldName: 'product',
      });
    };

  const handlerNotify: ReturnType<UsePublishEvent>[0]['handlerNotify'] = () => {
    toaster.success({ title: 'Событие успешно создано' });
  };

  const handlerSuccess = (data: PublishEventMutation) => {
    if (resetCache) {
      handlerResetCache();
    }
    if (notify) {
      handlerNotify();
    }
    onSuccess && onSuccess(data);
  };

  const handleError = (error: ApolloError) => {
    onError && onError(error);
  };

  useEffect(() => {
    if (data) {
      handlerSuccess(data);
    }
    if (error) {
      handleError(error);
    }
  }, [data, error]);

  return [
    { handler: handlerAction, handlerNotify, handlerResetCache },
    {
      data,
      validationErrors: ApolloErrorService.getFirstError(error?.graphQLErrors)
        ?.extensions?.validationErrors,
    },
    result,
  ];
};
