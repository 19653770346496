import { useEffect } from 'react';

import {
  CreateAddressMutation,
  CreateAddressMutationVariables,
  useCreateAddressMutation,
} from '@/apolloGenerated';
import {
  ApolloErrorService,
  BaseModelAction,
  BaseModelActionParams,
  useOrganizerId,
} from '@/shared';
import { ApolloError } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

type UseCreateAddressParams = BaseModelActionParams<CreateAddressMutation>;

type UseCreateAddress = BaseModelAction<
  CreateAddressMutation,
  Pick<CreateAddressMutationVariables, 'input'>,
  UseCreateAddressParams
>;
export const useCreateAddress: UseCreateAddress = ({
  idOrganizer: idOrganizerParam,
  notify,
  onError,
  onSuccess,
  resetCache,
}) => {
  const idCurrentOrganizer = useOrganizerId()!;
  const idOrganizer = idOrganizerParam || idCurrentOrganizer;

  const [createAddress, result] = useCreateAddressMutation();
  const { client, data, error } = result;

  const handlerAction: ReturnType<UseCreateAddress>[0]['handler'] = ({
    input,
  }) =>
    createAddress({
      variables: {
        input,
        organizerId: idOrganizer,
      },
    });

  const handlerResetCache: ReturnType<UseCreateAddress>[0]['handlerResetCache'] =
    () => {
      client.cache.evict({ fieldName: 'addressesList' });
    };

  const handlerNotify: ReturnType<UseCreateAddress>[0]['handlerNotify'] =
    () => {
      toaster.success({ title: 'Адрес успешно создан' });
    };

  const handlerSuccess = (data: CreateAddressMutation) => {
    if (resetCache) {
      handlerResetCache();
    }
    if (notify) {
      handlerNotify();
    }
    onSuccess && onSuccess(data);
  };

  const handleError = (error: ApolloError) => {
    onError && onError(error);
  };

  useEffect(() => {
    if (data) {
      handlerSuccess(data);
    }
    if (error) {
      handleError(error);
    }
  }, [data, error]);

  return [
    { handler: handlerAction, handlerNotify, handlerResetCache },
    {
      data,
      validationErrors: ApolloErrorService.getFirstError(error?.graphQLErrors)
        ?.extensions?.validationErrors,
    },
    result,
  ];
};
