import React, { FC, useState } from 'react';

import {
  useActiveUsersStatsQuery,
  UserGroupType,
  useUserGroupsQuery,
  useUsersBlockedCountQuery,
  useUsersRegisteredCountQuery,
  useUsersStatsQuery,
} from '@/apolloGenerated';
import { useModal, useOrganizerId } from '@/shared';
import {
  Button,
  EditDenseIcon,
  LockDenseIcon,
  PlusIcon,
  TabItem,
  Tabs,
} from '@letsdance/ui-kit';

export enum ContactTabsEnum {
  All = 'All',
  Active = 'Active',
  Registered = 'Registered',
  Blocked = 'Blocked',
}

export type ContactTabs = ContactTabsEnum & string;

export type EditedGroup = Pick<UserGroupType, 'name' | 'uuid'>;

export interface ContactListTabsProps {
  initTab?: ContactTabs;
  onChange(tab: ContactTabs): void;
  hideCreateGroupButton?: boolean;
  hideBlocked?: boolean;
  hideRegistered?: boolean;
  hideAll?: boolean;
  hideActive?: boolean;
  hideGroups?: boolean;
  onEditGroup?(group: EditedGroup): void;
}

export const ContactListTabs: FC<ContactListTabsProps> = ({
  hideActive,
  hideAll,
  hideBlocked,
  hideCreateGroupButton,
  hideGroups,
  hideRegistered,
  initTab,
  onChange,
  onEditGroup,
}) => {
  const organizerId = useOrganizerId()!;
  const openCreateGroupModal = useModal('createUserGroup');
  const [tab, setTab] = useState<ContactTabs>(initTab || ContactTabsEnum.All);

  const { data: statsData } = useUsersStatsQuery({
    skip: !organizerId || hideAll,
    variables: { organizerId },
  });
  const { data: activeStatsData } = useActiveUsersStatsQuery({
    skip: !organizerId || hideActive,
    variables: { organizerId },
  });
  const { data: blockedCountData } = useUsersBlockedCountQuery({
    skip: !organizerId || hideBlocked,
    variables: { organizerId },
  });
  const { data: registeredCountData } = useUsersRegisteredCountQuery({
    skip: !organizerId || hideRegistered,
    variables: { organizerId },
  });
  const { data: usersGroupData } = useUserGroupsQuery({
    skip: !organizerId || hideGroups,
    variables: { filters: {}, idOrganizer: organizerId },
  });

  const allCount = statsData?.statsUsers.count || 0;
  const activeCount = activeStatsData?.statsUsersWithActiveOrders.count || 0;
  const blockedCount = blockedCountData?.blacklist.count || 0;
  const registeredCount = registeredCountData?.users.count || 0;

  const handleChange = (tab: ContactTabs) => {
    onChange(tab);
    setTab(tab);
  };

  return (
    <div
      className="flex-align-center justify-space-between
    ">
      <Tabs initValue={tab} onChange={handleChange}>
        {!hideAll && (
          <TabItem value={ContactTabsEnum.All} label={`Все (${allCount})`} />
        )}
        {!hideRegistered && (
          <TabItem value={ContactTabsEnum.Registered}>
            Зарег. ({registeredCount})
          </TabItem>
        )}
        {!hideActive && (
          <TabItem
            value={ContactTabsEnum.Active}
            label={`Активные (${activeCount})`}
          />
        )}
        {!hideBlocked && (
          <TabItem
            style={{ alignItems: 'center', display: 'flex', gap: 4 }}
            value={ContactTabsEnum.Blocked}>
            <LockDenseIcon width={16} height={16} />
            Заблок. ({blockedCount})
          </TabItem>
        )}
        {(hideGroups ? [] : usersGroupData?.userGroups.rows || []).map((el) => (
          <TabItem
            key={el.uuid}
            style={{ alignItems: 'center', display: 'flex', gap: 8 }}
            value={el.uuid}>
            {el.name} ({el.usersCount})
            {onEditGroup && (
              <EditDenseIcon
                width={16}
                height={16}
                onClick={() => onEditGroup({ name: el.name, uuid: el.uuid })}
              />
            )}
          </TabItem>
        ))}
      </Tabs>
      {!hideCreateGroupButton && (
        <Button
          variant="text"
          prependIcon={PlusIcon}
          size="large"
          onClick={() => openCreateGroupModal()}>
          Добавить группу
        </Button>
      )}
    </div>
  );
};
