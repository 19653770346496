import React, { FC, useEffect } from 'react';

import { useRemoveAddressMutation } from '@/apolloGenerated';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface RemoveAddressModalProps {
  uuid: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    removeAddress: RemoveAddressModalProps;
  }
}
export const RemoveAddressModal: FC<
  WithCloseModal<RemoveAddressModalProps>
> = ({ onClose, onSuccess, uuid }): JSX.Element => {
  const [removeAddress, { client, data, error, loading }] =
    useRemoveAddressMutation();

  const handleSubmit = () => {
    removeAddress({ variables: { uuid } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Адрес успешно удалён',
      });
      client.cache.evict({ fieldName: 'addressesList' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка удаления. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Удалить место проведения?"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button
                color="danger"
                fullWidth
                onClick={handleSubmit}
                disabled={loading}>
                Удалить
              </Button>
            </div>
          }
        />
      }
    />
  );
};
