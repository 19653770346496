import React, { FC, useEffect } from 'react';

import { useActivateBotMutation } from '@/apolloGenerated';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface ActivateBotModalProps {
  id: number;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    activateBot: ActivateBotModalProps;
  }
}
export const ActivateBotModal: FC<WithCloseModal<ActivateBotModalProps>> = ({
  id,
  onClose,
  onSuccess,
}): JSX.Element => {
  const [activateBot, { client, data, error, loading }] =
    useActivateBotMutation();

  const handleSubmit = () => {
    activateBot({ variables: { id } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Бот успешно активирован',
      });
      client.cache.evict({ fieldName: 'botsList' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка активации бота. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Активировать бота?"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button fullWidth onClick={handleSubmit} disabled={loading}>
                Активировать
              </Button>
            </div>
          }
        />
      }
    />
  );
};
