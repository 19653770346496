import React, { FC, useEffect } from 'react';

import { ProductAddressInput, ProductAddressType } from '@/apolloGenerated';
import { ErrorNestedKeys, FormSection, useImageUploader } from '@/shared';
import {
  EditorTextField,
  FileSize,
  ImageUploader,
  Spacer,
  TextField,
  useForm,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface AddressFormProps {
  initValues: ProductAddressType;
  onChange(val: ProductAddressInput): void;
  errors?: Record<ErrorNestedKeys<ProductAddressInput>, string[]>;
  readonly?: boolean;
}
export const AddressForm: FC<AddressFormProps> = ({
  errors,
  initValues,
  onChange,
  readonly,
}) => {
  const uploadImage = useImageUploader();
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<ProductAddressInput>(console.log, initValues);

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div className={styles.addressForm}>
      <FormSection title="Основная информация">
        <div className={styles.addressForm__mainInfo}>
          <ImageUploader
            initValue={values.img}
            onChange={(val) => setBatchValues({ img: val })}
            error={errors?.img}
            uploadImageCallback={uploadImage}
            maxFileSize={{
              size: 10,
              unit: FileSize.MB,
            }}
          />
          <div>
            <TextField
              label="Адрес"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.address}
              name="address"
              initValue={values.address!}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <EditorTextField
              label="Описание"
              disabled={readonly}
              error={errors?.description}
              initValue={values.description!}
              onChange={(e) => setBatchValues({ description: e })}
            />
          </div>
        </div>
      </FormSection>
    </div>
  );
};
