import { FC, useState } from 'react';

import { TerminalInput, TerminalType } from '@/apolloGenerated';
import {
  ActionButton,
  ActionButtons,
  ApolloErrorService,
  Container,
} from '@/shared';
import {
  getTerminalInitState,
  TerminalForm,
  useCreateTerminal,
} from '@entities/Terminal';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export interface TerminalCreateViewProps {
  params: {
    idOrganizer: string;
  };
}

export const TerminalCreateView: FC<TerminalCreateViewProps> = ({ params }) => {
  const [createTerminal, { client, error, loading }] = useCreateTerminal();
  const [data, setData] = useState<TerminalInput>();

  const onCreate = async () => {
    if (!data) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    createTerminal({
      idOrganizer: +params.idOrganizer,
      input: data,
    });
    client.cache.evict({ fieldName: 'terminalsList' });
  };

  const actions: ActionButton[] = [
    {
      handler: () => onCreate(),
      label: 'Создать',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading} />
      <Container slim>
        <Head title="Новый терминал" />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        <TerminalForm
          initValues={getTerminalInitState() as TerminalType}
          onChange={setData}
          errors={
            ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
              ?.validationErrors
          }
        />
      </Container>
    </>
  );
};
