import React, { FC } from 'react';
import cx from 'classnames';

import { Maybe } from '@/apolloGenerated';
import { Typography, useMediaQuery } from '@letsdance/ui-kit';

export interface TableCellItemValueProps {
  label: string | JSX.Element;
  value?: Maybe<number | string | JSX.Element>;
  onClick?(): void;
}
export const TableCellLabelValue: FC<TableCellItemValueProps> = ({
  label,
  onClick,
  value,
}) => {
  const { isMobileLarge, isTablet } = useMediaQuery();

  return (
    <div className={cx(onClick && 'cursor-pointer')} onClick={onClick}>
      <Typography
        variant={isMobileLarge || isTablet ? 'body-12' : 'body-14'}
        color="on-surface-primary-1">
        {label}
      </Typography>
      {value && (
        <Typography variant="body-12" color="on-surface-primary-2">
          {value}
        </Typography>
      )}
    </div>
  );
};

export const renderCellItemValue = (args: TableCellItemValueProps) => (
  <TableCellLabelValue {...args} />
);
