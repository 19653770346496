import { FC, useState } from 'react';

import { ListFilterWrapper, SearchTextField } from '@/shared';

export interface ContactListFilterData {
  search: string;
}
export interface ContactListFilterProps {
  initValue?: ContactListFilterData;
  onChange(filter: ContactListFilterData): void;
}
export const ContactListFilter: FC<ContactListFilterProps> = ({
  initValue,
  onChange,
}) => {
  const [filter, setFilter] = useState<ContactListFilterData>(
    initValue || { search: '' },
  );

  const handleChange = function <T extends keyof ContactListFilterData>(
    key: T,
    value: ContactListFilterData[T],
  ) {
    setFilter((state) => ({ ...state, [key]: value }));
    onChange({ ...filter, [key]: value });
  };

  return (
    <ListFilterWrapper>
      <SearchTextField
        name="search"
        placeholder="ID, фамилия, соцсети, почта"
        onChange={(e) => handleChange('search', e.target.value)}
        onClear={() => handleChange('search', '')}
      />
    </ListFilterWrapper>
  );
};
