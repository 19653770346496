import React, { FC, useEffect, useState } from 'react';

import { BotInput } from '@/apolloGenerated';
import { NestedKeys, Spacer, TextField } from '@letsdance/ui-kit';

export interface BotFormProps {
  errors?: Record<NestedKeys<BotInput>, string[]>;
  onChange(data: BotInput): void;
}

export const BotForm: FC<BotFormProps> = ({ errors, onChange }) => {
  const [token, setToken] = useState<string>('');
  const [name, setName] = useState<string>('');

  useEffect(() => {
    onChange({ name, token });
  }, [token, name]);

  return (
    <div>
      <TextField
        label="Название"
        size="large"
        fullWidth
        name="name"
        error={errors?.name}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Spacer size={8} />
      <TextField
        label="Telegram Bot токен"
        size="large"
        fullWidth
        name="token"
        error={errors?.token}
        value={token}
        onChange={(e) => setToken(e.target.value)}
      />
    </div>
  );
};
