import { create } from 'zustand';

import { StepperStoreType } from './types';

import { StepperManager } from './StepperManager';

export const createStepperStore = <Key>(steps: Key[]) => {
  const stepperManager = new StepperManager<Key>();

  return create<StepperStoreType<Key>>()((set, get) => ({
    ...stepperManager.initializeState(steps),

    nextStep() {
      const { currentStep, steps } = get();

      set(stepperManager.getNextStepState(steps, currentStep.key));
    },

    prevStep() {
      const { currentStep, steps } = get();

      set(stepperManager.getPrevStepState(steps, currentStep.key));
    },

    resetStepper() {
      set(stepperManager.initializeState(steps));
    },

    selectStep(step: Key) {
      const { steps } = get();

      set(stepperManager.getSelectStepState(steps, step));
    },
  }));
};
