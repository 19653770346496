import React, { FC, useEffect, useState } from 'react';

import {
  TransferToProductOrderForm,
  TransferToProductOrderInput,
} from '@entities/Order';
import { useTransferToProductOrder } from '@features/Orders/libs/hooks';
import {
  Button,
  Dialog,
  DialogFooter,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface TransferToProductOrderModalProps {
  id: number;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    transferOrderToProduct: TransferToProductOrderModalProps;
  }
}
export const TransferToProductOrderModal: FC<
  WithCloseModal<TransferToProductOrderModalProps>
> = ({ id, onClose, onSuccess }): JSX.Element => {
  const { data, handleTransfer, loading } = useTransferToProductOrder();
  const [transferData, setTransferData] = useState<TransferToProductOrderInput>(
    {
      notify: {
        sendNotifyToEmail: false,
      },
      productUuid: '',
    },
  );
  const handleSubmit = () => {
    handleTransfer({ orderId: +id, ...transferData });
  };

  useEffect(() => {
    if (data) {
      onClose();
      onSuccess && onSuccess();
    }
  }, [data]);

  return (
    <Dialog
      title="Перенос билета"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button fullWidth onClick={handleSubmit} disabled={loading}>
                Подтвердить
              </Button>
            </div>
          }
        />
      }>
      <TransferToProductOrderForm
        initValues={transferData}
        onChange={setTransferData}
      />
    </Dialog>
  );
};
