import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';
import { useMediaQuery } from 'react-responsive';

import {
  ProductInput,
  ProductType,
  StatusEnum,
  useEventQuery,
} from '@/apolloGenerated';
import {
  ActionButtons,
  ApolloErrorService,
  Container,
  DisplayWrapper,
  FloatDriver,
  FormSection,
  ScrollXWrapper,
  useModal,
} from '@/shared';
import { RouterName } from '@app/router';
import { EventForm, useUpdateEvent } from '@entities/Event';
import { EventControl } from '@features/Event';
import { EventTariffs } from '@features/Tariff';
import {
  Divider,
  LoaderOverlay,
  Maybe,
  Spacer,
  TabItem,
  Tabs,
  toaster,
} from '@letsdance/ui-kit';
import { useFeatureFlags } from '@shared/libs/hooks/useFeatureFlags';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';
import { GiveOrderUsers, InviteOrderUsers } from '@widgets/Purchases';
import { ReferralEvent } from '@widgets/Referral';
import {
  EventManualTerminalController,
  EventTerminalsController,
} from '@widgets/Terminal';

export interface EventEditViewProps {
  params: {
    uuid: string;
  };
  query: {
    readonly?: boolean;
  };
}

enum EventTabs {
  Info = 'info',
  Tariffs = 'tariffs',
  Referral = 'referral',
  Terminals = 'Terminals',
}

export const EventEditView: FC<EventEditViewProps> = ({
  params: { uuid },
  query,
}) => {
  const { createReferral } = useFeatureFlags();
  const [tab, setTab] = useState<EventTabs>(EventTabs.Info);
  const openPublishModal = useModal('publishEvent');
  const { navigate } = useNavigate();
  const [updateEvent, { error, loading: saveLoading }] = useUpdateEvent();
  const [eventData, setEventData] = useState<ProductInput>();
  const [createOrderData, setCreateOrderData] = useState<Maybe<string>>(null);
  const [inviteData, setInviteData] = useState<Maybe<string>>(null);

  const { data, loading, refetch } = useEventQuery({
    variables: {
      uuid,
    },
  });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const product = data?.product;
  const isDraft = product?.status === StatusEnum.Draft;
  const isArchive = product?.status === StatusEnum.Archive;
  const isReadonly = !!query.readonly || isArchive;

  const onClose = () => {
    navigate(RouterName.Events);
  };
  const onPublish = () => {
    openPublishModal({ uuid });
  };
  const onSave = async () => {
    if (!eventData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateEvent({
      input: eventData,
      uuid,
    });
    await refetch();
  };

  const actions: ActionButton[] = isReadonly
    ? [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: () => navigate(RouterName.EventEdit, { uuid }),
          label: 'Редактировать',
        },
      ]
    : [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: onSave,
          label: isDraft ? 'Сохранить черновик' : 'Сохранить изменения',
        },
        ...(isDraft
          ? [
              {
                handler: onPublish,
                label: 'Опубликовать',
              },
            ]
          : []),
      ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head
          title={product?.name || ''}
          onBack={onClose}
          rightSlot={
            product && isMobile ? (
              <EventControl
                event={product as ProductType}
                status={product?.status}
                hideEdit
                onInvite={() => setInviteData(product.uuid)}
                onCreateOrder={() => setCreateOrderData(product.uuid)}
              />
            ) : null
          }
        />
        <ScrollXWrapper>
          <Tabs initValue={tab} onChange={setTab}>
            <TabItem value={EventTabs.Info} label="Информация" />
            <TabItem value={EventTabs.Tariffs} label="Тарифы" />
            {createReferral && (
              <TabItem value={EventTabs.Referral} label="Реферальные ссылки" />
            )}
            <TabItem value={EventTabs.Terminals} label="Терминалы оплаты" />
          </Tabs>
        </ScrollXWrapper>
      </Container>
      <Divider />
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={8} />
      <Container slim>
        {data && (
          <>
            <DisplayWrapper visible={tab === EventTabs.Info}>
              <EventForm
                initValues={data.product as ProductType}
                onChange={setEventData}
                errors={
                  ApolloErrorService.getFirstError(error?.graphQLErrors)
                    ?.extensions?.validationErrors
                }
                readonly={isReadonly}
              />
            </DisplayWrapper>
            <DisplayWrapper visible={tab === EventTabs.Tariffs}>
              <EventTariffs eventUuid={uuid} />
            </DisplayWrapper>
            <DisplayWrapper visible={tab === EventTabs.Referral}>
              <ReferralEvent
                eventUuid={data.product.uuid}
                disabled={isReadonly}
              />
            </DisplayWrapper>
            <DisplayWrapper visible={tab === EventTabs.Terminals}>
              <FormSection title="Терминалы оплаты">
                <EventTerminalsController
                  productUuid={uuid}
                  disabled={isReadonly}
                />
                <Spacer size={20} />
                <EventManualTerminalController
                  productUuid={uuid}
                  disabled={isReadonly}
                />
              </FormSection>
            </DisplayWrapper>
          </>
        )}
      </Container>
      <FloatDriver
        isOpen={!!createOrderData}
        onClose={() => setCreateOrderData(null)}>
        <GiveOrderUsers
          productUuid={createOrderData}
          onCancel={() => setCreateOrderData(null)}
        />
      </FloatDriver>
      <FloatDriver isOpen={!!inviteData} onClose={() => setInviteData(null)}>
        <InviteOrderUsers
          productUuid={inviteData}
          onCancel={() => setInviteData(null)}
        />
      </FloatDriver>
    </>
  );
};
