import {
  BaseTextsKeysEnum,
  ProductInput,
  ProductType,
  useBaseEventTextsQuery,
} from '@/apolloGenerated';

export const getEventInput = ({
  description,
  endDate,
  featureFlags: {
    bonusPay,
    durationBeforeStartForRefundOrder,
    durationBeforeStartForTransferToProductOrder,
    durationBeforeStartForTransferToUserOrder,
    refundOrder,
    repeatBuyOrder,
    transferFromProductOrder,
    transferToProductOrder,
    transferToUserOrder,
  },
  img,
  name,
  query,
  salesStartedDate,
  salesStartedText,
  startedDate,
  successBuyText,
  successPairBuyText,
  tariffsEmptyText,
  tariffsText,
  tariffsUnavailableText,
  transferOrderToUserSuccessText,
}: ProductInput): ProductInput => ({
  description,
  endDate,
  featureFlags: {
    bonusPay,
    durationBeforeStartForRefundOrder,
    durationBeforeStartForTransferToProductOrder,
    durationBeforeStartForTransferToUserOrder,
    refundOrder,
    repeatBuyOrder,
    transferFromProductOrder,
    transferToProductOrder,
    transferToUserOrder,
  },
  img,
  name,
  query,
  salesStartedDate,
  salesStartedText,
  startedDate,
  successBuyText,
  successPairBuyText,
  tariffsEmptyText,
  tariffsText,
  tariffsUnavailableText,
  transferOrderToUserSuccessText,
});

export const useEventInitState = () => {
  const { data, loading } = useBaseEventTextsQuery();

  const getByKey = (key: BaseTextsKeysEnum) =>
    data?.baseTexts.find((el) => el.key === key)?.text;

  return {
    getEventInitState: (): ProductInput & Pick<ProductType, 'tariffs'> => ({
      description: '',
      endDate: new Date(),
      featureFlags: {
        bonusPay: true,
        durationBeforeStartForRefundOrder: 'P0D',
        durationBeforeStartForTransferToProductOrder: 'P0D',
        durationBeforeStartForTransferToUserOrder: 'P0D',
        refundOrder: true,
        repeatBuyOrder: true,
        transferFromProductOrder: true,
        transferToProductOrder: true,
        transferToUserOrder: true,
      },
      img: '',
      name: '',
      query: null,
      salesStartedDate: null,
      salesStartedText: getByKey(BaseTextsKeysEnum.ProductSalesStartedText),
      startedDate: new Date(),
      successBuyText: getByKey(BaseTextsKeysEnum.ProductSuccessBuyText),
      successPairBuyText: getByKey(BaseTextsKeysEnum.ProductSuccessBuyText),
      tariffs: [],
      tariffsEmptyText: getByKey(BaseTextsKeysEnum.ProductTariffsEmptyText),
      tariffsText: getByKey(BaseTextsKeysEnum.ProductTariffsText),
      tariffsUnavailableText: getByKey(
        BaseTextsKeysEnum.ProductTariffsUnavailableText,
      ),
      transferOrderToUserSuccessText: getByKey(
        BaseTextsKeysEnum.ProductTransferOrderToUserSuccessText,
      ),
    }),
    loading,
  };
};
