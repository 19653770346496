import { useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  CreateOrganizerMutation,
  CreateOrganizerMutationVariables,
  useCreateOrganizerMutation,
  useUpdateOrganizerSynonymsMutation,
} from '@/apolloGenerated';
import { RouterName } from '@/app/router';
import { MutationResult } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

import { getOrganizerInput } from './getOrganizerInput';

type CreateOrganizerVars = {
  synonyms: string[];
} & CreateOrganizerMutationVariables;

export const useCreateOrganizer = (): [
  (vars: CreateOrganizerVars) => Promise<void>,
  MutationResult<CreateOrganizerMutation>,
] => {
  const { navigate } = useNavigate();
  const [createOrganizer, result] = useCreateOrganizerMutation();
  const [updateOrganizerSynonyms] = useUpdateOrganizerSynonymsMutation();
  const onCreateOrganizer = async ({
    input,
    synonyms,
  }: CreateOrganizerVars) => {
    const { data } = await createOrganizer({
      variables: {
        input: getOrganizerInput(input),
      },
    });

    if (!data?.createOrganizer?.id) {
      return;
    }
    await updateOrganizerSynonyms({
      variables: {
        idOrganizer: +data.createOrganizer.id,
        values: synonyms,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      const { client, data } = result;

      client.cache.evict({
        args: { id: result.data.createOrganizer?.id },
        fieldName: 'organizersList',
      });
      navigate(RouterName.OrganizerEdit, {
        id: data?.createOrganizer.id,
      });
      toaster.success({ title: 'Организатор успешно создан' });
    }
  }, [result.data, result.error]);

  return [onCreateOrganizer, result];
};
