import { FC } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

export interface BotStatusProps {
  active: boolean;
}
export const BotStatus: FC<BotStatusProps> = ({ active }) => (
  <div className={styles.botStatus}>
    <span
      className={cx(
        styles.botStatus__dot,
        active && styles.botStatus__dot_active,
      )}
    />
  </div>
);
