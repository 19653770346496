import { useEffect, useState } from 'react';

import { Maybe } from '@letsdance/ui-kit';

interface EditorParams {
  readonly?: boolean;
  type: 'edit' | 'create';
  uuid?: string;
}
export const useListPicker = <T extends { uuid: string }>(
  data: T[] | null | undefined,
  changeCallback?: (val: T[]) => void,
) => {
  const [value, setValue] = useState<T[]>([]);
  const [editor, setEditor] = useState<Maybe<EditorParams>>(null);

  useEffect(() => {
    if (data) {
      setValue(data);
    }
  }, [data]);

  const handleClose = () => {
    setEditor(null);
  };

  const handleChange = (val: T[]) => {
    setValue(val);
    changeCallback && changeCallback(val);
    handleClose();
  };

  const handleAdd = (data: T) => {
    handleChange([...value, data]);
    handleClose();
  };

  const openEditor = (data: EditorParams) => {
    setEditor(data);
  };

  const handleEdit = (uuid: string, data: T) => {
    const newValues = [...value];
    const elIndex = newValues.findIndex((el) => el.uuid === uuid);

    if (elIndex !== -1) {
      newValues[elIndex] = data;
      handleChange(newValues);
    }
  };

  return {
    editor,
    handleAdd,
    handleChange,
    handleClose,
    handleEdit,
    openEditor,
    value,
  };
};
