import { gql } from '@apollo/client';

export const addressesList = gql`
  query addressesList(
    $filters: ProductAddressListFilterInput
    $params: ProductAddressFilter!
  ) {
    addressesList(filters: $filters, params: $params) {
      rows {
        createdAt
        updatedAt
        uuid
        address
        img
        description
      }
      count
    }
  }
`;

export const address = gql`
  query address($uuid: UUID!) {
    address(uuid: $uuid) {
      createdAt
      updatedAt
      uuid
      address
      img
      description
    }
  }
`;

export const createAddress = gql`
  mutation createAddress($input: ProductAddressInput!, $organizerId: Int!) {
    createAddressProduct(idOrganizer: $organizerId, input: $input) {
      uuid
    }
  }
`;

export const updateAddress = gql`
  mutation updateAddress($uuid: UUID!, $input: ProductAddressInput!) {
    updateAddressProduct(input: $input, uuid: $uuid) {
      uuid
    }
  }
`;

export const removeAddress = gql`
  mutation removeAddress($uuid: UUID!) {
    removeAddressProduct(uuid: $uuid)
  }
`;
