import { FC } from 'react';

import { EventMainInfoForm, useEventMainInfoStore } from '@entities/Event';

export const CreateEventMainInfo: FC = () => {
  const { errors, fields, updateField } = useEventMainInfoStore();

  return (
    <EventMainInfoForm
      initValues={fields}
      errors={errors}
      onChange={updateField}
    />
  );
};
