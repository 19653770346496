import { FC, useEffect, useState } from 'react';

import { useCreateAdminUserMutation } from '@/apolloGenerated';
import { ActionButtons, ApolloErrorService, Container } from '@/shared';
import { NewUserForm, NewUserFormValues } from '@entities/Contact';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';

export interface CreateOrganizerAdminProps {
  organizerId: number;
  onClose(): void;
}
export const CreateOrganizerAdmin: FC<CreateOrganizerAdminProps> = ({
  onClose,
  organizerId,
}) => {
  const [createAdmin, { client, data, error }] = useCreateAdminUserMutation();
  const [userData, setUserData] = useState<NewUserFormValues>();
  const handleSave = () => {
    if (!userData) {
      return;
    }
    createAdmin({
      variables: {
        input: {
          organizerId,
          password: userData.password,
          username: userData.username,
        },
      },
    });
  };

  useEffect(() => {
    if (data?.createAdminUser.uuid) {
      client.cache.evict({ fieldName: 'usersControlOrganizer' });
      toaster.success({ title: 'Администратор успешно создан' });
      onClose();
    }
  }, [data]);

  return (
    <>
      <LoaderOverlay show={false} />
      <ActionButtons
        actions={[
          {
            handler: onClose,
            label: 'Отменить',
          },
          {
            disabled: !userData?.username || !userData?.password,
            handler: handleSave,
            label: 'Создать администратора',
          },
        ]}
      />
      <Spacer size={10} />
      <Container>
        <NewUserForm
          onChange={setUserData}
          errors={
            ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
              ?.validationErrors
          }
        />
      </Container>
    </>
  );
};
