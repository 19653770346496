import { Router } from 'react-justanother-router';

import { ActionModal, ListPickerRemoveModal } from '@/shared';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from '@app/apollo';
import { BotController } from '@entities/Bot';
import { TariffOrderInfoModal } from '@entities/Tariff';
import { RemoveAddressModal } from '@features/Address';
import {
  ActivateBotModal,
  AddOrganizerBotModal,
  CreateBotModal,
  DeactivateBotModal,
  RemoveBotModal,
} from '@features/Bot';
import {
  AddUserGroupModal,
  AddUserToGroupModal,
  DeactivateUserOrganizerModal,
  RemoveUserFromGroupModal,
  RemoveUserGroupModal,
} from '@features/Contact';
import {
  ArchiveEventModal,
  PublishEventModal,
  RemoveEventModal,
} from '@features/Event';
import {
  DisableOrderModal,
  TransferToProductOrderModal,
} from '@features/Orders';
import { EditFeatureOrganizerModal } from '@features/Organizer';
import {
  CancelPaymentModal,
  ProofPaymentModal,
  SubmitPaymentModal,
} from '@features/Purchases';
import { CreateReferralModal, RemoveReferralModal } from '@features/Referral';
import { RemoveBaseTariffModal } from '@features/Tariff';
import { RemoveTerminalModal } from '@features/Terminal';
import { WithdrawCancelModal } from '@features/Withdraw';
import { GoblinProvider, ModalProviderProps, Toaster } from '@letsdance/ui-kit';

import './configs';

import { history, renderer, router } from './router';

import './styles/index.scss';

const componentDictionary: ModalProviderProps['components'] = {
  action: ActionModal,
  activateBot: ActivateBotModal,
  addOrganizerToBot: AddOrganizerBotModal,
  addUserToGroup: AddUserToGroupModal,
  archiveEvent: ArchiveEventModal,
  cancelPayment: CancelPaymentModal,
  cancelWithdraw: WithdrawCancelModal,
  createBot: CreateBotModal,
  createReferral: CreateReferralModal,
  createUserGroup: AddUserGroupModal,
  deactivateBot: DeactivateBotModal,
  deactivateUserOrganizer: DeactivateUserOrganizerModal,
  disableOrder: DisableOrderModal,
  editFeatureOrganizer: EditFeatureOrganizerModal,
  listPickerRemove: ListPickerRemoveModal,
  proofPayment: ProofPaymentModal,
  publishEvent: PublishEventModal,
  removeAddress: RemoveAddressModal,
  removeBaseTariff: RemoveBaseTariffModal,
  removeBot: RemoveBotModal,
  removeEvent: RemoveEventModal,
  removeReferral: RemoveReferralModal,
  removeTerminal: RemoveTerminalModal,
  removeUserFromGroup: RemoveUserFromGroupModal,
  removeUserGroup: RemoveUserGroupModal,
  submitPayment: SubmitPaymentModal,
  tariffOrderInfo: TariffOrderInfoModal,
  transferOrderToProduct: TransferToProductOrderModal,
};

export const Root = () => (
  <ApolloProvider client={apolloClient}>
    <BotController>
      <GoblinProvider modalComponents={componentDictionary}>
        <Router history={history} router={router} renderer={renderer}>
          <Toaster />
        </Router>
      </GoblinProvider>
    </BotController>
  </ApolloProvider>
);
