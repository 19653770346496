import { FC } from 'react';

import { DesignIcon, IconProps, LockIcon } from '@letsdance/ui-kit';

export interface ContactStatusIconProps {
  isBlocked?: boolean;
}
export const ContactStatusIcon: FC<ContactStatusIconProps & IconProps> = ({
  color = 'var(--on-surface-secondary-1)',
  isBlocked,
  ...iconProps
}) => {
  if (isBlocked) {
    return <LockIcon color={color} {...iconProps} />;
  }

  return <DesignIcon color="transparent" />;
};
