import React, { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Container } from '@/shared';
import { RouterName } from '@app/router';
import { Divider, Spacer } from '@letsdance/ui-kit';
import { EventCreate } from '@widgets/Event';
import { Head } from '@widgets/Layout';

export const EventCreateView: FC = () => {
  const { navigate } = useNavigate();
  const onClose = () => {
    navigate(RouterName.Events);
  };

  return (
    <>
      <Container>
        <Head title="Новое мероприятие" onBack={onClose} />
      </Container>
      <Divider />
      <Spacer size={20} />
      <Container slimV2>
        <EventCreate />
      </Container>
    </>
  );
};
