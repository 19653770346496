import { useEffect } from 'react';

import {
  CreateEventMutation,
  CreateEventMutationVariables,
  useCreateEventMutation,
} from '@/apolloGenerated';
import {
  ApolloErrorService,
  BaseModelAction,
  BaseModelActionParams,
  useOrganizerId,
} from '@/shared';
import { ApolloError } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

interface UseCreateEventParams
  extends BaseModelActionParams<CreateEventMutation> {
  publish: boolean;
}

type UseCreateEvent = BaseModelAction<
  CreateEventMutation,
  Pick<CreateEventMutationVariables, 'input'>,
  UseCreateEventParams
>;
export const useCreateEvent: UseCreateEvent = ({
  idOrganizer: idOrganizerParam,
  notify,
  onError,
  onSuccess,
  publish,
  resetCache,
}) => {
  const idCurrentOrganizer = useOrganizerId()!;
  const idOrganizer = idOrganizerParam || idCurrentOrganizer;

  const [createEvent, result] = useCreateEventMutation();
  const { client, data, error } = result;

  const handlerAction: ReturnType<UseCreateEvent>[0]['handler'] = ({ input }) =>
    createEvent({
      variables: {
        input,
        organizerId: idOrganizer,
        publish,
      },
    });

  const handlerResetCache: ReturnType<UseCreateEvent>[0]['handlerResetCache'] =
    () => {
      client.cache.evict({ fieldName: 'productsList' });
      client.cache.evict({ fieldName: 'productsCount' });
    };

  const handlerNotify: ReturnType<UseCreateEvent>[0]['handlerNotify'] = () => {
    toaster.success({ title: 'Событие успешно создано' });
  };

  const handlerSuccess = (data: CreateEventMutation) => {
    if (resetCache) {
      handlerResetCache();
    }
    if (notify) {
      handlerNotify();
    }
    onSuccess && onSuccess(data);
  };

  const handleError = (error: ApolloError) => {
    onError && onError(error);
  };

  useEffect(() => {
    if (data) {
      handlerSuccess(data);
    }
    if (error) {
      handleError(error);
    }
  }, [data, error]);

  return [
    { handler: handlerAction, handlerNotify, handlerResetCache },
    {
      data,
      validationErrors: ApolloErrorService.getFirstError(error?.graphQLErrors)
        ?.extensions?.validationErrors,
    },
    result,
  ];
};
