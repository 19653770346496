import { array, object, ObjectSchema } from 'yup';

import { EventTerminalType } from '@entities/Terminal';

export interface EventTerminalsValidationObject {
  terminals: EventTerminalType[];
}
export const createEventTerminalsSchema: ObjectSchema<EventTerminalsValidationObject> =
  object().shape({
    terminals: array()
      .required()
      .min(1, 'Нужно включить хотя бы один способ оплаты'),
  });
