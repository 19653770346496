import React, { FC } from 'react';

import { useEventQuery } from '@/apolloGenerated';
import { useModal } from '@/shared';
import { Button, PlusDenseIcon, Tooltip } from '@letsdance/ui-kit';

export interface ReferralEventCreateButtonProps {
  eventUuid: string;
}
export const ReferralEventCreateButton: FC<ReferralEventCreateButtonProps> = ({
  eventUuid,
}) => {
  const createModal = useModal('createReferral');
  const { data } = useEventQuery({ variables: { uuid: eventUuid } });

  return (
    <Tooltip
      className="flex"
      label="Для создания реферальной ссылки необходимо заполнить диплник у события"
      disabled={!!data?.product.query}
      zIndex={1000}>
      <Button
        prependIcon={PlusDenseIcon}
        onClick={() =>
          data?.product.query ? createModal({ uuidProduct: eventUuid }) : null
        }>
        Создать
      </Button>
    </Tooltip>
  );
};
