import { gql } from '@apollo/client';

export const terminalFragment = gql`
  fragment TerminalFields on TerminalType {
    createdAt
    updatedAt
    uuid
    name
    label
    holdPercentage
    invoiceLifetime
    type
    status
    token
  }
`;

export const terminal = gql`
  ${terminalFragment}
  query terminal($uuid: UUID!) {
    terminalAdmin(uuid: $uuid) {
      ...TerminalFields
    }
  }
`;

export const terminalsByProduct = gql`
  ${terminalFragment}
  query terminalsByProduct($uuidProduct: UUID!) {
    terminalsByProduct(uuidProduct: $uuidProduct) {
      ...TerminalFields
    }
  }
`;

export const terminals = gql`
  ${terminalFragment}
  query terminals(
    $status: [StatusEnum!]
    $idOrganizer: Float
    $filters: TerminalListFilterInput
  ) {
    terminalsList(
      filters: $filters
      idOrganizer: $idOrganizer
      status: $status
    ) {
      rows {
        ...TerminalFields
        organizer {
          name
          telegram
        }
      }
      count
    }
  }
`;

export const terminalsCount = gql`
  query terminalsCount($status: [StatusEnum!], $idOrganizer: Float) {
    terminalsList(
      filters: { pagination: { pageSize: 1, page: 1 } }
      idOrganizer: $idOrganizer
      status: $status
    ) {
      count
    }
  }
`;

export const createTerminal = gql`
  ${terminalFragment}
  mutation createTerminal($idOrganizer: Float!, $input: TerminalInput!) {
    createTerminal(input: $input, idOrganizer: $idOrganizer) {
      ...TerminalFields
    }
  }
`;

export const updateTerminal = gql`
  ${terminalFragment}
  mutation updateTerminal($uuid: UUID!, $input: TerminalInput!) {
    updateTerminal(input: $input, uuid: $uuid) {
      ...TerminalFields
    }
  }
`;

export const updateProductTerminals = gql`
  ${terminalFragment}
  mutation updateProductTerminals(
    $uuidProduct: UUID!
    $uuidTerminals: [UUID!]!
  ) {
    updateProductTerminals(
      uuidProduct: $uuidProduct
      uuidTerminals: $uuidTerminals
    )
  }
`;

export const removeTerminal = gql`
  mutation removeTerminal($uuid: UUID!) {
    removeTerminal(uuid: $uuid)
  }
`;
