import { PaymentStatusEnum } from '@/apolloGenerated';

export const PAYMENT_STATUS: Record<PaymentStatusEnum, string> = {
  [PaymentStatusEnum.AwaitingConfirmation]: 'Ожидает подтверждения',
  [PaymentStatusEnum.Canceled]: 'Оплата отменена',
  [PaymentStatusEnum.Failed]: 'Ошибка оплаты',
  [PaymentStatusEnum.Overdue]: 'Истекло время оплаты',
  [PaymentStatusEnum.Pending]: 'Ожидание оплаты',
  [PaymentStatusEnum.Success]: 'Успешная оплата',
};
