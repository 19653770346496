import { useEffect } from 'react';

import {
  TransferOrderToProductMutationVariables,
  useTransferOrderToProductMutation,
} from '@/apolloGenerated';
import { toaster } from '@letsdance/ui-kit';

export const useTransferToProductOrder = () => {
  const [transferToProduct, { client, data, error, loading }] =
    useTransferOrderToProductMutation();

  const handleTransfer = (
    variables: TransferOrderToProductMutationVariables,
  ) => {
    transferToProduct({
      variables,
    });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Билет успешно перенесён',
      });
      client.cache.evict({ fieldName: 'ordersList' });
      client.cache.evict({ fieldName: 'paymentsList' });
    }
    if (error) {
      toaster.error({
        title: 'Ошибка переноса. Обратитесь за помощью в техподдержку',
      });
    }
  }, [data, error]);

  return {
    data,
    error,
    handleTransfer,
    loading,
  };
};
