import React, { FC, useEffect } from 'react';
import moment from 'moment-timezone';

import {
  ProductFeatureFlagsInput,
  ProductInput,
  ProductType,
  useEventShortcodeQuery,
  useReferralsOrganizerListQuery,
} from '@/apolloGenerated';
import {
  DeeplinkCopy,
  ErrorNestedKeys,
  FormSection,
  handleQueryInput,
  timezone,
  useImageUploader,
} from '@/shared';
import {
  DateTimePicker,
  EditorTextField,
  FileSize,
  ImageUploader,
  Maybe,
  PeriodTextField,
  Spacer,
  Switch,
  TextField,
  Tooltip,
  useForm,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventFormProps {
  initValues: ProductType;

  onChange(val: ProductInput): void;

  errors?: Record<ErrorNestedKeys<ProductInput>, string[]>;
  readonly?: boolean;
  tariffsSlot?: JSX.Element | false;
}

const getInfoForBeforeStart = (
  startedDate?: Maybe<Date>,
  duration?: Maybe<string>,
) =>
  startedDate && duration
    ? ` (до ${moment(startedDate).tz(timezone).subtract(duration).format('DD.MM.YY HH:mm')})`
    : '';

export const EventForm: FC<EventFormProps> = ({
  errors,
  initValues,
  onChange,
  readonly,
  tariffsSlot,
}) => {
  const { data: dataReferrals } = useReferralsOrganizerListQuery({
    skip: !initValues?.uuid,
    variables: {
      filters: {
        pagination: { page: 1, pageSize: 1 },
      },
      params: {
        uuidProduct: initValues?.uuid,
      },
    },
  });
  const { data: shortcodes } = useEventShortcodeQuery();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const uploadImage = useImageUploader();
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<ProductInput>(console.log, initValues);

  useEffect(() => {
    onChange(values);
  }, [values]);

  const updateFeatureFlags = function <
    T extends keyof ProductFeatureFlagsInput,
  >(key: T, val: ProductFeatureFlagsInput[T]) {
    setBatchValues({
      featureFlags: {
        ...values.featureFlags,
        [key]: val,
      },
    });
  };

  return (
    <div className={styles.eventForm}>
      <FormSection title="Основная информация">
        <div className={styles.eventForm__mainInfo}>
          <ImageUploader
            initValue={values.img}
            onChange={(val) => setBatchValues({ img: val })}
            disabled={readonly}
            error={errors?.img}
            uploadImageCallback={uploadImage}
            size={300}
            maxFileSize={{
              size: 10,
              unit: FileSize.MB,
            }}
          />
          <div>
            <TextField
              label="Название"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.name}
              name="name"
              initValue={values.name}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <div className="grid-2">
              <DateTimePicker
                initValue={new Date(values.startedDate)}
                label={`Дата начала мероприятия (${timeZone})`}
                fullWidth
                size="large"
                onChange={(val) => setBatchValues({ startedDate: val! })}
                error={errors?.startedDate}
                disabled={readonly}
                minDate={new Date()}
              />
              <DateTimePicker
                initValue={new Date(values.endDate)}
                label={`Дата окончания мероприятия (${timeZone})`}
                fullWidth
                size="large"
                onChange={(val) => setBatchValues({ endDate: val! })}
                error={errors?.endDate}
                disabled={readonly || !values?.startedDate}
                minDate={new Date()}
              />
            </div>
            <Spacer size={10} />
            <Tooltip
              className="flex"
              label="Невозможно изменить диплинк, так как уже имеются реферальные ссылки"
              disabled={!dataReferrals?.referralsOrganizerList.count}
              zIndex={1000}>
              <TextField
                label="Ключ диплинка (латиница)"
                size="large"
                disabled={
                  readonly || !!dataReferrals?.referralsOrganizerList.count
                }
                fullWidth
                error={errors?.query}
                name="query"
                initValue={values.query!}
                onKeyDown={handleQueryInput}
                onChange={onChangeValue}
              />
            </Tooltip>
            <Spacer size={10} />
            <DeeplinkCopy to="event" value={values.query} />
          </div>
        </div>
        <Spacer size={10} />
        <EditorTextField
          label="Описание"
          disabled={readonly}
          error={errors?.description}
          initValue={values.description}
          onChange={(e) => setBatchValues({ description: e })}
        />
      </FormSection>
      <FormSection title="Способ оплаты">
        <div className="grid-2">
          <DateTimePicker
            initValue={
              values.salesStartedDate ? new Date(values.salesStartedDate) : null
            }
            label={`Дата старта продаж (${timeZone})`}
            fullWidth
            size="large"
            onChange={(val) =>
              setBatchValues({
                salesStartedDate: val,
              })
            }
            error={errors?.startedDate}
            disabled={readonly}
            minDate={new Date()}
            clearable
          />
        </div>
        <Spacer size={10} />
        <EditorTextField
          label="Текст до старта продаж"
          disabled={readonly}
          error={errors?.salesStartedText}
          initValue={values.salesStartedText!}
          onChange={(e) => setBatchValues({ salesStartedText: e })}
          shortCodes={shortcodes?.productShortcodes}
        />
      </FormSection>
      {tariffsSlot && <FormSection title="Тарифы">{tariffsSlot}</FormSection>}
      <FormSection title="Фичи">
        <div className="grid-2">
          <div>
            <Spacer size={5} />
            <Switch
              label="Перенос билетов на данное событие"
              checked={values.featureFlags.transferFromProductOrder}
              disabled={readonly}
              onChange={(e) =>
                updateFeatureFlags('transferFromProductOrder', e.target.checked)
              }
            />
          </div>
          <div>
            <PeriodTextField
              label={
                'Отключение переноса в часах до начала' +
                getInfoForBeforeStart(
                  values.startedDate,
                  values.featureFlags
                    .durationBeforeStartForTransferToProductOrder,
                )
              }
              size="large"
              disabled={readonly}
              fullWidth
              error={
                errors?.[
                  'featureFlags.durationBeforeStartForTransferToProductOrder'
                ]
              }
              name="durationBeforeStartForTransferToProductOrder"
              initValue={
                values.featureFlags
                  .durationBeforeStartForTransferToProductOrder!
              }
              onChange={(e) =>
                updateFeatureFlags(
                  'durationBeforeStartForTransferToProductOrder',
                  e.target.value,
                )
              }
              period="hour"
            />
            <Spacer size={10} />
            <PeriodTextField
              label={
                'Отключение передачи в часах до начала' +
                getInfoForBeforeStart(
                  values.startedDate,
                  values.featureFlags.durationBeforeStartForTransferToUserOrder,
                )
              }
              size="large"
              disabled={readonly}
              fullWidth
              error={
                errors?.[
                  'featureFlags.durationBeforeStartForTransferToUserOrder'
                ]
              }
              name="durationBeforeStartForTransferToUserOrder"
              initValue={
                values.featureFlags.durationBeforeStartForTransferToUserOrder!
              }
              onChange={(e) =>
                updateFeatureFlags(
                  'durationBeforeStartForTransferToUserOrder',
                  e.target.value,
                )
              }
              period="hour"
            />
            <Spacer size={10} />
            <PeriodTextField
              label={
                'Отключение возврата в часах до начала' +
                getInfoForBeforeStart(
                  values.startedDate,
                  values.featureFlags.durationBeforeStartForRefundOrder,
                )
              }
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.['featureFlags.durationBeforeStartForRefundOrder']}
              name="durationBeforeStartForRefundOrder"
              initValue={values.featureFlags.durationBeforeStartForRefundOrder!}
              onChange={(e) =>
                updateFeatureFlags(
                  'durationBeforeStartForRefundOrder',
                  e.target.value,
                )
              }
              period="hour"
            />
          </div>
        </div>
      </FormSection>
      <FormSection title="Тексты">
        <EditorTextField
          label="Текст успешной покупки"
          disabled={readonly}
          error={errors?.successBuyText}
          initValue={values.successBuyText!}
          onChange={(e) => setBatchValues({ successBuyText: e })}
        />
        <Spacer size={10} />
        <EditorTextField
          label="Текст успешной покупки парного билета"
          disabled={readonly}
          error={errors?.successPairBuyText}
          initValue={values.successPairBuyText!}
          onChange={(e) => setBatchValues({ successPairBuyText: e })}
        />
        <Spacer size={10} />
        <EditorTextField
          label="Текст успешной передачи билета"
          disabled={readonly}
          error={errors?.transferOrderToUserSuccessText}
          initValue={values.transferOrderToUserSuccessText!}
          onChange={(e) =>
            setBatchValues({ transferOrderToUserSuccessText: e })
          }
        />
        <Spacer size={10} />
        <EditorTextField
          label="Глобальный текст тарифов"
          disabled={readonly}
          error={errors?.tariffsText}
          initValue={values.tariffsText!}
          onChange={(e) => setBatchValues({ tariffsText: e })}
        />
        <Spacer size={10} />
        <EditorTextField
          label="Текст при отсутствии активных тарифов"
          disabled={readonly}
          error={errors?.tariffsEmptyText}
          initValue={values.tariffsEmptyText!}
          onChange={(e) => setBatchValues({ tariffsEmptyText: e })}
        />
        <Spacer size={10} />
        <EditorTextField
          label="Текст при отсутствии доступных билетов у тарифов"
          disabled={readonly}
          error={errors?.tariffsUnavailableText}
          initValue={values.tariffsUnavailableText!}
          onChange={(e) => setBatchValues({ tariffsUnavailableText: e })}
        />
      </FormSection>
    </div>
  );
};
