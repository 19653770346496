import { FC, useEffect } from 'react';

import { ProductInviteTextsInput } from '@/apolloGenerated';
import { EditorTextField, useForm } from '@letsdance/ui-kit';

export interface EventInviteTextsFormProps {
  initData: ProductInviteTextsInput;
  onChange: (data: ProductInviteTextsInput) => void;
}
export const EventInviteTextsForm: FC<EventInviteTextsFormProps> = ({
  initData,
  onChange,
}) => {
  const { setBatchValues, values } = useForm<ProductInviteTextsInput>(
    console.log,
    initData,
  );

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div className="grid-2">
      <EditorTextField
        label="Текст пришедшего приглашения"
        initValue={values.inviteOrderText!}
        onChange={(e) => setBatchValues({ inviteOrderText: e })}
      />
      <EditorTextField
        label="Текст успешного принятия приглашения"
        initValue={values.inviteOrderSuccessText!}
        onChange={(e) => setBatchValues({ inviteOrderSuccessText: e })}
      />
    </div>
  );
};
