import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { SubTariffType } from '@/apolloGenerated';

interface EventTariffsStoreState {
  tariffs: SubTariffType[];
  errors: string[];
}

interface EventTariffsStoreType extends EventTariffsStoreState {
  addTariff(tariff: SubTariffType): void;
  removeTariff(uuid: string): void;
  setTariffs(tariff: SubTariffType[]): void;
  clearErrors(): void;
  reset(): void;
  setErrors(errors: string[]): void;
}

const initState: EventTariffsStoreState = {
  errors: [],
  tariffs: [],
};

export const useEventTariffsStore = create<EventTariffsStoreType>()(
  immer((set) => ({
    ...initState,
    addTariff: (tariff) =>
      set((state) => {
        state.tariffs = [...state.tariffs, tariff];
      }),
    clearErrors: () =>
      set((state) => {
        state.errors = [];
      }),
    removeTariff: (uuid) =>
      set((state) => {
        state.tariffs = state.tariffs.filter((tariff) => tariff.uuid !== uuid);
      }),
    reset: () => set(initState),
    setErrors: (errors) =>
      set((state) => {
        state.errors = errors;
      }),
    setTariffs: (tariffs) =>
      set((state) => {
        state.tariffs = tariffs;
      }),
  })),
);
