import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  Maybe,
  OrganizersListQuery,
  OrganizerSortKeys,
  useOrganizersListQuery,
} from '@/apolloGenerated';
import { RouterName } from '@/app/router';
import {
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
} from '@/shared';
import { OrganizerControl } from '@features/Organizer';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

const headers = [
  { key: true, sortable: true, title: 'ID', value: 'id', width: 80 },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 180 },
  { sortable: true, title: 'Данные', value: 'name' },
  { title: 'Продаж', value: 'ordersCount' },
  { title: 'Баланс, ₽', value: 'salesBalance' },
  { title: 'Реф. баланс, ₽', value: 'referralBalance' },
  { title: 'Задолжность, ₽', value: 'creditBalance' },
  { title: 'Оборот, ₽', value: 'turnover' },
  { title: ' ', value: 'settings', width: 48 },
];

const pageSize = 15;
const DEFAULT_PAGE = 1;

export interface OrganizersTableProps {
  verified?: boolean;
}

export const OrganizersTable: FC<OrganizersTableProps> = ({ verified }) => {
  const { navigate } = useNavigate();
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrganizerSortKeys>(OrganizerSortKeys.Id);

  useEffect(() => {
    if (verified) {
      setPage(DEFAULT_PAGE);
    }
  }, [verified]);

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useOrganizersListQuery({
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      verified: verified || null,
    },
  });

  const data = curData || previousData;

  const rowTemplate = (
    organizer: OrganizersListQuery['organizersList']['rows'][0],
  ) => ({
    createdAt: renderCellDate({ timestamp: organizer.createdAt }),
    creditBalance: formatAmount(organizer.creditBalance, true),
    id: organizer.id,
    name: renderCellItemValue({
      label: organizer.name,
      value: organizer.telegram || '-',
    }),
    ordersCount: String(organizer.ordersCount),
    referralBalance: formatAmount(organizer.referralBalance, true),
    salesBalance: formatAmount(organizer.salesBalance, true),
    settings: <OrganizerControl id={organizer.id} />,
    turnover: formatAmount(organizer.statsPayments.turnover, true),
  });
  const items = useMemo(
    () => (data?.organizersList?.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.organizersList?.count}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as OrganizerSortKeys);
        }}
        onClickRow={(e, organizer) =>
          navigate(RouterName.OrganizerEdit, { id: organizer.id })
        }
        notResetPage
        onRefetch={error && refetch}
        hasError={!!error}
      />
    </div>
  );
};
