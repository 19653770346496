import React, { FC, useState } from 'react';

import { useBotsListCountQuery } from '@/apolloGenerated';
import { Container, ScrollXWrapper } from '@/shared';
import { BotsTable } from '@features/Bot';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export enum BotsTabs {
  All = 'all',
  Active = 'active',
}

export const BotsLists: FC = () => {
  const [tab, setTab] = useState<BotsTabs>(BotsTabs.Active);
  const { data: allOrganizers } = useBotsListCountQuery();
  const { data: activeOrganizers } = useBotsListCountQuery({
    variables: {
      isActive: true,
    },
  });

  return (
    <div>
      <Container>
        <ScrollXWrapper>
          <Tabs initValue={tab} onChange={setTab}>
            <TabItem
              value={BotsTabs.Active}
              label={`Активные (${activeOrganizers?.botsList?.count || 0})`}
            />
            <TabItem
              value={BotsTabs.All}
              label={`Все (${allOrganizers?.botsList?.count || 0})`}
            />
          </Tabs>
        </ScrollXWrapper>
      </Container>
      <Divider />
      <Container>
        <Spacer size={12} />
        <ScrollXWrapper>
          <BotsTable active={tab === BotsTabs.Active} />
        </ScrollXWrapper>
      </Container>
    </div>
  );
};
