import { FC } from 'react';
import cx from 'classnames';

import { useMeQuery } from '@/apolloGenerated';
import { isAdmin, LogoIconShort } from '@/shared';
import { useOrganizerBotStore } from '@entities/Bot';
import { BotSelect } from '@features/Bot';
import { SidebarIcon } from '@letsdance/ui-kit';
import { ProfileControl } from '@widgets/Layout/ui/ProfileControl/ProfileControl';

import styles from './styles.module.scss';

export interface MobileHeaderProps {
  className?: string;
  onClick?(): void;
}

export const MobileHeader: FC<MobileHeaderProps> = ({ className, onClick }) => {
  const { data } = useMeQuery();
  const { allOrganizerBots } = useOrganizerBotStore();

  return (
    <div className={cx(styles.mobileHeader, className)}>
      <LogoIconShort
        color="var(--neutrals-1)"
        className={styles.mobileHeader__logo}
      />
      {!isAdmin(data?.me.role) && allOrganizerBots.length > 1 && <BotSelect />}
      <ProfileControl />
      <SidebarIcon onClick={onClick} color={'white'} />
    </div>
  );
};
