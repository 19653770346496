import { FC, useState } from 'react';

import { ListFilterWrapper } from '@/shared';
import { Switch } from '@letsdance/ui-kit';

export interface ReferralLinksFilterType {
  onChange?(e: boolean): void;
  initValue?: boolean;
}

export const ReferralLinksFilter: FC<ReferralLinksFilterType> = ({
  initValue = true,
  onChange,
}) => {
  const [value, setValue] = useState<boolean>(initValue);

  const handleChange = () => {
    setValue(!value);
    onChange && onChange(!value);
  };

  return (
    <ListFilterWrapper>
      <Switch label="Активные" checked={value} onChange={handleChange} />
    </ListFilterWrapper>
  );
};
