import React, { FC, useMemo, useState } from 'react';

import { TerminalEnum, TerminalType } from '@/apolloGenerated';
import { ExtendedSwitch } from '@/shared';
import { ICON_ONLINE_TERMINAL } from '@entities/Terminal';
import { Spacer, Typography } from '@letsdance/ui-kit';

export interface OnlineTerminalsInputProps {
  terminals: TerminalType[];
  activeTerminals: TerminalEnum[];
  onChange(terminals: TerminalEnum[]): void;
  hasError?: boolean;
}
export const OnlineTerminalsInput: FC<OnlineTerminalsInputProps> = ({
  activeTerminals: activeTerminalsInit,
  hasError,
  onChange,
  terminals,
}) => {
  const [activeTerminals, setActiveTerminals] =
    useState<TerminalEnum[]>(activeTerminalsInit);

  const terminalsSorted = useMemo(
    () => [...terminals].sort((a, b) => a.holdPercentage - b.holdPercentage),
    [terminals],
  );

  const handleChange = (type: TerminalEnum, state: boolean) => {
    let updatedTerminals = [...activeTerminals];

    if (state) {
      updatedTerminals.push(type);
    } else {
      updatedTerminals = updatedTerminals.filter((el) => el !== type);
    }

    setActiveTerminals(updatedTerminals);
    onChange(updatedTerminals);
  };

  return (
    <div>
      {terminalsSorted.map((terminal) => (
        <div key={terminal.uuid}>
          <ExtendedSwitch
            onChange={(val) => handleChange(terminal.type, val)}
            checked={activeTerminals.includes(terminal.type)}
            label={terminal.name}
            hasError={hasError}
            icon={ICON_ONLINE_TERMINAL[terminal.type]}
          />
          {!!terminal.holdPercentage && (
            <>
              <Spacer size={2} />
              <Typography variant="body-16" color="on-surface-primary-2">
                {terminal.holdPercentage}% комиссии от 1 транзакции
              </Typography>
            </>
          )}
          <Spacer size={10} />
        </div>
      ))}
    </div>
  );
};
