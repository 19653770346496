import { FC, useEffect } from 'react';

import { SectionCard } from '@/shared';
import {
  EventTerminalType,
  ManualTerminalInput,
  OnlineTerminalsInput,
} from '@entities/Terminal';
import { useManualTerminal, useOnlineTerminal } from '@features/Terminal';
import { FieldError, LoaderOverlay, Spacer } from '@letsdance/ui-kit';
import { useFeatureFlags } from '@shared/libs/hooks/useFeatureFlags';

export interface EventTerminalsProps {
  eventUuid: string;
  errors?: string[];
  onChange?(terminals: EventTerminalType[]): void;
}
export const EventTerminals: FC<EventTerminalsProps> = ({
  errors,
  eventUuid,
  onChange,
}) => {
  const hasError = !!errors?.length;
  const { manualPayment } = useFeatureFlags();
  const [
    { updateActive: updateManual, updateText: updateManualText },
    { active: activeManual, text: manualText },
  ] = useManualTerminal(eventUuid);
  const [updateOnlineTerminals, { currentTerminals, loading, terminals }] =
    useOnlineTerminal(eventUuid);

  const handleChange = () => {
    const updatedTerminals: EventTerminalType[] = [...currentTerminals];

    if (activeManual) {
      updatedTerminals.push('Manual');
    }
    onChange && onChange(updatedTerminals);
  };

  useEffect(() => {
    handleChange();
  }, [activeManual, currentTerminals]);

  if (loading) {
    return <LoaderOverlay show />;
  }

  return (
    <SectionCard title="Оплата">
      <OnlineTerminalsInput
        terminals={terminals}
        activeTerminals={currentTerminals}
        onChange={updateOnlineTerminals}
        hasError={hasError}
      />
      {manualPayment && (
        <ManualTerminalInput
          text={manualText}
          active={activeManual}
          onChangeActive={updateManual}
          onChangeText={updateManualText}
          hasError={hasError}
        />
      )}
      {hasError && (
        <>
          <Spacer size={10} />
          <FieldError error={errors} />
        </>
      )}
    </SectionCard>
  );
};
