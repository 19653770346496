import { gql } from '@apollo/client';

export const user = gql`
  query User($uuid: UUID!, $organizerId: Int!) {
    user(uuid: $uuid) {
      createdAt
      updatedAt
      deletedAt
      uuid
      tgId
      username
      first_name
      last_name
      language
      role
      email
      phone
      ordersCount
      activatedOrdersCount
      statsPayments {
        turnover
      }
      gender
      balance(idOrganizer: $organizerId)
      isBlocked(idOrganizer: $organizerId)
    }
  }
`;

export const usersStats = gql`
  query usersStats($organizerId: Int!) {
    statsUsers(organizerId: $organizerId) {
      count
      maleCount
      femaleCount
    }
  }
`;

export const activeUsersStats = gql`
  query activeUsersStats($organizerId: Int!) {
    statsUsersWithActiveOrders(organizerId: $organizerId) {
      count
      maleCount
      femaleCount
    }
  }
`;

export const usersControlOrganizer = gql`
  query usersControlOrganizer(
    $filters: UserListFilterInput
    $organizerId: Int!
  ) {
    usersControlOrganizer(filters: $filters, organizerId: $organizerId) {
      rows {
        createdAt
        uuid
        first_name
        last_name
        username
        phone
        email
      }
      count
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($uuid: UUID!, $input: UpdateUserInput!) {
    updateUser(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const deactivateUserOrganizer = gql`
  mutation deactivateUserOrganizer($uuid: UUID!) {
    deactivateUserOrganizer(uuid: $uuid) {
      uuid
    }
  }
`;

export const createAdminUser = gql`
  mutation createAdminUser($input: CreateAdminUserInput!) {
    createAdminUser(input: $input) {
      uuid
    }
  }
`;
