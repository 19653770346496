import { FC, isValidElement, PropsWithChildren } from 'react';

import { Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

interface SectionCardProps {
  title?: string | JSX.Element | false;
  rightHeadSlot?: JSX.Element | false;
}
export const SectionCard: FC<PropsWithChildren<SectionCardProps>> = ({
  children,
  rightHeadSlot,
  title,
}) => (
  <div className={styles.sectionCard}>
    {title && (
      <div className={styles.sectionCard__header}>
        {isValidElement(title) ? (
          title
        ) : (
          <Typography variant="head-20" color="on-surface-primary-1">
            {title}
          </Typography>
        )}
        {rightHeadSlot && (
          <div className={styles.sectionCard__rightHeadSlot}>
            {rightHeadSlot}
          </div>
        )}
      </div>
    )}
    <div className={styles.sectionCard__content}>{children}</div>
  </div>
);
