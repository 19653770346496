import { useEffect } from 'react';

import {
  UserGroupType,
  useUpdateGroupUsersNameMutation,
  useUpdateUsersInGroupMutation,
} from '@/apolloGenerated';
import { MaybeNotDefined, toaster } from '@letsdance/ui-kit';
import { GroupSettings } from '@widgets/Contact';

export const useUpdateUsersGroup = () => {
  const [updateGroupName, { client, error: updateGroupNameError }] =
    useUpdateGroupUsersNameMutation();

  const [updateUsersInGroup, { data: groupData, error: groupError }] =
    useUpdateUsersInGroupMutation();

  const updateGroup = async (
    e: GroupSettings & { uuid: MaybeNotDefined<UserGroupType['uuid']> },
    { updateName }: { updateName: boolean },
  ) => {
    if (!e?.uuid) {
      throw new Error('Edited group has not name or uuid');
    }
    updateName &&
      (await updateGroupName({
        variables: {
          name: e.name,
          uuid: e.uuid,
        },
      }));
    await updateUsersInGroup({
      variables: {
        uuidGroup: e.uuid,
        uuidUsers: e.users,
      },
    });
  };

  useEffect(() => {
    if (groupData) {
      toaster.success({
        title: `Группа успешно обновлена`,
      });
      client.cache.evict({ fieldName: 'userGroups' });
      client.cache.evict({
        fieldName: 'usersByGroup',
      });
    }
    if (updateGroupNameError || groupError) {
      toaster.error({
        title: 'Ошибка изменения группы. Обратитесь за помощью в техподдержку',
      });
    }
  }, [groupData, updateGroupNameError, groupError]);

  return [updateGroup];
};
