import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  BaseTariffType,
  ProductInput,
  SubTariffInput,
  SubTariffType,
  useSubTariffQuery,
} from '@/apolloGenerated';
import { ActionButtons, ApolloErrorService } from '@/shared';
import { RouterName } from '@app/router';
import { SubTariffForm, useUpdateTariff } from '@entities/Tariff';
import { LoaderOverlay, Spacer, toaster, Typography } from '@letsdance/ui-kit';
import { readableStatus } from '@shared/libs/utils/format';
import { Head } from '@widgets/Layout';

export interface TariffEditProps {
  eventData: ProductInput;
  uuid: string;
  disabled?: boolean;
  onClose(): void;
  onSuccess(val: SubTariffType): void;
}
export const TariffEdit: FC<TariffEditProps> = ({
  disabled,
  eventData,
  onClose,
  onSuccess,
  uuid,
}) => {
  const { urlFor } = useNavigate();
  const { data, loading, refetch } = useSubTariffQuery({ variables: { uuid } });
  const [updateTariff, { data: updateData, error, loading: saveLoading }] =
    useUpdateTariff();
  const [tariffData, setTariffData] = useState<SubTariffInput>();

  const handleSubmit = () => {
    if (!tariffData) {
      return toaster.error({ title: 'Отсутствуют данные для тарифа' });
    }
    updateTariff({
      input: tariffData,
      uuid,
    });
  };

  useEffect(() => {
    if (updateData) {
      refetch();
      onSuccess(updateData.updateTariff);
    }
  }, [updateData]);

  const onOpenBaseTariff = () => {
    window.open(
      urlFor(RouterName.BaseTariffEdit, {
        uuid: data?.subTariff.baseTariff?.uuid,
      }),
      '_blank',
    );
  };

  return (
    <div>
      <LoaderOverlay show={loading || saveLoading} />
      <Head
        title={data?.subTariff.name || data?.subTariff.baseTariff?.name || ''}
        rightSlot={
          data && (
            <Typography variant="body-16" color="on-surface-primary-1">
              {readableStatus(data.subTariff.status)}
            </Typography>
          )
        }
      />
      {!disabled && (
        <ActionButtons
          actions={[
            { handler: onClose, label: 'Отменить' },
            { handler: onOpenBaseTariff, label: 'Базовый тариф' },
            { handler: handleSubmit, label: 'Сохранить изменения' },
          ]}
        />
      )}

      <Spacer size={10} />
      {data && (
        <SubTariffForm
          eventData={eventData}
          initValues={data.subTariff as SubTariffType}
          baseValues={data.subTariff.baseTariff as BaseTariffType}
          readonly={disabled}
          onChange={setTariffData}
          errors={
            ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
              ?.validationErrors
          }
        />
      )}
    </div>
  );
};
