import { useEffect } from 'react';

import {
  UpdateAddressMutation,
  UpdateAddressMutationVariables,
  useUpdateAddressMutation,
} from '@/apolloGenerated';
import {
  ApolloErrorService,
  BaseModelAction,
  BaseModelActionParams,
} from '@/shared';
import { ApolloError } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

type UseUpdateAddressParams = BaseModelActionParams<UpdateAddressMutation>;

type UseUpdateAddressMainInfo = BaseModelAction<
  UpdateAddressMutation,
  UpdateAddressMutationVariables,
  UseUpdateAddressParams
>;
export const useUpdateAddress: UseUpdateAddressMainInfo = ({
  notify,
  onError,
  onSuccess,
  resetCache,
}) => {
  const [updateAddress, result] = useUpdateAddressMutation();
  const { client, data, error } = result;

  const handlerAction: ReturnType<UseUpdateAddressMainInfo>[0]['handler'] = ({
    input,
    uuid,
  }) =>
    updateAddress({
      variables: {
        input,
        uuid,
      },
    });

  const handlerResetCache: ReturnType<UseUpdateAddressMainInfo>[0]['handlerResetCache'] =
    () => {
      client.cache.evict({
        args: { uuid: result.data?.updateAddressProduct.uuid },
        fieldName: 'address',
      });
      client.cache.evict({ fieldName: 'addressesList' });
    };

  const handlerNotify: ReturnType<UseUpdateAddressMainInfo>[0]['handlerNotify'] =
    () => {
      toaster.success({ title: 'Адрес успешно создан' });
    };

  const handlerSuccess = (data: UpdateAddressMutation) => {
    if (resetCache) {
      handlerResetCache();
    }
    if (notify) {
      handlerNotify();
    }
    onSuccess && onSuccess(data);
  };

  const handleError = (error: ApolloError) => {
    onError && onError(error);
  };

  useEffect(() => {
    if (data) {
      handlerSuccess(data);
    }
    if (error) {
      handleError(error);
    }
  }, [data, error]);

  return [
    { handler: handlerAction, handlerNotify, handlerResetCache },
    {
      data,
      validationErrors: ApolloErrorService.getFirstError(error?.graphQLErrors)
        ?.extensions?.validationErrors,
    },
    result,
  ];
};
