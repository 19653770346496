import { useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  CreateTerminalMutation,
  CreateTerminalMutationVariables,
  useCreateTerminalMutation,
} from '@/apolloGenerated';
import { RouterName } from '@/app/router';
import { MutationResult } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

import { getTerminalInput } from './getTerminalInput';

export const useCreateTerminal = (
  onSuccess?: (data: CreateTerminalMutation['createTerminal']) => void,
): [
  (vars: CreateTerminalMutationVariables) => Promise<void>,
  MutationResult<CreateTerminalMutation>,
] => {
  const { navigate } = useNavigate();
  const [createTerminal, result] = useCreateTerminalMutation();

  const onCreateTerminal = async ({
    idOrganizer,
    input,
  }: CreateTerminalMutationVariables) => {
    await createTerminal({
      variables: {
        idOrganizer,
        input: getTerminalInput(input),
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      const { client, data } = result;

      client.cache.evict({
        args: { id: result.data.createTerminal?.uuid },
        fieldName: 'organizersList',
      });
      navigate(RouterName.TerminalEdit, {
        uuid: data?.createTerminal.uuid,
      });
      toaster.success({ title: 'Терминал успешно создан' });
      onSuccess && onSuccess(result.data.createTerminal);
    }
  }, [result.data, result.error]);

  return [onCreateTerminal, result];
};
