import React, { FC } from 'react';

import { formatAmount } from '@/shared';
import { isNumber, Spacer, Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

interface StatItemProps {
  label: string;
  value: number | string;
  format?: boolean;
  fixed?: boolean;
  hintSlot?: JSX.Element;
}
export const StatItem: FC<StatItemProps> = ({
  fixed = true,
  format,
  hintSlot,
  label,
  value,
}) => (
  <div className={styles.statItem}>
    <Typography variant="body-14" color="on-surface-primary-2">
      {label}
    </Typography>
    <Spacer size={2} />
    <div className={styles.statItem__bottom}>
      <Typography variant="head-20" color="on-surface-primary-1">
        {format && isNumber(value) ? formatAmount(value, fixed) : value}
      </Typography>
      {hintSlot}
    </div>
  </div>
);
