import { useState } from 'react';
import { ObjectSchema, ValidationError } from 'yup';

import { ErrorNestedKeys } from '@/shared';
import { Maybe, NestedKeys } from '@letsdance/ui-kit';

export interface UseValidateResult<T extends Record<string, any>> {
  errors: Maybe<Partial<Record<ErrorNestedKeys<T>, string[]>>>;
}

export const useValidate = <T extends Record<string, any>>(
  schema: ObjectSchema<T>,
): [(values: T) => Promise<UseValidateResult<T>>, UseValidateResult<T>] => {
  const [result, setResult] = useState<UseValidateResult<T>>({ errors: null });
  const validate = async (values: T): Promise<UseValidateResult<T>> => {
    try {
      await schema.validate(values, { abortEarly: false });

      return { errors: null };
    } catch (error) {
      if (error instanceof ValidationError) {
        const allErrors = error.inner.reduce<
          Partial<Record<NestedKeys<T>, string[]>>
        >((acc, curr) => {
          if (curr.path) {
            acc[curr.path as NestedKeys<T>] = curr.errors;
          }

          return acc;
        }, {});
        const result = { errors: allErrors };

        setResult(result);

        return result;
      }
      console.error('Failed validate yup');

      return { errors: null };
    }
  };

  return [validate, result];
};
