import React, { FC, useMemo } from 'react';

import { StatusEnum, TerminalType, useTerminalsQuery } from '@/apolloGenerated';
import { formatEmpty, useOrganizerId } from '@/shared';
import { LoaderOverlay, Spacer, Typography } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

import styles from './styles.module.scss';

export interface TerminalEventCreateProps {
  currentUuids: string[];
  onClose(): void;
  onSelect(val: TerminalType): void;
}
export const TerminalEventSelect: FC<TerminalEventCreateProps> = ({
  currentUuids,
  onClose,
  onSelect,
}) => {
  const idOrganizer = useOrganizerId()!;
  const { data, loading } = useTerminalsQuery({
    variables: {
      idOrganizer,
      status: [StatusEnum.Active],
    },
  });

  const items = useMemo(
    () =>
      (data?.terminalsList.rows || []).filter(
        (el) => !currentUuids.includes(el.uuid),
      ),
    [data, currentUuids],
  );

  return (
    <div>
      <LoaderOverlay show={loading} />
      <Head title="Добавление терминала" onBack={onClose} />
      <Spacer size={10} />
      <div className={styles.terminalPicker}>
        {items.map((el) => (
          <div
            className={styles.terminalPicker__item}
            key={el.uuid}
            onClick={() => onSelect(el as TerminalType)}>
            <Typography variant="head-16" color="on-surface-primary-1">
              {el.name}
            </Typography>
            <Spacer size={4} />
            <Typography variant="body-14" color="on-surface-primary-1">
              Тип: {el.type}
            </Typography>
            <Typography variant="body-14" color="on-surface-primary-1">
              Текст на кнопке: {el.label || '-'}
            </Typography>
            <Typography variant="body-14" color="on-surface-primary-1">
              Комиссия:{' '}
              {formatEmpty(el.holdPercentage, `${el.holdPercentage}%`)}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
