import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  CreateAddressMutation,
  ProductAddressInput,
  ProductAddressType,
} from '@/apolloGenerated';
import { ActionButtons, Container } from '@/shared';
import { RouterName } from '@app/router';
import {
  AddressForm,
  getAddressInitState,
  getAddressInput,
  useCreateAddress,
} from '@entities/Address';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

export const AddressCreateView: FC = () => {
  const { navigate } = useNavigate();
  const [
    { handler: createAddress },
    { validationErrors },
    { loading: createLoading },
  ] = useCreateAddress({
    notify: true,
    onSuccess(result: CreateAddressMutation) {
      navigate(RouterName.AddressEdit, {
        uuid: result.createAddressProduct.uuid,
      });
    },
    resetCache: true,
  });
  const [addressData, setAddressData] = useState<ProductAddressInput>();

  const onClose = () => {
    navigate(RouterName.Addresses);
  };
  const onCreate = async () => {
    if (!addressData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await createAddress({
      input: getAddressInput(addressData),
    });
  };

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: () => onCreate(),
      label: 'Создать место',
    },
  ];

  return (
    <>
      <LoaderOverlay show={createLoading} />
      <Container slim>
        <Head title="Новое место проведения" onBack={onClose} />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        <AddressForm
          initValues={getAddressInitState() as ProductAddressType}
          onChange={setAddressData}
          errors={validationErrors as any}
        />
      </Container>
    </>
  );
};
