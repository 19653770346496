import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  useAddManualTerminalToProductMutation,
  useManualProductTerminalQuery,
  useManualTerminalByOrganizerQuery,
  useRemoveManualTerminalFromProductMutation,
  useUpdateManualTerminalTextMutation,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { Maybe } from '@letsdance/ui-kit';

export const useManualTerminal = (
  uuidProduct: string,
): [
  {
    updateActive: (val: boolean) => void;
    updateText: (val: Maybe<string>) => void;
  },
  { active: boolean; loading: boolean; text: Maybe<string> },
] => {
  const idOrganizer = useOrganizerId()!;
  const { data: manualTerminal, loading } = useManualProductTerminalQuery({
    variables: {
      uuid: uuidProduct,
    },
  });
  const { data: manualTerminalBase } = useManualTerminalByOrganizerQuery({
    skip: !idOrganizer,
    variables: {
      idOrganizer,
    },
  });
  const uuidManualTerminal = manualTerminalBase?.manualTerminalByOrganizer
    ?.uuid as string;
  const [updateManualTerminalText] = useUpdateManualTerminalTextMutation();
  const [addTerminal] = useAddManualTerminalToProductMutation();
  const [removeTerminal] = useRemoveManualTerminalFromProductMutation();

  const [active, setActive] = useState<boolean>(false);
  const [text, setText] = useState<Maybe<string>>('');
  const updateActive = (state: boolean) => {
    setActive(state);
    if (state) {
      addTerminal({
        variables: {
          uuidManualTerminal,
          uuidProduct,
        },
      });
    } else {
      removeTerminal({
        variables: {
          uuidProduct,
        },
      });
    }
  };

  const updateText = useDebouncedCallback((value) => {
    setText(value);
    updateManualTerminalText({
      variables: {
        text: value,
        uuidProduct,
      },
    });
  }, 1000);

  useEffect(() => {
    if (manualTerminal?.product) {
      setActive(!!manualTerminal?.product.manualTerminal);
      setText(manualTerminal?.product.manualPaymentText);
    }
  }, [manualTerminal]);

  return [
    {
      updateActive: updateActive,
      updateText: updateText,
    },
    {
      active,
      loading,
      text,
    },
  ];
};
