import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const LogoIcon: FC<IconProps> = ({
  height = 50,
  width = 280,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 280 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      d="M132.822 49.2045H119.049V10.3306H132.936C136.844 10.3306 140.208 11.1088 143.029 12.6653C145.849 14.2091 148.019 16.4299 149.536 19.3278C151.067 22.2256 151.832 25.6929 151.832 29.7296C151.832 33.7789 151.067 37.2588 149.536 40.1693C148.019 43.0798 145.837 45.3133 142.991 46.8698C140.158 48.4262 136.768 49.2045 132.822 49.2045ZM127.263 42.1624H132.481C134.909 42.1624 136.952 41.7321 138.609 40.8716C140.278 39.9985 141.53 38.6508 142.365 36.8286C143.212 34.9937 143.636 32.6274 143.636 29.7296C143.636 26.857 143.212 24.5097 142.365 22.6875C141.53 20.8652 140.284 19.5239 138.628 18.6634C136.971 17.8029 134.928 17.3727 132.5 17.3727H127.263V42.1624Z"
      fill="#404040"
    />
    <path
      d="M166.075 49.7549C164.216 49.7549 162.559 49.4323 161.104 48.7869C159.65 48.1289 158.499 47.1608 157.651 45.8827C156.817 44.592 156.399 42.9849 156.399 41.0615C156.399 39.4417 156.696 38.0814 157.291 36.9805C157.885 35.8795 158.695 34.9937 159.719 34.3231C160.744 33.6524 161.907 33.1462 163.21 32.8045C164.525 32.4629 165.904 32.2225 167.346 32.0833C169.041 31.9061 170.407 31.7416 171.444 31.5897C172.481 31.4252 173.234 31.1848 173.701 30.8684C174.169 30.5521 174.403 30.0839 174.403 29.4638V29.3499C174.403 28.1478 174.024 27.2177 173.265 26.5597C172.519 25.9016 171.456 25.5726 170.078 25.5726C168.623 25.5726 167.466 25.8953 166.606 26.5407C165.746 27.1734 165.177 27.9706 164.899 28.9323L157.424 28.3249C157.803 26.5533 158.549 25.0222 159.662 23.7314C160.775 22.428 162.211 21.4284 163.969 20.7324C165.74 20.0237 167.789 19.6694 170.116 19.6694C171.735 19.6694 173.284 19.8592 174.764 20.2389C176.256 20.6185 177.578 21.2069 178.729 22.0041C179.893 22.8013 180.81 23.8263 181.48 25.0791C182.15 26.3192 182.485 27.8061 182.485 29.5397V49.2045H174.821V45.1614H174.593C174.125 46.0725 173.499 46.8761 172.715 47.5721C171.931 48.2554 170.989 48.7932 169.888 49.1855C168.788 49.5651 167.517 49.7549 166.075 49.7549ZM168.389 44.1744C169.578 44.1744 170.628 43.9403 171.539 43.4721C172.449 42.9912 173.164 42.3459 173.682 41.536C174.201 40.7261 174.46 39.8087 174.46 38.7837V35.6897C174.207 35.8542 173.86 36.0061 173.417 36.1453C172.987 36.2718 172.5 36.392 171.956 36.5059C171.412 36.6072 170.868 36.7021 170.325 36.7906C169.781 36.8666 169.287 36.9362 168.845 36.9994C167.896 37.1386 167.068 37.3601 166.359 37.6638C165.651 37.9675 165.101 38.3788 164.709 38.8976C164.317 39.4037 164.121 40.0365 164.121 40.7957C164.121 41.8966 164.519 42.7381 165.316 43.3202C166.125 43.8897 167.15 44.1744 168.389 44.1744Z"
      fill="#404040"
    />
    <path
      d="M196.837 32.349V49.2045H188.756V20.049H196.458V25.193H196.8C197.445 23.4973 198.526 22.156 200.044 21.1689C201.561 20.1693 203.402 19.6694 205.564 19.6694C207.588 19.6694 209.352 20.1123 210.858 20.9981C212.363 21.8839 213.533 23.1493 214.367 24.7944C215.202 26.4268 215.619 28.3756 215.619 30.6407V49.2045H207.538V32.0833C207.55 30.299 207.095 28.907 206.172 27.9073C205.248 26.895 203.977 26.3888 202.358 26.3888C201.271 26.3888 200.309 26.6229 199.475 27.0911C198.652 27.5594 198.007 28.2427 197.539 29.1411C197.084 30.0269 196.85 31.0962 196.837 32.349Z"
      fill="#404040"
    />
    <path
      d="M235.156 49.7739C232.171 49.7739 229.603 49.1412 227.453 47.8758C225.316 46.5977 223.671 44.8261 222.52 42.561C221.382 40.2959 220.813 37.6891 220.813 34.7407C220.813 31.7542 221.388 29.1348 222.539 26.8823C223.703 24.6172 225.354 22.852 227.491 21.5865C229.629 20.3085 232.171 19.6694 235.118 19.6694C237.66 19.6694 239.886 20.1313 241.796 21.0551C243.706 21.9788 245.217 23.2759 246.33 24.9462C247.443 26.6166 248.056 28.578 248.17 30.8305H240.544C240.329 29.3752 239.759 28.2047 238.836 27.3189C237.925 26.4205 236.73 25.9712 235.25 25.9712C233.998 25.9712 232.904 26.3129 231.968 26.9962C231.045 27.6669 230.324 28.6476 229.806 29.9384C229.287 31.2291 229.028 32.7919 229.028 34.6268C229.028 36.4869 229.281 38.0687 229.787 39.3721C230.305 40.6755 231.032 41.6689 231.968 42.3522C232.904 43.0355 233.998 43.3772 235.25 43.3772C236.174 43.3772 237.002 43.1874 237.736 42.8077C238.482 42.4281 239.095 41.8777 239.576 41.1564C240.069 40.4224 240.392 39.5429 240.544 38.5179H248.17C248.044 40.7451 247.437 42.7065 246.349 44.4022C245.274 46.0852 243.788 47.4013 241.891 48.3503C239.993 49.2994 237.748 49.7739 235.156 49.7739Z"
      fill="#404040"
    />
    <path
      d="M266.625 49.7739C263.627 49.7739 261.047 49.1665 258.884 47.9517C256.734 46.7242 255.077 44.9906 253.914 42.7508C252.75 40.4983 252.168 37.8346 252.168 34.7596C252.168 31.7606 252.75 29.1285 253.914 26.8634C255.077 24.5983 256.715 22.833 258.828 21.5676C260.952 20.3021 263.444 19.6694 266.302 19.6694C268.225 19.6694 270.015 19.9794 271.671 20.5995C273.341 21.2069 274.795 22.1243 276.035 23.3518C277.287 24.5793 278.261 26.1231 278.957 27.9833C279.652 29.8308 280 31.9947 280 34.4749V36.6957L260.156 36.7147V31.9504L272.392 31.6846C272.392 30.5205 272.139 29.4891 271.633 28.5907C271.128 27.6922 270.426 26.9899 269.528 26.4837C268.642 25.9649 267.611 25.7055 266.435 25.7055C265.208 25.7055 264.121 25.9902 263.172 26.5597C262.236 27.1165 261.503 27.8694 260.971 28.8185C260.44 29.7549 260.168 30.7988 260.156 31.9504V36.7147C260.156 38.1573 260.421 39.4038 260.952 40.4541C261.496 41.5044 262.261 42.3142 263.248 42.8837C264.234 43.4531 265.404 43.7378 266.758 43.7378C267.656 43.7378 268.478 43.6113 269.224 43.3582C269.97 43.1051 270.609 42.7255 271.14 42.2193C271.671 41.7132 272.076 41.0931 272.354 40.3591L279.829 40.8527C279.45 42.6496 278.672 44.2187 277.496 45.5601C276.332 46.8887 274.827 47.9264 272.98 48.673C271.147 49.4069 269.028 49.7739 266.625 49.7739Z"
      fill="#404040"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.771 24.775C107.771 8.22911 97.7624 0 84.1147 0H0V7.49688H82.21C93.0766 7.49688 100.003 12.9016 100.003 24.775C100.003 36.6416 93.0766 42.054 82.21 42.054H65.5756C68.9766 37.5437 76.2091 35.1678 80.3281 31.2504C82.7527 28.9423 84.703 25.8507 84.703 21.6525C84.703 17.5856 82.8397 14.444 80.1271 12.4256C77.5432 10.5025 75.0582 9.69146 72.1061 9.69146C64.9239 9.69146 57.3507 14.9467 57.3507 24.7826H65.1059C65.1059 19.8895 68.5395 17.1845 72.1061 17.1845C74.4944 17.1845 76.9477 19.0751 76.9477 21.6563C76.9477 25.3001 72.3536 28.1546 69.6325 29.7766C63.5927 33.4103 57.4457 37.8231 57.4457 45.6622L57.3735 49.55H82.2062C97.952 49.55 107.771 41.3213 107.771 24.775Z"
      fill="#FF2F6C"
    />
    <path
      d="M41.8381 36.93C41.4513 28.4302 38.7369 15.2987 28.5055 15.2987C18.2741 15.2987 15.5597 28.4302 15.1733 36.93H14.5571L7.90635 10.7049H0.0834433L11.3319 49.5377H19.0503C19.3371 48.2037 19.5749 46.1836 19.8506 43.8416C20.849 35.3546 22.3492 22.602 28.5055 22.602C34.6618 22.602 36.1624 35.3546 37.1608 43.8416C37.4366 46.1836 37.6743 48.2037 37.9611 49.5377H45.6796L56.9335 10.6854L49.0689 10.6859L42.4509 36.93H41.8381Z"
      fill="#404040"
    />
  </svg>
);
