import { FC, useMemo, useState } from 'react';

import {
  Maybe,
  ReferralOrganizerSortKeys,
  ReferralsOrganizerListQuery,
  StatusEnum,
  useReferralsOrganizerListQuery,
} from '@/apolloGenerated';
import { formatEmpty, formatSort, renderCellDate, useModal } from '@/shared';
import { useOrganizerBotStore } from '@entities/Bot';
import { createTgReferralLink } from '@features/Referral/libs/utils';
import {
  ArrayType,
  Button,
  LoaderOverlay,
  MobileItemConfig,
  SortType,
  TableController,
  TrashDenseIcon,
  Typography,
} from '@letsdance/ui-kit';
import { readableStatus } from '@shared/libs/utils/format';

const headers = [
  {
    hide: true,
    key: true,
    value: 'key',
  },
  { sortable: true, title: 'Дата создания', value: 'createdAt', width: 160 },
  {
    sortable: true,
    title: 'Статус',
    value: 'status',
  },
  {
    sortable: true,
    title: 'Название',
    value: 'name',
  },
  {
    title: 'Организатор',
    value: 'organizer',
  },
  {
    sortable: true,
    title: 'Реф. ссылка',
    value: 'link',
  },
  {
    sortable: true,
    title: 'Процент',
    value: 'percentage',
  },
  {
    title: 'Покупок',
    value: 'ordersCount',
  },
  {
    title: '',
    value: 'remove',
    width: 64,
  },
];
const pageSize = 15;

export interface ReferralEventTableProps {
  eventUuid: string;
}
export const ReferralEventTable: FC<ReferralEventTableProps> = ({
  eventUuid,
}) => {
  const openRemoveModal = useModal('removeReferral');
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<ReferralOrganizerSortKeys>(
    ReferralOrganizerSortKeys.CreatedAt,
  );

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useReferralsOrganizerListQuery({
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      params: {
        uuidProduct: eventUuid,
      },
    },
  });
  const { selectedOrganizerBot } = useOrganizerBotStore();
  const data = curData || previousData;
  const rowTemplate = ({
    createdAt,
    key,
    name,
    ordersCount,
    percentage,
    product,
    status,
    usedOrganizer,
  }: ReferralsOrganizerListQuery['referralsOrganizerList']['rows'][0]) => {
    const link = selectedOrganizerBot?.username
      ? createTgReferralLink(
          selectedOrganizerBot.username || '',
          product.query || '',
          key,
        )
      : '';

    return {
      createdAt: renderCellDate({ time: true, timestamp: createdAt }),
      key,
      link: (
        <Typography
          tag={link ? 'a' : 'div'}
          color="primary-1"
          // @ts-ignore
          href={link}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'underline' }}>
          {link ? key : '-'}
        </Typography>
      ),
      name,
      ordersCount: formatEmpty(ordersCount, ordersCount),
      organizer: usedOrganizer.name,
      percentage: `${percentage}%`,
      remove:
        status === StatusEnum.Active ? (
          <Button
            icon
            prependIcon={TrashDenseIcon}
            variant="outlined"
            onClick={() => openRemoveModal({ code: key })}
          />
        ) : (
          ' '
        ),
      status: readableStatus(status),
    };
  };
  const items = useMemo(
    () => (data?.referralsOrganizerList.rows || []).map(rowTemplate),
    [data, selectedOrganizerBot],
  );

  const mobileItemConfig: MobileItemConfig<ArrayType<typeof items>> = {
    subtitleSlot: {
      key: 'link',
    },
    titleSlot: {
      key: 'name',
    },
    valueSlot: {
      key: 'remove',
    },
  };

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        mobileItemConfig={mobileItemConfig}
        total={data?.referralsOrganizerList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as ReferralOrganizerSortKeys);
        }}
        notResetPage
        onRefetch={error && refetch}
      />
    </div>
  );
};
