import React, { FC } from 'react';

import { CreditIcon, ExtendedSwitch } from '@/shared';
import { EditorTextField, Maybe, Spacer } from '@letsdance/ui-kit';

export interface ManualTerminalInputProps {
  active: boolean;
  text: Maybe<string>;
  hasError: boolean;
  onChangeText(val: string): void;
  onChangeActive(val: boolean): void;
}
export const ManualTerminalInput: FC<ManualTerminalInputProps> = ({
  active,
  hasError,
  onChangeActive,
  onChangeText,
  text,
}) => (
  <ExtendedSwitch
    onChange={onChangeActive}
    checked={active}
    label="Переводом на карту"
    icon={CreditIcon}
    hasError={hasError}
    bottomSlot={
      active ? (
        <>
          <Spacer size={8} />
          <EditorTextField
            label="Текст сообщения для оплаты"
            initValue={text || ''}
            onChange={onChangeText}
          />
        </>
      ) : (
        <></>
      )
    }
  />
);
