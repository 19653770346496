import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { UserSortKeys, UserType } from '@/apolloGenerated';
import {
  formatAmount,
  renderCellCheckbox,
  renderCellDate,
  renderCellItemValue,
} from '@/shared';
import { RouterName } from '@app/router';
import {
  ContactListFilter,
  ContactListFilterData,
  ContactStatusIcon,
  ContactTabs,
} from '@entities/Contact';
import { getReadableGender } from '@entities/Contact/libs/helpers';
import { ValidationError } from '@entities/Tariff';
import {
  ArrayType,
  LoaderOverlay,
  MobileItemConfig,
  SortType,
  Spacer,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

import { ContactControl } from '../ContactControl/ContactControl';

export enum ContactTableHeader {
  Check = 'check',
  Icon = 'icon',
  CreatedAt = 'createdAt',
  Name = 'name',
  Data = 'data',
  Orders = 'orders',
  Visited = 'visited',
  Receipts = 'receipts',
  BonusAmount = 'bonusAmount',
  Settings = 'settings',
}

export interface ContactTablePayload {
  page: number;
  pageSize: number;
  sort: SortType;
  sortBy: UserSortKeys;
  filter: ContactListFilterData;
}

export type ContactSelectValidationHandler = (
  user: UserType,
) => ValidationError;

export interface ContactTableSelectProps {
  selected?: UserType[];
  validationData?: object;
  validator?: ContactSelectValidationHandler;

  onChangeSelect?(uuids: UserType[]): void;

  fetchAllUsers?(
    tab: ContactTabs,
    payload: ContactTablePayload,
  ): Promise<UserType[]> | UserType[];

  resetSelectedOnChangeTab?: boolean;
}

export type ContactTableProps = ContactTableSelectProps & {
  tab: ContactTabs;
  data: UserType[] | undefined;
  total: number;
  loading: boolean;
  headers: ContactTableHeader[];
  payload: ContactTablePayload;
  clickedRow?: boolean;
  onChangePayload(payload: ContactTablePayload): void;
  onRefetch?(): void;
  hasError?: boolean;
};
export const defaultContactPayload: ContactTablePayload = {
  filter: { search: '' },
  page: 1,
  pageSize: 10,
  sort: 'desc',
  sortBy: UserSortKeys.CreatedAt,
};
export const ContactTable = ({
  clickedRow,
  data,
  fetchAllUsers,
  hasError,
  headers,
  loading,
  onChangePayload,
  onChangeSelect,
  onRefetch,
  payload,
  resetSelectedOnChangeTab,
  selected = [],
  tab,
  total,
  validationData,
  validator,
}: ContactTableProps) => {
  const { urlFor } = useNavigate();
  const { filter, page, pageSize, sort, sortBy } = payload;

  const filterAndSortHeaders = <T extends { value: string }>(
    headers: ContactTableHeader[],
    filteredHeaders: T[],
  ) => {
    const filtered = filteredHeaders.filter((header) =>
      headers.includes(header.value as ContactTableHeader),
    );

    const sorted = headers.map((headerKey) =>
      filtered.find((header) => header.value === headerKey),
    );

    return [{ hide: true, key: true, value: 'uuid' }, ...sorted] as T[];
  };

  const [checkedAll, setCheckedAll] = useState<boolean>();
  const filterUsers = (users: UserType[]): UserType[] =>
    users.filter((el) =>
      validator ? validator(el).status : true,
    ) as UserType[];

  useEffect(() => {
    onChangeSelect && onChangeSelect(filterUsers(selected));
  }, [data, validationData]);

  const handleReset = () => {
    onChangeSelect && onChangeSelect([]);
  };

  useEffect(() => {
    if (resetSelectedOnChangeTab) {
      handleReset();
    }
    setCheckedAll(false);
  }, [tab]);

  const handleChangeChecked = (checked: boolean, user: UserType) => {
    if (!onChangeSelect) {
      return;
    }
    if (checked) {
      onChangeSelect([...selected, user]);
    } else {
      onChangeSelect(selected.filter((el) => el.uuid !== user.uuid));
    }
  };
  const handleChangeCheckedAll = async (checked: boolean) => {
    setCheckedAll(checked);
    if (!onChangeSelect) {
      return;
    }
    const users = filterUsers(await fetchAllUsers!(tab, payload));

    if (checked) {
      onChangeSelect([
        ...selected.filter(
          (el) => !users.some((user) => user.uuid === el.uuid),
        ),
        ...users,
      ]);
    } else {
      onChangeSelect(
        selected.filter((el) => !users.some((user) => user.uuid === el.uuid)),
      );
    }
  };

  const filteredHeaders = useMemo(
    () =>
      filterAndSortHeaders(headers, [
        {
          title:
            fetchAllUsers && total > 1
              ? renderCellCheckbox({
                  onChange(val: boolean) {
                    handleChangeCheckedAll(val);
                  },
                  value: checkedAll,
                })
              : undefined,
          value: ContactTableHeader.Check,
          width: 48,
        },
        { value: ContactTableHeader.Icon, width: 48 },
        {
          sortable: true,
          title: 'Дата регистрации',
          value: ContactTableHeader.CreatedAt,
          width: 180,
        },
        { title: 'Фамилия/Имя/Пол', value: ContactTableHeader.Name },
        { title: 'Данные', value: ContactTableHeader.Data },
        { title: 'Покупки', value: ContactTableHeader.Orders },
        { title: 'Посещено', value: ContactTableHeader.Visited },
        { title: 'Потрачено, ₽', value: ContactTableHeader.Receipts },
        { title: 'Баланс, Б', value: ContactTableHeader.BonusAmount },
        { title: '', value: ContactTableHeader.Settings, width: 48 },
      ]),
    [headers, fetchAllUsers, total],
  );

  const handleChangePayload = function <T extends keyof ContactTablePayload>(
    key: T,
    value: ContactTablePayload[T],
  ) {
    onChangePayload({ ...payload, [key]: value });
  };

  const rowTemplate = (user: UserType) => {
    const validation = validator
      ? validator(user)
      : { message: '', status: true };
    const checked = selected.some((el) => user.uuid === el.uuid);

    return {
      [ContactTableHeader.BonusAmount]: formatAmount(user.balance, true),
      [ContactTableHeader.Check]: renderCellCheckbox({
        disabled: !validation.status,
        onChange(val: boolean) {
          handleChangeChecked(val, user);
        },
        tooltipText: validation.message,
        value: checked,
      }),
      [ContactTableHeader.CreatedAt]: renderCellDate({
        time: true,
        timestamp: user.createdAt,
      }),
      [ContactTableHeader.Data]: renderCellItemValue({
        label: (
          <div className="flex">
            {user.username ? (
              <Typography
                variant="body-14"
                color="on-surface-primary-1"
                rel="noreferrer"
                style={{ marginRight: 4 }}>
                @{user.username}
              </Typography>
            ) : (
              '-'
            )}{' '}
            {user.phone && ' / ' + user.phone}
          </div>
        ),
        value: user.email,
      }),
      [ContactTableHeader.Icon]: (
        <ContactStatusIcon isBlocked={user.isBlocked} />
      ),
      [ContactTableHeader.Name]: renderCellItemValue({
        label: `${user.last_name || ''} ${user.first_name}`,
        value: getReadableGender(user.gender),
      }),
      [ContactTableHeader.Orders]: String(user.ordersCount),
      [ContactTableHeader.Receipts]: formatAmount(
        user.statsPayments.turnover,
        true,
      ),
      [ContactTableHeader.Settings]: (
        <ContactControl
          uuid={user.uuid}
          username={user.username}
          name={`${user.last_name || ''} ${user.first_name}`}
          isBlocked={user.isBlocked}
          type={tab}
        />
      ),
      [ContactTableHeader.Visited]: String(user.activatedOrdersCount),
      uuid: user.uuid,
    };
  };
  const items = useMemo(
    () => (data || []).map((item) => rowTemplate(item)),
    [data, selected],
  );

  const mobileItemConfig: MobileItemConfig<ArrayType<typeof items>> = {
    actionsSlot: {
      key: ContactTableHeader.Settings,
    },
    checkSlot: {
      key: ContactTableHeader.Check,
    },
    subtitleSlot: {
      key: ContactTableHeader.Data,
    },
    titleSlot: {
      key: ContactTableHeader.Name,
    },
    valueSlot: {
      key: ContactTableHeader.Receipts,
    },
  };

  return (
    <div className="relative" key={tab}>
      <ContactListFilter
        initValue={filter}
        onChange={(filter) => handleChangePayload('filter', filter)}
      />
      <Spacer size={4} />
      <LoaderOverlay show={loading} />
      <TableController
        hasError={hasError}
        data={items}
        mobileItemConfig={mobileItemConfig}
        headers={filteredHeaders}
        total={total}
        initPage={page}
        pageSize={pageSize}
        onChangePage={(page) => handleChangePayload('page', page)}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          onChangePayload({
            ...payload,
            sort: sort,
            sortBy: sortBy as UserSortKeys,
          });
        }}
        onClickRow={
          clickedRow
            ? (e, user) =>
                window.open(
                  urlFor(RouterName.ContactEdit, { uuid: user.uuid }),
                  '_blank',
                )
            : undefined
        }
        notResetPage
        onRefetch={onRefetch}
      />
    </div>
  );
};
