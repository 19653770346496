import { FC, useMemo, useState } from 'react';

import {
  Maybe,
  useWithdrawalsListQuery,
  WithdrawalFromEnum,
  WithdrawalsListQuery,
  WithdrawalSortKeys,
  WithdrawalStatusEnum,
} from '@/apolloGenerated';
import {
  Container,
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { WithdrawStatus } from '@entities/Withdraw';
import {
  ArrayType,
  LoaderOverlay,
  MobileItemConfig,
  SortType,
  TableController,
} from '@letsdance/ui-kit';

import { WithdrawCancel } from '../WithdrawCancel/WithdrawCancel';

const headers = [
  { sortable: true, title: 'Дата', value: 'updatedAt', width: 160 },
  { title: 'Сведения', value: 'info' },
  { title: 'Контрагент', value: 'organizer' },
  { sortable: true, title: 'Сумма, ₽', value: 'amount' },
  { sortable: true, title: 'Статус', value: 'status' },
  { title: ' ', value: 'cancel', width: 60 },
];
const pageSize = 15;

interface WithdrawTableProps {
  from?: WithdrawalFromEnum;
}
export const WithdrawTable: FC<WithdrawTableProps> = ({ from }) => {
  const idOrganizer = useOrganizerId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<WithdrawalSortKeys>(
    WithdrawalSortKeys.UpdatedAt,
  );
  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = useWithdrawalsListQuery({
    skip: !idOrganizer,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      from,
      idOrganizer,
    },
  });
  const data = curData || previousData;

  const rowTemplate = (
    withdraw: WithdrawalsListQuery['withdrawalsByOrganizer']['rows'][0],
  ) => ({
    amount: `- ${formatAmount(withdraw.amount)}`,
    cancel:
      withdraw.status === WithdrawalStatusEnum.InProgress ? (
        <WithdrawCancel uuid={withdraw.uuid} onSuccess={refetch} />
      ) : (
        ' '
      ),
    info: renderCellItemValue({
      label: withdraw.paymentAccount.name,
      value: withdraw.paymentAccount.accountNumber,
    }),
    organizer: renderCellItemValue({
      label: withdraw.organizer.name,
      value: '-',
    }),
    status: <WithdrawStatus status={withdraw.status} />,
    updatedAt: renderCellDate({ time: true, timestamp: withdraw.updatedAt }),
  });
  const items = useMemo(
    () => (data?.withdrawalsByOrganizer.rows || []).map(rowTemplate),
    [data],
  );

  const mobileItemConfig: MobileItemConfig<ArrayType<typeof items>> = {
    subtitleSlot: {
      key: 'amount',
    },
    titleSlot: {
      key: 'info',
    },
    valueSlot: {
      key: 'cancel',
    },
  };

  return (
    <Container className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        mobileItemConfig={mobileItemConfig}
        total={data?.withdrawalsByOrganizer.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as WithdrawalSortKeys);
        }}
        notResetPage
        onRefetch={error && refetch}
      />
    </Container>
  );
};
