import { useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import { useDuplicateBaseTariffMutation } from '@/apolloGenerated';
import { RouterName } from '@app/router';
import { toaster } from '@letsdance/ui-kit';

export const useDuplicateBaseTariff = () => {
  const { navigate } = useNavigate();
  const [duplicateBaseTariff, { client, data, error }] =
    useDuplicateBaseTariffMutation();

  useEffect(() => {
    if (data?.duplicateBaseTariff) {
      toaster.success({
        title: 'Тариф успешно дублирован',
      });
      client.cache.evict({ fieldName: 'baseTariffsList' });
      navigate(RouterName.BaseTariffEdit, {
        uuid: data.duplicateBaseTariff.uuid,
      });
    }
    if (error) {
      toaster.error({ title: 'Ошибка дублирования тарифа' });
    }
  }, [data, error]);

  return (uuid: string) => duplicateBaseTariff({ variables: { uuid } });
};
