import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const TinkoffIcon: FC<IconProps> = ({
  height = 24,
  width = 24,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    {...rest}>
    <path
      d="M0 0H24V12.0703C24 15.1645 22.3493 18.0239 19.6697 19.5712L12 24L4.33031 19.5712C1.65068 18.0239 0 15.1645 0 12.0703V0Z"
      fill="#FFDD2D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.58682 6.35293V10.107C7.10018 9.52733 8.03355 9.13499 9.10074 9.13499H10.2605V13.4991C10.2605 14.6602 9.94502 15.6764 9.47696 16.2354H14.5216C14.0545 15.6758 13.7398 14.6609 13.7398 13.5013V9.13499H14.8995C15.9667 9.13499 16.9001 9.52733 17.4135 10.107V6.35293H6.58682Z"
      fill="#333333"
    />
  </svg>
);
