import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

export interface ScrollXWrapperProps {
  mobile?: boolean;
  className?: string;
}

export const ScrollXWrapper: FC<PropsWithChildren<ScrollXWrapperProps>> = ({
  children,
  className,
  mobile = true,
}) =>
  (
    <div
      className={cx(
        styles.scrollXWrapper,
        mobile && styles.scrollXWrapper_mobile,
        className,
      )}>
      {children}
    </div>
  ) || <div className={styles.scrollXWrapper}>{children}</div>;
