import { FC } from 'react';

import {
  StatusEnum,
  TerminalType,
  useTerminalsByProductQuery,
  useTerminalsCountQuery,
  useUpdateProductTerminalsMutation,
} from '@/apolloGenerated';
import {
  FloatDriver,
  ListPicker,
  useListPicker,
  useOrganizerId,
} from '@/shared';
import { TerminalEventSelect } from '@features/Terminal';
import { LoaderOverlay } from '@letsdance/ui-kit';

export interface EventTerminalsControllerProps {
  productUuid: string;
  disabled?: boolean;
}
export const EventTerminalsController: FC<EventTerminalsControllerProps> = ({
  disabled,
  productUuid,
}) => {
  const idOrganizer = useOrganizerId()!;
  const { data: allTerminals } = useTerminalsCountQuery({
    variables: {
      idOrganizer,
      status: [StatusEnum.Active],
    },
  });
  const { data: terminals, loading } = useTerminalsByProductQuery({
    fetchPolicy: 'network-only',
    variables: {
      uuidProduct: productUuid,
    },
  });
  const [updateProductTerminals] = useUpdateProductTerminalsMutation();
  const { editor, handleAdd, handleChange, handleClose, openEditor, value } =
    useListPicker<TerminalType>(
      terminals?.terminalsByProduct as TerminalType[],
      (val: TerminalType[]) => {
        updateProductTerminals({
          variables: {
            uuidProduct: productUuid,
            uuidTerminals: val.map((el) => el.uuid),
          },
        });
      },
    );

  if (loading) {
    return <LoaderOverlay show />;
  }

  return (
    <>
      <ListPicker
        initValues={value}
        labelRender={(item) => `${item.name} (${item.type})`}
        valueKey="uuid"
        renderSubtitle={(item) => item.label}
        onChange={handleChange}
        onAdd={() =>
          openEditor({
            type: 'create',
          })
        }
        hasAdd={(allTerminals?.terminalsList.count || 0) > value.length}
        disabled={disabled}
        texts={{
          addBtn: 'Добавить терминал',
          removeModal: {
            title: 'Удалить терминал?',
          },
        }}
      />
      <FloatDriver isOpen={!!editor} onClose={handleClose}>
        {editor?.type === 'create' && (
          <TerminalEventSelect
            currentUuids={value.map((el) => el.uuid)}
            onClose={handleClose}
            onSelect={handleAdd}
          />
        )}
      </FloatDriver>
    </>
  );
};
