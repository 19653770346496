import React, { FC, useEffect } from 'react';

import {
  OrderActionEmailNotifyParam,
  StatusEnum,
  TransferOrderToProductMutationVariables,
  useEventsListQuery,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { getEventName } from '@entities/Event';
import {
  DropdownItem,
  NestedKeys,
  Select,
  Spacer,
  Switch,
  useForm,
} from '@letsdance/ui-kit';

export type TransferToProductOrderInput = Omit<
  TransferOrderToProductMutationVariables,
  'orderId'
>;
export interface TransferToProductOrderFormProps {
  initValues: TransferToProductOrderInput;

  onChange(val: TransferToProductOrderInput): void;

  errors?: Record<NestedKeys<TransferToProductOrderInput>, string[]>;
  readonly?: boolean;
}

export const TransferToProductOrderForm: FC<
  TransferToProductOrderFormProps
> = ({ initValues, onChange, readonly }) => {
  const organizerId = useOrganizerId()!;
  const { data: eventsData } = useEventsListQuery({
    variables: {
      params: {
        organizerId,
      },
      status: [StatusEnum.Active, StatusEnum.Draft],
    },
  });
  const selectItems = eventsData?.productsList.rows || [];
  const { setBatchValues, values } = useForm<TransferToProductOrderInput>(
    console.log,
    initValues,
  );

  useEffect(() => {
    onChange(values);
  }, [values]);

  const updateNotify = function <T extends keyof OrderActionEmailNotifyParam>(
    key: T,
    val: OrderActionEmailNotifyParam[T],
  ) {
    setBatchValues({
      notify: {
        ...values.notify,
        [key]: val,
      },
    });
  };

  return (
    <div>
      <Select
        size="large"
        placeholder="Выберите событие"
        onChange={(e) => setBatchValues({ productUuid: e as string })}
        renderValue={(val) => {
          const item = selectItems.find((el) => el.uuid === val);

          return item
            ? getEventName(item.name, item.startedDate)
            : (val as string);
        }}
        hideOnSelect
        fullWidth>
        {selectItems.map(({ name, startedDate, uuid }) => (
          <DropdownItem
            key={uuid}
            label={getEventName(name, startedDate)}
            value={uuid}
          />
        ))}
      </Select>
      <Spacer size={10} />
      <Switch
        label="Отправить уведомление"
        checked={!!values.notify?.sendNotifyToEmail}
        disabled={readonly}
        onChange={(e) => updateNotify('sendNotifyToEmail', e.target.checked)}
      />
    </div>
  );
};
