import { gql } from '@apollo/client';
import { baseTariffFragment } from '@shared/query/baseTariff';

export const subTariffFragment = gql`
  fragment SubTariffFields on SubTariffType {
    createdAt
    updatedAt
    uuid
    name
    price
    specialPrice
    description
    query
    ordersCount
    startedDate
    endDate
    status
    availableOrdersCount
    visible
    isLimitedOrders
    statsOrdersCount {
      paid
      refunded
      transferredProduct
      transferredUser
      awaitPay
    }
  }
`;

export const subTariffsList = gql`
  ${subTariffFragment}
  query subTariffsList(
    $productUuid: UUID!
    $status: [StatusEnum!]
    $filters: SubTariffListFilterInput
  ) {
    subTariffsList(
      productUuid: $productUuid
      status: $status
      filters: $filters
    ) {
      rows {
        ...SubTariffFields
        baseTariff {
          name
          type
          ordersCount
          isLimitedOrders
        }
      }
    }
  }
`;

export const subTariff = gql`
  ${subTariffFragment}
  ${baseTariffFragment}
  query subTariff($uuid: UUID!) {
    subTariff(uuid: $uuid) {
      ...SubTariffFields
      baseTariff {
        ...BaseTariffFields
      }
    }
  }
`;

export const createTariff = gql`
  ${subTariffFragment}
  mutation createTariff(
    $input: SubTariffInput!
    $uuidProduct: UUID
    $uuidParentTariff: UUID!
  ) {
    createTariff(
      input: $input
      uuidParentTariff: $uuidParentTariff
      uuidProduct: $uuidProduct
    ) {
      ...SubTariffFields
      baseTariff {
        ...BaseTariffFields
      }
    }
  }
`;

export const updateTariff = gql`
  ${subTariffFragment}
  mutation updateTariff($uuid: UUID!, $input: SubTariffInput!) {
    updateTariff(input: $input, uuid: $uuid) {
      ...SubTariffFields
      baseTariff {
        ...BaseTariffFields
      }
    }
  }
`;

export const removeTariff = gql`
  mutation removeTariff($uuid: UUID!) {
    removeTariff(uuid: $uuid)
  }
`;

export const updateProductTariffs = gql`
  mutation updateProductTariffs($uuids: [UUID!]!, $productUuid: UUID!) {
    updateProductTariffs(uuids: $uuids, productUuid: $productUuid)
  }
`;
