import React, { FC, useState } from 'react';

import { UserGroupType, useUserGroupsQuery } from '@/apolloGenerated';
import { FormSection, useOrganizerId } from '@/shared';
import { Select } from '@letsdance/ui-kit';

export interface ContactTariffAccessSelectProps {
  initValue?: UserGroupType[];
  onChange(uuids: string[]): void;
}
export const ContactTariffAccessSelect: FC<ContactTariffAccessSelectProps> = ({
  initValue,
  onChange,
}) => {
  const idOrganizer = useOrganizerId()!;
  const { data: groupsData } = useUserGroupsQuery({
    variables: {
      idOrganizer,
    },
  });
  const [value, setValue] = useState<UserGroupType[]>(initValue || []);

  const handleChange = (val: UserGroupType[]) => {
    setValue(val);
    onChange(val?.map((el) => el.uuid) || []);
  };

  return (
    <FormSection
      title={`Группы пользователей (${value?.length > 0 ? value.length : 'доступно всем'})`}>
      <div className="grid-2">
        <Select
          size="large"
          placeholder="Группы"
          initValue={initValue}
          items={(groupsData?.userGroups.rows || []) as UserGroupType[]}
          trackKey={'uuid'}
          labelKey={'name'}
          onChange={handleChange}
          multiple
          fullWidth
        />
      </div>
    </FormSection>
  );
};
