import { FC, useEffect } from 'react';
import moment from 'moment-timezone';

import {
  ProductInput,
  ProductType,
  StatusEnum,
  SubTariffType,
  useEventQuery,
  useRemoveTariffMutation,
  useSubTariffsListQuery,
} from '@/apolloGenerated';
import {
  FloatDriver,
  ListPicker,
  SectionCard,
  timezone,
  useListPicker,
  useModal,
} from '@/shared';
import {
  Button,
  FieldError,
  LoaderOverlay,
  PlusDenseIcon,
  Spacer,
  Typography,
} from '@letsdance/ui-kit';
import { readableStatus } from '@shared/libs/utils/format';
import { TariffCreate, TariffEdit } from '@widgets/Tariff';

export interface EventTariffsProps {
  eventUuid: string;
  errors?: string[];
  onChange?(tariffs: SubTariffType[]): void;
}
export const EventTariffs: FC<EventTariffsProps> = ({
  errors,
  eventUuid,
  onChange,
}) => {
  const { data: product } = useEventQuery({
    variables: {
      uuid: eventUuid,
    },
  });
  const { data: subTariffs, loading } = useSubTariffsListQuery({
    fetchPolicy: 'network-only',
    variables: {
      productUuid: eventUuid,
      status: [StatusEnum.Draft, StatusEnum.Active],
    },
  });
  const openTariffInfoModal = useModal('tariffOrderInfo');
  const [removeTariff] = useRemoveTariffMutation();
  const {
    editor,
    handleAdd,
    handleChange,
    handleClose,
    handleEdit,
    openEditor,
    value,
  } = useListPicker<SubTariffType>(
    subTariffs?.subTariffsList.rows as SubTariffType[],
  );
  const hasTariffs = value.length > 0;

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  const renderSubtitle = ({
    availableOrdersCount,
    baseTariff,
    endDate,
    isLimitedOrders,
    ordersCount,
    startedDate,
    status,
  }: SubTariffType) => {
    let text = readableStatus(status);

    if (status === StatusEnum.Draft && startedDate) {
      text += ` до ${moment(startedDate).tz(timezone).format('DD MMM YYYY HH:mm')}`;
    }

    if (status === StatusEnum.Active && endDate) {
      text += ` до ${moment(endDate).tz(timezone).format('DD MMM YYYY HH:mm')}`;
    }

    if (
      isLimitedOrders ||
      (isLimitedOrders === null && baseTariff?.isLimitedOrders)
    ) {
      text += ` (доступно ${availableOrdersCount} из ${ordersCount || baseTariff?.ordersCount})`;
    }

    return text;
  };

  if (loading) {
    return <LoaderOverlay show />;
  }

  const AddTariffButton = (
    <Button
      prependIcon={PlusDenseIcon}
      error={!!errors?.length && value.length === 0}
      variant="outlined"
      size="small"
      onClick={() => openEditor({ type: 'create' })}>
      Добавить тариф
    </Button>
  );

  return (
    <SectionCard title="Тарифы" rightHeadSlot={hasTariffs && AddTariffButton}>
      {!hasTariffs && (
        <div>
          <Typography variant="body-14" color="on-surface-primary-2">
            Нет добавленных тарифов
          </Typography>
          <Spacer size={5} />
          {AddTariffButton}
          {!!errors?.length && (
            <>
              <Spacer size={4} />
              <FieldError error={errors} />
            </>
          )}
        </div>
      )}
      <ListPicker
        initValues={value}
        labelRender={(item) => item.name || item.baseTariff?.name || ''}
        valueKey="uuid"
        renderSubtitle={renderSubtitle}
        onChange={handleChange}
        hasAdd={false}
        onEdit={(val, readonly) =>
          openEditor({ readonly, type: 'edit', uuid: val.uuid })
        }
        onRemove={(val) => removeTariff({ variables: { uuid: val.uuid } })}
        onDetailInfo={(val) => openTariffInfoModal({ uuid: val.uuid })}
        texts={{
          removeModal: {
            title: 'Удалить тариф?',
          },
        }}
      />
      <FloatDriver isOpen={!!editor} onClose={handleClose}>
        {editor?.type === 'edit' && (
          <TariffEdit
            eventData={product?.product as ProductInput}
            uuid={editor.uuid!}
            disabled={editor.readonly}
            onClose={handleClose}
            onSuccess={(data) => handleEdit(editor.uuid!, data)}
          />
        )}
        {editor?.type === 'create' && (
          <TariffCreate
            eventData={product?.product as ProductType}
            onClose={handleClose}
            onSuccess={(data) => handleAdd(data)}
          />
        )}
      </FloatDriver>
    </SectionCard>
  );
};
