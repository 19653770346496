import React, { FC, useEffect } from 'react';

import {
  OrganizerFeatureFlagsInput,
  OrganizerInput,
  OrganizerOrderInstructionsInput,
  OrganizerSupplierInfoInput,
  OrganizerType,
} from '@/apolloGenerated';
import {
  DeeplinkCopy,
  FormSection,
  handleNumberInput,
  handleQueryInput,
  useImageUploader,
} from '@/shared';
import {
  Combobox,
  EditorTextField,
  FileSize,
  ImageUploader,
  isArray,
  NestedKeys,
  Spacer,
  TextField,
  useForm,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventFormProps {
  initValues: OrganizerType;

  onChange(val: OrganizerInput): void;

  onSynonymsChange(val: string[]): void;

  errors?: Record<NestedKeys<OrganizerInput>, string[]>;
}

export const OrganizerForm: FC<EventFormProps> = ({
  errors,
  initValues,
  onChange,
  onSynonymsChange,
}) => {
  const uploadImage = useImageUploader();
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<OrganizerInput>(console.log, initValues);

  const synonyms = isArray(initValues.synonyms)
    ? initValues.synonyms.map((el) => el.value)
    : [];

  useEffect(() => {
    onChange(values);
  }, [values]);

  const updateSupplierInfo = function <
    T extends keyof OrganizerSupplierInfoInput,
  >(key: T, value: OrganizerSupplierInfoInput[T]) {
    setBatchValues({
      supplierInfo: {
        ...values.supplierInfo,
        [key]: value || null,
      },
    });
  };

  const updateInstructions = function <
    T extends keyof OrganizerOrderInstructionsInput,
  >(key: T, value: OrganizerOrderInstructionsInput[T]) {
    setBatchValues({
      orderInstructions: {
        ...values.orderInstructions,
        [key]: value || null,
      },
    });
  };

  const updateFeatureFlags = function <
    T extends keyof OrganizerFeatureFlagsInput,
  >(key: T, val: OrganizerFeatureFlagsInput[T]) {
    setBatchValues({
      featureFlags: {
        ...values.featureFlags,
        [key]: val,
      },
    });
  };

  return (
    <div className={styles.organizerForm}>
      <FormSection title="Основная информация">
        <div className={styles.organizerForm__mainInfo}>
          <ImageUploader
            initValue={values.image}
            onChange={(val) => setBatchValues({ image: val })}
            error={errors?.image}
            uploadImageCallback={uploadImage}
            maxFileSize={{
              size: 10,
              unit: FileSize.MB,
            }}
          />
          <div>
            <TextField
              label="Название"
              size="large"
              fullWidth
              error={errors?.name}
              name="name"
              initValue={values.name}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <TextField
              label="Telegram"
              size="large"
              fullWidth
              error={errors?.telegram}
              name="telegram"
              initValue={values.telegram!}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <TextField
              label="Ключ диплинка (латиница)"
              size="large"
              fullWidth
              error={errors?.query}
              name="query"
              initValue={values.query!}
              onKeyDown={handleQueryInput}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <DeeplinkCopy to="organizer" value={values.query} />
            <Spacer size={10} />
            <DeeplinkCopy
              label="Ссылка для регистрации пользователя"
              to="registration"
              value={values.query}
            />
          </div>
        </div>
        <Spacer size={10} />
        <EditorTextField
          label="Описание"
          initValue={values.description}
          error={errors?.description}
          onChange={(e) => setBatchValues({ description: e })}
        />
      </FormSection>
      <FormSection title="Фичи">
        <div className="grid-2">
          <TextField
            label="Разрешенное кол-во переносов билета"
            size="large"
            fullWidth
            error={
              errors?.['featureFlags.allowedNumberForTransferToProductOrder']
            }
            onKeyPress={handleNumberInput}
            name="allowedNumberForTransferToProductOrder"
            initValue={String(
              values.featureFlags.allowedNumberForTransferToProductOrder,
            )}
            onChange={(e) =>
              updateFeatureFlags(
                'allowedNumberForTransferToProductOrder',
                +e.target.value || 0,
              )
            }
          />
        </div>
      </FormSection>
      <FormSection title="Инструкции">
        <EditorTextField
          label="Инструкция переноса"
          error={errors?.['orderInstructions.transferToProduct']}
          initValue={values.orderInstructions.transferToProduct!}
          onChange={(e) => updateInstructions('transferToProduct', e)}
        />
        <Spacer size={10} />
        <EditorTextField
          label="Инструкция передачи"
          error={errors?.['orderInstructions.transferToUser']}
          initValue={values.orderInstructions.transferToUser!}
          onChange={(e) => updateInstructions('transferToUser', e)}
        />
        <Spacer size={10} />
        <EditorTextField
          label="Инструкция возврата"
          error={errors?.['orderInstructions.refund']}
          initValue={values.orderInstructions.refund!}
          onChange={(e) => updateInstructions('refund', e)}
        />
      </FormSection>
      <FormSection title="Платёжная информация" id="organizerSupplierInfo">
        <div className="grid-3">
          <TextField
            label="ФИО"
            size="large"
            fullWidth
            error={errors?.['supplierInfo.name']}
            initValue={values.supplierInfo?.name || ''}
            onChange={(e) => updateSupplierInfo('name', e.target.value)}
          />
          <TextField
            label="Номер телефона"
            size="large"
            fullWidth
            error={errors?.['supplierInfo.phone']}
            initValue={values.supplierInfo?.phone || ''}
            onChange={(e) => updateSupplierInfo('phone', e.target.value)}
          />
          <TextField
            label="ИНН"
            size="large"
            fullWidth
            error={errors?.['supplierInfo.inn']}
            onKeyPress={handleNumberInput}
            initValue={values.supplierInfo?.inn || ''}
            onChange={(e) => updateSupplierInfo('inn', e.target.value)}
          />
        </div>
      </FormSection>
      <FormSection title="Дополнительная информация">
        <Combobox
          label="Синонимы"
          fullWidth
          max={10}
          initValue={synonyms}
          onChange={onSynonymsChange}
        />
      </FormSection>
    </div>
  );
};
