import { Route } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { AuthView } from '@pages/Auth';
import { AuthLayout } from '@widgets/Layout';

import { adminRoutes } from './admin';
import { staticRoutes } from './static';

export const routes: Route[] = [
  {
    component: AuthView,
    name: RouterName.Home,
    path: '/',
    props: {
      layout: AuthLayout,
    },
  },
  ...adminRoutes,
  ...staticRoutes,
];
