import { FC } from 'react';

import { useEventMainInfoStore } from '@entities/Event';
import { useEventTariffsStore } from '@entities/Tariff';
import { EventTariffs } from '@features/Tariff';

export const CreateEventTariffs: FC = () => {
  const { uuid } = useEventMainInfoStore();
  const { errors, setTariffs } = useEventTariffsStore();

  if (!uuid) {
    throw new Error('Event not was created');
  }

  return (
    <EventTariffs eventUuid={uuid} errors={errors} onChange={setTariffs} />
  );
};
