import { FC, useMemo, useState } from 'react';

import {
  Maybe,
  OrderType,
  PaymentsListQuery,
  PaymentSortKeys,
  PaymentStatusEnum,
  usePaymentsListQuery,
} from '@/apolloGenerated';
import {
  calculateAmounts,
  Container,
  formatAmount,
  formatEmpty,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useModal,
  useOrganizerId,
} from '@/shared';
import { router, RouterName } from '@app/router';
import { OrderEventInfoCell } from '@entities/Purchases';
import {
  ArrayType,
  Button,
  CheckDenseIcon,
  CloseDenseIcon,
  LoaderOverlay,
  MobileItemConfig,
  SortType,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

const pageSize = 15;

export const AwaitSubmitPaymentsTable: FC = () => {
  const openSubmitOrderModal = useModal('submitPayment');
  const openCancelOrderModal = useModal('cancelPayment');
  const openProofOrderModal = useModal('proofPayment');
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<PaymentSortKeys>(
    PaymentSortKeys.CreatedAt,
  );
  const headers = [
    {
      hide: true,
      key: true,
      value: 'key',
    },
    { sortable: true, title: 'Дата', value: 'createdAt', width: 160 },
    {
      title: 'Операция',
      value: 'id',
    },
    { title: 'Сведения', value: 'info' },
    { title: 'Контрагент', value: 'user' },
    { title: 'Стоимость, ₽ ', value: 'turnover' },
    { title: ' ', value: 'actions' },
  ];

  const {
    data: curData,
    error,
    loading,
    previousData,
    refetch,
  } = usePaymentsListQuery({
    skip: !organizerId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      organizerId,
      status: [PaymentStatusEnum.AwaitingConfirmation],
    },
  });

  const viewContact = (uuid: string) => {
    window.open(router.urlFor(RouterName.ContactEdit, { uuid }), '_blank');
  };

  const data = curData || previousData;
  const rowTemplate = ({
    createdAt,
    order,
    payment_uuid,
    price,
    proof,
    user,
    uuid,
  }: PaymentsListQuery['paymentsList']['rows'][0]) => {
    const { commission, turnover } = calculateAmounts({
      holdPercentage: order.fixedHoldPercentage,
      price,
      referralPercentage: order.referralOrganizer?.percentage,
    });

    return {
      actions: (
        <div className="d-flex gap-2 justify-content-end">
          {proof?.file && (
            <Button
              onClick={() => openProofOrderModal({ proof: proof.file })}
              variant="outlined">
              Чек
            </Button>
          )}
          <Button
            onClick={() => openSubmitOrderModal({ uuid })}
            variant="outlined"
            icon
            prependIcon={() => CheckDenseIcon({ color: 'var(--success-2)' })}
          />
          <Button
            onClick={() => openCancelOrderModal({ uuid })}
            variant="outlined"
            icon
            prependIcon={() => CloseDenseIcon({ color: 'var(--error-2)' })}
          />
        </div>
      ),
      commission: formatEmpty(commission, `- ${formatAmount(commission)}`),
      createdAt: renderCellDate({ time: true, timestamp: createdAt }),
      id: renderCellItemValue({
        label: `ID: ${order.id}`,
        value: payment_uuid,
      }),
      info: <OrderEventInfoCell order={order as OrderType} showPair />,
      key: uuid,
      turnover: formatAmount(turnover),
      user: renderCellItemValue({
        label: (
          <Typography
            variant="body-14"
            color="on-surface-primary-1"
            rel="noreferrer"
            className="cursor-pointer"
            onClick={() => viewContact(user.uuid)}>
            {user.last_name} {user.first_name}
          </Typography>
        ),
        value: user.email,
      }),
    };
  };
  const items = useMemo(
    () => (data?.paymentsList.rows || []).map(rowTemplate),
    [data],
  );

  const mobileItemConfig: MobileItemConfig<ArrayType<typeof items>> = {
    subtitleSlot: {
      key: 'user',
    },
    titleSlot: {
      key: 'id',
    },
    valueSlot: {
      key: 'createdAt',
    },
  };

  return (
    <Container className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        hasError={!!error}
        data={items}
        headers={headers}
        mobileItemConfig={mobileItemConfig}
        total={data?.paymentsList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as PaymentSortKeys);
        }}
        notResetPage
        onRefetch={error && refetch}
      />
    </Container>
  );
};
