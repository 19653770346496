import { FC, useMemo } from 'react';

import {
  UsersControlOrganizerQuery,
  useUsersControlOrganizerQuery,
} from '@/apolloGenerated';
import { renderCellDate, renderCellItemValue } from '@/shared';
import { UserOrganizerControl } from '@features/Contact';
import { LoaderOverlay, TableController, Typography } from '@letsdance/ui-kit';

const headers = [
  { hide: true, key: true, value: 'uuid' },
  { sortable: true, title: 'Дата регистрации', value: 'createdAt', width: 180 },
  { title: 'Фамилия/имя/ID', value: 'name' },
  { title: 'Данные', value: 'data' },
  { title: '', value: 'settings', width: 48 },
];

export interface UsersControlTableProps {
  organizerId: number;
}

export const UsersControlTable: FC<UsersControlTableProps> = ({
  organizerId,
}) => {
  const { data, loading } = useUsersControlOrganizerQuery({
    variables: {
      organizerId,
    },
  });

  const rowTemplate = (
    user: UsersControlOrganizerQuery['usersControlOrganizer']['rows'][0],
  ) => ({
    createdAt: renderCellDate({ time: true, timestamp: user.createdAt }),
    data: renderCellItemValue({
      label: (
        <>
          {user.username ? (
            <Typography
              variant="body-14"
              color="on-surface-primary-1"
              rel="noreferrer">
              @{user.username}
            </Typography>
          ) : (
            '-'
          )}{' '}
          {user.phone && ' / ' + user.phone}
        </>
      ),
      value: user.email,
    }),
    name: renderCellItemValue({
      label: `${user.last_name || ''} ${user.first_name}`,
      value: user.uuid,
    }),
    settings: <UserOrganizerControl uuid={user.uuid} />,
    uuid: user.uuid,
  });
  const items = useMemo(
    () =>
      (data?.usersControlOrganizer.rows || []).map((item) => rowTemplate(item)),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController data={items} headers={headers} />
    </div>
  );
};
