import { FC, PropsWithChildren, useEffect } from 'react';

import { useMeLazyQuery } from '@/apolloGenerated';
import { history, HomeRouteName, router } from '@app/router';

import styles from './styles.module.scss';

export const AuthLayout: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const [fetchUser] = useMeLazyQuery();

  const authMiddleware = async () => {
    const { data } = await fetchUser();

    if (data?.me.role && HomeRouteName[data?.me.role]) {
      history.navigate(router.urlFor(HomeRouteName[data?.me.role]));
    }
  };

  useEffect(() => {
    authMiddleware();
  }, []);

  return (
    <div className={styles.auth}>
      <div className={styles.auth__container}>
        <div className={styles.auth__content}>{children}</div>
      </div>
    </div>
  );
};
