import { TerminalEnum, TerminalInput } from '@/apolloGenerated';

export const getTerminalInput = ({
  holdPercentage,
  invoiceLifetime,
  label,
  name,
  token,
  type,
}: TerminalInput): TerminalInput => ({
  holdPercentage,
  invoiceLifetime,
  label,
  name,
  token,
  type,
});

export const getTerminalInitState = (): TerminalInput => ({
  holdPercentage: null,
  invoiceLifetime: 'PT1H',
  label: '',
  name: '',
  token: null,
  type: TerminalEnum.Tinkoff,
});
