import React, { FC, useEffect } from 'react';

import { useCancelWithdrawalMutation } from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface WithdrawCancelModalProps {
  uuid: string;
  onSuccess(): void;
}
declare global {
  interface ModalProps {
    cancelWithdraw: WithdrawCancelModalProps;
  }
}
export const WithdrawCancelModal: FC<
  WithCloseModal<WithdrawCancelModalProps>
> = ({ onClose, onSuccess, uuid }): JSX.Element => {
  const idOrganizer = useOrganizerId()!;
  const [cancelWithdraw, { client, data, error, loading }] =
    useCancelWithdrawalMutation();

  const handleSubmit = () => {
    cancelWithdraw({ variables: { idOrganizer, uuid } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Запрос на вывод сресдств успешно отменён',
      });
      client.cache.evict({ fieldName: 'organizer' });
      onSuccess();
    }
    if (error) {
      toaster.error({
        title:
          'Ошибка отмены запроса на вывод. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Отменить заявку на вывод"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button fullWidth onClick={handleSubmit} disabled={loading}>
                Подтвердить
              </Button>
            </div>
          }
        />
      }>
      <Typography variant="body-16">
        После отмены заявки на вывод средств сумма вывода поступит обратно на
        ваш счёт
      </Typography>
    </Dialog>
  );
};
