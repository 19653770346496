import { gql } from '@apollo/client';

export const supplierInfoFragment = gql`
  fragment SupplierInfoFields on OrganizerSupplierInfoType {
    name
    inn
    phone
  }
`;

export const organizerAdminFeatureFragment = gql`
  fragment OrganizerAdminFeatureFields on OrganizerFeatureType {
    createReferral
    viewReferral
    manualPayment
  }
`;

export const organizerFragment = gql`
  fragment OrganizerFields on OrganizerType {
    id
    name
    createdAt
    productsCount
    telegram
    ordersCount
    salesBalance
    referralBalance
    creditBalance
    statsPayments {
      turnover
    }
  }
`;

export const organizersListCount = gql`
  query organizersListCount($verified: Boolean) {
    organizersList(verified: $verified) {
      count
    }
  }
`;

export const organizersList = gql`
  ${organizerFragment}
  query organizersList(
    $verified: Boolean
    $status: [StatusEnum!]
    $filters: OrganizerListFilterInput
  ) {
    organizersList(verified: $verified, status: $status, filters: $filters) {
      rows {
        ...OrganizerFields
        bots {
          username
          id
        }
      }
      count
    }
  }
`;

export const organizer = gql`
  ${supplierInfoFragment}
  query organizer($id: Int!) {
    organizerAdmin(id: $id) {
      createdAt
      updatedAt
      deletedAt
      id
      image
      name
      query
      description
      telegram
      featureFlags {
        allowedNumberForTransferToProductOrder
      }
      orderInstructions {
        transferToProduct
        transferToUser
        refund
      }
      supplierInfo {
        ...SupplierInfoFields
      }
      synonyms {
        value
      }
    }
  }
`;

export const organizerFiles = gql`
  query organizerFiles($id: Int!) {
    organizerAdmin(id: $id) {
      files {
        oferta
        agent
        policy
      }
    }
  }
`;

export const organizerFeatures = gql`
  ${organizerAdminFeatureFragment}
  query organizerFeatures($idOrganizer: Float!) {
    organizerFeatures(idOrganizer: $idOrganizer) {
      ...OrganizerAdminFeatureFields
    }
  }
`;

export const updateAdminOrganizerFeatures = gql`
  ${organizerAdminFeatureFragment}
  mutation updateAdminOrganizerFeatures(
    $idOrganizer: Float!
    $input: OrganizerFeatureInput!
  ) {
    updateOrganizerFeatures(idOrganizer: $idOrganizer, input: $input) {
      ...OrganizerAdminFeatureFields
    }
  }
`;

export const updateOrganizer = gql`
  mutation updateOrganizer($id: Float!, $input: OrganizerInput!) {
    updateOrganizer(id: $id, input: $input) {
      id
    }
  }
`;

export const createOrganizer = gql`
  mutation createOrganizer($input: OrganizerInput!) {
    createOrganizer(input: $input) {
      id
    }
  }
`;

export const organizerShortcodes = gql`
  query organizerShortcodes {
    organizerShortcodes
  }
`;

export const updateOrganizerSynonyms = gql`
  mutation updateOrganizerSynonyms($idOrganizer: Float!, $values: [String!]!) {
    updateOrganizerSynonyms(idOrganizer: $idOrganizer, values: $values)
  }
`;
