import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { TerminalEnum } from '@/apolloGenerated';

export type EventTerminalType = TerminalEnum | 'Manual';
interface EventTerminalsStoreState {
  terminals: EventTerminalType[];
  errors: string[];
}

interface EventTerminalsStoreType extends EventTerminalsStoreState {
  setTerminals(terminals: EventTerminalType[]): void;
  clearErrors(): void;
  reset(): void;
  setErrors(errors: string[]): void;
}

const initState: EventTerminalsStoreState = {
  errors: [],
  terminals: [],
};

export const useEventTerminalsStore = create<EventTerminalsStoreType>()(
  immer((set) => ({
    ...initState,
    clearErrors: () =>
      set((state) => {
        state.errors = [];
      }),
    reset: () => set(initState),
    setErrors: (errors) =>
      set((state) => {
        state.errors = errors;
      }),
    setTerminals: (terminals) =>
      set((state) => {
        state.terminals = terminals;
      }),
  })),
);
