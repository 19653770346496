import React, { FC, MouseEvent, useState } from 'react';

import { useModal } from '@/shared';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  MoreIcon,
  stopEvent,
} from '@letsdance/ui-kit';

interface BotControlProps {
  id: number;
  active: boolean;
}

export const BotControl: FC<BotControlProps> = ({ active, id }) => {
  const openDeactivateModal = useModal('deactivateBot');
  const openRemoveModal = useModal('removeBot');
  const openActivateModal = useModal('activateBot');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleChangeDropdown = (val: boolean) => {
    setIsOpen(val);
  };

  const eventCallback = (callback: () => void) => (e: MouseEvent) => {
    setIsOpen(false);
    stopEvent(e);
    callback();
  };

  return (
    <Dropdown
      value={isOpen}
      targetSlot={
        <Button
          icon
          prependIcon={MoreIcon}
          variant="text"
          size="large"
          onClick={stopEvent}
        />
      }
      placement="bottom-end"
      width={260}
      zIndex={1000}
      onChange={handleChangeDropdown}>
      <DropdownList maxHeight={100}>
        {active ? (
          <DropdownItem
            fullWidth
            onClick={eventCallback(() => openDeactivateModal({ id }))}
            label="Деактивировать"
          />
        ) : (
          <DropdownItem
            fullWidth
            onClick={eventCallback(() => openActivateModal({ id }))}
            label="Активировать"
          />
        )}
        <DropdownItem
          fullWidth
          onClick={eventCallback(() => openRemoveModal({ id }))}
          label="Удалить бота"
        />
      </DropdownList>
    </Dropdown>
  );
};
