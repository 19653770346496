import cx from 'classnames';

import { useModal } from '@/shared';
import {
  ArrowBottomIcon,
  ArrowRightIcon,
  Button,
  InfoIcon,
  MinusIcon,
  Typography,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ListPickerProps<T> {
  initValues?: T[];
  valueKey: keyof T;
  labelRender: (item: T) => string;
  renderSubtitle?(item: T): string;
  disabled?: boolean;
  onChange?(val: T[]): void;
  onEdit?(val: T, readonly?: boolean): void;
  onAdd?(): void;
  hasAdd?: boolean;
  onRemove?(val: T): void;
  onDetailInfo?(val: T): void;
  texts: {
    addBtn?: string;
    removeModal: {
      title: string;
    };
  };
}
export const ListPicker = function <T>({
  disabled,
  hasAdd = true,
  initValues = [],
  labelRender,
  onAdd,
  onChange,
  onDetailInfo,
  onEdit,
  onRemove,
  renderSubtitle,
  texts: { addBtn: addBtnText, removeModal: removeModalTexts },
  valueKey,
}: ListPickerProps<T>): JSX.Element {
  const openRemoveModal = useModal('listPickerRemove');

  const handleRemove = (item: T) => {
    openRemoveModal({
      onSubmit() {
        const newValues = initValues.filter(
          (el) => el[valueKey] !== item[valueKey],
        );

        onChange && onChange(newValues);
        onRemove && onRemove(item);
      },
      title: removeModalTexts.title,
    });
  };

  return (
    <div className={styles.listPicker}>
      {initValues.map((el) => (
        <div key={el[valueKey] as string} className={styles.listPicker__item}>
          <div
            className={cx(
              styles.listPicker__wrap,
              onEdit && styles.listPicker__wrap_clickable,
            )}
            onClick={() => onEdit && onEdit(el, disabled)}>
            <div>
              <Typography
                variant="body-16"
                color="on-surface-primary-1"
                className={styles.listPicker__label}>
                {labelRender(el)}
              </Typography>
              {renderSubtitle && (
                <Typography variant="body-12" color="on-surface-primary-2">
                  {renderSubtitle(el)}
                </Typography>
              )}
            </div>
            {onEdit && <ArrowRightIcon color="var(--on-surface-primary-1)" />}
          </div>
          {onDetailInfo && (
            <Button
              className={styles.listPicker__actionBtn}
              icon
              prependIcon={InfoIcon}
              variant="outlined"
              size="large"
              onClick={() => onDetailInfo(el)}
            />
          )}
          <Button
            className={styles.listPicker__actionBtn}
            icon
            disabled={disabled}
            prependIcon={MinusIcon}
            variant="outlined"
            size="large"
            onClick={() => handleRemove(el)}
          />
        </div>
      ))}
      {hasAdd && (
        <div
          className={styles.listPicker__item}
          onClick={() => !disabled && onAdd && onAdd()}>
          <div
            className={cx(
              styles.listPicker__wrap,
              styles.listPicker__wrap_clickable,
              disabled && styles.listPicker__wrap_disabled,
            )}>
            <Typography
              variant="body-16"
              color="on-surface-primary-1"
              className={styles.listPicker__label}>
              {addBtnText}
            </Typography>
            <ArrowBottomIcon color="var(--on-surface-primary-1)" />
          </div>
        </div>
      )}
    </div>
  );
};
