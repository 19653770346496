import { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import {
  ProductSortKeys,
  ProductType,
  SortEnum,
  StatusEnum,
  useEventsListQuery,
} from '@/apolloGenerated';
import { timezone, useOrganizerId } from '@/shared';
import { sortEventsByStartDate } from '@features/Event/libs/utils';
import { Chip, ChipGroup, Maybe } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventPickerProps {
  initValue?: Maybe<string>;

  onChange(value: Maybe<string>): void;

  status?: StatusEnum[];
  hideAll?: boolean;
  autoSetFirst?: boolean;
  chipPosition?: 'start' | 'end';
}

export const EventPicker: FC<EventPickerProps> = ({
  autoSetFirst,
  chipPosition = 'start',
  hideAll,
  initValue,
  onChange,
  status = [StatusEnum.Active, StatusEnum.Archive],
}) => {
  const organizerId = useOrganizerId()!;
  const { data } = useEventsListQuery({
    skip: !organizerId,
    variables: {
      filters: {
        order: { sort: SortEnum.Desc, sortBy: ProductSortKeys.StartedDate },
      },
      params: {
        organizerId,
      },
      status,
    },
  });
  const [value, setValue] = useState<Maybe<string>>(initValue || null);
  const handleChange = (val: Maybe<string>) => {
    setValue(val);
    onChange(val);
  };

  const items = useMemo(() => {
    const chips = sortEventsByStartDate(data?.productsList.rows || []);

    if (chipPosition === 'start' && !hideAll) {
      chips.unshift({ name: 'Все мероприятия' } as ProductType);
    }
    if (chipPosition === 'end' && !hideAll) {
      chips.push({ name: 'Все мероприятия' } as ProductType);
    }

    return chips;
  }, [data]);

  useEffect(() => {
    if (autoSetFirst && !initValue && items.length > 0) {
      handleChange(items[0].uuid);
    }
  }, [items]);

  return (
    <div className={styles.eventPicker}>
      <ChipGroup>
        {items.map((el) =>
          el.uuid ? (
            <Chip
              className={styles.eventPicker__chip}
              key={el.uuid}
              onClick={() => handleChange(el.uuid)}
              active={value === el.uuid}>
              {el.name} ·{' '}
              {moment(el.startedDate).tz(timezone).format('DD.MM.YYYY')}
            </Chip>
          ) : (
            <Chip
              key="all"
              className={styles.eventPicker__chip}
              onClick={() => handleChange(null)}
              active={!value}>
              Все вечеринки
            </Chip>
          ),
        )}
      </ChipGroup>
    </div>
  );
};
