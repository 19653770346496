import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { OrderType } from '@/apolloGenerated';
import { TableCellLabelValue } from '@/shared';
import { RouterName } from '@app/router';
import { getEventName } from '@entities/Event';
import { Typography } from '@letsdance/ui-kit';

export interface OrderEventInfoCellProps {
  order: OrderType;
  showPair?: boolean;
}
export const OrderEventInfoCell: FC<OrderEventInfoCellProps> = ({
  order,
  showPair,
}) => {
  const { urlFor } = useNavigate();
  const pairInfo = order.pairOrder && showPair ? order.pairOrder : null;

  return (
    <TableCellLabelValue
      label={getEventName(order.product.name, order.product.startedDate)}
      value={
        <>
          {order.tariff.name}{' '}
          {pairInfo && (
            <Typography
              tag="span"
              variant="body-12"
              color="on-surface-primary-2"
              className="cursor-pointer"
              onClick={() =>
                window.open(
                  urlFor(RouterName.ContactEdit, {
                    uuid: pairInfo?.user.uuid,
                  }),
                  '_blank',
                )
              }>
              ({pairInfo.user.last_name} {pairInfo.user.first_name})
            </Typography>
          )}
        </>
      }
    />
  );
};
