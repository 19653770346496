import React, { FC, useEffect } from 'react';

import { useCancelManualInvoiceMutation } from '@/apolloGenerated';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface CancelPaymentModalProps {
  uuid: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    cancelPayment: CancelPaymentModalProps;
  }
}
export const CancelPaymentModal: FC<
  WithCloseModal<CancelPaymentModalProps>
> = ({ onClose, onSuccess, uuid }): JSX.Element => {
  const [cancelManualPayment, { client, data, error, loading }] =
    useCancelManualInvoiceMutation();

  const handleSubmit = () => {
    cancelManualPayment({ variables: { uuidPayment: uuid } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Покупка отменена',
      });
      client.cache.evict({ fieldName: 'paymentsList' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка отмены покупки. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Подтвердить отмену покупки?"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button
                fullWidth
                onClick={handleSubmit}
                color="danger"
                disabled={loading}>
                Подтвердить
              </Button>
            </div>
          }
        />
      }
    />
  );
};
