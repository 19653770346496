import { gql } from '@apollo/client';

export const botFragment = gql`
  fragment BotFields on BotType {
    createdAt
    updatedAt
    id
    username
    token
    name
    active
  }
`;

export const botsList = gql`
  ${botFragment}
  query botsList(
    $isActive: Boolean
    $filters: BotListFilterInput
    $params: BotListParamsInput
  ) {
    botsList(filters: $filters, params: $params, isActive: $isActive) {
      rows {
        ...BotFields
        organizers {
          id
        }
        usersCount
      }
      count
    }
  }
`;

export const botsListCount = gql`
  query botsListCount($isActive: Boolean) {
    botsList(
      filters: { pagination: { pageSize: 1, page: 1 } }
      isActive: $isActive
    ) {
      count
    }
  }
`;

export const botsByOrganizer = gql`
  query botsByOrganizer($id: Int!) {
    organizerAdmin(id: $id) {
      bots {
        id
        username
      }
    }
  }
`;

export const botInfoByToken = gql`
  mutation botInfoByToken($token: String!) {
    botInfoByToken(token: $token) {
      id
      username
      token
    }
  }
`;

export const createBot = gql`
  ${botFragment}
  mutation createBot($input: BotInput!) {
    createBot(input: $input) {
      ...BotFields
    }
  }
`;

export const disableBot = gql`
  mutation disableBot($id: Float!) {
    disableBot(id: $id)
  }
`;

export const activateBot = gql`
  mutation activateBot($id: Float!) {
    activateBot(id: $id)
  }
`;

export const removeBot = gql`
  mutation removeBot($id: Float!) {
    removeBot(id: $id)
  }
`;

export const addBotToOrganizer = gql`
  mutation addBotToOrganizer($idOrganizer: Float!, $idBot: Float!) {
    addBotToOrganizer(idOrganizer: $idOrganizer, idBot: $idBot)
  }
`;

export const removeBotFromOrganizer = gql`
  mutation removeBotFromOrganizer($idOrganizer: Float!, $idBot: Float!) {
    removeBotFromOrganizer(idOrganizer: $idOrganizer, idBot: $idBot)
  }
`;
