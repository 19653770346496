import { FC } from 'react';

import { FormSection } from '@/shared';
import {
  ReferralEventCreateButton,
  ReferralEventTable,
} from '@features/Referral';

export interface ReferralEventProps {
  eventUuid: string;
  disabled?: boolean;
}
export const ReferralEvent: FC<ReferralEventProps> = ({
  disabled,
  eventUuid,
}) => (
  <FormSection
    title="Реферальные ссылки"
    rightTopSlot={
      disabled ? <></> : <ReferralEventCreateButton eventUuid={eventUuid} />
    }>
    <ReferralEventTable eventUuid={eventUuid} />
  </FormSection>
);
