import { FC } from 'react';

import { Container } from '@/shared';
import { RouterName } from '@app/router';
import { Button, SearchIcon, Spacer, TextField } from '@letsdance/ui-kit';
import { FinanceLists, FinanceStats } from '@widgets/Finance';
import { Head } from '@widgets/Layout';

export const FinanceView: FC = () => (
  <div>
    <Container>
      <Head
        title="Финансы"
        rightSlot={
          <Button to={RouterName.WithdrawCreate}>Вывод средств</Button>
        }
      />
      <FinanceStats
        filterSlot={
          <TextField
            placeholder="Операция, курс, контрагент"
            prependSlot={<SearchIcon />}
            fullWidth
          />
        }
      />
    </Container>
    <Spacer size={12} />
    <FinanceLists />
  </div>
);
